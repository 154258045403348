'use client';

import { MutableRefObject, ReactNode, Ref } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from './card';

interface Properties {
  children: ReactNode;
  modalRef: MutableRefObject<HTMLDialogElement | undefined>;
  title: string;
}

export default function Modal({ children, modalRef, title }: Properties) {
  return (
    <dialog
      style={{ border: 'none !important' }}
      className="bg-transparent border-none w-[90%] md:w-[32rem] lg:w-[40rem]"
      ref={modalRef as Ref<HTMLDialogElement>}
      onClick={(event) => {
        event.stopPropagation();
        if (modalRef.current) {
          const modal = modalRef.current as HTMLDialogElement;
          const dialogDimensions = modal.getBoundingClientRect();
          if (
            event.clientX < dialogDimensions.left ||
            event.clientX > dialogDimensions.right ||
            event.clientY < dialogDimensions.top ||
            event.clientY > dialogDimensions.bottom
          ) {
            modal.close();
          }
        }
      }}
    >
      <Card className="shadow-md">
        <CardHeader>
          <CardTitle>{title}</CardTitle>
        </CardHeader>
        <CardContent className="flex justify-center">{children}</CardContent>
      </Card>
    </dialog>
  );
}

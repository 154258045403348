import { useEffect, useMemo, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

import { store, useAppSelector } from '../stores/AppStore';
import { selectHasOneOfTheRoles } from '../features/user/userSlice';
import {
  completeReview,
  goToProblem,
  loadNextProblem,
  loadPreviousProblem,
  selectCurrentProblem,
  selectCurrentProblemIndex,
  selectCurrentSolvedProblem,
  selectIsCompleted,
  selectIsLastProblem,
  selectIsPractice,
  selectIsUnderReview,
  setVideoWatchedForPastProblems,
} from '../features/quiz/quizSlice';
import {
  selectAnyReviewRequestPending,
  useGetQuizDetailsQuery,
  useGetResultQuery,
  useGetSkillResultsForResultIdQuery,
  useGetSkillsForProblemsQuery,
  useGetSubjectResultsForResultIdQuery,
  useGetTutorialsForProblemsQuery,
  useUpdateReviewProgressMutation,
} from '../services/apiSlice';
import useSkillCloudData from '../hooks/useSkillCloudData';
import { ROLES } from '../constants';

import Problem from '../components/Problem';
import { LoadingBlock } from '../components/Loading';
import useBlockReviewNavigation from '../hooks/useBlockReviewNavigation';
import QuizResult from '../partials/QuizResult';

export default function Review() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(true);
  const [actionButtonText, setActionButtonText] = useState('');
  const currentQuiz = useAppSelector((state) => state.quiz.selectedQuiz);
  const currentProblem = useAppSelector(selectCurrentProblem);
  const currentProblemIndex = useAppSelector(selectCurrentProblemIndex);
  const currentSolvedProblem = useAppSelector(selectCurrentSolvedProblem);
  const isAnyRequestsPending = useAppSelector(selectAnyReviewRequestPending);
  const isCompleted = useAppSelector(selectIsCompleted);
  const isLastProblem = useAppSelector(selectIsLastProblem);
  const isPractice = useAppSelector(selectIsPractice);
  const isStudent = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.student]),
  );
  const isUnderReview = useAppSelector(selectIsUnderReview);
  const resultId = useAppSelector((state) => state.quiz.resultId);
  const resultStats = useAppSelector((state) => state.quiz.resultStats);
  const solvedProblems = useAppSelector((state) => state.quiz.solvedProblems);
  const trackSerialNumber = useAppSelector(
    (state) => state.quiz.trackSerialNum,
  );
  const tutorials = useAppSelector((state) => state.quiz.tutorials);
  const { user } = useAppSelector((state) => state.user);
  const lastQuiz =
    user?.reviewedQuizzes &&
    user?.reviewedQuizzes[user?.reviewedQuizzes.length - 1];
  if (!isPractice && isUnderReview && solvedProblems) {
    store.dispatch(
      setVideoWatchedForPastProblems({ index: lastQuiz?.progress?.num }),
    );
  }
  const canProceed =
    isPractice ||
    currentSolvedProblem?.isStudentCorrect ||
    currentSolvedProblem?.isVideoWatched;

  const [updateReviewProgress] = useUpdateReviewProgressMutation();
  const { isFetching: isFetchingResult } = useGetResultQuery(
    { quizId: lastQuiz?.id ?? '' },
    { skip: isPractice || !lastQuiz?.id, refetchOnMountOrArgChange: true },
  );
  const { isFetching: isFetchingQuizDetails } = useGetQuizDetailsQuery(
    {
      quizId: lastQuiz?.id ?? '',
      trackSerialNum: isStudent
        ? trackSerialNumber
        : user?.followsTracks?.[0]?.serialNum,
    },
    {
      skip: isPractice || !lastQuiz?.id || (isStudent && !trackSerialNumber),
      refetchOnMountOrArgChange: true,
    },
  );

  useGetTutorialsForProblemsQuery(
    { problemNums: currentQuiz?.problems.map((p) => p.id) ?? [] },
    {
      skip: !currentQuiz?.problems || currentQuiz.problems.length === 0,
      refetchOnMountOrArgChange: true,
    },
  );

  useGetSubjectResultsForResultIdQuery(
    { resultId: resultId ?? '' },
    { skip: isPractice || !resultId, refetchOnMountOrArgChange: true },
  );

  useGetSkillResultsForResultIdQuery(
    { resultId: resultId ?? '' },
    { skip: isPractice || !resultId, refetchOnMountOrArgChange: true },
  );
  const { data: problemSkills } = useGetSkillsForProblemsQuery(
    { problems: currentQuiz?.problems.map((p) => p._id ?? '') ?? [] },
    { skip: !isUnderReview || !currentQuiz, refetchOnMountOrArgChange: true },
  );
  const currentProblemSkills = problemSkills?.find(
    (p) => p._id === currentProblem?._id || p.problemId === currentProblem?.id,
  );

  const skillsData = useMemo(
    () =>
      isPractice
        ? {
            skills: problemSkills?.flatMap((p) => p.skills),
            results: resultStats.skills,
          }
        : {
            skills: resultStats?.skills?.map((s) => s.skill),
            results: resultStats.skills,
          },
    [problemSkills, resultStats, isPractice],
  );
  const skillCloudData = useSkillCloudData(skillsData);

  const finishReview = () => {
    try {
      store.dispatch(completeReview());
      if (
        !isPractice &&
        currentSolvedProblem?.isStudentCorrect &&
        currentProblem &&
        currentQuiz &&
        currentProblemIndex &&
        Number.isFinite(currentProblemIndex) &&
        lastQuiz?.progress &&
        currentProblemIndex > lastQuiz.progress.num
      ) {
        updateReviewProgress({
          problemId: currentProblem.id,
          problemNum: currentProblemIndex,
          quizId: currentQuiz.id,
          didFinishReview: isLastProblem,
        });
      }
      setShowModal(true);
    } catch {}
  };

  useEffect(() => {
    if (isFetchingResult || isFetchingQuizDetails || !currentQuiz) {
      return;
    }
    if (
      !isPractice &&
      solvedProblems &&
      lastQuiz?.progress?.num !== undefined
    ) {
      store.dispatch(goToProblem(lastQuiz.progress.num + 1));
    } else {
      store.dispatch(goToProblem(0));
    }
  }, [isFetchingResult, isFetchingQuizDetails, currentQuiz]);

  useEffect(() => {
    const buttonText = isCompleted
      ? 'Go to dashboard'
      : isPractice
      ? 'Begin the solution review'
      : solvedProblems && lastQuiz?.progress?.num !== undefined
      ? `Continue the solution review (${lastQuiz.progress.num + 1} / ${
          solvedProblems.length
        })`
      : 'Begin the solution review';
    setActionButtonText(() => buttonText);
    if (!isCompleted && !isPractice && showModal) {
      toast(
        `Note: Click on the "${buttonText}" button to proceed with the solution review`,
        {
          id: 'button-click',
        },
      );
    }
  }, [isCompleted, isPractice, solvedProblems, lastQuiz]);

  useBlockReviewNavigation();

  useEffect(() => {
    if (!currentQuiz?.id) {
      return;
    }
    navigate(
      `/review/${currentQuiz?.trackSerialNum}/${currentQuiz?.id}/${currentProblem?.id}`,
    );
  }, [currentProblemIndex]);

  if (
    !currentQuiz ||
    !currentProblem ||
    currentProblemIndex === undefined ||
    skillCloudData === undefined ||
    tutorials === undefined ||
    resultStats === undefined ||
    isAnyRequestsPending
  ) {
    return <LoadingBlock />;
  }

  return (
    <div className="grid grid-cols-4 gap-6 px-3 md:px-6 pb-6 pt-3 rounded-xl bg-gray-100">
      <h1 className="col-span-4 text-3xl md:text-4xl font-medium w-full my-4">
        Solution Review of {currentQuiz.id}
        {isPractice ? ' - Practice Mode' : ''}
      </h1>
      <div
        className={`${
          showModal ? 'hidden' : 'grid'
        } col-span-4 justify-items-center items-center text-center pt-3 gap-4 px-3`}
      >
        {!showModal &&
          (currentSolvedProblem?.isStudentCorrect ? (
            <div data-testid="success" className="flex flex-col">
              <p className="self-center mb-4 text-xl md:text-3xl uppercase font-bold text-emerald-500 border-emerald-500 border-4 p-3 -rotate-6 rounded-xl w-fit">
                You nailed it!
              </p>
              <p className="text-sm md:text-base text-tttDefault font-medium">
                But watch the optional video to think even more clearly!
              </p>
            </div>
          ) : (
            <div data-testid="fail" className="flex flex-col">
              <p className="self-center mb-4 text-xl md:text-3xl uppercase font-bold text-rose-500 border-rose-500 border-4 p-3 rotate-6 rounded-xl w-fit">
                Try Again!
              </p>
              <p className="text-sm md:text-base text-tttDefault font-medium">
                Watch the video and think again!
              </p>
            </div>
          ))}
      </div>
      <div className="grid col-span-4 md:px-6">
        {showModal ? (
          <QuizResult
            actionButtonText={actionButtonText}
            setShowModal={setShowModal}
          />
        ) : (
          isUnderReview && (
            <div className="grid grid-rows-1 grid-cols-[auto_1fr_auto] w-full col-span-4 items-start gap-3">
              <div className="grid justify-items-start col-start-1 h-full row-start-1 row-span-2">
                <div
                  data-testid="displayed-problem-number"
                  className="row-start-1 col-start-1 self-start uppercase text-white text-lg font-bold px-2 rounded-full bg-tttDefault"
                >
                  {currentProblemIndex + 1}
                </div>
                {currentProblemIndex > 0 && (
                  <ChevronLeftIcon
                    data-testid={'previous-button'}
                    className="row-start-1 col-start-1 self-center h-6 md:h-8 w-6 md:w-8 text-tttDefault cursor-pointer transition ease-in-out duration-300 md:hover:scale-125"
                    onClick={() => store.dispatch(loadPreviousProblem())}
                  />
                )}
              </div>
              <div className="grid col-start-2 justify-items-start self-start overflow-hidden w-full">
                <Problem
                  currentProblem={currentProblem}
                  currentProblemSkills={currentProblemSkills?.skills ?? []}
                  solvedProblem={currentSolvedProblem}
                />
              </div>
              <div className="grid col-start-3 cursor-pointer justify-items-end self-center row-start-1 row-span-2">
                {isLastProblem ? (
                  <div
                    data-testid={'next-button'}
                    className={`${
                      canProceed
                        ? 'text-tttDefault transition ease-in-out duration-300 md:hover:scale-125'
                        : 'text-slate-400'
                    } font-medium md:my-3`}
                    onClick={finishReview}
                  >
                    Finish
                  </div>
                ) : (
                  <ChevronRightIcon
                    data-testid={'next-button'}
                    className={`${
                      canProceed
                        ? 'text-tttDefault transition ease-in-out duration-300 md:hover:scale-125'
                        : 'text-slate-400'
                    } h-6 md:h-8 w-6 md:w-8 md:my-3`}
                    onClick={() => {
                      store.dispatch(loadNextProblem());
                      if (
                        !isPractice &&
                        currentSolvedProblem?.isStudentCorrect &&
                        (!lastQuiz?.progress?.num ||
                          (lastQuiz?.progress?.num &&
                            lastQuiz.progress.num < currentProblemIndex))
                      ) {
                        updateReviewProgress({
                          problemId: currentProblem.id,
                          problemNum: currentProblemIndex,
                          quizId: currentQuiz.id,
                          didFinishReview: isLastProblem,
                        });
                      }
                    }}
                  />
                )}
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}

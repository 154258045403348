import { FilterType } from '../constants';
import SubjectGeometrySvg from './svg/SubjectGeometry';
import SubjectAlgebraSvg from './svg/SubjectAlgebra';
import SubjectPreAlgebraSvg from './svg/SubjectPreAlgebra';
import SubjectStatisticsSvg from './svg/SubjectStatistics';

export type FilterValue<T = any> = {
  label: string;
  value: T;
  icon?: string;
  subLabel?: string;
};
interface FilterProperties<T> {
  options: FilterValue[];
  filterType: FilterType;
  onSelect: (value: FilterValue<T>) => void;
  onHover?: (value: FilterValue<T>) => void;
  selected?: FilterValue<T>;
  isStretched?: boolean;
}

export default function Filter<T = any>({
  options,
  filterType,
  onSelect,
  onHover,
  selected,
  isStretched,
}: FilterProperties<T>) {
  const isSelected = (option: FilterValue) =>
    selected?.value === option.value ? true : false;

  const icons: Record<string, JSX.Element> = {
    'subject-geometry': <SubjectGeometrySvg />,
    'subject-algebra': <SubjectAlgebraSvg />,
    'subject-pre-algebra': <SubjectPreAlgebraSvg />,
    'subject-statistics': <SubjectStatisticsSvg />,
  };

  return (
    <div
      data-testid={`filter-${filterType}`}
      className={`flex sm:flex-wrap ${
        isStretched ? 'sm:w-full' : ''
      } gap-2 cursor-pointer capitalize max-h-[250px] overflow-y-auto`}
    >
      {options.map((option) => (
        <div
          key={JSON.stringify(option.label)}
          data-testid={`filterOption-${option.label}`}
          className={`rounded-xl py-3 pl-4 ${
            isStretched ? 'pr-12 sm:flex-1' : 'pr-4'
          } ${
            option.icon ? 'xl:pr-16' : ''
          } whitespace-nowrap cursor-pointer transition-all duration-100 ease-in-out ${
            isSelected(option)
              ? 'bg-tttDefault text-white'
              : 'bg-white text-black hover:bg-gray-200 hover:text-black'
          }`}
          onClick={() => onSelect(option)}
          onMouseOver={
            typeof onHover === 'function' ? () => onHover(option) : undefined
          }
        >
          <div className="flex items-center">
            {option.icon && (
              <div
                className="w-5 h-5 mr-2"
                style={{
                  color: isSelected(option) ? 'white' : 'black',
                }}
              >
                {icons[option.icon]}
              </div>
            )}
            <div
              className={`text-left ${
                option.icon ? 'p-1 pl-2 xl:p-2 xl:pl-4' : ''
              }`}
            >
              <div>{option.label}</div>
              <div className="text-xs">{option.subLabel}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { FromSchema } from 'json-schema-to-ts';

import {
  AppState,
  ImportDocument,
  ImportState,
  ImportStats,
  importStateSchema,
} from '../../types';
import { getPersistedState } from '../../services/LocalStorageService';
import { ImportStatus, defaultImportYear } from '../../constants';
import { RootState } from '../../stores/AppStore';

const importInitialState: ImportState = {
  uploadPercentage: 0,
  importYear: defaultImportYear,
  details: {
    _id: '',
    status: ImportStatus.init,
    progress: 0,
    stats: {
      classes: 0,
      problems: 0,
      quizschedules: 0,
      skills: 0,
      tracks: 0,
      tutorials: 0,
      users: 0,
    } as ImportStats,
    warnings: [] as string[],
    importErrors: [] as string[],
  },
};

const globalStateSchema = {
  type: 'object',
  properties: {
    appState: { enum: Object.values(AppState) },
    clientVersion: { type: 'string' },
    importState: importStateSchema,
    metrics: {
      type: 'object',
      properties: {
        welcomeVideoWatchedPct: { type: 'number' },
      },
      required: ['welcomeVideoWatchedPct'],
    },
    settings: {
      type: 'object',
      properties: {
        show: {
          type: 'object',
          properties: {
            welcomeVideoPopup: { type: 'boolean' },
          },
          required: ['welcomeVideoPopup'],
        },
      },
      required: ['show'],
    },
  },
  required: ['appState', 'importState', 'metrics', 'settings'],
  additionalProperties: false,
} as const;

type GlobalState = FromSchema<typeof globalStateSchema>;

const initialState = getPersistedState<GlobalState>(
  'global',
  globalStateSchema,
) ?? {
  appState: AppState.init,
  clientVersion: undefined,
  importState: importInitialState,
  metrics: {
    welcomeVideoWatchedPct: 0,
  },
  settings: {
    show: {
      welcomeVideoPopup: true,
    },
  },
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setAppState: (state, action: PayloadAction<AppState>) => {
      state.appState = action.payload;
    },
    setClientVersion: (state, action: PayloadAction<string>) => {
      state.clientVersion = action.payload;
    },
    resetImportState: (state) => {
      state.importState = importInitialState;
    },
    setImportDetails: (state, action: PayloadAction<ImportDocument>) => {
      state.importState.details = action.payload;
    },
    setImportState: (state, action: PayloadAction<ImportState>) => {
      state.importState = action.payload;
    },
    setImportUploadPercentage: (
      state,
      action: PayloadAction<number | string>,
    ) => {
      state.importState.uploadPercentage = Number.parseInt(
        action.payload as string,
      );
    },
    setImportStatus: (state, action: PayloadAction<ImportStatus>) => {
      state.importState.details.status = action.payload;
      if (action.payload === ImportStatus.failed) {
        state.importState.uploadPercentage = 0;
      }
    },
    setImportYear: (state, action: PayloadAction<string>) => {
      state.importState.importYear = action.payload;
    },
    setWelcomeVideoWatchedPct: (state, action: PayloadAction<number>) => {
      state.metrics.welcomeVideoWatchedPct = action.payload;
      // TODO: update API: useUpdateUserMetricsSettingsMutation
    },
    setShowWelcomeVideoPopup: (state, action: PayloadAction<boolean>) => {
      state.settings.show.welcomeVideoPopup = action.payload;
      // TODO: update API: useUpdateUserMetricsSettingsMutation
    },
  },
});

export const selectWelcomeVideoWatchedPct = (state: RootState) =>
  state.global.metrics.welcomeVideoWatchedPct;

export const selectShowWelcomeVideoPopup = (state: RootState) =>
  state.global.settings.show.welcomeVideoPopup;

export const {
  resetImportState,
  setAppState,
  setClientVersion,
  setImportDetails,
  setImportState,
  setImportUploadPercentage,
  setImportStatus,
  setImportYear,
  setWelcomeVideoWatchedPct,
  setShowWelcomeVideoPopup,
} = globalSlice.actions;

export default globalSlice.reducer;

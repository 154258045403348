import { Link } from 'react-router-dom';

import { CompletedQuiz, StudentTrackWithSchedule } from '../../types';
import { downloadWorkbook, getColor } from '../../util';
import { PiDownloadSimpleLight } from 'react-icons/pi';

interface CompletedAssignmentProperties {
  assignment: CompletedQuiz;
  track?: StudentTrackWithSchedule;
  canDownloadWorkbook?: boolean;
  onClickViewDetails: (quiz: string) => void;
}

const CompletedAssignment = ({
  assignment,
  track,
  canDownloadWorkbook = false,
  onClickViewDetails,
}: CompletedAssignmentProperties) => {
  const handleDownloadWorkbook = () => {
    if (!canDownloadWorkbook || !track || !assignment.workbookUrl) {
      return;
    }
    downloadWorkbook({
      quizId: assignment.id,
      url: assignment.workbookUrl,
    });
  };

  return (
    <div
      data-testid={`completed-assignment-${assignment.id}`}
      className={`flex flex-col md:flex-row flex-wrap gap-4 md:gap-x-14 bg-white rounded-xl border border-1-gray p-4 ${
        assignment.isReview && 'border border-tttDefault'
      }`}
    >
      <div className="flex-grow flex gap-3 items-center justify-between">
        <p className="font-medium whitespace-nowrap">{assignment.id}</p>
        <div className="flex gap-2 items-center">
          <p
            className={`rounded-full bg-${getColor(
              assignment.result * 100,
            )} text-xs md:text-sm py-1 px-2 h-max whitespace-nowrap`}
          >
            Score: {assignment.result * 100} %
          </p>
        </div>
      </div>
      <div className="flex gap-3">
        <Link
          to={track ? `/quiz/${track.serialNum}/${assignment.id}/practice` : ''}
          className="
            px-4 py-1.5 w-max h-max md:my-auto
            rounded-full border border-tttDefault
            text-sm md:text-base
            font-medium text-tttDefault whitespace-nowrap
            transition-all duration-100 ease-in-out
            hover:shadow hover:bg-tttDefault hover:text-white
          "
        >
          Practice
        </Link>
        <button
          onClick={() => onClickViewDetails(assignment.id)}
          className="
            px-4 py-1.5 w-max h-max md:my-auto
            rounded-full border border-tttDefault
            text-sm md:text-base
            font-medium text-tttDefault whitespace-nowrap
            transition-all duration-100 ease-in-out
            hover:shadow hover:bg-tttDefault hover:text-white
          "
        >
          View Details
        </button>
      </div>
      {canDownloadWorkbook && (
        <div
          onClick={handleDownloadWorkbook}
          className="w-full flex gap-2 text-tttDefault items-center whitespace-nowrap hover:underline cursor-pointer"
        >
          <PiDownloadSimpleLight className="w-5 h-5" />
          Download Workbook
        </div>
      )}
    </div>
  );
};

export default CompletedAssignment;

import { useState } from 'react';
import { XIcon } from '@heroicons/react/outline';
import toast from 'react-hot-toast';
import {
  BadgeCheckIcon,
  TrashIcon,
  UsersIcon,
  PencilAltIcon,
} from '@heroicons/react/outline';
import dayjs from 'dayjs';
import * as Sentry from '@sentry/react';

import { ROLES, FeatureFlag } from '../constants';
import { UsersListItem } from '../types';
import {
  useDeleteUserResultMutation,
  usePushUserThroughReviewMutation,
  useSwitchUserMutation,
  useToggleFeatureForUserMutation,
  useUpdatePasswordForUserMutation,
} from '../services/apiSlice';
import Button from '../components/Button';
import { useNavigate } from 'react-router-dom';
import Input from '../components/Input';
import SwitchButton from '../components/Switch';

interface Properties {
  user: UsersListItem;
  setChangedPasswordAt: (date: string) => Promise<void>;
  setToggledFeature: (featureFlags?: string[]) => Promise<void>;
  showUserDetailsModal: (show: boolean) => void;
}

export default function UserDetailsModal({
  user,
  setChangedPasswordAt,
  setToggledFeature,
  showUserDetailsModal,
}: Properties) {
  const navigate = useNavigate();
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [password, setPassword] = useState<string>();
  const [switchUser, { isLoading: isSwitchingUser }] = useSwitchUserMutation();
  const [pushUserThroughReview, { isLoading: isPushingThrough }] =
    usePushUserThroughReviewMutation();
  const [deleteUserResult, { isLoading: isDeletingResult }] =
    useDeleteUserResultMutation();
  const [updatePassword, { isLoading }] = useUpdatePasswordForUserMutation();
  const [toggleFeature, { isLoading: isTogglingFeature }] =
    useToggleFeatureForUserMutation();

  if (!user) {
    return <></>;
  }

  const fullName = `${user.firstName} ${user.lastName}`;

  const handleRemoveResult = async (student?: UsersListItem) => {
    if (!student || !student.lastSubmittedAvailableQuiz) {
      return;
    }
    const commonText = `${
      user.roles.includes(ROLES.student) ? 'current' : 'last'
    } (${student.lastSubmittedAvailableQuiz}) result of ${fullName}`;
    const confirmed = window.confirm(
      `Are you sure you want to remove the ${commonText}? This action cannot be undone.`,
    );

    if (confirmed) {
      try {
        await deleteUserResult({
          userId: student._id,
          quizId: student.lastSubmittedAvailableQuiz,
        }).unwrap();
      } catch {
        toast.error(
          `Some unexpected error happened while trying to delete the ${commonText}. Please try again later!`,
        );
        return;
      }
      toast.success(`The ${commonText} was deleted successfully.`);
      showUserDetailsModal(false);
    }
  };

  const handlePasswordChange = async () => {
    if (!password || password.trim().length < 8) {
      toast.error('Password must be at least 8 characters long');
      return;
    }

    if (
      window.confirm(
        "Are you sure you'd like to change the password of this user? Please make sure you notify the user about the new password!",
      )
    ) {
      try {
        const updatedUser = await updatePassword({
          userId: user._id,
          password,
        }).unwrap();
        if (updatedUser?.changedPasswordAt) {
          await setChangedPasswordAt(updatedUser.changedPasswordAt);
        }
        toast.success('Password successfully changed!');
        setIsChangingPassword(false);
        setPassword(undefined);
      } catch (error) {
        Sentry.captureException(error, {
          tags: {
            request: 'ownerUpdatePasswordForUser',
          },
        });
        toast.error(
          'Some unexpected error happened while trying to update the password. Please try again later!',
        );
        return;
      }
    }
  };

  const handlePushThroughReview = async () => {
    if (!user) {
      return;
    }
    try {
      const sure = window.confirm(
        "Are you sure you'd like to push this user through the current solution review?",
      );
      if (!sure) {
        return;
      }
      await pushUserThroughReview(user._id).unwrap();
    } catch (error: any) {
      let errorText = '';
      if (error?.status === 400 || error?.status === 403) {
        errorText += error?.data?.message;
      }
      errorText
        ? toast.error(errorText)
        : toast.error(
            `Some unexpected error happened while trying to push ${user?._id} through the current solution review. Please try again later!`,
          );
      return;
    }
    showUserDetailsModal(false);
    toast.success(
      `Successfully pushed user ${user?._id} through the current solution review.`,
    );
  };

  const handleToggleFeature = async (feature: string, enabled: boolean) => {
    if (!user) {
      return;
    }
    try {
      const updatedUser = await toggleFeature({
        userId: user._id,
        feature,
        enabled,
      }).unwrap();
      await setToggledFeature(updatedUser.featureFlags);
      toast.success(
        `Feature ${feature.split('-').join(' ')} was successfully ${
          enabled ? 'enabled' : 'disabled'
        } for user ${fullName}.`,
      );
    } catch {
      toast.error(
        `Some unexpected error happened while trying to toggle the feature ${feature
          .split('-')
          .join(' ')} for user ${fullName}. Please try again later!`,
      );
      return;
    }
  };

  return (
    <div className="z-20">
      <div
        className="absolute grid justify-items-end w-full pr-8 cursor-pointer"
        onClick={() => showUserDetailsModal(false)}
      >
        <XIcon className="h-5 w-5" />
      </div>
      <div className="text-sm text-slate-500 w-[80%] break-words max-w-[400px] p-3">
        By signing in as {fullName}, you will be logged out from your account,
        and logged in to {user.firstName}'s account. All your actions will count
        as the actions of this user. Remember to log out when you finish. Do you
        want to proceed?
      </div>
      <div className="grid grid-flow-row bg-gray-100 rounded-xl p-3">
        <div className="grid grid-flow-row">
          <div className="font-medium mb-1 select-all">{fullName}</div>
          <div className="grid grid-flow-row items-center divide-y">
            <div className="text-sm text-slate-400 pb-3 select-all">
              {user._id}
            </div>
            <div className="grid grid-flow-col py-1">
              <div>Email: </div>
              <div className="grid justify-self-end text-right select-all">
                {user.email}
              </div>
            </div>
            {user.school && (
              <div className="grid grid-flow-col py-1">
                <div>School: </div>
                <div className="grid justify-self-end text-right">
                  {user.school}
                </div>
              </div>
            )}
            {user.roles.includes(ROLES.student) && (
              <>
                <div className="grid grid-flow-col py-1">
                  <div>Class: </div>
                  <div className="grid justify-self-end text-right">
                    {user.class}
                  </div>
                </div>
                <div className="grid grid-flow-col py-1">
                  <div>Student ID: </div>
                  <div className="grid justify-self-end text-right">
                    {user.studentId}
                  </div>
                </div>
              </>
            )}{' '}
            {user.roles.includes(ROLES.thinker) && (
              <>
                <div className="grid grid-flow-col py-1">
                  <div>Track: </div>
                  <div className="grid justify-self-end text-right">
                    {user.followsTracks}
                  </div>
                </div>
                <div className="grid grid-flow-col py-1">
                  <div>Schedule frequency: </div>
                  <div className="grid justify-self-end text-right">
                    {user.scheduleFrequency} per week
                  </div>
                </div>
                <div className="grid grid-flow-col py-1">
                  <div>Mentor: </div>
                  <div className="grid justify-self-end text-right">
                    {user.mentor}
                  </div>
                </div>
              </>
            )}
            <div className="grid grid-flow-col py-1">
              <div>Roles: </div>
              <div className="grid justify-self-end text-right">
                {user?.roles}
              </div>
            </div>
            {user?.lastQuiz && (
              <div className="grid grid-flow-col py-1">
                <div>{user.lastQuiz?.id} review:</div>
                <div className="grid justify-self-end">
                  {user?.lastQuiz?.didFinishReview
                    ? '✔'
                    : typeof user?.lastQuiz?.progress?.num === 'number'
                    ? `✘ (Problem ${user?.lastQuiz?.progress?.num + 1} done)`
                    : '✘'}
                </div>
              </div>
            )}
            <div className="grid grid-flow-col py-1">
              {isChangingPassword ? (
                <div className="grid grid-cols-1 lg:grid-cols-2 items-center">
                  <div className="-mb-4">
                    <Input
                      name="password"
                      placeholder="New password"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        setPassword(event.target.value);
                      }}
                    />
                  </div>
                  <div className="flex">
                    <Button
                      bgColor={'bg-blue-300'}
                      width="w-full"
                      textSize="text-sm"
                      action={handlePasswordChange}
                      disabled={isLoading}
                    >
                      Change
                    </Button>
                    <Button
                      bgColor={'bg-blue-300'}
                      width="w-full"
                      textSize="text-sm"
                      buttonText="Cancel"
                      action={() => setIsChangingPassword(false)}
                      disabled={isLoading}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div>
                    Last password change:{' '}
                    {user?.changedPasswordAt
                      ? dayjs(user?.changedPasswordAt).format(
                          'MM/DD/YYYY HH:mm',
                        )
                      : 'never'}
                  </div>
                  <div
                    className="self-center justify-self-end h-5 w-5 text-tttDefault hover:text-orange cursor-pointer"
                    title="Change password of this user"
                    onClick={() => setIsChangingPassword(true)}
                  >
                    <PencilAltIcon className="h-5 w-5" />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {/* Features */}
        <div className="grid grid-flow-row mt-4">
          <div className="font-medium mb-1 select-all">Features</div>
          {Object.values(FeatureFlag).map((feature) => (
            <div key={feature} className="grid grid-flow-col py-1">
              <div className="capitalize">{feature.split('-').join(' ')}</div>
              <div className="grid justify-self-end text-right">
                <SwitchButton
                  id={`${feature}-toggle`}
                  checked={user.featureFlags?.includes(feature) || false}
                  loading={isTogglingFeature}
                  shouldHaveText={false}
                  onChange={(enabled: boolean) =>
                    handleToggleFeature(feature, enabled)
                  }
                />
              </div>
            </div>
          ))}
        </div>

        {/* Actions */}
        <div className="grid grid-flow-row justify-items-center items-center pt-6">
          <Button
            action={async () => {
              if (user) {
                navigate(
                  `/system-logs/${user._id}/${user.firstName} ${user.lastName}`,
                );
              }
            }}
            bgColor={'bg-blue-300'}
            bgColorHover={'bg-blue-400'}
          >
            <span>
              <UsersIcon className="h-4 w-4 mr-2 mt-[-4px] inline" />
              Show logs
            </span>
          </Button>
          <Button
            action={async () => {
              if (user) {
                await switchUser(user?._id);
              }
            }}
            bgColor={'bg-blue-300'}
            bgColorHover={'bg-blue-400'}
            disabled={isSwitchingUser}
          >
            <span>
              <UsersIcon className="h-4 w-4 mr-2 mt-[-4px] inline" />
              Switch to user
            </span>
          </Button>
          {user.lastQuiz?.didFinishReview === false && (
            <Button
              action={handlePushThroughReview}
              bgColor={'bg-blue-300'}
              bgColorHover={'bg-blue-400'}
              disabled={isPushingThrough}
            >
              <span>
                <BadgeCheckIcon className="h-4 w-4 mr-2 mt-[-4px] inline" />
                Push through the solution review
              </span>
            </Button>
          )}
          {(user.roles.includes(ROLES.student) ||
            user.roles.includes(ROLES.thinker)) && (
            <Button
              action={() => handleRemoveResult(user)}
              bgColor={'bg-blue-300'}
              bgColorHover={'bg-blue-400'}
              disabled={!user.lastSubmittedAvailableQuiz || isDeletingResult}
              disabledProgress={
                user.lastSubmittedAvailableQuiz
                  ? undefined
                  : 'No current result to delete'
              }
            >
              <span>
                <TrashIcon className="h-4 w-4 mr-2 mt-[-4px] inline" />
                Remove {user.roles.includes(ROLES.student)
                  ? 'current'
                  : 'last'}{' '}
                ({user.lastSubmittedAvailableQuiz}) result
              </span>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

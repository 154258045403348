import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';

import { ROLES } from '../constants';
import { useGetIncompleteResultQuery } from '../services/apiSlice';
import { selectHasOneOfTheRoles } from '../features/user/userSlice';
import { store, useAppSelector } from '../stores/AppStore';
import { resetQuizState } from '../features/quiz/quizSlice';

const useIncompleteQuizRedirect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const solvedProblems = useAppSelector((state) => state.quiz.solvedProblems);
  const isStudentOrThinker = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.student, ROLES.thinker]),
  );
  const {
    data: incompleteStudentResult,
    isFetching: isFetchingIncompleteStudentResult,
  } = useGetIncompleteResultQuery(undefined, {
    skip: !isStudentOrThinker,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (!isFetchingIncompleteStudentResult && incompleteStudentResult) {
      const trackSerialNumber = incompleteStudentResult.track.serialNum;
      const quizId = incompleteStudentResult.quiz.id;
      const pathname = location.pathname;

      if (
        !/review/.test(pathname) &&
        !/quiz/.test(pathname) &&
        !/help/.test(pathname) &&
        !/login/.test(pathname) &&
        !/change-password/.test(pathname)
      ) {
        toast(
          "You have an incomplete quiz. You've been redirected to the quiz!",
          { id: 'incomplete-quiz' },
        );

        // show quiz init screen after redirect if no answers submitted yet
        if (solvedProblems?.every((p) => p.givenAnswerKey === undefined)) {
          store.dispatch(resetQuizState());
        }

        navigate(`/quiz/${trackSerialNumber}/${quizId}/solve`, {
          state: { isIncompleteResult: true },
        });
      }
    }
  }, [
    isFetchingIncompleteStudentResult,
    incompleteStudentResult,
    location.pathname,
  ]);
};

export default useIncompleteQuizRedirect;

import { useEffect, useState } from 'react';

const useScrollToTop = () => {
  const [scrollVisibility, setScrollVisibility] = useState(false);
  useEffect(() => {
    const handleScrollVisibility = () =>
      setScrollVisibility(() => document.body.scrollTop > 300);

    document.body.addEventListener('scroll', handleScrollVisibility);

    return () =>
      document.body.removeEventListener('scroll', handleScrollVisibility);
  }, []);

  return scrollVisibility;
};

export default useScrollToTop;

import * as Sentry from '@sentry/react';
import { type Primitive } from '@sentry/types';
import { toast } from 'react-hot-toast';

export function handleError(
  error: Error | unknown,
  info?: {
    componentStack?: string;
    displayedError?: string;
    tags?: {
      [key: string]: Primitive;
    };
  },
) {
  if (process.env.NODE_ENV === 'development') {
    console.error('Error caught by handleError():', error, info);
  } else {
    Sentry.captureException(error, {
      tags: {
        ...info?.tags,
      },
    });
  }
  if (info?.displayedError) {
    toast.error(info.displayedError);
  }
}

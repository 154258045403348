import { SVGProps } from 'react';

const SubjectStatisticsSvg = (
  properties: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>,
) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 26 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path
      d="M25 21.25H23.75V1C23.75 0.801088 23.671 0.610322 23.5303 0.46967C23.3897 0.329018 23.1989 0.25 23 0.25H16C15.8011 0.25 15.6103 0.329018 15.4697 0.46967C15.329 0.610322 15.25 0.801088 15.25 1V6.25H9C8.80109 6.25 8.61032 6.32902 8.46967 6.46967C8.32902 6.61032 8.25 6.80109 8.25 7V12.25H3C2.80109 12.25 2.61032 12.329 2.46967 12.4697C2.32902 12.6103 2.25 12.8011 2.25 13V21.25H1C0.801088 21.25 0.610322 21.329 0.46967 21.4697C0.329018 21.6103 0.25 21.8011 0.25 22C0.25 22.1989 0.329018 22.3897 0.46967 22.5303C0.610322 22.671 0.801088 22.75 1 22.75H25C25.1989 22.75 25.3897 22.671 25.5303 22.5303C25.671 22.3897 25.75 22.1989 25.75 22C25.75 21.8011 25.671 21.6103 25.5303 21.4697C25.3897 21.329 25.1989 21.25 25 21.25ZM16.75 1.75H22.25V21.25H16.75V1.75ZM9.75 7.75H15.25V21.25H9.75V7.75ZM3.75 13.75H8.25V21.25H3.75V13.75Z"
      fill="currentColor"
    />
  </svg>
);

export default SubjectStatisticsSvg;

import { makeAutoObservable } from 'mobx';
import { createContext, useContext } from 'react';

import { UIStore } from './UIStore';
import { StatsStore } from './StatsStore';

export class RootStore {
  uiStore: UIStore;
  statsStore: StatsStore;

  constructor() {
    makeAutoObservable(this);
    this.uiStore = new UIStore(this);
    this.statsStore = new StatsStore(this);
  }
}

export const rootStore = new RootStore();

const rootStoreContext = createContext({
  rootStore,
});

export const useStores = () => useContext(rootStoreContext);

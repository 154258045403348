import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Collapsible from 'react-collapsible';

import { useStores } from '../stores/RootStore';
import Button from '../components/Button';
import { getTextColor } from '../util';
import { quizscheduleTimeZone } from '../constants';

dayjs.extend(utc);
dayjs.extend(timezone);
const localTZ = dayjs.tz.guess();

export type ActionButton = {
  action?: () => void;
  targetPath?: string;
  bgColor?: string;
  text: string;
};

export type ListData = {
  id?: string;
  quizId?: string;
  subject?: string;
  info?: string;
  link: string;
  result?: number;
  date?: Date;
  actionButtons?: ActionButton[];
  isReview?: boolean;
  isCheckpointTest?: boolean;
  checkPointTestInfo?: string;
  canStart?: boolean;
};

interface Properties {
  title: string;
  data: any;
  collapsible?: boolean;
  selectable?: boolean;
  bgClass?: string;
  selectOnClick?: (quiz: ListData, event: React.MouseEvent) => void;
}

const BaseList: FC<Properties> = ({
  title,
  data,
  collapsible,
  selectable,
  bgClass = 'bg-blue-50',
  selectOnClick,
}) => {
  const {
    rootStore: { uiStore },
  } = useStores();

  const isLastElement = (index: number) => {
    return index === data.length - 1;
  };

  return (
    <div
      className={`flex flex-col overflow-hidden gap-2 px-4 md:px-6 py-4 w-full rounded-xl min-w-[160px] ${bgClass}`}
    >
      <div className="place-items-center font-bold text-center text-base md:text-2xl pb-3">
        {title}
      </div>
      {typeof data === 'string' ? (
        <p className="text-center text-lg md:text-lg lg:text-xl">{data}</p>
      ) : (
        <div className="w-full overflow-x-auto">
          {data?.map((d: ListData, index: number) =>
            collapsible ? (
              <Collapsible
                key={index}
                transitionTime={200}
                trigger={
                  <CollapsedElement
                    key={d.id}
                    title={`${d.quizId}`}
                    titleColor={
                      d.isCheckpointTest ? 'text-tttDefault' : undefined
                    }
                    details={
                      d.info ??
                      (typeof d.result === 'number'
                        ? `${Math.round(Number(d.result) * 100)}%`
                        : '')
                    }
                    detailsColor={
                      d.result ? getTextColor(d.result * 100) : undefined
                    }
                    isReview={d.isReview}
                  />
                }
              >
                <div
                  data-testid={`collapsible-buttons-${d.quizId}`}
                  className="w-full flex flex-row flex-wrap justify-items-center items-center md:mt-0 mb-2"
                >
                  {d.actionButtons?.map((button, index_) => (
                    <Button
                      data-testid={`${button.text}-button`}
                      key={index_}
                      action={button.action}
                      targetPath={button.targetPath}
                      bgColor={button.bgColor ?? 'bg-blue-300'}
                      buttonText={button.text}
                      disabled={uiStore.isRefreshing}
                    />
                  ))}
                  <div className="text-gray-500 ml-2">
                    {d.checkPointTestInfo}
                  </div>
                </div>
              </Collapsible>
            ) : selectable ? (
              <div key={`assignments-${index}`} className="box">
                <NavLink
                  data-testid={`current-assignment-${d.quizId}`}
                  onClick={
                    !d.canStart && selectOnClick
                      ? (event) => selectOnClick(d, event)
                      : undefined
                  }
                  to={d.link}
                  className={`grid items-center overflow-hidden text-base md:text-xl grid-cols-2 grid-rows-1 gap-2 p-2 rounded-lg ${
                    d.canStart
                      ? 'hover:bg-blue-300'
                      : 'cursor-default text-gray-400'
                  }`}
                >
                  <div
                    className={`box justify-self-start ${
                      d.isReview && 'font-bold'
                    }`}
                  >
                    {d.quizId}
                  </div>
                  <div
                    className={`box justify-self-end px-2 font-medium text-end text-sm md:text-base ${
                      d.info
                        ? d.canStart
                          ? `text-gray-600`
                          : 'text-gray-400'
                        : getTextColor(d.result ? d.result * 100 : 0)
                    }`}
                  >
                    {d.info ||
                      (d.quizId
                        ? `${Math.round(d.result ? d.result * 100 : 0)}%`
                        : `${Math.round(d.result ? d.result * 100 : 0)}%`)}
                  </div>
                </NavLink>
              </div>
            ) : (
              <div
                data-testid={`item-${d.quizId ?? d.subject ?? d}`}
                key={`item-${index}`}
                className="grid grid-cols-[auto_1fr] items-center overflow-hidden text-base md:text-l grid-rows-1 gap-2 p-0"
              >
                <div
                  className={`box justify-self-start ${
                    !isLastElement(index) && `mb-2 md:mb-3`
                  } ${d.isReview && 'font-bold'}`}
                >
                  {typeof d === 'string' && d}
                  {d.quizId ? `${d.quizId}` : d.subject}
                  {d.date && (
                    <span className="text-sm text-gray-600 hover:text-black">
                      {' '}
                      (
                      {dayjs
                        .tz(d.date, quizscheduleTimeZone)
                        .tz(localTZ)
                        .from(dayjs().tz(localTZ))}
                      )
                    </span>
                  )}
                </div>
                <div
                  className={`box justify-self-end pl-2 px-2 font-bold ${getTextColor(
                    d.result ? d.result * 100 : 0,
                  )}`}
                >
                  {typeof d !== 'string' &&
                    (d.quizId
                      ? `${Math.round(d.result ? d.result * 100 : 0)}%`
                      : d.result === null
                      ? 'N/A'
                      : `${Math.round(d.result ? d.result * 100 : 0)}%`)}
                </div>
              </div>
            ),
          )}
        </div>
      )}
    </div>
  );
};

interface ElementProperties {
  title: string;
  titleColor?: string;
  details: string;
  detailsColor?: string;
  isReview: boolean | undefined;
}

const CollapsedElement: FC<ElementProperties> = ({
  title,
  titleColor,
  details,
  detailsColor,
  isReview,
}) => {
  return (
    <div className="grid grid-cols-[auto_1fr] items-center overflow-hidden text-base md:text-xl grid-rows-1 gap-2 p-2 hover:bg-blue-300 rounded-lg">
      <div
        className={`box justify-self-start ${
          isReview && 'font-bold'
        } ${titleColor}`}
      >
        {title}
      </div>
      <div
        className={`box justify-self-end px-2 font-medium text-end text-sm md:text-base ${
          detailsColor ?? 'text-gray-600'
        }`}
      >
        {details}
      </div>
    </div>
  );
};

const List = observer(BaseList);
export default List;

export function TermsConditions() {
  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-semibold text-center mb-8">
        Terms & Conditions
      </h1>
      <ol className="list-decimal pl-5">
        <li>
          <p className="mb-4">
            <strong>Introduction</strong> - These Terms and Conditions govern
            the acquisition and use of our software, "Fill the Math GAPS"
            (hereafter referred to as "the Software"), which is intended for use
            exclusively within the United States. By purchasing and using the
            Software, you agree to be bound by these terms, which form a binding
            legal agreement between you and The Think Tank.
          </p>
        </li>
        <li>
          <p className="mb-4">
            <strong>License Grant</strong> - The Think Tank grants you a
            non-exclusive, non-transferable license to use the Software on one
            device owned or controlled by you, for personal, non-commercial
            purposes. This license is effective for 365 days beginning on the
            date of your payment of the purchase price of $149 per license.
          </p>
        </li>
        <li>
          <p className="mb-4">
            <strong>Scope of License</strong> - The license granted hereunder is
            limited to a non-transferable license to use the Software on any
            device that you own or control. This license does not allow the
            Software to be used on any device that you do not own or control,
            and you may not distribute or make the Software available over a
            network where it could be used by multiple devices at the same time.
          </p>
        </li>
        <li>
          <p className="mb-4">
            <strong>Restrictions</strong> - You may not rent, lease, lend, sell,
            redistribute, or sublicense the Software. You may not copy (except
            as expressly permitted by this license and the Usage Rules),
            decompile, reverse-engineer, disassemble, attempt to derive the
            source code of, modify, or create derivative works of the Software,
            any updates, or any part thereof (except as and only to the extent
            any foregoing restriction is prohibited by applicable law or to the
            extent as may be permitted by the licensing terms governing use of
            any open-sourced components included with the Software).
          </p>
        </li>
        <li>
          <p className="mb-4">
            <strong>Intellectual Property</strong> - You acknowledge that no
            title to the intellectual property in the Software is transferred to
            you. Title, ownership, rights, and intellectual property rights in
            and to the Software shall remain with The Think Tank.
          </p>
        </li>
        <li>
          <p className="mb-4">
            <strong>Termination</strong> - The license is effective until
            terminated by you or The Think Tank. Your rights under this license
            will terminate automatically without notice from The Think Tank if
            you fail to comply with any term(s) of this license. Upon
            termination of the license, you shall cease all use of the Software,
            and destroy all copies, full or partial, of the Software.
          </p>
        </li>
        <li>
          <p className="mb-4">
            <strong>Disclaimer of Warranties</strong> - You expressly
            acknowledge and agree that use of the Software is at your sole risk.
            The Software is provided "AS IS" and "AS AVAILABLE" without warranty
            of any kind, and The Think Tank hereby disclaims all warranties and
            conditions with respect to the Software, either express, implied, or
            statutory, including, but not limited to, the implied warranties
            and/or conditions of merchantability, of satisfactory quality, of
            fitness for a particular purpose, of accuracy, of quiet enjoyment,
            and non-infringement of third party rights.
          </p>
        </li>
        <li>
          <p className="mb-4">
            <strong>Communication and Notification</strong> - By providing your
            phone number and email address, you consent to receive
            communications from us, including but not limited to, SMS text
            messages and emails related to your account, updates, GAPS related
            promotions, and other service-related notifications. Standard
            messaging rates may apply. You may opt-out of receiving promotional
            communications at any time by following the unsubscribe instructions
            provided in the communication or by contacting us directly. However,
            you may continue to receive non- promotional messages essential to
            the operation of your account. Your information will NOT be used for
            marketing purposes and WILL NOT be shared with 3rd party affiliates.
            We do not sell, trade, or otherwise transfer your personal
            information like phone numbers to third parties/affiliates/business
            partners.
          </p>
        </li>
        <li>
          <p className="mb-4">
            <strong>Limitation of Liability</strong> - In no event shall The
            Think Tank be liable for personal injury, or any incidental,
            special, indirect, or consequential damages whatsoever, including,
            without limitation, damages for loss of profits, loss of data,
            business interruption, or any other commercial damages or losses,
            arising out of or related to your use or inability to use the
            Software, however caused, regardless of the theory of liability
            (contract, tort, or otherwise) and even if The Think Tank has been
            advised of the possibility of such damages.
          </p>
        </li>
        <li>
          <p className="mb-4">
            <strong>Governing Law</strong> - These Terms and Conditions will be
            governed by and construed in accordance with the laws of the United
            States, excluding its conflicts of law rules.
          </p>
        </li>
        <li>
          <p className="mb-4">
            <strong>Contact Information</strong> - For any questions,
            complaints, or claims with respect to the Software, please contact
            The Think Tank at{' '}
            <a href="mailto:help@thinktankprep.com">help@thinktankprep.com</a>.
            By purchasing and using "Fill the Math GAPS," you acknowledge that
            you have read these Terms and Conditions, understand them, and agree
            to be bound by them.
          </p>
        </li>
        <li>
          <p className="mb-4">
            <strong>Changes to Terms and Conditions</strong> - The Think Tank
            reserves the right to modify these Terms and Conditions at any time,
            without prior notice. It is your responsibility to review these
            Terms and Conditions periodically for any changes. Your continued
            use of the Software after any modifications indicates your
            acceptance of the updated terms.
          </p>
        </li>
        <li>
          <p className="mb-4">
            <strong>Entire Agreement</strong> - These Terms and Conditions
            constitute the entire agreement between you and The Think Tank
            regarding the use of the Software, superseding any prior agreements
            or understandings. Any waiver of any provision of these Terms and
            Conditions will only be effective if in writing and signed by The
            Think Tank.
          </p>
        </li>
        <li>
          <p className="mb-4">
            <strong>Severability</strong> - If any provision of these Terms and
            Conditions is found to be invalid or unenforceable, the remaining
            provisions will continue to be valid and enforceable.
          </p>
        </li>
      </ol>
      <p className="mb-4">
        By purchasing and using the Software, you confirm that you have read,
        understood, and agree to these Terms and Conditions. If you do not agree
        with any part of these Terms and Conditions, you should not purchase or
        use the Software.
      </p>
    </div>
  );
}

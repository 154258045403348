import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FILTERS, Subject } from '../constants';

import {
  apiSlice,
  useGetSchoolResultOverviewQuery,
  useGetSchoolSubjectResultsQuery,
  useGetSchoolSubjectSkillResultsQuery,
  useGetTracksForSchoolQuery,
} from '../services/apiSlice';
import { useAppSelector } from '../stores/AppStore';
import useFilteredResults from '../hooks/useFilteredResults';

import BreakoutCircular from '../components/BreakoutCircular';
import { LoadingBlock } from '../components/Loading';
import { FilterValue } from '../components/Filter';
import SchoolAdminHeader from '../partials/SchooladminHeader';

const SchoolSubjectScores: FC = () => {
  const navigate = useNavigate();
  const [
    cumulativeOrReviewOrQuizFilter,
    setCumulativeOrReviewOrQuizFilterFilter,
  ] = useState<FilterValue | null>({
    label: 'Cumulative Score',
    value: FILTERS.overall,
  });
  const [isQuizFilter, setIsQuizFilter] = useState(false);
  const [trackFilter, setTrackFilter] = useState<FilterValue | null>({
    label: 'Cumulative Score',
    value: FILTERS.overall,
  });
  const [selectedSubject, setSelectedSubject] = useState(Subject.geometry);
  const [skillsTitle, setSkillsTitle] = useState('');
  const user = useAppSelector((state) => state.user.user);
  if (!user || !user.adminOfSchool) {
    return <></>;
  }

  const { data: tracks, isFetching: isFetchingTracks } =
    useGetTracksForSchoolQuery(user.adminOfSchool);
  const getTrackId = (trackId: string) => {
    return trackFilter?.value === FILTERS.overall ? undefined : trackId;
  };
  const { data: resultOverview, isFetching: isFetchingResultOverview } =
    useGetSchoolResultOverviewQuery({
      school: user.adminOfSchool,
      trackId: getTrackId(trackFilter?.value),
    });
  const { data: subjectResult, isFetching: isFetchingSubjectResult } =
    useGetSchoolSubjectResultsQuery({
      school: user.adminOfSchool,
      trackId: getTrackId(trackFilter?.value),
    });
  const { data: subjectSkillResult, isFetching: isFetchingSubjectSkillResult } =
    useGetSchoolSubjectSkillResultsQuery({
      school: user.adminOfSchool,
      subject: selectedSubject,
      trackId: getTrackId(trackFilter?.value),
    });
  const prefetchSchoolSubjectSkillResults = apiSlice.usePrefetch(
    'getSchoolSubjectSkillResults',
  );
  const filteredSkillsData = useFilteredResults({
    filter: cumulativeOrReviewOrQuizFilter?.value,
    result: subjectSkillResult,
    label: 'skill',
    sortBy: 'value',
    removeUndefined: true,
  });

  useEffect(() => {
    const text =
      trackFilter?.value === FILTERS.overall ? '' : `/${trackFilter?.label}`;
    navigate(`/schooladmin/gaps-scores${text}`);
  }, [trackFilter]);

  useEffect(() => {
    let trackTitle = '';
    if (trackFilter?.value !== FILTERS.overall) {
      trackTitle = `- ${trackFilter?.label}`;
    }
    setSkillsTitle(
      `${selectedSubject} sub-skills - ${cumulativeOrReviewOrQuizFilter?.value} Scores ${trackTitle}`,
    );
  }, [cumulativeOrReviewOrQuizFilter, selectedSubject, trackFilter]);

  if (isFetchingTracks) {
    return <LoadingBlock />;
  } else if (!tracks) {
    return <div>You don't have any data to display yet.</div>;
  }

  return (
    <div className="grid gap-12">
      <SchoolAdminHeader
        circularOnSelect={(subject) => {
          setSelectedSubject(subject as Subject);
        }}
        circularOnHover={(subject) => {
          if (!user.adminOfSchool) {
            return;
          }
          prefetchSchoolSubjectSkillResults({
            school: user.adminOfSchool,
            subject: subject as Subject,
            trackId: getTrackId(trackFilter?.value),
          });
        }}
        circularSelected={selectedSubject}
        circularIsSelectable={true}
        resultOverview={resultOverview}
        subjectResults={subjectResult}
        isRefreshing={isFetchingResultOverview || isFetchingSubjectResult}
        isQuizFilter={isQuizFilter}
        setIsQuizFilter={setIsQuizFilter}
        cumulativeOrReviewOrQuizFilter={cumulativeOrReviewOrQuizFilter}
        setCumulativeOrReviewOrQuizFilter={
          setCumulativeOrReviewOrQuizFilterFilter
        }
        tracks={tracks}
        trackFilter={trackFilter}
        setTrackFilter={setTrackFilter}
      />
      {subjectResult && subjectResult?.length > 0 && (
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-y-6 px-6 pb-6 pt-3 shadow-xl rounded-xl border-tttDefault border-[1px]">
          <div
            data-testid={'skill-section-title'}
            className="grid justify-self-center text-center col-span-2 lg:col-span-4 max-w-[90%] justify-items-center items-center overflow-hidden uppercase text-white font-bold md:text-xl lg:text-2xl p-3 -mt-7 shadow-md rounded-xl bg-tttDefault"
          >
            {skillsTitle}
          </div>
          <div className="sm:hidden grid col-span-4 col-start-1 text-sm text-slate-400 text-center">
            Touch the skills to see their description.
          </div>
          <div
            data-testid={'skill-section'}
            className="grid col-start-1 col-span-4 grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-8 lg:gap-4 p-6"
          >
            {isFetchingSubjectSkillResult ? (
              <LoadingBlock width={6} height={32} />
            ) : filteredSkillsData && filteredSkillsData?.length > 0 ? (
              <BreakoutCircular
                data={filteredSkillsData}
                isSelectable={false}
                hasTooltips
              />
            ) : (
              <div className="col-span-2 md:col-span-4 lg:col-span-6 justify-self-center text-center normal-case p-6 text-gray-500">
                The selected track does not have any {selectedSubject} skill
                results available.
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SchoolSubjectScores;

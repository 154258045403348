import { FC, useEffect, useState } from 'react';
import Select, { StylesConfig } from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import {
  useAddThinkerMutation,
  useGetMentorsQuery,
  useGetTracksQuery,
} from '../services/apiSlice';

import {
  AddThinkerFormData,
  SelectOption,
  TrackOptionValue,
  emptyFunction,
} from '../types';
import { emailPattern } from '../constants';

interface Properties {
  onSuccess: typeof emptyFunction;
}

const AddThinkerForm: FC<Properties> = ({ onSuccess }) => {
  const [trackOptions, setTrackOptions] = useState<SelectOption[]>();
  const [mentorOptions, setMentorOptions] = useState<SelectOption[]>();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<AddThinkerFormData>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      track: '',
      trackSerialNum: '',
      mentor: '',
    },
  });
  const { data: tracks, isFetching: isFetchingTracks } = useGetTracksQuery();
  const { data: mentors, isFetching: isFetchingMentors } = useGetMentorsQuery();
  const [addThinker, { isLoading }] = useAddThinkerMutation();

  const scheduleFrequencyOptions = Array.from({ length: 5 }, (_, index) => ({
    value: index + 1,
    label: `${index + 1} per week`,
  }));

  useEffect(() => {
    if (tracks) {
      setTrackOptions(() =>
        tracks.map((track) => ({
          value: { track: track._id, serialNum: track.serialNum },
          label: track.track,
        })),
      );
    }
  }, [tracks]);

  useEffect(() => {
    if (mentors) {
      setMentorOptions(() =>
        mentors.map((mentor) => ({
          value: mentor._id,
          label: `${mentor.firstName} ${mentor.lastName}`,
        })),
      );
    }
  }, [mentors]);

  const formSelectClasses =
    'text-left w-full -m-1 text-slate-500 border-slate-500';

  const formSelectStyles: StylesConfig = {
    control: (baseStyles) => ({
      ...baseStyles,
      background: 'rgba(0, 105, 217, 0)',
      boxShadow: 'none',
      borderWidth: 1,
      borderColor: 'rgba(229, 231, 235, 1)',
      '&:hover': {
        borderColor: 'rgba(229, 231, 235, 1)',
        cursor: 'pointer',
      },
      marginLeft: '4px',
      marginRight: '-4px',
      marginBottom: '4px',
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      marginLeft: '4px',
      marginRight: '-4px',
      marginTop: '-24px',
    }),
    singleValue: (baseStyles) => ({
      ...baseStyles,
      color: 'rgb(100, 116, 139)',
    }),
    dropdownIndicator: (baseStyles) => ({
      ...baseStyles,
      color: 'lightgrey',
      opacity: 0.7,
      '&:hover': {
        color: 'grey',
        cursor: 'pointer',
      },
    }),
  };

  const handleAddThinker = async (formData: AddThinkerFormData) => {
    try {
      await addThinker({
        ...formData,
        trackSerialNum: (
          trackOptions?.find(
            (t) => (t.value as TrackOptionValue)?.track === formData.track,
          )?.value as TrackOptionValue
        )?.serialNum,
      }).unwrap();
    } catch {
      toast.error(
        'Some unexpected error happened while trying to add the thinker. Please try again later!',
      );
      return;
    }
    onSuccess();
  };

  return (
    <div className="text-sm text-slate-500 w-[95%] break-words max-w-[400px] p-3">
      This form allows you to add a new <strong>Individual Thinker</strong>.
      <br />
      When you submit the form, the system will send a welcome email with a
      login link.
      <br />
      Do <strong>not</strong> try to use this for adding <em>Students</em> to a{' '}
      <em>Class</em>.
      <br />
      <form
        onSubmit={handleSubmit(handleAddThinker)}
        className="grid max-w-md mx-auto mt-8 mb-0 gap-2"
      >
        <div className="font-medium">First name</div>
        <input
          className="w-full p-2 border rounded-md"
          {...register('firstName', {
            required: true,
            minLength: 2,
            maxLength: 50,
          })}
          placeholder="First Name"
        />
        {errors.firstName && (
          <p className="text-sm text-rose-600">
            Please enter a valid first name.
          </p>
        )}
        <div className="font-medium">Last name</div>
        <input
          className="w-full p-2 border rounded-md"
          {...register('lastName', {
            required: true,
            minLength: 2,
            maxLength: 50,
          })}
          placeholder="Last Name"
        />
        {errors.lastName && (
          <p className="text-sm text-rose-600">
            Please enter a valid last name.
          </p>
        )}
        <div className="font-medium">Email</div>
        <input
          className="w-full p-2 border rounded-md"
          {...register('email', {
            required: true,
            pattern: emailPattern,
          })}
          placeholder="Email Address"
        />
        {errors.email && (
          <p className="text-sm text-rose-600">
            Please enter a valid email address.
          </p>
        )}
        <div className="font-medium">Track</div>
        <Controller
          name="track"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              data-type="select"
              placeholder={'Select a track!'}
              isLoading={isFetchingTracks}
              options={trackOptions}
              onChange={(selectedOption) =>
                field.onChange(
                  ((selectedOption as any)?.value as TrackOptionValue)?.track,
                )
              }
              value={trackOptions?.find(
                (option) =>
                  (option.value as TrackOptionValue).track === field.value,
              )}
              className={formSelectClasses}
              components={{
                // eslint-disable-next-line unicorn/no-null
                IndicatorSeparator: () => null,
              }}
              styles={formSelectStyles}
            />
          )}
        />
        {errors.track && (
          <p className="text-sm text-rose-600">Please select a track.</p>
        )}
        <div className="font-medium">Mentor</div>
        <Controller
          name="mentor"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              data-type="select"
              placeholder={'Select a mentor!'}
              isLoading={isFetchingMentors}
              options={mentorOptions}
              onChange={(selectedOption) =>
                field.onChange((selectedOption as SelectOption)?.value)
              }
              value={mentorOptions?.find(
                (option) => option.value === field.value,
              )}
              className={formSelectClasses}
              components={{
                // eslint-disable-next-line unicorn/no-null
                IndicatorSeparator: () => null,
              }}
              styles={formSelectStyles}
            />
          )}
        />
        {errors.mentor && (
          <p className="text-sm text-rose-600">Please select a mentor.</p>
        )}
        <div className="font-medium">Schedule frequency</div>
        <Controller
          name="scheduleFrequency"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              data-type="select"
              placeholder={'Select the frequency of the quizzes!'}
              options={scheduleFrequencyOptions}
              onChange={(selectedOption) =>
                field.onChange((selectedOption as SelectOption)?.value)
              }
              value={scheduleFrequencyOptions.find(
                (option) => option.value === field.value,
              )}
              className={formSelectClasses}
              components={{
                // eslint-disable-next-line unicorn/no-null
                IndicatorSeparator: () => null,
              }}
              styles={formSelectStyles}
            />
          )}
        />
        {errors.scheduleFrequency && (
          <p className="text-sm text-rose-600">
            Please select the schedule frequency.
          </p>
        )}
        <button
          type="submit"
          className="justify-self-center max-w-[90%] mt-6 py-2.5 px-2 bg-tttDefault text-white font-bold text-xs uppercase rounded shadow-md hover:bg-blue-600 hover:shadow-lg disabled:opacity-50"
          disabled={isLoading}
        >
          Add Individual Thinker
        </button>
      </form>
    </div>
  );
};
export default AddThinkerForm;

/* eslint-disable unicorn/consistent-function-scoping */
import { CSSProperties } from 'react';
import { getColor, getColorHex } from '../util';

const ScoreBar = ({ result }: { result?: number }) => {
  const getBarStyle = (index: number) => {
    if (result === undefined || result === null) {
      return 'bg-gray-100 md:bg-gray-200';
    }
    const segmentValue = (index + 1) * 10; // 10% segments
    const resultValue = result * 100; // convert to percentage

    if (resultValue >= segmentValue) {
      const filledColor = getColor(resultValue);
      return `bg-${filledColor}`;
    } else if (resultValue >= segmentValue - 10) {
      // get single digit percentage
      let filledPercentage = ((resultValue - (segmentValue - 10)) / 10) * 100;
      if (index === 0 && filledPercentage < 10) {
        filledPercentage = 10;
      }
      return {
        '--filledPercentage': `${filledPercentage}%`,
        '--filledColorHex': getColorHex(resultValue),
      };
    } else {
      return 'bg-gray-100 md:bg-gray-200';
    }
  };
  const hasResult = result !== undefined && Number.isFinite(result);

  return (
    <div className="flex gap-6 w-full items-center">
      <div className="flex flex-col gap-1 w-full">
        <div className="flex md:space-x-1 w-full">
          {Array.from({ length: 10 }, (_, index) => (
            <div
              key={index}
              className={`h-1.5 w-full md:rounded-full
            ${index === 0 ? 'rounded-l-full' : ''}
            ${
              typeof getBarStyle(index) === 'string'
                ? getBarStyle(index)
                : 'skill-gradient'
            }`}
              style={
                typeof getBarStyle(index) === 'string'
                  ? {}
                  : (getBarStyle(index) as CSSProperties)
              }
            />
          ))}
        </div>
        <div className="flex lg:space-x-2 justify-between w-full text-gray-500 text-sm">
          <div key={0} className="w-[10%] text-start">
            0
          </div>
          <div key={100} className="w-[10%] text-end">
            100
          </div>
        </div>
      </div>
      <h1
        className={`font-medium text-3xl pb-4 min-w-[76px] ${
          hasResult ? 'text-right' : 'text-center text-gray-300'
        }`}
      >
        {hasResult ? Math.round(result * 100) : ''}%
      </h1>
    </div>
  );
};

export default ScoreBar;

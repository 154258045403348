import { LockClosedIcon, MailIcon } from '@heroicons/react/outline';

interface Properties {
  type?: string;
  name: string;
  register?: any;
  [key: string]: any;
}

export default function Input({ register, name, type, ...rest }: Properties) {
  const registered = typeof register === 'function' ? register(name) : [];
  return (
    <>
      <input
        data-testid={`${type}-input`}
        {...registered}
        {...rest}
        type={type ?? 'text'}
        className="w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4"
      />
      {type === 'password' && (
        <div className="flex justify-end">
          <LockClosedIcon className="h-6 -mt-14 pr-2 text-gray-300 opacity-40 pointer-events-none" />
        </div>
      )}
      {type === 'email' && (
        <div className="flex justify-end">
          <MailIcon className="h-6 -mt-14 pr-2 text-gray-300 opacity-40 pointer-events-none" />
        </div>
      )}
    </>
  );
}

import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Tooltip from 'rc-tooltip';
import { Options, SingleValue } from 'react-select';

import { useStores } from '../stores/RootStore';
import Select from './Select';

interface Properties {
  id?: string;
  title?: string;
  body: number | string | any[] | undefined;
  tooltipText?: string;
  plainNumber?: boolean;
  precisePct?: boolean;
  postFix?: string;
  select?: boolean;
  selected?: {
    value: any;
    label: string;
  };
  selectFn?: (
    newValue: SingleValue<{
      value: any;
      label: string;
    }>,
  ) => void;
  selectOptions?: Options<{
    value: any;
    label: string;
  }>;
  selectPlaceholder?: string;
}

const BaseCard: FC<Properties> = ({
  id,
  title,
  body,
  tooltipText,
  plainNumber,
  precisePct,
  postFix,
  select,
  selected,
  selectFn,
  selectOptions,
  selectPlaceholder,
}) => {
  const {
    rootStore: { uiStore },
  } = useStores();

  const content = () => {
    return (
      <div
        data-testid={id}
        className={`grid text-shadow grid-cols-1 px-3 md:px-6 py-4 justify-items-start items-center h-full rounded-lg shadow ${
          plainNumber
            ? 'bg-blue-100'
            : typeof body === 'number'
            ? uiStore.getColor(body)
            : 'bg-tttDefault'
        }`}
      >
        {select && selectFn ? (
          <Select
            dataTestId={`card-select`}
            key={`card-select-${selected?.label}`}
            selectFn={selectFn}
            selectOptions={selectOptions}
            selectPlaceholder={selectPlaceholder}
            selected={selected}
          />
        ) : (
          <p
            data-testid={'card-title'}
            className={`${
              !plainNumber && 'text-white'
            } font-bold text-sm md:text-base lg:text-lg`}
          >
            {title}
          </p>
        )}
        <div data-testid={'card-body'} className="overflow-visible pt-3">
          {typeof body === 'number' || typeof body === 'string' ? (
            precisePct ? (
              <p className="font-bold text-xl lg:text-3xl">
                {`${(Number.parseFloat(String(body)) * 100).toFixed(2)}%`}
              </p>
            ) : plainNumber ? (
              <p className="font-bold  text-xl lg:text-3xl">
                {typeof body === 'number'
                  ? `${Number.parseFloat(
                      Number.parseFloat(String(body)).toFixed(2),
                    )}${postFix ?? ''}`
                  : body}
              </p>
            ) : (
              <p className="text-white font-bold text-xl lg:text-3xl">
                {typeof body === 'number' ? `${Math.round(body)}%` : body}
              </p>
            )
          ) : (
            <p
              className={`${
                !plainNumber && 'text-white'
              } font-bold text-xl lg:text-3xl`}
            >
              N/A
            </p>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      {tooltipText ? (
        <Tooltip
          placement="bottom"
          overlay={<TooltipOverlay tooltipText={tooltipText} />}
          overlayClassName="max-w-[30%]"
          destroyTooltipOnHide={true}
        >
          {content()}
        </Tooltip>
      ) : (
        content()
      )}
    </div>
  );
};

interface ElementProperties {
  tooltipText: string;
}

const TooltipOverlay: FC<ElementProperties> = ({ tooltipText }) => {
  return (
    <div className="grid grid-flow-row gap-3 justify-between w-full py-2 items-center font-medium capitalize">
      <div>{tooltipText}</div>
    </div>
  );
};

const Card = observer(BaseCard);
export default Card;

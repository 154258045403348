import { selectStepTitle } from '../../features/signUp/signUpSlice';
import { useAppSelector } from '../../stores/AppStore';

const SignupHeader = () => {
  const title = useAppSelector(selectStepTitle);
  const { host } = window.location;
  const isProduction =
    process.env.NODE_ENV === 'production' && !host.startsWith('staging');
  const environment =
    process.env.NODE_ENV === 'development' ? 'develop' : host.slice(0, 7);

  return (
    <header className="flex justify-between items-center w-full md:w-[90%] mx-auto py-2 md:p-4">
      {!isProduction && (
        <div className="fixed right-[-5px] top-[-5px] z-50 overflow-hidden w-[75px] h-[75px] text-right pointer-events-none">
          <span className="block absolute text-xs font-bold font-sans text-white uppercase text-center leading-6 rotate-45 w-[100px] opacity-90 bg-gradient-to-b from-orange to-amber-800 shadow-md top-[22px] right-[-18px]">
            {environment}
          </span>
        </div>
      )}
      <a
        href="https://www.thinktankprep.com/fill-the-math-gaps-parents/"
        target="_blank"
        className="md:mr-4"
      >
        <img
          src="/images/FilltheMathGAPS-logo.png"
          className="max-h-[40px] m-2 md:m-0 w-52 sm:w-72 md:w-auto"
          alt="The Think Tank Logo"
        />
      </a>
      {title && (
        <p
          data-testid="signup-header-title"
          className="absolute md:relative top-14 md:top-0 text-tttDefault uppercase text-2xl"
        >
          {title}
        </p>
      )}
      <a
        href="mailto:help@thinktankprep.com?subject=Subscription Issue"
        target="_blank"
        className="rounded-full border border-gray-300 py-1 px-3 h-min hover:bg-gray-100 hover:border-gray-500"
        rel="noreferrer"
      >
        Need Help?
      </a>
    </header>
  );
};

export default SignupHeader;

import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

import { CurrentQuiz } from '../types';
import { history, store, useAppSelector } from '../stores/AppStore';
import { leaveQuiz, selectIsInProgress } from '../features/quiz/quizSlice';

interface NavigationBlockingParameters {
  practiceOrSolve?: string;
  quizId?: string;
  currentAssignments: CurrentQuiz[];
  isLoading: boolean;
  isIncompleteResult?: boolean;
}

const useBlockQuizNavigation = ({
  practiceOrSolve,
  quizId,
  currentAssignments,
  isLoading,
  isIncompleteResult,
}: NavigationBlockingParameters) => {
  const navigate = useNavigate();
  const isInProgress = useAppSelector(selectIsInProgress);
  const currentQuiz = useAppSelector((state) => state.quiz.selectedQuiz);

  useEffect(() => {
    const unblock = history.block(({ location }) => {
      if (practiceOrSolve === 'practice') {
        store.dispatch(leaveQuiz());
        unblock();
        navigate(location.pathname);
        return;
      }
      const relevantAssignment = currentAssignments.find(
        (assignment) => assignment.id === quizId,
      );
      if (
        !isLoading &&
        ((currentQuiz?.id && quizId !== currentQuiz?.id) ||
          !relevantAssignment?.canStart)
      ) {
        // invalid quiz loaded --> allow leaving by logic in App.tsx
        unblock();
        navigate(location.pathname);
        return;
      }
      if (/review/.test(location.pathname)) {
        unblock();
        navigate(location.pathname);
        return;
      }

      if (!isIncompleteResult && !isInProgress) {
        unblock();
        navigate(location.pathname);
        return;
      }

      if (
        /password-reset/.test(location.pathname) ||
        /help/.test(location.pathname)
      ) {
        unblock();
        navigate(location.pathname);
        return;
      } else if (/login/.test(location.pathname)) {
        const confirmLeaveQuiz = confirm(
          'Quiz taking is in progress. Are you sure you want to exit (your progress has been autosaved)?',
        );
        if (confirmLeaveQuiz) {
          store.dispatch(leaveQuiz());
          unblock();
          navigate(location.pathname);
        }
        return;
      } else if (!/quiz/.test(location.pathname)) {
        Sentry.captureException(new Error('Cannot leave Quiz taking'), {
          tags: {
            quizId,
          },
        });
        toast.error(
          'Quiz taking is in progress. You are unable to proceed until your current quiz is completed.',
        );
      }
    });

    return () => {
      unblock();
    };
  }, [
    history.location.pathname,
    isInProgress,
    quizId,
    currentQuiz,
    currentAssignments,
    isLoading,
  ]);
};

export default useBlockQuizNavigation;

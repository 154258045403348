import { StripeAddressElementOptions, ContactOption } from '@stripe/stripe-js';

export enum ROLES {
  owner = 'owner',
  sysadmin = 'sysadmin',
  schooladmin = 'schooladmin',
  teacher = 'teacher',
  student = 'student',
  thinker = 'thinker',
  mentor = 'mentor',
}

export const MonthlyPricingPerLicenseCount: Record<number, number> = {
  1: 14.9,
  2: 27.9, // ~ 5% discount
  3: 39.9, // ~ 10% discount
  4: 50.8, // ~ 15% discount
  5: 59.5, // ~ 20% discount
};

export const YearlyPricingPerLicenseCount: Record<number, number> = {
  // two months off
  1: 149,
  2: 279, // ~ 5% discount
  3: 399, // ~ 10% discount
  4: 508, // ~ 15% discount
  5: 595, // ~ 20% discount
};

export enum Subject {
  geometry = 'Geometry',
  algebra = 'Algebra',
  preAlgebra = 'Pre-Algebra',
  statistics = 'Statistics',
}

export type Filter = {
  type: FilterType;
  filter: FILTERS | string;
  value: any;
};

export enum FILTERS {
  overall = 'cumulative',
  reviewOverall = 'review',
}

export enum FilterType {
  quizOrReviewOrOverall = 'quizOrReviewOrOverall',
  trackFilter = 'track',
  classFilter = 'class',
  subjectFilter = 'subject',
}

export enum ImportStatus {
  init = 'init',
  processing = 'processing',
  success = 'success',
  failed = 'failed',
}

export enum FeatureFlag {
  TargetedPractice = 'targeted-practice',
}

export const defaultImportYear = '2023/2024';

export const VideoMaxSpeed = 1.5;

export function getVideoMinWatchedPercentage(roles?: ROLES[]) {
  if (
    !roles ||
    process.env.REACT_APP_NODE_ENV === 'test' ||
    process.env.REACT_APP_NODE_ENV === 'development'
  ) {
    return 0;
  }
  if (roles.includes(ROLES.thinker)) {
    return 0.5;
  }
  return 0.8; // students
}

export const getHelpVideos = (roles: ROLES[]) => {
  const videoUrls: string[] = [];
  if (roles.includes(ROLES.student) || roles.includes(ROLES.thinker)) {
    videoUrls.push('https://player.vimeo.com/video/1004933202?h=0e993a9404');
  }
  if (roles.includes(ROLES.schooladmin)) {
    videoUrls.push('https://player.vimeo.com/video/855839184?h=2a5109fdaa');
  }
  if (roles.includes(ROLES.teacher)) {
    videoUrls.push('https://player.vimeo.com/video/857357113?h=5983cba748');
  }
  if (roles.includes(ROLES.mentor)) {
    videoUrls.push('https://player.vimeo.com/video/1004931688?h=a9659da5d4');
  }
  return videoUrls;
};

export const popupVideoUrl =
  'https://player.vimeo.com/video/851037804?h=58369e02d2';

export const thinkerMentorWelcomeVideoUrl =
  'https://player.vimeo.com/video/1010791623?h=ff76dccf9d';

export const congratulationsQuizCompletedVideoUrl =
  'https://player.vimeo.com/video/1010021117?h=98afb6928c';

export const thinkerMentorWelcomeVideoMinimumWatchPercentage = 50;

export const getPopupVideoWidth = () => {
  let videoWidth = 300;
  if (window.innerWidth > 640 && window.innerWidth <= 768) {
    videoWidth = 600;
  } else if (window.innerWidth > 768 && window.innerWidth <= 1024) {
    videoWidth = 700;
  } else if (window.innerWidth > 1024) {
    videoWidth = 800;
  }
  return videoWidth;
};

export const getCongratulationsVideoWidth = () => {
  let videoWidth = 300;
  if (window.innerWidth > 640 && window.innerWidth <= 768) {
    videoWidth = 500;
  } else if (window.innerWidth > 768 && window.innerWidth <= 1024) {
    videoWidth = 600;
  } else if (window.innerWidth > 1024) {
    videoWidth = 700;
  }
  return videoWidth;
};

export const midPercentageThreshold = 50;
export const topPercentageThreshold = 75;

export const emailPattern =
  /(?:[\w!#$%&'*+/=?^`{|}~-]+(?:\.[\w!#$%&'*+/=?^`{|}~-]+)*|"(?:[\u0001-\u0008\u000B\u000C\u000E-\u001F!\u0023-\u005B\u005D-\u007F]|\\[\u0001-\u0009\u000B\u000C\u000E-\u007F])*")@(?:(?:[\dA-Za-z](?:[\dA-Za-z-]*[\dA-Za-z])?\.)+[\dA-Za-z](?:[\dA-Za-z-]*[\dA-Za-z])?|\[(?:(?:25[0-5]|2[0-4]\d|[01]?\d{1,2})\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d{1,2}|[\dA-Za-z-]*[\dA-Za-z]:(?:[\u0001-\u0008\u000B\u000C\u000E-\u001F\u0021-\u007F]|\\[\u0001-\u0009\u000B\u000C\u000E-\u007F])+)])/;

export const usCellPhonePattern = /^\([2-9]\d{2}\) ?\d{3}-\d{4}$/;

export const passwordPattern =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\d!#$%&()-_a-z]{8,100}$/;

export const quizscheduleTimeZone = 'America/Los_Angeles'; // controlls all "default" timezone for quiz schedules and dates

export const maxNumberOfTargetedPracticeSkills = 3;

export const featureFlagsOverride: FeatureFlag[] = [
  // Don't commit overrides!
  // FeatureFlag.TargetedPractice,
];

export const isFeatureFlagOverridden = (flag: FeatureFlag) =>
  featureFlagsOverride.includes(flag);

export const getStripeAddressElementOptions = (
  defaultValues?: Partial<ContactOption>,
) => {
  const options: StripeAddressElementOptions = {
    mode: 'billing',
    allowedCountries: ['us'],
    defaultValues,
  };

  return options;
};

export const trackRecommendationInfoTexts = [
  'Foundational skills to build the base',
  'Intermediate steps to solidify your knowledge',
  'Advanced path to challenge yourself',
];

export const signupGoals = [
  'Improving grades',
  'Exam preparation',
  'Enrichment',
  'Skill development',
];

export const signupDevices = ['Desktop', 'Laptop', 'Tablet', 'Smartphone'];

export const signupReferralTypes = [
  'Friend/Family',
  'School',
  'Online Ad',
  'Social Media',
  'Other',
];

export const thinkerChartEmptyText =
  'When the thinker starts taking quizzes, their results will be displayed here. Every fifth quiz is a special Review Quiz that covers past topics, and its results will also be shown on this chart.';

export const mathJaxConfig = {
  loader: { load: ['[tex]/ams'] },
  tex: { packages: { '[+]': ['ams'] } },
};

export const isSafari = async () => {
  const availableMediaDevices = await navigator.mediaDevices.enumerateDevices();
  return availableMediaDevices.some(
    (device) =>
      device.kind === 'videoinput' && /safari|mobile/i.test(device.label),
  );
};

import { InformationCircleIcon } from '@heroicons/react/outline';
import { Line } from 'rc-progress';
import Tooltip from 'rc-tooltip';
import dayjs from 'dayjs';

import { StudentResultOverview, StudentTrackWithSchedule } from '../types';
import { quizscheduleTimeZone } from '../constants';

interface Properties {
  result: StudentTrackWithSchedule & StudentResultOverview;
  variety: 'mentor' | 'thinker';
}

export default function LearningTrackProgress({
  result: resultProperty,
  variety,
}: Properties) {
  const localTZ = dayjs.tz.guess();
  const upcomingQuiz =
    resultProperty && 'currentAssignments' in resultProperty
      ? resultProperty.currentAssignments.find(
          (a) =>
            !resultProperty?.completedAssignments?.some(
              (completed) => a.id === completed.id,
            ),
        )
      : undefined;
  const nextReview =
    resultProperty && 'currentSchedules' in resultProperty
      ? resultProperty.currentSchedules.find(
          (a) =>
            a.isReview &&
            !resultProperty?.completedAssignments?.some(
              (completed) => a.quiz === completed.id,
            ),
        )
      : undefined;
  const trackProgress =
    resultProperty && 'currentAssignments' in resultProperty
      ? resultProperty.completedAssignments.length /
        resultProperty.totalNumberOfQuizzes
      : 0;

  return (
    <div
      data-testid="track-details"
      className={`gap-4 items-start mt-auto bg-white rounded-xl ${
        variety === 'thinker' ? 'grid md:flex md:flex-row p-8' : 'grid p-0'
      }`}
    >
      <div className="flex flex-col justify-between items-center gap-2 w-full">
        <div className="flex justify-between items-center gap-2 w-full">
          <h2
            className={`${
              variety === 'thinker' ? 'md:text-xl' : 'md:text-lg'
            } font-medium`}
          >
            Learning Track Progress
          </h2>
          <p className="text-gray-500 text-sm">
            {resultProperty.completedAssignments.length} /{' '}
            {resultProperty.totalNumberOfQuizzes} quizzes
          </p>
        </div>
        <Line
          percent={trackProgress * 100}
          strokeWidth={1}
          strokeColor={'#0069d9'}
        />
      </div>
      <div
        className={`flex gap-6 ${
          variety === 'thinker'
            ? 'md:min-w-[120px] md:ml-4 md:pl-6 md:border-l md:border-l-gray-300'
            : ''
        }`}
      >
        {variety === 'mentor' && (
          <div className="flex flex-col gap-1">
            <p className="text-gray-600">Upcoming Quiz</p>
            <p className="text-tttDefault">{upcomingQuiz?.id}</p>
          </div>
        )}
        <div className="flex flex-col gap-1">
          <p className="text-gray-600">Next Review</p>
          <div className="flex items-center">
            {nextReview?.dueDate
              ? dayjs
                  .tz(nextReview.dueDate, quizscheduleTimeZone)
                  .tz(localTZ)
                  .format('MMM D')
              : ''}
            &nbsp;
            <Tooltip
              placement="bottom"
              overlay={
                <p>
                  Review quizzes are special quizzes that cover past topics.
                </p>
              }
              overlayClassName="max-w-[200px]"
              destroyTooltipOnHide={true}
            >
              <InformationCircleIcon className="h-4 w-4 text-gray-600" />
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}

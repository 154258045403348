import { FC, useEffect, useState } from 'react';

import { FilterType } from '../../constants';
import { ClassDocument } from '../../types';

import { useGetClassesTrackDetailsQuery } from '../../services/apiSlice';
import { useAppSelector } from '../../stores/AppStore';

import Filter, { FilterValue } from '../../components/Filter';
import { Loading } from '../../components/Loading';
import TargetedPracticeAssignTable from '../../components/TargetedPracticeAssign';
import TargetedPracticeResultsTable from '../../components/TargetedPracticeResults';

const ClassTargetedPractice: FC = () => {
  const classFilterOptions: FilterValue<
    ClassDocument & { teacherName?: string }
  >[] = [];
  const [selectedClass, setSelectedClass] = useState<FilterValue>();
  const user = useAppSelector((state) => state.user.user);
  if (!user || !user.teachesClasses) {
    return <></>;
  }
  const { data: classes, isFetching: isFetchingClassesDetails } =
    useGetClassesTrackDetailsQuery({
      classes: user.teachesClasses ?? [],
    });

  if (classes) {
    for (const period of classes) {
      classFilterOptions.push({
        label: `Class ${period.class}`,
        value: period,
      });
    }
  }

  useEffect(() => {
    if (classes && classes[0]) {
      setSelectedClass({
        label: `Class ${classes[0].class}`,
        value: classes[0],
      });
    }
  }, [classes]);

  return (
    <div className="grid gap-12">
      <div className="flex flex-col gap-8 md:hidden h-[50%] w-[90%] my-auto mx-auto text-center text-gray-700">
        This functionality works best on bigger screens. Please switch to a
        tablet, desktop or try rotating your device.
        <img src="/rotate.png" alt="rotate" className="h-[50%] mx-auto" />
      </div>
      <div className="hidden md:grid w-[90%] mx-auto justify-items-center">
        <Filter
          filterType={FilterType.classFilter}
          options={classFilterOptions}
          selected={selectedClass}
          onSelect={(selectedFilter) => {
            setSelectedClass(selectedFilter);
          }}
        />
      </div>
      <div
        data-testid="heading-section"
        className="hidden md:grid grid-cols-1 gap-6 px-6 pb-6 pt-3 justify-items-center shadow-2xl rounded-xl border-tttDefault border-[1px] relative"
      >
        <div className="grid justify-self-center col-span-1 max-w-[90%] justify-items-center items-center text-center overflow-hidden uppercase text-white font-bold md:text-xl lg:text-2xl p-3 -mt-7 shadow-md rounded-xl bg-tttDefault">
          Assign new lessons
        </div>
        <div className="absolute top-0 right-0 mt-3 mr-3">
          {isFetchingClassesDetails && <Loading />}
        </div>
        <TargetedPracticeAssignTable
          selectedClass={selectedClass}
          isFetching={isFetchingClassesDetails}
        />
      </div>

      <div
        data-testid="heading-section"
        className="hidden md:grid grid-cols-1 gap-6 px-6 pb-6 pt-3 justify-items-center shadow-2xl rounded-xl border-tttDefault border-[1px] relative"
      >
        <div className="grid justify-self-center col-span-1 max-w-[90%] justify-items-center items-center text-center overflow-hidden uppercase text-white font-bold md:text-xl lg:text-2xl p-3 -mt-7 shadow-md rounded-xl bg-tttDefault">
          Targeted practice results
        </div>
        <div className="absolute top-0 right-0 mt-3 mr-3">
          {isFetchingClassesDetails && <Loading />}
        </div>
        <TargetedPracticeResultsTable
          selectedClass={selectedClass}
          isFetching={isFetchingClassesDetails}
        />
      </div>
    </div>
  );
};

export default ClassTargetedPractice;

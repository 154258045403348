import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';

import { ROLES } from '../constants';
import { useAppSelector } from '../stores/AppStore';
import { selectHasOneOfTheRoles } from '../features/user/userSlice';

interface Properties {
  allowedRoles: any[];
}

const BaseAuth: FC<Properties> = ({ allowedRoles }) => {
  const location = useLocation();
  const { token, user } = useAppSelector((state) => state.user);
  const isOwner = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.owner]),
  );
  const isStudentOrThinker = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.student, ROLES.thinker]),
  );
  const isTeacher = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.teacher]),
  );
  const isMentor = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.mentor]),
  );
  const isSchoolAdmin = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.schooladmin]),
  );
  const isSysAdmin = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.sysadmin]),
  );
  if (!user?._id || !token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (
    !user.changedPasswordAt &&
    !/change-password/.test(window.location.pathname)
  ) {
    return (
      <Navigate to="/change-password" state={{ from: location }} replace />
    );
  }

  return user?.roles?.some((role: any) => {
    return allowedRoles?.includes(role);
  }) ? (
    <Outlet />
  ) : isOwner ? (
    <Navigate to="/kpi-dashboard" replace />
  ) : isStudentOrThinker ? (
    <Navigate to="/summary" replace />
  ) : isTeacher ? (
    <Navigate to="/teacher/student-scores" replace />
  ) : isMentor ? (
    <Navigate to="/mentor/thinkers" replace />
  ) : isSchoolAdmin ? (
    <Navigate to="/schooladmin/teacher-scores/overall" replace />
  ) : isSysAdmin ? (
    <Navigate to="/users" replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

const Auth = observer(BaseAuth);
export default Auth;

import { Link } from 'react-router-dom';
import { PlayIcon } from '@heroicons/react/outline';

import ScoreBar from './ScoreBar';

interface SkillResultProperties {
  result: {
    skill: string;
    description: string;
    overallResult: number;
    tutorialsLink: string;
  };
}

const SkillResult = ({ result }: SkillResultProperties) => {
  const hasResult = Number.isFinite(result.overallResult);
  return (
    <div className="flex flex-col gap-4 bg-white md:bg-gray-100 p-4 rounded-xl">
      <div className="flex flex-col gap-1 md:gap-4 md:flex-row md:items-start">
        <div>
          <span className="font-medium md:text-lg pr-2">{result.skill}</span>
          <span className="text-gray-500">{result.description}</span>
        </div>
        {hasResult && (
          <Link
            to={result.tutorialsLink}
            className="flex gap-1 items-center text-tttDefault w-max whitespace-nowrap hover:underline cursor-pointer md:ml-auto"
          >
            <PlayIcon className="h-5 w-5" />
            Watch Tutorials
          </Link>
        )}
      </div>
      <ScoreBar result={result.overallResult} />
    </div>
  );
};

export default SkillResult;

import { FC } from 'react';
import { NavLink } from 'react-router-dom';

interface Properties {
  path: string;
  title: string;
  children: any;
}

export const SidebarItem: FC<Properties> = ({ path, title, children }) => {
  return (
    <NavLink
      to={path}
      className={(navData) =>
        navData.isActive
          ? 'flex gap-2 p-2 text-sm bg-blue-200 rounded-lg'
          : 'flex gap-2 p-2 text-sm hover:bg-blue-200 hover:bg-opacity-70 rounded-lg max-h-max'
      }
    >
      <div className="box justify-self-center self-center">{children}</div>
      <div className="box col-span-2 self-center pr-3 font-medium">{title}</div>
    </NavLink>
  );
};

import { FC, useState } from 'react';
import { push } from 'redux-first-history';

import { Subject } from '../constants';

import { selectUserId } from '../features/user/userSlice';
import {
  apiSlice,
  useGetStudentOrThinkerGAPSResultsQuery,
} from '../services/apiSlice';
import { useAppDispatch, useAppSelector } from '../stores/AppStore';

import BreakoutCircular from '../components/BreakoutCircular';
import { Loading, LoadingBlock } from '../components/Loading';

const StudentGapsBreakout: FC = () => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(selectUserId);
  if (!userId) {
    return <></>;
  }
  const [selectedSubject, setSelectedSubject] = useState<Subject>(
    Subject.geometry,
  );

  const { data, isLoading, isFetching, isError } =
    useGetStudentOrThinkerGAPSResultsQuery({
      userId,
      subject: selectedSubject,
    });

  const prefetchGAPSData = apiSlice.usePrefetch(
    'getStudentOrThinkerGAPSResults',
  );

  if (isLoading) {
    return <LoadingBlock />;
  } else if (isError) {
    throw new Error('Failed to load Student GAPS data.');
  } else if (!data) {
    return <div>You don't have any data to display yet.</div>;
  }

  const subjectData = data.subjectResults.map((item) => ({
    label: item.subject,
    value: item.result,
  }));

  const subjectDataAvailable =
    subjectData.length > 0 && subjectData.every((d) => d.value !== undefined);
  const skillsData = data.skillResults
    .map((item) => ({
      label: item.skill,
      value: item.overallResult,
      tooltip: {
        actionName: 'Show tutorial',
        action: () => {
          dispatch(
            push({
              pathname: `/tutorials/${item.subject}`,
              search: `${new URLSearchParams({ skills: item.skill })}`,
            }),
          );
        },
        description: item.description,
        metadata: item.subject,
      },
    }))
    .sort((a, b) => a.value - b.value);

  return (
    <div className="grid gap-12">
      <div className="grid grid-cols-4 gap-6 px-6 pb-6 pt-3 shadow-2xl rounded-xl border-tttDefault border-[1px]">
        <div
          data-testid={'gaps-section-title'}
          className="grid justify-self-center col-span-4 max-w-[90%] justify-items-center items-center text-center overflow-hidden uppercase text-white font-bold md:text-xl lg:text-2xl p-3 -mt-7 shadow-md rounded-xl bg-tttDefault"
        >
          GAPS Breakout Scores
        </div>
        <div className="absolute right-8">{isFetching && <Loading />}</div>
        <div
          data-testid={'gaps-section'}
          className="box col-start-1 col-span-4 text-center justify-items-center"
        >
          {subjectDataAvailable ? (
            <div className="grid grid-cols-2 md:grid-cols-4 gap-12 p-6 w-full">
              <BreakoutCircular
                data={subjectData}
                selected={selectedSubject}
                isSelectable={true}
                onSelect={(subject) => setSelectedSubject(subject as Subject)}
                onHover={(subject) => {
                  prefetchGAPSData({
                    userId,
                    subject,
                  });
                }}
              />
            </div>
          ) : (
            <div className="col-span-4 justify-self-center text-center p-6 text-gray-500">
              You currently don't have any results. <br /> You will see your
              overall score, current result and GAPS breakout here.
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-4 gap-6 px-6 pb-6 pt-3 shadow-2xl rounded-xl border-tttDefault border-[1px]">
        <div className="grid justify-self-center col-span-4 max-w-[90%] justify-items-center items-center text-center overflow-hidden uppercase text-white font-bold md:text-xl lg:text-2xl p-3 -mt-7 shadow-md rounded-xl bg-tttDefault">
          {selectedSubject} sub-skills
        </div>
        <div className="sm:hidden grid col-span-4 col-start-1 text-sm text-slate-400 text-center">
          Touch the skills to see their description.
        </div>
        <div className="grid col-start-1 col-span-4 grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-8 lg:gap-4 p-6">
          {skillsData?.length > 0 ? (
            <BreakoutCircular
              data={skillsData}
              isSelectable={false}
              hasTooltips
            />
          ) : (
            <div className="col-span-6 justify-self-center text-center p-6 text-gray-500">
              You currently don't have any results for the selected subject.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentGapsBreakout;

import { FC, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { EyeIcon, EyeOffIcon, UserCircleIcon } from 'lucide-react';
import { Controller, useForm } from 'react-hook-form';

import { passwordPattern } from '../constants';
import { PasswordResetParameters } from '../types';
import {
  useGetUserByTokenQuery,
  useResetPasswordMutation,
} from '../services/apiSlice';

import { Loading } from '../components/Loading';
import { Skeleton } from '../components/ui/skeleton';

const PasswordRestore: FC = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
    watch,
  } = useForm<{
    password: string;
    confirm: string;
  }>({ mode: 'all', defaultValues: { password: '', confirm: '' } });
  const formStatePassword = watch('password');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const { data: user, isFetching: isFetchingUser } = useGetUserByTokenQuery(
    token ?? '',
    { skip: !token },
  );
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const handlePasswordChange = async (data: PasswordResetParameters) => {
    if (!token || token.length !== 64) {
      toast.error('Invalid token!');
      return;
    }
    try {
      await resetPassword({ ...data, token }).unwrap();
    } catch (error: any) {
      if (error?.data?.message && /token expired/gi.test(error.data.message)) {
        toast.error(
          'The link has expired. Please request a new password reset link from the login page.',
        );
      } else {
        toast.error(
          'Some unexpected error happened while trying to set your password. Please try again later!',
        );
      }
      return;
    }
    toast.success('Password successfully updated. Please sign in now.');
    navigate('/login');
  };

  return (
    <div
      className="
        flex flex-col gap-2 md:gap-4
        w-full md:w-[60%] lg:w-[50%]
        items-center
        mx-auto px-4 py-8 md:px-6 lg:px-8
        bg-gray-100 rounded-xl
      "
    >
      <h1 className="text-2xl md:text-3xl font-medium text-center">
        Create Password
      </h1>
      <p className="text-gray-600">
        Set up a strong password to access your account.
      </p>
      {isFetchingUser ? (
        <Skeleton className="h-10 w-48 rounded-full bg-blue-100 mx-auto" />
      ) : user ? (
        <div
          className="
            bg-blue-100 text-gray-600
            flex items-center gap-4
            px-3 py-2 rounded-full w-max mx-auto
            font-medium text-white bg-tttDefault
          "
        >
          <UserCircleIcon className="h-5 w-5 text-tttDefault" />
          {user.email}
        </div>
      ) : (
        <></>
      )}
      <div className="w-full">
        {isLoading ? (
          <div className="flex justify-center items-center h-[160px]">
            <Loading />
          </div>
        ) : (
          <form
            className="flex flex-col gap-4 md:gap-6"
            onSubmit={handleSubmit(handlePasswordChange)}
          >
            <div className="flex flex-col gap-1 md:gap-2">
              <div className="flex justify-between">
                <p className="font-medium">Create Password</p>
                <div
                  onClick={() => setShowPassword((previous) => !previous)}
                  className="flex gap-2 items-center text-tttDefault cursor-pointer"
                >
                  {showPassword ? (
                    <>
                      <EyeOffIcon className="h-5 w-5" />
                      Hide
                    </>
                  ) : (
                    <>
                      <EyeIcon className="h-5 w-5" />
                      Show
                    </>
                  )}
                </div>
              </div>
              <Controller
                render={({ field }) => (
                  <input
                    {...field}
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    className="border border-gray-300 rounded-md px-3 py-2 w-full"
                    onCopy={(event) => event.preventDefault()}
                    onCut={(event) => event.preventDefault()}
                    onPaste={(event) => event.preventDefault()}
                  />
                )}
                name="password"
                control={control}
                rules={{
                  required: true,
                  minLength: 8,
                  maxLength: 100,
                  pattern: passwordPattern,
                }}
              />
              <div className="grid grid-cols-1 md:grid-cols-2">
                <div className="flex gap-2 items-center">
                  <div
                    className={`h-2 w-2 rounded-full ${
                      /[a-z]/.test(formStatePassword)
                        ? 'bg-green'
                        : 'bg-gray-400'
                    }`}
                  />
                  <p className="text-sm text-gray-500">
                    One lowercase character
                  </p>
                </div>
                <div className="flex gap-2 items-center">
                  <div
                    className={`h-2 w-2 rounded-full ${
                      /[A-Z]/.test(formStatePassword)
                        ? 'bg-green'
                        : 'bg-gray-400'
                    }`}
                  />
                  <p className="text-sm text-gray-500">
                    One uppercase character
                  </p>
                </div>
                <div className="flex gap-2 items-center">
                  <div
                    className={`h-2 w-2 rounded-full ${
                      /\d/.test(formStatePassword) ? 'bg-green' : 'bg-gray-400'
                    }`}
                  />
                  <p className="text-sm text-gray-500">One number</p>
                </div>
                <div className="flex gap-2 items-center">
                  <div
                    className={`h-2 w-2 rounded-full ${
                      /[!"#$%&()*,.:<>?@^{|}]/.test(formStatePassword)
                        ? 'bg-green'
                        : 'bg-gray-400'
                    }`}
                  />
                  <p className="text-sm text-gray-500">One special character</p>
                </div>
                <div className="flex gap-2 items-center">
                  <div
                    className={`h-2 w-2 rounded-full ${
                      formStatePassword.length >= 8 ? 'bg-green' : 'bg-gray-400'
                    }`}
                  />
                  <p className="text-sm text-gray-500">Minimum 8 characters</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-1 md:gap-2">
              <div className="flex justify-between">
                <p className="font-medium">Confirm Password</p>
                <div
                  onClick={() => setShowConfirm((previous) => !previous)}
                  className="flex gap-2 items-center text-tttDefault cursor-pointer"
                >
                  {showConfirm ? (
                    <>
                      <EyeOffIcon className="h-5 w-5" />
                      Hide
                    </>
                  ) : (
                    <>
                      <EyeIcon className="h-5 w-5" />
                      Show
                    </>
                  )}
                </div>
              </div>
              <Controller
                render={({ field }) => (
                  <input
                    {...field}
                    id="confirm"
                    type={showConfirm ? 'text' : 'password'}
                    className="border border-gray-300 rounded-md px-3 py-2 w-full"
                    onCopy={(event) => event.preventDefault()}
                    onCut={(event) => event.preventDefault()}
                    onPaste={(event) => event.preventDefault()}
                  />
                )}
                name="confirm"
                control={control}
                rules={{
                  required: true,
                  validate: (value) => value === getValues('password'),
                }}
              />
              {errors.confirm && (
                <p className="text-rose-500 text-sm">Passwords do not match.</p>
              )}
            </div>
            <button
              type="submit"
              disabled={!isValid}
              className="
                px-8 py-3 rounded-full w-max mx-auto
                font-medium text-white bg-tttDefault
                transition-all duration-300 ease-in-out
                hover:shadow hover:bg-opacity-90
                disabled:opacity-50 disabled:cursor-not-allowed
              "
            >
              Set password
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default PasswordRestore;

import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FILTERS, Subject } from '../constants';

import { useAppSelector } from '../stores/AppStore';
import {
  apiSlice,
  useGetClassResultOverviewQuery,
  useGetClassStudentResultsQuery,
  useGetClassSubjectResultsQuery,
  useGetClassTotalsForTeacherQuery,
  useGetSchoolResultOverviewQuery,
  useGetSchoolSubjectResultsQuery,
  useGetSchoolTeacherResultsQuery,
  useGetStudentOrThinkerSummaryDataQuery,
  useGetTracksForSchoolQuery,
} from '../services/apiSlice';
import useResultAndSubmissionChartData from '../hooks/useResultAndSubmissionChartData';
import useFilteredResults from '../hooks/useFilteredResults';

import BreakoutCircular from '../components/BreakoutCircular';
import Card from '../components/Card';
import Chart from '../components/Chart';
import { FilterValue } from '../components/Filter';
import { Loading, LoadingBlock } from '../components/Loading';
import SchoolAdminHeader from '../partials/SchooladminHeader';
import StudentDrillDown from '../partials/StudentDrillDown';
import { QuizResult } from '../types';

type TeacherScoresTitles = {
  teacherlistTitle: string;
  teachersClassTitle: string;
  classGapsTitle: string;
  classStudentsTitle: string;
  chartTitle: string;
};

type Filters = {
  teacher?: string;
  class?: string;
  student?: string;
  subject: Subject;
  classQuiz?: FilterValue;
};

const TeacherScores: FC = () => {
  const navigate = useNavigate();
  const [
    cumulativeOrReviewOrQuizFilter,
    setCumulativeOrReviewOrQuizFilterFilter,
  ] = useState<FilterValue | null>({
    label: 'Cumulative Score',
    value: FILTERS.overall,
  });
  const [isQuizFilter, setIsQuizFilter] = useState(false);
  const [trackFilter, setTrackFilter] = useState<FilterValue | null>({
    label: 'Cumulative Score',
    value: FILTERS.overall,
  });
  const [filters, setFilters] = useState<Filters>({
    subject: Subject.geometry,
  });
  const [titles, setTitles] = useState<TeacherScoresTitles>({
    teacherlistTitle: '',
    teachersClassTitle: '',
    classGapsTitle: '',
    classStudentsTitle: '',
    chartTitle: '',
  });
  const user = useAppSelector((state) => state.user.user);
  if (!user || !user.adminOfSchool) {
    return <></>;
  }
  const getClassDocument = (period?: string) => {
    if (!classTotals) {
      return;
    }
    return classTotals?.find((d) => d.class === period) ?? classTotals[0];
  };
  const getStudentDocument = (studentName?: string) =>
    classStudentResult?.find((student) => student.name === studentName);
  const { data: tracks, isFetching: isFetchingTracks } =
    useGetTracksForSchoolQuery(user.adminOfSchool);
  const getTrackId = (trackId: string) => {
    return trackFilter?.value === FILTERS.overall ? undefined : trackId;
  };
  const { data: resultOverview, isFetching: isFetchingResultOverview } =
    useGetSchoolResultOverviewQuery({
      school: user.adminOfSchool,
      trackId: getTrackId(trackFilter?.value),
    });
  const { data: subjectResult, isFetching: isFetchingSubjectResult } =
    useGetSchoolSubjectResultsQuery({
      school: user.adminOfSchool,
      trackId: getTrackId(trackFilter?.value),
    });
  const { data: teacherResults, isFetching: isFetchingTeacherResults } =
    useGetSchoolTeacherResultsQuery({
      school: user.adminOfSchool,
      trackId: getTrackId(trackFilter?.value),
    });
  const getTeacherDocument = (teacherName?: string) => {
    if (!teacherResults) {
      return;
    }
    return teacherResults.find(
      (teacherResult) => teacherResult.name === teacherName,
    );
  };
  const { data: classTotals, isFetching: isFetchingClassTotals } =
    useGetClassTotalsForTeacherQuery(
      {
        userId: getTeacherDocument(filters.teacher)?.teacher ?? '',
      },
      { skip: !filters.teacher },
    );
  const {
    data: classResultOverview,
    isFetching: isFetchingClassResultOverview,
  } = useGetClassResultOverviewQuery(
    {
      teacherId: getTeacherDocument(filters.teacher)?.teacher ?? '',
      classId: getClassDocument(filters.class)?._id ?? '',
    },
    { skip: !classTotals || !getTeacherDocument(filters.teacher) },
  );
  const { data: classGapsResult, isFetching: isFetchingClassGapsResult } =
    useGetClassSubjectResultsQuery(
      {
        classId: getClassDocument(filters.class)?._id ?? '',
        teacherId: getTeacherDocument(filters.teacher)?.teacher ?? '',
      },
      {
        skip:
          !classTotals ||
          !getClassDocument(filters.class) ||
          !getTeacherDocument(filters.teacher),
      },
    );
  const { data: classStudentResult, isFetching: isFetchingClassStudentResult } =
    useGetClassStudentResultsQuery(
      {
        classId: getClassDocument(filters.class)?._id ?? '',
      },
      { skip: !classTotals || !getClassDocument(filters.class) },
    );
  const {
    data: studentResult,
    isLoading: isLoadingStudentResult,
    isFetching: isFetchingStudentResult,
  } = useGetStudentOrThinkerSummaryDataQuery(
    {
      studentId: getStudentDocument(filters.student)?.student ?? '',
      shouldFetchTrack: false,
    },
    { skip: !classTotals || getStudentDocument(filters.student) === undefined },
  );
  const chartData = useResultAndSubmissionChartData(resultOverview);
  const filteredTeacherResults = useFilteredResults({
    filter: cumulativeOrReviewOrQuizFilter?.value,
    result: teacherResults,
    label: 'name',
    sortBy: 'label',
  });
  const filteredClassTotals = useFilteredResults({
    filter: cumulativeOrReviewOrQuizFilter?.value,
    result: classTotals,
    label: 'class',
    sortBy: 'label',
  });
  const filteredClassSubjectData = useFilteredResults({
    filter: cumulativeOrReviewOrQuizFilter?.value,
    result: classGapsResult?.subjectResults,
    label: 'subject',
  });
  const filteredStudentResults = useFilteredResults({
    filter: cumulativeOrReviewOrQuizFilter?.value,
    result: classStudentResult,
    label: 'name',
    sortBy: 'value',
  });

  const prefetchClassTotals = apiSlice.usePrefetch('getClassTotalsForTeacher');
  const prefetchClassStudentResults = apiSlice.usePrefetch(
    'getClassStudentResults',
  );
  const prefetchStudentResult = apiSlice.usePrefetch(
    'getStudentOrThinkerSummaryData',
  );

  useEffect(() => {
    if (filters.teacher && classTotals && classTotals[0]) {
      setFilters((previousState) => ({
        ...previousState,
        class: classTotals[0].class,
      }));
    }
  }, [classTotals, filters.teacher]);

  useEffect(() => {
    if (
      classResultOverview &&
      Array.isArray(classResultOverview.quizResults) &&
      classResultOverview.quizResults.length > 0
    ) {
      setFilters((previousState) => {
        if (
          !classResultOverview.quizResults ||
          !classResultOverview.quizResults[0]
        ) {
          return previousState;
        }
        return {
          ...previousState,
          classQuiz: {
            label: classResultOverview.quizResults[0].quiz,
            value: classResultOverview.quizResults[0],
          },
        };
      });
    }
  }, [classResultOverview]);

  useEffect(() => {
    const text =
      trackFilter?.value === FILTERS.overall ? '' : `/${trackFilter?.label}`;
    navigate(`/schooladmin/teacher-scores${text}`);
    setFilters({ subject: Subject.geometry });
  }, [trackFilter]);

  useEffect(() => {
    let trackTitle = '';
    if (trackFilter?.value !== FILTERS.overall) {
      trackTitle = `- ${trackFilter?.label}`;
    }
    setTitles({
      teacherlistTitle: `Teacher ${cumulativeOrReviewOrQuizFilter?.value} Scores ${trackTitle}`,
      teachersClassTitle: `${filters.teacher}'s classes  - ${cumulativeOrReviewOrQuizFilter?.value} Scores ${trackTitle}`,
      classGapsTitle: `Period ${filters.class} GAPS - ${
        cumulativeOrReviewOrQuizFilter?.value
      } Scores - ${getClassDocument(filters.class)?.trackDetails?.track}`,
      classStudentsTitle: `Period ${filters.class} Student ${
        cumulativeOrReviewOrQuizFilter?.value
      } Scores - ${getClassDocument(filters.class)?.trackDetails?.track}`,
      chartTitle: `${
        trackFilter?.value === FILTERS.overall ? 'School' : trackFilter?.label
      } Quiz Results and Submission Rates`,
    });
  }, [trackFilter, cumulativeOrReviewOrQuizFilter, filters]);

  if (isFetchingTracks) {
    return <LoadingBlock />;
  } else if (!tracks) {
    return <div>You don't have any data to display yet.</div>;
  }

  return (
    <div className="grid gap-12">
      <SchoolAdminHeader
        resultOverview={resultOverview}
        subjectResults={subjectResult}
        isRefreshing={isFetchingResultOverview || isFetchingSubjectResult}
        isQuizFilter={isQuizFilter}
        setIsQuizFilter={setIsQuizFilter}
        cumulativeOrReviewOrQuizFilter={cumulativeOrReviewOrQuizFilter}
        setCumulativeOrReviewOrQuizFilter={
          setCumulativeOrReviewOrQuizFilterFilter
        }
        tracks={tracks}
        trackFilter={trackFilter}
        setTrackFilter={setTrackFilter}
      />
      {chartData && (
        <Chart
          loading={isFetchingResultOverview}
          data={chartData}
          baseType="bar"
          styledTitle={titles.chartTitle}
        />
      )}
      <div
        data-testid={'teacher-list-section'}
        className="grid grid-cols-2 lg:grid-cols-4 gap-6 px-6 pb-6 pt-3 shadow-xl rounded-xl border-tttDefault border-[1px]"
      >
        <div
          data-testid="teacher-list-title"
          className="grid justify-self-center text-center col-span-2 lg:col-span-4 max-w-[90%] justify-items-center items-center overflow-hidden uppercase text-white font-bold md:text-xl lg:text-2xl p-3 px-6 -mt-7 shadow-md rounded-xl bg-tttDefault"
        >
          {titles.teacherlistTitle}
        </div>
        {isFetchingTeacherResults ? (
          <div className="col-span-2 lg:col-span-4">
            <LoadingBlock width={6} height={32} />
          </div>
        ) : (
          <div className="box grid col-span-2 lg:col-span-4 overflow-hidden font-medium">
            {filteredTeacherResults && filteredTeacherResults.length > 0 ? (
              <div className="grid grid-flow-row gap-12 p-6 auto-cols-auto grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 w-full justify-self-center text-sm md:text-base">
                <BreakoutCircular
                  data={filteredTeacherResults}
                  selected={filters.teacher}
                  isSelectable={true}
                  onSelect={(teacherName) => {
                    setFilters(() => ({
                      subject: Subject.geometry,
                      teacher: teacherName,
                    }));
                  }}
                  onHover={(teacherName) => {
                    prefetchClassTotals({
                      userId: getTeacherDocument(teacherName)?.teacher ?? '',
                    });
                  }}
                />
              </div>
            ) : (
              <div className="col-span-2 lg:col-span-4 justify-self-center text-center p-6 text-gray-500">
                No teacher results available for the selected track.
              </div>
            )}
          </div>
        )}
      </div>
      {isFetchingClassTotals ? (
        <div className="">
          <LoadingBlock width={6} height={32} />
        </div>
      ) : (
        filters.teacher &&
        filteredTeacherResults &&
        filteredTeacherResults.length > 0 && (
          <>
            <div
              data-testid={'teacher-classes-section'}
              className="grid grid-cols-2 lg:grid-cols-4 gap-6 px-6 pb-6 pt-3 shadow-xl rounded-xl border-tttDefault border-[1px]"
            >
              <div
                data-testid="teacher-classes-title"
                className="grid justify-self-center text-center col-span-2 lg:col-span-4 max-w-[90%] justify-items-center items-center overflow-hidden uppercase text-white font-bold md:text-xl lg:text-2xl p-3 -mt-7 shadow-md rounded-xl bg-tttDefault"
              >
                {titles.teachersClassTitle}
              </div>
              <div className="absolute right-8">
                {isFetchingClassResultOverview && <Loading />}
              </div>
              <div className="grid grid-cols-2 row-start-2 row-span-1 col-start-1 col-span-2 lg:col-start-1 lg:col-end-1 lg:grid-cols-1 lg:row-span-2 justify-items-center items-center gap-3">
                <div
                  data-testid={'cumulative-card'}
                  className="box w-[90%] xl:w-[70%] capitalize"
                >
                  <Card
                    title="Cumulative"
                    body={
                      classResultOverview?.overallResult
                        ? classResultOverview.overallResult * 100
                        : undefined
                    }
                  />
                </div>
                <div
                  data-testid={'review-card'}
                  className="box w-[90%] xl:w-[70%] capitalize"
                >
                  <Card
                    title="Review Score"
                    body={
                      classResultOverview?.reviewOverall
                        ? classResultOverview.reviewOverall * 100
                        : undefined
                    }
                  />
                </div>
                <div
                  data-testid={'quiz-card'}
                  className="box w-[90%] xl:w-[70%] capitalize"
                >
                  <Card
                    select={true}
                    selectPlaceholder={'Quiz'}
                    selectFn={(newValue) => {
                      setFilters((previousState) => ({
                        ...previousState,
                        classQuiz: {
                          label: String(newValue?.label),
                          value: newValue?.value as QuizResult,
                        },
                      }));
                    }}
                    selectOptions={classResultOverview?.quizResults?.map(
                      (r) => ({
                        label: r.quiz,
                        value: r,
                      }),
                    )}
                    selected={filters.classQuiz ?? undefined}
                    body={
                      filters.classQuiz?.value
                        ? filters.classQuiz.value.result * 100
                        : undefined
                    }
                  />
                </div>
                <div
                  data-testid={'submission-rate-card'}
                  className="box w-[90%] xl:w-[70%] capitalize"
                >
                  <Card
                    title={`${
                      filters.classQuiz?.label
                        ? `${filters.classQuiz?.label} - `
                        : ''
                    }Submission rate`}
                    body={
                      classResultOverview?.submissionRates &&
                      classResultOverview.submissionRates[
                        filters.classQuiz?.label ?? ''
                      ]
                        ? classResultOverview.submissionRates[
                            filters.classQuiz?.label ?? ''
                          ] * 100
                        : undefined
                    }
                    tooltipText={`${
                      filters.classQuiz?.label && filters.classQuiz?.label
                    } submission rate for the selected class.`}
                  />
                </div>
              </div>
              <div className="box grid overflow-hidden row-start-3 lg:row-start-2 lg:row-span-2 col-start-1 lg:col-start-2 col-span-2 lg:col-span-3 font-medium justify-items-center">
                {filteredClassTotals && (
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-12 lg:gap-8 p-6 w-full items-center">
                    <BreakoutCircular
                      data={filteredClassTotals}
                      labelPrefix={'Period '}
                      selected={filters.class}
                      isSelectable={true}
                      onSelect={(period) => {
                        setFilters((previousState) => ({
                          ...previousState,
                          class: period,
                        }));
                      }}
                      onHover={(period) => {
                        prefetchClassStudentResults({
                          classId: getClassDocument(period)?._id ?? '',
                        });
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div
              data-testid={'teacher-class-gaps-section'}
              className="grid grid-cols-2 lg:grid-cols-4 gap-6 px-6 pb-6 pt-3 shadow-xl rounded-xl border-tttDefault border-[1px]"
            >
              <div
                data-testid={'teacher-class-gaps-title'}
                className="grid justify-self-center text-center col-span-2 lg:col-span-4 max-w-[90%] justify-items-center items-center overflow-hidden uppercase text-white font-bold md:text-xl lg:text-2xl p-3 -mt-7 shadow-md rounded-xl bg-tttDefault"
              >
                {titles.classGapsTitle}
              </div>
              {isFetchingClassGapsResult ? (
                <LoadingBlock width={6} height={32} />
              ) : (
                <div className="grid col-start-1 col-span-2 lg:col-span-4 grid-cols-2 md:grid-cols-4 gap-12 p-6 font-bold capitalize md:text-xl">
                  {filteredClassSubjectData &&
                  filteredClassSubjectData.length > 0 ? (
                    <BreakoutCircular
                      data={filteredClassSubjectData}
                      isSelectable={false}
                    />
                  ) : (
                    <div className="col-span-6 justify-self-center text-center normal-case p-6 text-gray-500">
                      Your selected class does not have any results available.
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        )
      )}
      {!isFetchingClassGapsResult &&
        filteredClassTotals &&
        filteredClassTotals.length > 0 &&
        filters.class && (
          <div
            data-testid={'teacher-student-scores-section'}
            className="grid grid-cols-2 lg:grid-cols-4 gap-6 px-6 pb-6 pt-3 shadow-xl rounded-xl border-tttDefault border-[1px]"
          >
            <div
              data-testid={'teacher-student-scores-title'}
              className="grid justify-self-center text-center col-span-2 lg:col-span-4 max-w-[90%] justify-items-center items-center overflow-hidden uppercase text-white font-bold md:text-xl lg:text-2xl p-3 px-6 -mt-7 shadow-md rounded-xl bg-tttDefault"
            >
              {titles.classStudentsTitle}
            </div>
            {isFetchingClassStudentResult ? (
              <LoadingBlock width={6} height={32} />
            ) : (
              <div
                data-testid={'student-list-section'}
                className="box grid col-span-2 lg:col-span-4 overflow-hidden font-medium"
              >
                {filteredStudentResults && filteredStudentResults.length > 0 ? (
                  <div className="grid grid-flow-row gap-12 p-6 auto-cols-auto grid-cols-2 md:grid-cols-4 w-full justify-self-center text-sm md:text-base">
                    <BreakoutCircular
                      data={filteredStudentResults}
                      selected={filters.student}
                      isSelectable={true}
                      onSelect={(student) => {
                        setFilters((previousState) => ({
                          ...previousState,
                          student,
                        }));
                      }}
                      onHover={(student) => {
                        prefetchStudentResult({
                          studentId: getStudentDocument(student)?.student ?? '',
                          shouldFetchTrack: false,
                        });
                      }}
                    />
                  </div>
                ) : (
                  <div className="col-span-6 justify-self-center text-center p-6 text-gray-500">
                    No student results available for your selected class.
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      {filters.student && (
        <StudentDrillDown
          isLoading={isLoadingStudentResult || isFetchingStudentResult}
          isOpen={!!studentResult}
          result={studentResult}
        />
      )}
    </div>
  );
};

export default TeacherScores;

import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';
import toast from 'react-hot-toast';
import * as Sentry from '@sentry/react';
import {
  EventSourceMessage,
  EventStreamContentType,
  fetchEventSource,
} from '@microsoft/fetch-event-source';

import { rootStore } from '../stores/RootStore';
import { ExtendedError } from '../types';
import { history, store } from '../stores/AppStore';
import { logout } from '../features/user/userSlice';

const controller = new AbortController();
const { signal } = controller;

const redirect = () => {
  rootStore.uiStore.apiError = true;
  if (rootStore.uiStore.redirectCount < 4) {
    setTimeout(() => {
      rootStore.uiStore.increaseRedirectCount();
      history.push('rootStore.uiStore.getDefaultPagePath()'); // TODO remove
      window.location.reload();
    }, 5000);
  } else {
    // rootStore.uiStore.shouldShowErrorModal(true);
  }
};

const sentryCaptureAxiosError = (error: AxiosError) => {
  Sentry.captureException(
    new ExtendedError({
      name: (error.response?.data as any)?.error || error.name,
      message: (error.response?.data as any)?.message || error.message,
      stack: error.stack,
    }),
  );
};

const handleErrors = async (error: AxiosError, url: string) => {
  if (!error.response) {
    toast.error('No Server Response');
  }
  const { data, status } = error.response as AxiosResponse;
  if (status === 418) {
    await rootStore.uiStore.fetchClientVersion();
    window.location.reload();
    rootStore.uiStore.setClientVersionLoggedAt('');
    return;
  }
  if (status === 401 && url !== '/auth') {
    store.dispatch(logout());
    // TODO: resetApiState
    // store.dispatch(apiSlice.util.resetApiState());
    return;
  }
  if (status === 401 && url === '/auth') {
    const { message } = data as any;
    toast.error(`Error: ${message}`);
    throw new Error(message);
  }
  if (status === 403) {
    const { message } = data as any;
    toast.error(`Forbidden: ${message}`);
    redirect();
    throw new Error(message);
  }
  if (status >= 400 && status < 500) {
    const { message } = data as any;
    toast.error(`Error: ${message}`);
    sentryCaptureAxiosError(error);
    throw new Error(message);
  }
  toast.error('Application server error. Please try again.');
  redirect();
  sentryCaptureAxiosError(error);
  throw new Error(data.message);
};

class RetriableError extends Error {}
class FatalError extends Error {}
class FinishedError extends Error {}

class APIService {
  baseUrl: string;
  apiClient: AxiosInstance;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
    this.apiClient = axios.create({
      baseURL: this.baseUrl,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    axiosRetry(this.apiClient, {
      retries: Number.POSITIVE_INFINITY,
      retryDelay: axiosRetry.exponentialDelay,
      retryCondition: (error) => {
        return (
          error.code === 'ERR_NETWORK' ||
          /mongo/gi.test((error.response?.data as any).name)
        );
      },
      onRetry(retryCount) {
        if (retryCount === 6) {
          rootStore.uiStore.apiNetworkError = true;
        }
      },
    });
  }

  addToken(config: any) {
    const state = store.getState();
    const { token } = state.user;
    return {
      ...config,
      headers: {
        ...config.headers,
        authorization: `Bearer ${token}`,
      },
    };
  }

  addClientVersion(config: any) {
    const state = store.getState();
    const { clientVersion } = state.global;
    return {
      ...config,
      headers: {
        ...config.headers,
        'X-Client-Version': clientVersion,
      },
    };
  }

  async listenToEvents(
    url: string,
    onEvent: (event: EventSourceMessage) => void,
  ) {
    const state = store.getState();
    const { clientVersion } = state.global;
    const { token } = state.user;
    await fetchEventSource(`${this.baseUrl}/${url}`, {
      headers: {
        authorization: `Bearer ${token}`,
        'X-Client-Version': clientVersion ?? '',
      },
      async onopen(response) {
        if (
          response.ok &&
          response.headers.get('content-type') === EventStreamContentType
        ) {
          return; // everything's good
        } else if (
          response.status >= 400 &&
          response.status < 500 &&
          response.status !== 429
        ) {
          // client-side errors are usually non-retriable:
          throw new FatalError();
        } else {
          throw new RetriableError();
        }
      },
      onmessage(event: any) {
        if (typeof onEvent === 'function') {
          onEvent(event);
        }
        let data: any = {};
        try {
          data = JSON.parse(event.data);
        } catch {}
        if (data.progressPercentage === 1) {
          controller.abort();
          throw new FinishedError();
        }
      },
      onclose() {
        console.log('Server closed the SSE connection');
        // if the server closes the connection unexpectedly, retry:
        throw new RetriableError();
      },
      onerror(error) {
        if (error instanceof FinishedError) {
          // Do nothing
          controller.abort();
        } else if (error instanceof FatalError) {
          throw error; // rethrow to stop the operation
        } else {
          return 2000;
        }
      },
      signal,
    });
  }

  async get(url: string, config: any = {}) {
    try {
      config = this.addToken(config);
      config = this.addClientVersion(config);
      const get = await this.apiClient.get(url, config);
      rootStore.uiStore.resetapiNetworkErrorState();
      return get;
    } catch (error: any) {
      return handleErrors(error, url);
      // throw error;
    }
  }

  async post(url: string, data: any = {}, config: any = {}) {
    try {
      if (url !== '/auth') {
        config = this.addToken(config);
      }
      config = this.addClientVersion(config);
      const post = await this.apiClient.post(url, data, config);
      rootStore.uiStore.resetapiNetworkErrorState();
      return post;
    } catch (error: any) {
      await handleErrors(error, url);
      // throw error;
    }
  }
}

export const baseUrl: string = process.env.REACT_APP_API_URL || '';
export const apiService = new APIService(baseUrl);

/* eslint-disable unicorn/consistent-function-scoping */
import { parse } from 'ansicolor';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useRef, useState } from 'react';

import { history } from '../stores/AppStore';
import { useGetSystemLogsQuery } from '../services/apiSlice';
import { Loading } from '../components/Loading';
import { DebouncedInput } from '../components/Table';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
const localTZ = dayjs.tz.guess();

const defaultInitialLinesShown = 20;
const defaultFilteredFromPosition = 60;
const defaultUnfilteredFromPositon = 90;
const maxUnfilteredRangeHours = 4;
const maxFilteredRangeHours = 48;

const getMarkedDates = () =>
  [...Array.from({ length: 10 }).keys()]
    .map((index) => dayjs().subtract(Math.pow(2, index), 'hours'))
    .reverse();

const getDateMarkLabels = () =>
  getMarkedDates().map((date) => dayjs().to(date));

// NOTE: getSliderPositionNearDate's logic must mirror getTimeBySliderPosition's logic
const getTimeBySliderPosition = (position: number) =>
  position === 100
    ? new Date()
    : position > 90
    ? dayjs()
        .subtract(Math.pow(2, 90 - position), 'hours')
        .toDate()
    : dayjs()
        .subtract(Math.pow(2, 9 - position / 10), 'hours')
        .toDate();

// NOTE: getSliderPositionNearDate's logic must mirror getTimeBySliderPosition's logic
// NOTE: doesn't work for positions 90-99 but it isn't needed at the moment
const getSliderPositionNearDate = (date: Date) => {
  if (dayjs(date).isAfter(dayjs())) {
    return 100;
  }
  const hoursDifference = dayjs().diff(dayjs(date), 'hours');
  const rawPositionDifference = Math.log2(hoursDifference);
  const position = Math.round((9 - rawPositionDifference) * 10);
  return position;
};

function getSliderMarks() {
  const marks: Record<number, string> = {
    100: 'now',
  };
  const dates = getDateMarkLabels();
  for (const [index, date] of dates.entries()) {
    marks[index * 10] = date;
  }
  return marks;
}

export default function SystemLogs() {
  const { filter: filterURLParameter, filterLabel } = useParams();
  const logAreaReference = useRef(null);
  const [numberOfLinesShown, setNumberOfLinesShown] = useState<number>(
    defaultInitialLinesShown,
  );
  const initialFromPosition =
    filterURLParameter && filterURLParameter.length > 4
      ? defaultFilteredFromPosition
      : defaultUnfilteredFromPositon;
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    getTimeBySliderPosition(initialFromPosition),
    new Date(),
  ]);
  const [dateRangeSliderValue, setDateRangeSliderValue] = useState<
    [number, number]
  >([initialFromPosition, 100]);
  const [showLocalTime, setShowLocalTime] = useState<boolean>(true);
  const [filter, setFilter] = useState<{
    searchTerm: string;
  }>({
    searchTerm: filterURLParameter ?? '',
  });
  const { data, isFetching } = useGetSystemLogsQuery({
    from: dayjs.tz(dateRange[0], localTZ).utc(false).toISOString(),
    to: dayjs.tz(dateRange[1], localTZ).utc(false).toISOString(),
    searchTerm: filter.searchTerm,
  });

  const resetScrollArea = () => {
    if (logAreaReference.current) {
      (logAreaReference.current as HTMLElement).scrollTo({
        top: 0,
      });
    }
    setNumberOfLinesShown(defaultInitialLinesShown);
  };

  const lines = data ? data.split('\n') : [];

  return (
    <div className="mx-auto md:w-[90%] xl:w-[80%]">
      <div
        className="grid grid-cols-1 gap-2 px-2 md:px-4 pb-6 mb-12 pt-3 shadow-2xl rounded-xl border-tttDefault border-[1px] transition-all duration-1000 relative"
        data-testid="header-section"
      >
        <div className="justify-self-center col-span-3 max-w-[90%] justify-items-center items-center uppercase text-white font-bold text-2xl p-3 -mt-7 mb-3 shadow-md rounded-xl bg-tttDefault">
          System Logs
        </div>
        <div className="absolute top-0 right-0 mt-3 mr-3">
          {isFetching && <Loading />}
        </div>
        <div className="grid grid-cols-1 mr-[-1rem]">
          <div className="flex p-2">
            <div className="flex flex-1 flex-col sm:flex-row">
              <div className="flex-1 min-w-[100px] max-w-[400px] mr-4">
                <DebouncedInput
                  value={filter.searchTerm}
                  onChange={(value) => {
                    if (String(value).length === 1) {
                      return; // Avoid searching 1 character long search expression, leave everything unchanged. 0 is needed for clearing
                    }
                    if (value !== filter.searchTerm) {
                      history.replace({ pathname: `/system-logs/${value}` });
                    }
                    setFilter(() => ({
                      searchTerm: String(value),
                    }));
                    resetScrollArea();
                  }}
                  className="w-full ml-2 sm:ml-auto p-2 xl:text-lg shadow border border-block rounded-lg"
                  placeholder="Type to filter logs..."
                />
              </div>
              <div className="w-[92%] sm:w-full max-w-[360px] mt-4 sm:mt-2 ml-4 mb-8">
                <Slider
                  range
                  pushable
                  min={0}
                  max={100}
                  marks={getSliderMarks()}
                  onChange={(value) => {
                    setDateRangeSliderValue(value as [number, number]);
                  }}
                  onChangeComplete={(value) => {
                    let from = (value as [number, number])[0];
                    let to = (value as [number, number])[1];
                    const maxRange =
                      filter.searchTerm.length > 4
                        ? maxFilteredRangeHours
                        : maxUnfilteredRangeHours;
                    if (
                      dayjs(getTimeBySliderPosition(to)).diff(
                        getTimeBySliderPosition(from),
                        'hours',
                      ) > maxRange
                    ) {
                      // Too large time range
                      if (from < dateRangeSliderValue[0]) {
                        // "from" is being moved back, "to" needs to follow
                        to = getSliderPositionNearDate(
                          dayjs(getTimeBySliderPosition(from))
                            .add(maxRange, 'hours')
                            .toDate(),
                        );
                        to = Math.max(from + 1, to); // to guarantee a non-zero long range
                      } else if (to > dateRangeSliderValue[1]) {
                        // "to" is being moved ahead, "from" needs to follow
                        from = getSliderPositionNearDate(
                          dayjs(getTimeBySliderPosition(to))
                            .subtract(maxRange, 'hours')
                            .toDate(),
                        );
                        from = Math.min(to - 1, from); // to guarantee a non-zero long range
                      }
                      setDateRangeSliderValue([from, to]);
                    }
                    setDateRange([
                      getTimeBySliderPosition(from),
                      getTimeBySliderPosition(to),
                    ]);
                    resetScrollArea();
                  }}
                  value={dateRangeSliderValue}
                  styles={{
                    handle: {
                      borderColor: '#0069D9',
                    },
                    track: {
                      backgroundColor: '#0069D9',
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <em className="text-slate-500 p-4">
            Displaying{' '}
            {filter.searchTerm
              ? filterLabel
                ? `${filterLabel}'s `
                : 'filtered '
              : ''}
            logs between{' '}
            {dayjs
              .tz(dateRange[0], localTZ)
              .utc(false)
              .tz(showLocalTime ? localTZ : 'UTC')
              .format(showLocalTime ? 'MMM DD. hh:mm A' : 'MMM DD. HH:mm')}{' '}
            and{' '}
            {dayjs
              .tz(dateRange[1], localTZ)
              .utc(false)
              .tz(showLocalTime ? localTZ : 'UTC')
              .format(showLocalTime ? 'MMM DD. hh:mm A' : 'MMM DD. HH:mm')}{' '}
            (
            <span
              className="font-bold hover:underline cursor-pointer"
              onClick={() => setShowLocalTime(!showLocalTime)}
            >
              {showLocalTime ? 'local' : 'UTC'}
            </span>
            )
          </em>
          <div className="w-full">
            {!data || data.length === 0 ? (
              <div className="w-full text-center text-slate-600">
                No logs available.
              </div>
            ) : (
              <div className="grid justify-self-center justify-items-center min-h-[100px] max-h-[400px] bg-gray-100 border-gray-500 border-[1px] rounded-lg select-text p-3 pr-0">
                <div
                  ref={logAreaReference}
                  className="justify-self-center self-center h-[386px] w-full md:px-2 overflow-x-hidden overflow-y-auto text-gray-500"
                >
                  <InfiniteScroll
                    loadMore={() => {
                      if (numberOfLinesShown < lines.length) {
                        setNumberOfLinesShown(
                          Math.min(numberOfLinesShown + 10, lines.length),
                        );
                      }
                    }}
                    initialLoad={true}
                    hasMore={numberOfLinesShown < lines.length}
                    loader={<div>Loading...</div>}
                    useWindow={false}
                    threshold={500}
                    className="w-full"
                  >
                    {lines.slice(0, numberOfLinesShown).map((line, index) => (
                      <p key={index}>
                        {parse(line).spans.map((span) => (
                          <span
                            className={`max-w-full ${
                              span.color?.name
                                ? 'text-' + span.color?.name
                                : /^20\d{2}-/.test(span.text)
                                ? 'font-extrabold'
                                : span.text[0] === '['
                                ? 'text-orange'
                                : ''
                            }`}
                          >
                            {/^20\d{2}-/.test(span.text)
                              ? dayjs
                                  .tz(span.text.trim(), 'UTC')
                                  .tz(showLocalTime ? localTZ : 'UTC')
                                  .format(
                                    showLocalTime
                                      ? 'MMM DD. hh:mm:ss A'
                                      : 'MMM DD. HH:mm:ss',
                                  )
                              : span.text}
                          </span>
                        ))}
                      </p>
                    ))}
                  </InfiniteScroll>
                </div>
                <div className="hidden tailwind workaround text-lightMagenta text-red" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import { useState } from 'react';
import { PiCheckFatThin, PiXLight } from 'react-icons/pi';
import { InformationCircleIcon, PlayIcon } from '@heroicons/react/outline';
import * as Sentry from '@sentry/react';
import { XIcon } from 'lucide-react';

import { ResultQuizProblem, Tutorial } from '../../types';
import TutorialCard from '../TutorialCard';

const ProblemResultDetails = ({
  problem,
  index,
  tutorial,
}: {
  problem: ResultQuizProblem;
  tutorial?: Tutorial;
  index: number;
}) => {
  const [showTutorialCard, setShowTutorialCard] = useState(false);

  const handleWatchTutorial = () => {
    if (!tutorial) {
      Sentry.captureException(
        new Error('Cannot find tutorial for problem in ProblemResultDetails'),
        { tags: { problemId: problem.problemId } },
      );
      return;
    }
    setShowTutorialCard(true);
  };

  return (
    <div className="flex flex-col gap-3 md:flex-row bg-gray-100 rounded-xl p-3 md:p-4">
      <div className="flex gap-2 md:gap-4">
        <p className="text-gray-500 w-24">Problem {index + 1}</p>
        {problem.isStudentCorrect ? (
          <>
            <PiCheckFatThin className="w-6 h-6 text-green" />
            Correct
          </>
        ) : problem.answerKey && !problem.isStudentCorrect ? (
          <>
            <PiXLight className="w-6 h-6 text-rose-500" />
            Incorrect
          </>
        ) : problem.answerKey ? (
          <></>
        ) : (
          <>
            <InformationCircleIcon className="w-6 h-6 text-gray-500" />
            Skipped
          </>
        )}
        {/* FIXME: tutorials page can't search by problem */}
      </div>
      <button
        onClick={handleWatchTutorial}
        className="flex gap-1 items-center text-tttDefault w-max whitespace-nowrap hover:underline cursor-pointer md:ml-auto"
      >
        <PlayIcon className="h-5 w-5" />
        Watch Tutorial
      </button>
      {showTutorialCard && tutorial && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex z-100 w-full h-full">
          <div className="flex flex-col gap-4 bg-white w-full md:w-[80%] xl:w-[60%] p-4 md:p-6 my-auto mx-auto">
            <div className="flex justify-between items-center">
              <h2 className="text-xl md:text-2xl font-medium">
                Problem {index + 1} Tutorial
              </h2>
              <XIcon
                className="h-7 w-7 p-1 ml-auto text-gray-500 border border-gray-400 rounded-full cursor-pointer hover:bg-gray-200"
                onClick={() => setShowTutorialCard(false)}
              />
            </div>
            <TutorialCard {...tutorial} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProblemResultDetails;

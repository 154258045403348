import { FC } from 'react';

const Error: FC = () => {
  return (
    <div className="grid grid-flow-row place-content-center text-center auto-rows-auto p-6">
      <div className="m-16 text-3xl font-bold">Sorry for this error!</div>
      An error occured, but we captured the details and we are working on making
      this better.
      <br />
      'You will be redirected to your home page in 5 seconds to try to recover
      the app.'
      <div className="mt-8">
        Try to go back to your{' '}
        <a href="/" className="text-tttDefault hover:underline">
          Dashboard
        </a>{' '}
        or feel free to visit our website in the meantime
        <a
          href="https://www.thinktankprep.com/"
          target="_blank"
          className="text-tttDefault hover:underline"
          rel="noreferrer"
        >
          {' '}
          by clicking here
        </a>
        .
      </div>
      <div className="justify-self-center max-w-[40%] mt-16">
        <img src={'/maintenanceLogo.gif'} alt="Error..." />
      </div>
    </div>
  );
};

export default Error;

/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable unicorn/consistent-destructuring */
import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Modal from 'react-modal';
import { XIcon } from '@heroicons/react/outline';
import Select from 'react-select';

import { useStores } from '../stores/RootStore';
import { useAppSelector } from '../stores/AppStore';
import { selectHasOneOfTheRoles } from '../features/user/userSlice';

import { ChartData } from '../types';

import Card from '../components/Card';
import { Loading, LoadingBlock } from '../components/Loading';
import Button from '../components/Button';
import { ROLES } from '../constants';
import Chart from '../components/Chart';
import SwitchButton from '../components/Switch';
import { getGAtrackingEnabled, setGAtrackingEnabled } from '../util';
import Input from '../components/Input';
import { useGetSchoolsQuery } from '../services/apiSlice';

const BaseKPIDashboard: FC = () => {
  const {
    rootStore: { uiStore, statsStore },
  } = useStores();
  const {
    recalculateAllDashboardData,
    flushAllRedisCache,
    pushTroughAllReviews,
    startBenchmark,
    backgroundProcessingStatus,
  } = statsStore;
  const { user } = useAppSelector((state) => state.user);
  const isOwner = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.owner]),
  );
  const [schools, setSchools] = useState<string[]>([]);
  const [schoolFilter, setSchoolFilter] = useState<string>();
  const [chartData, setChartData] = useState<ChartData | undefined>();
  const [submissionCardsData, setSubmissionCardsData] = useState<{
    cumulative: number;
    review: number;
  }>();
  const [enabled, setEnabled] = useState(false);
  const defaultBenchmarkStudentCount = 1000;
  const [benchmarkStudentCount, setBenchmarkStudentCount] = useState<number>(
    defaultBenchmarkStudentCount,
  );
  const { refetch: refetchSchoolsData } = useGetSchoolsQuery();

  const handleToggle = () => {
    uiStore.setRefreshing(true);
    const setGAtracking = async () => {
      const setGAtrackingEnabledResult = await setGAtrackingEnabled(!enabled);
      setEnabled(() => !!setGAtrackingEnabledResult);
      uiStore.setRefreshing(false);
    };
    setGAtracking();
  };

  useEffect(() => {
    uiStore.setRefreshing(false);
    uiStore.didLoad(false);
    uiStore.shouldShowKPIModal(false);
    setSchools([]);
    setSchoolFilter('');
    const getKPIData = async () => {
      const schoolsResult = await refetchSchoolsData();
      if (schoolsResult.data) {
        setSchools(schoolsResult.data);
      }
      await statsStore.getKPIData('');
      const getGAtrackingEnabledResult = await getGAtrackingEnabled();
      setEnabled(() => !!getGAtrackingEnabledResult);
      uiStore.didLoad(true);
    };
    getKPIData();
  }, []);

  useEffect(() => {
    if (!schoolFilter || schoolFilter === '') {
      return;
    }
    uiStore.setRefreshing(true);
    const getKPIData = async () => {
      await statsStore.getKPIData(schoolFilter);
      if (statsStore.kpiData.school.submissionRates) {
        const { cumulative, review, ...rest } =
          statsStore.kpiData.school.submissionRates;
        setSubmissionCardsData(() => ({
          cumulative,
          review,
        }));
        setChartData(() => ({
          labels: Object.keys(rest),
          datasets: [
            {
              data: Object.values(rest).map((v) => v * 100),
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
          ],
        }));
      }
      uiStore.setRefreshing(false);
    };
    getKPIData();
  }, [schoolFilter]);

  const customStyles = {
    content: {
      overflow: 'hidden',
      borderRadius: '12px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      outerWidth: '50px',
      transform: 'translate(-50%, -50%)',
    },
  };

  if (!uiStore.loaded) {
    return <LoadingBlock />;
  }

  const fullRecalculationTime =
    uiStore.isRecalculating && backgroundProcessingStatus.isLocked
      ? backgroundProcessingStatus.elapsedTimeSec
      : statsStore.kpiData.fullRecalcTime[0]?.duration;

  return (
    <div className="grid gap-12">
      <div
        data-testid="app-stats-section"
        className={`grid grid-cols-1 gap-6 px-6 pb-6 pt-3 shadow-2xl rounded-xl border-tttDefault border-[1px]`}
      >
        <div
          className={`grid justify-self-center max-w-[90%] justify-items-center items-center text-center overflow-hidden uppercase text-white font-bold md:text-xl lg:text-2xl p-3 -mt-7 shadow-md rounded-xl bg-tttDefault`}
        >
          Application Statistics
        </div>
        <div className="absolute right-8">
          {uiStore.isRefreshing && <Loading />}
        </div>
        <div
          className={`grid justify-items-start grid-flow-row md:grid-flow-col md:grid-rows-[auto_1fr] gap-6 md:px-6 md:py-6 w-full`}
        >
          <div className="grid max-w-24 w-full md:w-[70%] md:col-start-1 gap-6 items-start">
            <Card
              id="total-students-card"
              title={'Total number of Students'}
              body={statsStore.kpiData.totalStudents}
              tooltipText={'Excluding demo students'}
              plainNumber={true}
            />
            <Card
              id="total-results-card"
              title={'Total number of Result Submissions'}
              body={statsStore.kpiData.totalResults}
              tooltipText={
                'For the current academic year, Excluding demo results'
              }
              plainNumber={true}
            />
          </div>
          <div
            className={`grid w-full gap-12 md:row-start-1 ${
              schoolFilter && chartData && 'md:row-span-2'
            } md:col-start-2`}
          >
            <div data-testid="school-select">
              <Select
                key={'school-select'}
                value={
                  schoolFilter
                    ? {
                        value: schoolFilter,
                        label: schoolFilter,
                      }
                    : undefined
                }
                onChange={(newValue) => {
                  setSchoolFilter(() => newValue?.label);
                }}
                placeholder={'Click to select a school'}
                options={schools?.map((school) => ({
                  value: school,
                  label: school,
                }))}
                className="justify-self-start w-[100%] h-max"
                styles={{
                  control: (base) => ({
                    ...base,
                    borderRadius: '0.5rem',
                    borderWidth: 2,
                    borderColor: 'rgba(0, 105, 217)',
                  }),
                }}
              />
            </div>
            {!!schoolFilter && chartData && (
              <>
                <Chart
                  id="school-submission-rate-chart"
                  key={JSON.stringify(chartData)}
                  title={'Submission rates'}
                  data={chartData}
                />
                <div className="grid grid-cols-3 gap-6">
                  <Card
                    id="school-cumulative-card"
                    title="Cumulative score"
                    body={
                      statsStore.kpiData.school?.overallResult
                        ? statsStore.kpiData.school.overallResult * 100
                        : undefined
                    }
                  />
                  <Card
                    id="school-submission-rate-card"
                    title={'Cumulative submission rate'}
                    body={submissionCardsData?.cumulative}
                    plainNumber={true}
                    precisePct={true}
                  />
                  <Card
                    id="school-review-submission-rate-card"
                    title={'Review submission rate'}
                    body={submissionCardsData?.review}
                    plainNumber={true}
                    precisePct={true}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div
        data-testid="db-metrics-section"
        className={`grid grid-cols-1 gap-6 px-6 pb-6 pt-3 shadow-2xl rounded-xl border-tttDefault border-[1px]`}
      >
        <div
          className={`grid justify-self-center max-w-[90%] justify-items-center items-center text-center overflow-hidden uppercase text-white font-bold md:text-xl lg:text-2xl p-3 -mt-7 shadow-md rounded-xl bg-tttDefault`}
        >
          Database Health Metrics
        </div>
        <div className="md:text-lg font-medium md:px-6">
          Dashboard data recalculation times
        </div>
        <div
          className={`grid grid-flow-row md:grid-flow-col md:grid-cols-3 gap-6 md:gap-16 md:px-6 md:pb-6 w-full`}
        >
          <Card
            id="student-recalc-card"
            title={'Student dashboard'}
            body={statsStore.kpiData.studentRecalcTime[0]?.duration}
            tooltipText={
              'Recalculation time for a single student after submitting a result. This is not blocking the review for the student.'
            }
            plainNumber={true}
            postFix={' sec'}
          />
          <Card
            id="school-class-teacher-recalc-card"
            title={'School/class/teacher dashboard'}
            body={statsStore.kpiData.schoolClassStudentRecalcTime[0]?.duration}
            tooltipText={
              'Recalculation time for the teachers and school admins after a student submitted a result.'
            }
            plainNumber={true}
            postFix={' sec'}
          />
          <Card
            id="complete-recalc-card"
            title={'Complete dashboard data '}
            body={fullRecalculationTime}
            tooltipText={
              'Full recalculation time. You may trigger this below manually and it runs periodically scheduled as well.'
            }
            plainNumber={true}
            postFix={' sec'}
          />
        </div>
      </div>
      {isOwner && (
        <div
          data-testid="app-settings-section"
          className={`grid grid-cols-1 gap-6 px-6 pb-6 pt-3 shadow-2xl rounded-xl border-tttDefault border-[1px]`}
        >
          <div
            className={`grid justify-self-center max-w-[90%] justify-items-center items-center text-center overflow-hidden uppercase text-white font-bold md:text-xl lg:text-2xl p-3 -mt-7 shadow-md rounded-xl bg-tttDefault`}
          >
            Application Settings
          </div>
          <div className="absolute right-8">
            {uiStore.isRefreshing && <Loading />}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 px-6 pb-6 pt-3 w-full text-center ">
            <div className="grid grid-rows-2 items-center">
              <p className="font-medium">Recalculate all dashboard data</p>
              <Button
                id="recalculate-button"
                action={() =>
                  uiStore.shouldShowKPIModal(
                    true,
                    recalculateAllDashboardData.bind(statsStore),
                  )
                }
                bgColor={'bg-rose-500'}
                bgColorHover={'bg-rose-600'}
                buttonText={'Recalculate'}
                textColor={'text-white'}
                disabled={uiStore.isRefreshing}
                disabledProgress={
                  uiStore.isRecalculating
                    ? backgroundProcessingStatus.progress
                    : undefined
                }
              />
            </div>
            <div className="grid grid-rows-2 items-center">
              <p className="font-medium">Flush all Redis cache</p>
              <Button
                id="flush-redis-button"
                action={() =>
                  uiStore.shouldShowKPIModal(
                    true,
                    flushAllRedisCache.bind(statsStore),
                  )
                }
                bgColor={'bg-rose-500'}
                bgColorHover={'bg-rose-600'}
                buttonText={'Flush'}
                textColor={'text-white'}
                disabled={uiStore.isRefreshing}
              />
            </div>
            <div className="grid grid-rows-2 items-center">
              <p className="font-medium">
                Push all students trough the last review
              </p>
              <Button
                id="push-through-review-button"
                action={() =>
                  uiStore.shouldShowKPIModal(
                    true,
                    pushTroughAllReviews.bind(statsStore),
                    'pushTroughAll',
                  )
                }
                bgColor={'bg-rose-500'}
                bgColorHover={'bg-rose-600'}
                buttonText={'Push trough'}
                textColor={'text-white'}
                disabled={uiStore.isRefreshing}
              />
            </div>
            <div className="grid grid-rows-2 gap-3 justify-items-center items-center">
              <p className="font-medium">Google Analytics Tracking</p>
              <SwitchButton
                id="GA-button"
                checked={enabled}
                loading={uiStore.isRefreshing}
                onChange={handleToggle}
                enabledText="Enabled"
                disabledText="Disabled"
              />
            </div>
            {user?.email === 'zsolt.bako@toptal.com' && (
              <>
                <div className="grid grid-rows-2 items-center">
                  <p className="font-medium">Benchmarking</p>
                  <div className="flex flex-row">
                    <Input
                      name="benchmark-student-count"
                      type="number"
                      value={benchmarkStudentCount}
                      min={1}
                      max={10_000}
                      disabled={uiStore.isRefreshing}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        setBenchmarkStudentCount(Number(event.target.value));
                      }}
                      style={{
                        fontSize: '16px',
                        letterSpacing: '3px',
                        textAlign: 'right',
                        width: '50%',
                      }}
                    />
                    <div className="self-center mb-4 ml-2">students</div>
                  </div>
                  <Button
                    id="run-benchmark-button"
                    action={() =>
                      uiStore.shouldShowKPIModal(
                        true,
                        startBenchmark.bind(statsStore, benchmarkStudentCount),
                      )
                    }
                    bgColor={'bg-rose-500'}
                    bgColorHover={'bg-rose-600'}
                    buttonText={'Run benchmark'}
                    textColor={'text-white'}
                    disabled={uiStore.isRefreshing}
                    disabledProgress={
                      uiStore.isBenchmarking
                        ? Math.round(
                            backgroundProcessingStatus.progressPercentage * 100,
                          ) + ' %'
                        : undefined
                    }
                  />
                </div>
                {backgroundProcessingStatus.progressPercentage ? (
                  <div className="grid grid-rows-2 items-center">
                    <div>{backgroundProcessingStatus.progress}</div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      )}
      <Modal
        isOpen={uiStore.KPIModal.show}
        onRequestClose={() => uiStore.shouldShowKPIModal(false)}
        style={customStyles}
      >
        <>
          <div
            className="absolute grid justify-items-end w-full pr-8 cursor-pointer"
            onClick={() => uiStore.shouldShowKPIModal(false)}
          >
            <XIcon className="h-5 w-5" />
          </div>
          <div className="text-sm text-slate-500 w-[95%] break-words max-w-[400px] p-3">
            {uiStore.KPIModal.actionName === 'pushTroughAll' &&
              'This action pushes ALL students through their last unfinished reviews.'}
            {uiStore.KPIModal.actionName !== 'pushTroughAll' &&
              'This action has a temporary performance impact on the application.'}
            <br />
            It cannot be undone. Do you want to proceed?
          </div>
          <div className="grid justify-items-center items-center md:mt-0">
            <Button
              action={() => {
                if (uiStore.KPIModal.action) {
                  uiStore.KPIModal.action();
                }
                uiStore.shouldShowKPIModal(false);
              }}
              bgColor={'bg-blue-300'}
              bgColorHover={'bg-blue-400'}
              buttonText={'Continue'}
              disabled={uiStore.isRefreshing}
            />
          </div>
        </>
      </Modal>
    </div>
  );
};

const KPIDashboard = observer(BaseKPIDashboard);
export default KPIDashboard;

import { ReactNode, useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { MathJax } from 'better-react-mathjax';
import { FastForwardIcon, ReplyIcon } from '@heroicons/react/outline';
import toast from 'react-hot-toast';

import { Skeleton } from '../components/ui/skeleton';
import {
  Table,
  TableCaption,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from '../components/ui/table';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from '../components/ui/card';
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../components/ui/tooltip';
import { ScrollArea, ScrollBar } from '../components/ui/scroll-area';
import { ErrorMessage } from '../components/ErrorMessage';
import { formatSeconds, getTextColor } from '../util';
import { ProblemTotalScore, QuizResultStats, StudentScores } from '../types';
import { ROLES } from '../constants';
import Modal from '../components/ui/modal';
import TutorialCard from '../components/TutorialCard';
import { LoadingBlock } from '../components/Loading';
import { useAppSelector } from '../stores/AppStore';
import {
  useDeleteUserResultMutation,
  useGetQuizScoresQuery,
  useGetTutorialsForQuizzesQuery,
  usePushUserThroughReviewMutation,
} from '../services/apiSlice';
import { selectHasOneOfTheRoles } from '../features/user/userSlice';

interface Properties {
  id?: string;
  actionButtons?: {
    left?: { icon: ReactNode; action: () => void };
  };
  allowDeletingResult?: boolean;
  allowPushingThroughReview?: boolean;
  caption?: string;
  dataQuery: {
    school?: string;
    trackId?: string;
    classId?: string;
    quizId?: string;
    studentId?: string;
  };
  highlightRow?: string;
  isStudentList: boolean;
  onClickName?: (studentId: string, studentName: string) => void;
  scoresRef: React.Ref<HTMLDivElement>;
  showPracticeDetails?: boolean;
  showReviewColumn?: boolean;
  showTime?: boolean;
  showTotalScores?: boolean;
  testId?: string;
}

export function QuizScores({
  id,
  actionButtons,
  allowDeletingResult,
  allowPushingThroughReview,
  caption,
  dataQuery,
  highlightRow,
  isStudentList,
  onClickName,
  scoresRef,
  showPracticeDetails,
  showReviewColumn,
  showTime,
  showTotalScores,
  testId,
}: Properties) {
  const isStudentOrThinker = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.student, ROLES.thinker]),
  );
  const isMentor = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.mentor]),
  );
  const roles = useAppSelector((state) => state.user.user?.roles) ?? [];
  const { data, isFetching, isLoading, error, refetch } = useGetQuizScoresQuery(
    {
      school: dataQuery.school,
      trackId: dataQuery.trackId,
      classId: dataQuery.classId,
      quizId: dataQuery.quizId,
      roles,
      studentId: dataQuery.studentId,
    },
  );
  const { data: tutorialsData, isLoading: tutorialsAreLoading } =
    useGetTutorialsForQuizzesQuery(
      {
        quizIds: data?.quizScores?.scores.map((s) => s.name) ?? [],
        thinkerId: dataQuery.studentId,
      },
      {
        skip:
          (!isStudentOrThinker && !isMentor) ||
          (isMentor && !dataQuery.studentId) ||
          !data?.quizScores?.scores,
      },
    );
  const [deleteResult, { isLoading: isDeletingResult }] =
    useDeleteUserResultMutation();
  const [pushThroughReview, { isLoading: isPushingThroughReview }] =
    usePushUserThroughReviewMutation();
  const [highlightedRow, setHighlightedRow] = useState<string>();
  const [hoveredRow, setHoveredRow] = useState<{
    studentId: string;
    name: string;
  }>();
  const [selectedProblemIndex, setSelectedProblemIndex] = useState<number>();
  // eslint-disable-next-line unicorn/consistent-function-scoping
  const getTimeColor = (answerDuration: number) => {
    // if the answerDuration was less than 3 minutes
    return answerDuration <= 180 ? 'text-rose-500' : 'text-tttDefault';
  };

  useEffect(() => {
    setHighlightedRow(highlightRow);
  }, [highlightRow]);

  useEffect(() => {
    if (!highlightRow) {
      // eslint-disable-next-line unicorn/no-useless-undefined
      setHighlightedRow(undefined);
    }
  }, [data]);

  const problemModalReference = useRef<HTMLDialogElement>();

  const handleNameClick = (studentId: string, studentName: string) => {
    setHighlightedRow(studentName);
    if (onClickName) {
      onClickName(studentId, studentName);
    }
  };

  const handleDeleteResult = async () => {
    if (!id || !hoveredRow || !allowDeletingResult) {
      return;
    }

    const commonText = `${id} result of ${hoveredRow.name}`;
    const confirmed = window.confirm(
      `Are you sure you would like to delete ${commonText} to allow submitting this Quiz again?`,
    );
    if (!confirmed) {
      return;
    }
    try {
      await deleteResult({
        userId: hoveredRow.studentId,
        quizId: id,
      }).unwrap();
    } catch (error: any) {
      let errorText = '';
      if (error?.status === 400 || error?.status === 403) {
        errorText += error?.data?.message;
      }
      errorText
        ? toast.error(errorText)
        : toast.error(
            `Some unexpected error happened while trying to delete the ${commonText}. Please try again later!`,
          );
      return;
    }
    await refetch();
    toast.success(`The ${commonText} was deleted successfully.`);
  };

  const handlePushingThroughReview = async () => {
    if (!id || !hoveredRow || !allowPushingThroughReview) {
      return;
    }

    const commonText = `${hoveredRow.name} through the solution review of ${id}`;
    const confirmed = window.confirm(
      `Are you sure you would like push ${hoveredRow.name} through the solution review of ${id} to allow using all features of the app before the end of the week? If you do this because the student experienced technical issues, then please let us know.`,
    );
    if (!confirmed) {
      return;
    }
    try {
      await pushThroughReview(hoveredRow.studentId).unwrap();
    } catch (error: any) {
      let errorText = '';
      if (error?.status === 400 || error?.status === 403) {
        errorText += error?.data?.message;
      }
      errorText
        ? toast.error(errorText)
        : toast.error(
            `Some unexpected error happened while trying to push ${commonText}. Please try again later!`,
          );
      return;
    }
    await refetch();
    toast.success(`Push ${commonText} was successful.`);
  };

  const isQuizScoresEmpty = () =>
    (data?.quizScores?.scores as StudentScores[]).every(
      (score) => !score.hasOwnProperty('score'),
    );

  if (isLoading) {
    return (
      <Card ref={scoresRef} data-testid={testId ?? 'quiz-scores'}>
        <CardHeader>
          <CardTitle>Assignment Results{id && ` - ${id}`}</CardTitle>
        </CardHeader>
        <CardContent>
          <ScrollArea className="flex-1 max-w-[75vw] md:max-w-[90vw]">
            <ScrollBar orientation="horizontal" />
            <Table>
              {caption && <TableCaption>{caption}</TableCaption>}
              <TableHeader>
                <TableRow className="hover:bg-inherit">
                  <TableHead className="w-[60px]">Quiz</TableHead>
                  <TableHead className="text-right">Score</TableHead>
                  <TableHead className="text-right">Practice</TableHead>
                  {[...Array.from({ length: 10 }).keys()].map(
                    (number_: number) => (
                      <TableHead key={number_} className="text-center">
                        {number_ + 1}
                      </TableHead>
                    ),
                  )}
                </TableRow>
              </TableHeader>
              <TableBody>
                {[...Array.from({ length: 5 }).keys()].map(
                  (rowNumber: number) => (
                    <TableRow key={rowNumber}>
                      <TableCell className="">
                        <Skeleton className="w-[60px] h-[20px] rounded-full" />
                      </TableCell>
                      <TableCell className="flex justify-end">
                        <Skeleton className="w-[40px] h-[20px] rounded-full" />
                      </TableCell>
                      {[...Array.from({ length: 10 }).keys()].map(
                        (cellNumber: number) => (
                          <TableCell key={cellNumber}>
                            <div className="flex justify-center">
                              <Skeleton className="w-[20px] h-[20px] rounded-full" />
                            </div>
                          </TableCell>
                        ),
                      )}
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </ScrollArea>
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <ErrorMessage
        text={`Failed to load the detailed scores: ${(error as any).message}`}
      />
    );
  }

  const getTutorialCard = (quizId: string, problemIndex: number) => {
    const errorMessage = 'Sorry, the details could not be loaded at the moment';
    if (!tutorialsData || !quizId || !Number.isInteger(problemIndex)) {
      Sentry.captureException(
        new Error('Cannot generate Tutorial Card in QuizScores modal'),
        {
          tags: {
            quizId,
            problemIndex,
            tutorialsAreLoading,
          },
        },
      );
      return <ErrorMessage text={errorMessage} />;
    }
    const scores = (data?.quizScores?.scores as QuizResultStats[]).find(
      (s) => s.name === quizId,
    );
    if (!scores) {
      Sentry.captureException(
        new Error('Cannot find quizId in scores in QuizScores modal'),
        {
          tags: {
            quizId,
          },
        },
      );
      return <ErrorMessage text={errorMessage} />;
    }
    const problemNumber = scores.problemNums
      ? scores.problemNums[problemIndex]
      : undefined;
    if (!problemNumber) {
      Sentry.captureException(
        new Error(
          'Cannot find problemNumber in problemNums in QuizScores modal',
        ),
        {
          tags: {
            quizId,
            problemIndex,
            problemNums: JSON.stringify(scores.problemNums),
          },
        },
      );
      return <ErrorMessage text={errorMessage} />;
    }
    const tutorial = tutorialsData.find((t) =>
      scores.problemNums
        ? t.problemNum === scores.problemNums[problemIndex]
        : false,
    );
    if (!tutorial) {
      Sentry.captureException(
        new Error('Cannot find problem in tutorialsData in QuizScores modal'),
        {
          tags: {
            quizId,
            problemIndex,
            tutorialsAreLoading,
            tutorialsDataLength: tutorialsData.length,
          },
        },
      );
      return <ErrorMessage text={errorMessage} />;
    }
    return <TutorialCard {...tutorial} />;
  };

  const firstScoreWithResult = data?.quizScores?.scores
    ? (data?.quizScores?.scores as StudentScores[]).find(
        (quizScore: StudentScores) => Array.isArray(quizScore?.isAnswerCorrect),
      )
    : { isAnswerCorrect: [] };
  const numberOfProblemsSizeArray = firstScoreWithResult
    ? (firstScoreWithResult.isAnswerCorrect as boolean[])
    : [];

  return (
    <Card ref={scoresRef}>
      <CardHeader
        isFetching={isFetching || isDeletingResult || isPushingThroughReview}
      >
        <CardTitle>Assignment Results{id && ` - ${id}`}</CardTitle>
        <div
          onClick={() => {
            actionButtons?.left?.action();
            // eslint-disable-next-line unicorn/no-useless-undefined
            setHighlightedRow(undefined);
          }}
        >
          {actionButtons?.left?.icon}
        </div>
      </CardHeader>
      <CardContent className="flex justify-center">
        {isQuizScoresEmpty() ? (
          <div className="text-center text-gray-500 p-8">
            No assignment results are available.
          </div>
        ) : (
          <>
            {!isStudentList &&
              (isStudentOrThinker || isMentor) &&
              !!highlightedRow &&
              selectedProblemIndex !== undefined && (
                <Modal
                  modalRef={problemModalReference}
                  title={`${highlightedRow} - Problem ${
                    selectedProblemIndex + 1
                  }`}
                >
                  {tutorialsAreLoading || !tutorialsData ? (
                    <LoadingBlock />
                  ) : (
                    getTutorialCard(highlightedRow, selectedProblemIndex)
                  )}
                </Modal>
              )}
            <ScrollArea className="flex-1 max-w-[75vw] md:max-w-[90vw]">
              <ScrollBar orientation="horizontal" />
              <Table>
                {caption && <TableCaption>{caption}</TableCaption>}
                <TableHeader>
                  <TableRow className="hover:bg-inherit">
                    <TableHead className="w-[100px]">
                      {isStudentList ? 'Student' : 'Quiz'}
                    </TableHead>
                    <TableHead
                      className="text-right"
                      title="Overall score of the live quiz"
                    >
                      Score
                    </TableHead>
                    {showTime && (
                      <TableHead
                        className="text-right"
                        title="Time needed to take the live quiz, excluding the time spent on reviewing the quiz"
                      >
                        Time <br />
                        (exc. Review)
                      </TableHead>
                    )}
                    {(
                      data?.quizScores?.problemTotals ??
                      numberOfProblemsSizeArray
                    ).map(
                      (value: ProblemTotalScore | boolean, index: number) => (
                        <TableHead key={index} className="text-center">
                          {typeof value !== 'boolean' &&
                          value?.skills &&
                          Array.isArray(value.skills) ? (
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger>{index + 1}</TooltipTrigger>
                                <TooltipContent>
                                  <TooltipArrow />
                                  <div className="text-left font-bold">
                                    Question
                                  </div>
                                  <p className="text-left font-normal pb-2">
                                    <MathJax dynamic>{value.question}</MathJax>
                                  </p>
                                  <div className="text-left font-bold">
                                    Skill
                                    {value.skills.length > 1 ? 's' : ''}
                                  </div>
                                  <ul className="list-disc mx-4 text-left font-normal">
                                    {value.skills.map((skill: string) => (
                                      <li key={skill}>{skill}</li>
                                    ))}
                                  </ul>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          ) : (
                            index + 1
                          )}
                        </TableHead>
                      ),
                    )}
                    {showReviewColumn && (
                      <TableHead
                        className="text-center"
                        title="Indicates whether the student completed the solution review of the quiz or not"
                      >
                        Review
                      </TableHead>
                    )}
                    <TableHead
                      className="text-right"
                      title="Overall result of the last practice. It doesn't affect any other live results"
                    >
                      Practice
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {data?.quizScores?.scores?.map((quizScoreData) => (
                    <TableRow
                      key={quizScoreData.name}
                      className={
                        highlightedRow === quizScoreData.name
                          ? 'bg-blue-200'
                          : ''
                      }
                      onClick={() => setHighlightedRow(quizScoreData.name)}
                      onMouseEnter={() =>
                        setHoveredRow({
                          studentId: (quizScoreData as QuizResultStats)
                            .studentId,
                          name: quizScoreData.name,
                        })
                      }
                      onMouseLeave={() => setHoveredRow(undefined)}
                    >
                      <TableCell
                        className={`font-medium hover:cursor-pointer ${
                          quizScoreData.score === undefined && 'text-gray-400'
                        }`}
                        onClick={() =>
                          handleNameClick(
                            (quizScoreData as QuizResultStats).studentId,
                            quizScoreData.name,
                          )
                        }
                      >
                        {quizScoreData.name}
                      </TableCell>
                      <TableCell
                        className={`text-right font-bold ${getTextColor(
                          quizScoreData.score ? quizScoreData.score * 100 : 0,
                        )}`}
                      >
                        {typeof quizScoreData.score === 'number' &&
                          `${Math.round(quizScoreData.score * 100)}%`}
                      </TableCell>
                      {showTime && (
                        <TableCell
                          className={`text-right font-medium ${getTimeColor(
                            (quizScoreData as QuizResultStats).answerDuration,
                          )}`}
                        >
                          {typeof (quizScoreData as QuizResultStats)
                            .answerDuration === 'number' &&
                            formatSeconds(
                              (quizScoreData as QuizResultStats).answerDuration,
                            )}
                        </TableCell>
                      )}
                      {quizScoreData.isAnswerCorrect &&
                        quizScoreData.isAnswerCorrect.map(
                          (isCorrect, index: number) => (
                            <TableCell
                              key={index}
                              className={`text-center ${
                                isStudentList ||
                                (!isStudentOrThinker && !isMentor)
                                  ? ''
                                  : 'cursor-pointer'
                              }`}
                              title={
                                isStudentList ||
                                (!isStudentOrThinker && !isMentor)
                                  ? undefined
                                  : 'Click to show the details'
                              }
                              onClick={() => {
                                setHighlightedRow(quizScoreData.name);
                                setSelectedProblemIndex(index);
                                if (!isStudentList) {
                                  setTimeout(() => {
                                    problemModalReference.current?.showModal();
                                  }, 50);
                                }
                              }}
                            >
                              <div
                                key={`${quizScoreData.name}-${index}`}
                                className={`font-bold text-center ${
                                  isCorrect === null
                                    ? 'text-gray-500 text-opacity-80'
                                    : isCorrect
                                    ? 'text-emerald-500'
                                    : 'text-rose-500'
                                }`}
                              >
                                {isCorrect === null
                                  ? '∅'
                                  : isCorrect
                                  ? '✔'
                                  : '✘'}
                              </div>
                            </TableCell>
                          ),
                        )}
                      {!quizScoreData.isAnswerCorrect &&
                        (
                          data?.quizScores?.problemTotals ??
                          numberOfProblemsSizeArray
                        ).map((_value: any, index: number) => (
                          <TableCell
                            key={index}
                            className="text-center"
                          ></TableCell>
                        ))}
                      {showReviewColumn && (
                        <TableCell className="text-center">
                          <div className="flex gap-2 items-center w-12 mx-auto">
                            <div
                              className={`text-center w-full ${
                                'pushedThroughBy' in quizScoreData &&
                                quizScoreData.pushedThroughBy !== undefined
                                  ? 'text-orange'
                                  : quizScoreData.didReviewQuiz
                                  ? 'text-emerald-500'
                                  : 'text-rose-500'
                              }`}
                            >
                              {quizScoreData.didReviewQuiz === undefined
                                ? ''
                                : quizScoreData.didReviewQuiz === true
                                ? '✔'
                                : '✘'}
                            </div>
                            <div
                              className={`flex justify-center items-center ${
                                hoveredRow?.studentId ===
                                  (quizScoreData as QuizResultStats)
                                    .studentId &&
                                quizScoreData.didReviewQuiz !== undefined &&
                                allowDeletingResult
                                  ? 'block'
                                  : 'hidden'
                              } ${
                                isDeletingResult
                                  ? 'cursor-not-allowed'
                                  : 'cursor-pointer'
                              }`}
                              onClick={
                                isDeletingResult
                                  ? undefined
                                  : handleDeleteResult
                              }
                            >
                              <TooltipProvider>
                                <Tooltip>
                                  <TooltipTrigger>
                                    <ReplyIcon
                                      className={`w-4 h-4 text-gray-600 ${
                                        isDeletingResult ? 'animate-pulse' : ''
                                      }`}
                                    />
                                  </TooltipTrigger>
                                  <TooltipContent>
                                    <TooltipArrow />
                                    <div className="text-left font-bold">
                                      Delete {id} result of {quizScoreData.name}
                                    </div>
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            </div>
                            <div
                              className={`flex justify-center items-center ${
                                hoveredRow?.studentId ===
                                  (quizScoreData as QuizResultStats)
                                    .studentId &&
                                quizScoreData.didReviewQuiz === false &&
                                allowPushingThroughReview
                                  ? 'block'
                                  : 'hidden'
                              } ${
                                isPushingThroughReview
                                  ? 'cursor-not-allowed'
                                  : 'cursor-pointer'
                              }`}
                              onClick={
                                isPushingThroughReview
                                  ? undefined
                                  : handlePushingThroughReview
                              }
                            >
                              <TooltipProvider>
                                <Tooltip>
                                  <TooltipTrigger>
                                    <FastForwardIcon
                                      className={`my-auto w-4 h-4 text-gray-600 ${
                                        isPushingThroughReview
                                          ? 'animate-pulse'
                                          : ''
                                      }`}
                                    />
                                  </TooltipTrigger>
                                  <TooltipContent>
                                    <TooltipArrow />
                                    <div className="text-left font-bold">
                                      Push {quizScoreData.name} through {id}{' '}
                                      review
                                    </div>
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            </div>
                          </div>
                        </TableCell>
                      )}
                      {showPracticeDetails && (
                        <TableCell
                          className={`text-right font-bold ${getTextColor(
                            (quizScoreData as QuizResultStats).practiceResult
                              ?.quizResult
                              ? ((quizScoreData as QuizResultStats)
                                  ?.practiceResult?.quizResult ?? 0) * 100
                              : 0,
                          )}`}
                          title={
                            (quizScoreData as QuizResultStats).practiceResult
                              ?.answerDuration
                              ? `Last practice completed in ${formatSeconds(
                                  (quizScoreData as QuizResultStats)
                                    .practiceResult?.answerDuration ?? 0,
                                )}`
                              : ''
                          }
                        >
                          {typeof (quizScoreData as QuizResultStats)
                            .practiceResult?.quizResult === 'number' &&
                            `${Math.round(
                              ((quizScoreData as QuizResultStats)
                                ?.practiceResult?.quizResult ?? 0) * 100,
                            )}%`}
                        </TableCell>
                      )}
                      {!showPracticeDetails && (
                        <TableCell
                          className={`text-center font-bold text-green`}
                        >
                          {typeof (quizScoreData as QuizResultStats)
                            .practiceResult?.quizResult === 'number' && '✔'}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                  {showTotalScores && (
                    <TableRow>
                      <TableHead className="w-[100px]">Average</TableHead>
                      <TableHead
                        className={`text-right font-bold ${getTextColor(
                          data?.quizScores?.averageScore
                            ? data?.quizScores?.averageScore * 100
                            : 0,
                        )}`}
                      >
                        {typeof data?.quizScores?.averageScore === 'number' &&
                          `${Math.round(
                            data?.quizScores?.averageScore * 100,
                          )}%`}
                      </TableHead>
                      <TableCell
                        className={`text-right font-medium ${getTimeColor(
                          data?.quizScores?.averageAnswerDuration ?? 0,
                        )}`}
                      >
                        {typeof data?.quizScores?.averageAnswerDuration ===
                          'number' &&
                          formatSeconds(
                            data?.quizScores?.averageAnswerDuration,
                          )}
                      </TableCell>
                      {data?.quizScores?.problemTotals.map((total) => (
                        <TableCell
                          className={`text-center font-bold ${getTextColor(
                            total.result ? total.result * 100 : 0,
                          )}`}
                          key={total.problemId}
                        >
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger>
                                {typeof total.result === 'number' &&
                                  `${Math.round(total.result * 100)}%`}
                              </TooltipTrigger>
                              {total.skills && Array.isArray(total.skills) && (
                                <TooltipContent>
                                  <TooltipArrow />
                                  <div className="text-left font-bold">
                                    Question
                                  </div>
                                  <p className="text-left font-normal pb-2">
                                    <MathJax dynamic>{total.question}</MathJax>
                                  </p>
                                  <div className="text-left font-bold">
                                    Skill
                                    {total.skills.length > 1 ? 's' : ''}
                                  </div>
                                  <ul className="list-disc mx-4 text-left font-normal">
                                    {total.skills.map((skill: string) => (
                                      <li key={skill}>{skill}</li>
                                    ))}
                                  </ul>
                                </TooltipContent>
                              )}
                            </Tooltip>
                          </TooltipProvider>
                        </TableCell>
                      ))}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </ScrollArea>
          </>
        )}
      </CardContent>
    </Card>
  );
}

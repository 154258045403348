import { AlertCircle } from 'lucide-react';

import { Alert, AlertDescription, AlertTitle } from '../components/ui/alert';

export function ErrorMessage({ text }: { text: string }) {
  return (
    <Alert variant="destructive" className="text-left">
      <div className="flex gap-1">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
      </div>
      <AlertDescription>
        {text}
        <br />
        Please try again later
      </AlertDescription>
    </Alert>
  );
}

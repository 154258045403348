const LoadingSnake = () => {
  return (
    <div className="flex space-x-2">
      <div className="dot" />
      <div className="dot" />
      <div className="dot" />
    </div>
  );
};

export default LoadingSnake;

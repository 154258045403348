/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import {
  ChartData,
  StudentTrackWithSchedule,
  StudentQuizResult,
  StudentResultOverview,
} from '../types';
import { Subject } from '../constants';

import {
  apiSlice,
  useGetStudentOrThinkerGAPSResultsQuery,
  useGetStudentOrThinkerSummaryDataQuery,
} from '../services/apiSlice';
import { useAppSelector } from '../stores/AppStore';

import Chart from '../components/Chart';
import { LoadingBlock } from '../components/Loading';
import LearningTrackProgress from '../partials/LearningTrackProgress';
import ThinkerTasks from '../partials/ThinkerTasks';
import GapsSummary from '../partials/GapsSummary';
import SubjectSkillResults from '../components/SubjectSkillResults';
import WelcomeVideoPopup from '../components/WelcomeVideoPopup';

dayjs.extend(isBetween);

const StudentSummary: FC = () => {
  const [, setSelectedQuiz] = useState<
    | {
        value: StudentQuizResult;
        label: string;
      }
    | undefined
  >();
  const [selectedSubject, setSelectedSubject] = useState<Subject>(
    Subject.geometry,
  );

  const user = useAppSelector((state) => state.user.user);

  const {
    data: summaryData,
    isLoading,
    isError,
  } = useGetStudentOrThinkerSummaryDataQuery(
    { studentId: user?._id ?? '' },
    { skip: !user?._id },
  );

  const [skillsData, setSkillsData] = useState<
    {
      skill: string;
      description: string;
      overallResult: number;
      tutorialsLink: string;
    }[]
  >();

  const { data: subjectResult, isFetching } =
    useGetStudentOrThinkerGAPSResultsQuery(
      {
        userId: user?._id ?? '',
        subject: selectedSubject,
      },
      {
        skip: !user?._id,
      },
    );

  useEffect(() => {
    if (subjectResult && subjectResult.skillResults) {
      // TODO: merge and replace string data with rich object containing description and subject
      const mergedSkills = subjectResult.skillResults
        .map((item) => ({
          skill: item.skill,
          description: item.description,
          overallResult: item.overallResult,
          tutorialsLink: `/tutorials/${item.subject}?skills=${item.skill}`,
        }))
        .sort((a, b) => a.overallResult - b.overallResult);
      setSkillsData(mergedSkills);
    }
  }, [subjectResult]);

  useEffect(() => {
    if (!summaryData?.quizResults || !summaryData?.quizResults[0]) {
      return;
    }
    setSelectedQuiz(() => ({
      label: summaryData?.quizResults![0].quiz,
      value: summaryData?.quizResults![0],
    }));
  }, [summaryData?.quizResults]);

  // Currently unused
  // useEffect(() => {
  //   if (
  //     dayjs().isBetween('2023-08-30', '2023-10-29') &&
  //     !didWatchWelcomeVideo
  //   ) {
  //     // show video
  //   } else {
  //     // don't show video
  //   }
  // }, []);

  const prefetchStudentSkillsData = apiSlice.usePrefetch(
    'getStudentOrThinkerGAPSResults',
  );

  if (isLoading) {
    return <LoadingBlock />;
  } else if (isError) {
    throw new Error('Failed to load Student summary data');
  } else if (!summaryData) {
    return <div>You don't have any data to display yet.</div>;
  }

  const { completedAssignments } = summaryData as StudentTrackWithSchedule;
  const labels: any[] = [];
  const lineData: any[] = [];
  if (completedAssignments) {
    for (const completedAssignment of completedAssignments) {
      const result = Number.parseFloat(
        (completedAssignment.result * 100).toFixed(2),
      );
      if (Number.isFinite(result)) {
        labels.push(`${completedAssignment.id}`);
        lineData.push(result);
      }
    }
  }
  const chartData: ChartData = {
    labels,
    datasets: [
      {
        data: lineData,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        trendlineLinear: {
          color: 'rgba(53, 162, 235, 0.5)',
          lineStyle: 'dotted',
          width: 2,
          style: {},
        },
      },
    ],
  };

  return (
    <div className="grid gap-12">
      <div
        className="
          relative
          grid gap-4 p-4 md:p-8
          w-full h-full items-center 
        bg-gray-100 rounded-xl
        "
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <WelcomeVideoPopup />
          <div>
            <h1 className="text-3xl md:text-4xl font-medium w-full mb-4">
              Welcome, {user?.firstName}!
            </h1>
            <p className="text-gray-500 mb-6">
              It’s great to see you here! Let’s perfect your math skills
              together.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 bg-gray-100 rounded-xl py-4">
              <div className="flex gap-2">
                <img
                  src="/images/icons/thinker.svg"
                  alt="star-icon"
                  className="text-tttDefault w-5 h-5 pt-1"
                />
                <div className="w-[90%]">
                  <p className="text-gray-500">Your email</p>
                  <p
                    className="text-gray-800 overflow-ellipsis whitespace-nowrap overflow-hidden"
                    title={user?.email}
                  >
                    {user?.email}
                  </p>
                </div>
              </div>
              <div className="flex gap-2">
                <img
                  src="/images/icons/track.svg"
                  alt="star-icon"
                  className="text-tttDefault w-5 h-5 pt-1"
                />
                <div>
                  <p className="text-gray-500">Your learning track</p>
                  <p className="text-gray-800">{summaryData.track}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden md:block">
            <img
              src="/images/thinker-illustration.svg"
              alt="illustration"
              className="w-full h-full max-h-[200px] object-contain"
            />
          </div>
        </div>
        <LearningTrackProgress
          result={
            summaryData as StudentTrackWithSchedule & StudentResultOverview
          }
          variety="thinker"
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <ThinkerTasks />
          <GapsSummary
            subjectResults={summaryData.subjectResults}
            mainScore={summaryData.overallResult}
          />
        </div>
        {chartData && chartData.datasets[0]?.data?.length > 0 && (
          <Chart
            id="chart"
            title="Quiz Results"
            data={chartData}
            areDatapointsClickable={true}
          />
        )}
        <div id="skill-breakout" className="p-2" />
        <SubjectSkillResults
          title={`Skills Breakout`}
          subjectResults={summaryData?.subjectResults ?? []}
          skillResults={skillsData ?? []}
          isSkillResultsLoading={isFetching}
          onSubjectSelect={(subject) => {
            setSelectedSubject(subject);
          }}
          onSubjectHover={(subject) => {
            prefetchStudentSkillsData({
              userId: user?._id ?? '',
              subject,
            });
          }}
        />
      </div>
    </div>
  );
};

export default StudentSummary;

import { createListenerMiddleware } from '@reduxjs/toolkit';
import { validate } from 'jsonschema';
import { JSONSchema } from 'json-schema-to-ts';
import * as Sentry from '@sentry/react';

import { RootState } from '../stores/AppStore';

const persistKeyPostfix = '-state';
const slicesToPersist: Array<'global' | 'quiz' | 'user'> = [
  'global',
  'quiz',
  'user',
];

const persistStateListenerMiddleware = createListenerMiddleware();

const persistState = <T>(storeId: string, state: T) =>
  localStorage.setItem(`${storeId}${persistKeyPostfix}`, JSON.stringify(state));

persistStateListenerMiddleware.startListening({
  predicate: (action, currentState, previousState) =>
    slicesToPersist.some(
      (slice) =>
        JSON.stringify((currentState as RootState)[slice]) !==
        JSON.stringify((previousState as RootState)[slice]),
    ),
  effect: async (action, { getState }) => {
    const { global: globalState, quiz, user } = getState() as RootState;
    persistState('global', globalState);
    persistState('quiz', quiz);
    persistState('user', user);
  },
});

export const getPersistedState = <T>(storeId: string, schema: JSONSchema) => {
  const persistedState = localStorage.getItem(`${storeId}${persistKeyPostfix}`);
  let rehydrated: T | undefined;
  try {
    if (persistedState) {
      rehydrated = JSON.parse(persistedState);
    }
  } catch {}
  const validationResult = validate(rehydrated, schema);
  if (validationResult.valid) {
    return rehydrated;
  } else {
    Sentry.captureException(
      new Error('Persisted state in localStorage is invalid'),
      {
        tags: {
          storeId,
          persistedState,
          validationErrors: JSON.stringify(
            validationResult.errors.map((error) => error.stack),
          ),
        },
      },
    );
  }
};

export const persisterMiddleware = persistStateListenerMiddleware.middleware;

import { ReactElement, FC } from 'react';
import { observer } from 'mobx-react-lite';
import { NavLink, useNavigate } from 'react-router-dom';

import { useStores } from '../stores/RootStore';
import { ROLES } from '../constants';
import { useAppSelector } from '../stores/AppStore';
import {
  selectHasOneOfTheRoles,
  selectIsAuthenticated,
} from '../features/user/userSlice';
import { ChevronDown, UserCheck } from 'lucide-react';

const BaseUserMenu: FC = (): ReactElement => {
  const {
    rootStore: { uiStore },
  } = useStores();
  const user = useAppSelector((state) => state.user.user);
  const navigate = useNavigate();
  const toggleUserMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    uiStore.hideSidebar.apply(uiStore);
    uiStore.toggleUserMenu.apply(uiStore);
  };
  const name = user?.firstName ? `${user?.firstName} ${user?.lastName}` : '';
  const role = user?.roles.join(', ');

  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isOwner = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.owner]),
  );

  if (!isAuthenticated || !user) {
    return <></>;
  }
  return (
    <div className="flex justify-end items-center">
      <button
        data-testid={'user-menu'}
        onClick={toggleUserMenu}
        className="flex max-h-max p-2 md:p-2 md:px-3 gap-2 justify-items-center items-center text-start rounded-full bg-blue-100 hover:bg-blue-200 hover:shadow-md"
      >
        <UserCheck className="h-5 w-5 my-auto text-tttDefault" />
        <div className="hidden md:block text-sm">
          <p className="font-bold">{name}</p>
          <p className="capitalize text-gray-600">{role}</p>
        </div>
        <ChevronDown className="hidden md:block h-4 w-4 my-auto text-gray-600" />
      </button>
      {uiStore.isUserMenuOpen && (
        <div className="fixed top-20 sm:top-24 grid overflow-hidden grid-cols-1 w-fit min-w-[220px] shadow-2xl rounded-xl bg-blue-50 divide-y divide-tttDefault divide-opacity-25 z-10 p-0 border-tttDefault border-[1px] border-opacity-25">
          {isOwner && (
            <NavLink
              to={'/data-import'}
              className="px-4 py-2 cursor-pointer hover:bg-blue-200"
            >
              Data import
            </NavLink>
          )}
          <NavLink
            to={'/change-password'}
            className="px-4 py-2 cursor-pointer hover:bg-blue-200"
          >
            Change password
          </NavLink>
          <div
            onClick={() => {
              navigate('/login');
            }}
            className="px-4 py-2 cursor-pointer hover:bg-blue-200"
          >
            Log out
          </div>
        </div>
      )}
    </div>
  );
};

const UserMenu = observer(BaseUserMenu);
export default UserMenu;

import { Link } from 'react-router-dom';

import { QuizWithInfo, StudentTrackWithSchedule } from '../../types';

interface MissedAssignmentProperties {
  assignment: QuizWithInfo;
  track?: StudentTrackWithSchedule;
}

const MissedAssignment = ({
  assignment,
  track,
}: MissedAssignmentProperties) => {
  return (
    <div
      data-testid={`completed-assignment-${assignment.id}`}
      className={`flex flex-col md:flex-row flex-wrap gap-4 md:gap-x-14 bg-white rounded-xl border border-1-gray p-4 ${
        assignment.isReview && 'border border-tttDefault'
      }`}
    >
      <div className="flex-grow flex gap-3 items-center justify-between">
        <p className="font-medium whitespace-nowrap">{assignment.id}</p>
        <div className="flex gap-2 items-center">{/* TODO: remove */}</div>
      </div>
      <div className="flex gap-3">
        <Link
          to={track ? `/quiz/${track.serialNum}/${assignment.id}/practice` : ''}
          className="
            px-4 py-1.5 w-max h-max md:my-auto
            rounded-full border border-tttDefault
            text-sm md:text-base
            font-medium text-tttDefault whitespace-nowrap
            transition-all duration-100 ease-in-out
            hover:shadow hover:bg-tttDefault hover:text-white
          "
        >
          Practice
        </Link>
      </div>
    </div>
  );
};

export default MissedAssignment;

import { useState } from 'react';
import {
  Controller,
  DeepRequired,
  FieldErrorsImpl,
  useForm,
} from 'react-hook-form';
import * as Sentry from '@sentry/react';
import toast from 'react-hot-toast';
import Button from '../components/Button';
import {
  useLazyCheckEmailExistsQuery,
  useUpdateEmailForUserMutation,
} from '../services/apiSlice';
import { FieldError } from '../components/ui/field-error';
import { emailPattern } from '../constants';

export default function ThinkerEmailChangeForm({
  userId,
  onClose,
}: {
  userId: string;
  onClose: () => void;
}) {
  const [updateEmail, { isLoading }] = useUpdateEmailForUserMutation();
  const [
    checkEmailExists,
    { error: isCheckingEmailError, isFetching: isCheckingEmailExists },
  ] = useLazyCheckEmailExistsQuery();
  const {
    control,
    getValues,
    formState: { errors },
    setValue,
  } = useForm({
    // mode: 'all',
  });
  const [emailError, setEmailError] = useState('');

  const handleEmailChange = async () => {
    // validation
    const email = getValues('email').toLowerCase().trim();
    setValue('email', email);
    if (!emailPattern.test(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }
    const result = await checkEmailExists({ email });
    if (isCheckingEmailError) {
      setEmailError(
        'Could not verify the email address, please try again or contact us',
      );
      return;
    }
    if (result.data === true || result.error) {
      setEmailError('This email is already being used');
      return;
    }

    if (
      window.confirm(
        `Are you sure you'd like to change the email address of your Thinker to ${email}?`,
      )
    ) {
      try {
        if (!userId) {
          Sentry.captureException(new Error('User ID not found'), {
            tags: {
              request: 'mentorUpdateEmailForThinker',
            },
          });
          toast.error(
            'Unexpected error happened while trying to update the email address. Please try again later!',
          );
          return;
        }
        await updateEmail({
          userId,
          email,
        }).unwrap();
        toast.success('Email successfully changed!');
        onClose();
      } catch (error) {
        Sentry.captureException(error, {
          tags: {
            request: 'mentorUpdateEmailForThinker',
          },
        });
        toast.error(
          'Unexpected error happened while trying to update the email address. Please try again later!',
        );
        return;
      }
    }
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 items-center">
      <div className="">
        <Controller
          render={({ field }) => (
            <input
              {...field}
              className="border border-gray-300 rounded-md px-3 py-2 w-full"
              placeholder="Enter new email address"
              id="email"
              onChange={(event) => {
                field.onChange(event.target.value);
                setEmailError('');
              }}
            />
          )}
          name="email"
          control={control}
          rules={{ required: true, pattern: emailPattern }}
        />
      </div>
      <div className="flex">
        <Button
          bgColor={'bg-blue-300'}
          width="w-full"
          textSize="text-sm"
          action={handleEmailChange}
          disabled={isLoading || isCheckingEmailExists}
        >
          Change
        </Button>
        <Button
          bgColor={'bg-blue-300'}
          width="w-full"
          textSize="text-sm"
          buttonText="Cancel"
          action={onClose}
          disabled={isLoading || isCheckingEmailExists}
        />
      </div>
      <div className="col-span-2">
        {emailError && <p className="text-sm text-rose-600">{emailError}</p>}
        <FieldError
          errors={
            errors as FieldErrorsImpl<
              DeepRequired<{
                [x: string]: string;
              }>
            >
          }
          field="email"
          fieldName="Email"
        />
      </div>
    </div>
  );
}

import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { store } from '../stores/AppStore';
import { formatSeconds } from '../util';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

export default function Timer() {
  const [textColor, setTextColor] = useState<string>('');
  const [remainingSeconds, setRemainingSeconds] = useState<number>();

  useEffect(() => {
    setTextColor('opacity-30');
    const interval = setInterval(() => {
      const { quiz } = store.getState();
      if (quiz.dueAt) {
        const remaining = dayjs(quiz.dueAt).diff(dayjs(), 'seconds');
        if (remaining <= 0) {
          clearInterval(interval);
        } else if (remaining === 120) {
          toast('You have less than 2 minutes to complete the quiz!');
        } else if (remaining <= 120) {
          setTextColor('text-tttDefault font-bold opacity-90');
        }
        setRemainingSeconds(Math.max(remaining, 0));
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className={`${textColor} font-medium hover:opacity-100 transition duration-150 ease-in-out`}
    >
      {typeof remainingSeconds === 'number'
        ? remainingSeconds >= 0
          ? formatSeconds(remainingSeconds)
          : formatSeconds(0)
        : ''}
    </div>
  );
}

import * as React from 'react';

import { cn } from '../../lib/utils';
import { Loading } from '../Loading';

const Card = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...properties }, reference) => (
  <div
    ref={reference}
    className={cn(
      'bg-card text-card-foreground justify-items-center shadow-2xl rounded-xl border-tttDefault border-[1px]',
      className,
    )}
    {...properties}
  />
));
Card.displayName = 'Card';

const CardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { isFetching?: boolean }
>(({ className, isFetching, ...properties }, reference) => (
  <div className="relative">
    {isFetching && (
      <div className="absolute top-4 right-4">
        <Loading />
      </div>
    )}
    <div
      ref={reference}
      className={cn('flex flex-col space-y-1.5 p-6', className)}
      {...properties}
    />
  </div>
));
CardHeader.displayName = 'CardHeader';

const CardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...properties }, reference) => (
  <div className="w-full flex justify-center">
    <div
      ref={reference}
      className={cn(
        'justify-self-center text-center max-w-[90%] justify-items-center items-center overflow-hidden uppercase text-white font-bold md:text-xl lg:text-2xl px-6 py-3 -mt-10 shadow-md rounded-xl bg-tttDefault',
        className,
      )}
      {...properties}
    />
  </div>
));
CardTitle.displayName = 'CardTitle';

const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...properties }, reference) => (
  <p
    ref={reference}
    className={cn('text-sm text-muted-foreground', className)}
    {...properties}
  />
));
CardDescription.displayName = 'CardDescription';

const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...properties }, reference) => (
  <div ref={reference} className={cn('p-6 pt-0', className)} {...properties} />
));
CardContent.displayName = 'CardContent';

const CardFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...properties }, reference) => (
  <div
    ref={reference}
    className={cn(' flex items-center p-6 pt-0', className)}
    {...properties}
  />
));
CardFooter.displayName = 'CardFooter';

export {
  Card,
  CardHeader,
  CardFooter,
  CardTitle,
  CardDescription,
  CardContent,
};

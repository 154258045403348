import { FC, useEffect, useMemo, useRef } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';

import {
  TargetedPracticeAssignListItem,
  AssignedTargetedPracticeListItem,
} from '../types';
import { getTextColor } from '../util';

import { FilterValue } from './Filter';
import Table from './Table';
import {
  useGetAssignedTargetedPracticesQuery,
  useGetTargetedPracticeResultsByClassQuery,
} from '../services/apiSlice';

interface TargetedPracticeResultsTableProperties {
  selectedClass?: FilterValue;
  isFetching: boolean;
}

const TargetedPracticeResultsTable: FC<
  TargetedPracticeResultsTableProperties
> = ({ selectedClass, isFetching }) => {
  const scrollReference = useRef<HTMLDivElement>(null);

  const { data: assignedPractices, isFetching: isFetchingTargetedPractices } =
    useGetAssignedTargetedPracticesQuery(
      {
        classId: selectedClass?.value._id as string,
      },
      { skip: !selectedClass?.value?._id },
    );
  const { data: results, isFetching: isFetchingResults } =
    useGetTargetedPracticeResultsByClassQuery(
      {
        classId: selectedClass?.value._id as string,
      },
      { skip: !selectedClass?.value?._id },
    );

  const filteredAssignedPractices = useMemo(() => {
    return {
      items:
        assignedPractices?.items?.map((item) => {
          // Find the student results for the current item
          const studentResults = results?.find(
            (r) => r.studentId === item.studentId,
          );
          return {
            ...item,
            targetedPractices: item.targetedPractices.filter(
              (p) =>
                // Filter out practices that are not past
                dayjs(p.dueAt).isAfter(dayjs()) &&
                // Filter out practices that the student has already completed
                !studentResults?.results?.some(
                  (r) => r.quizName === p.quizName,
                ),
            ),
          };
        }) ?? [],
      total: assignedPractices?.total ?? 0,
    };
  }, [assignedPractices, results]);

  const columnsLength = useMemo(() => {
    return Math.max(
      // Get the number of results for each result item
      ...(results?.map((r) => r.results.length) ?? []),
      // Get the number of missed practices for each result item
      ...(results?.map((r) => r.missed.length) ?? []),
    );
  }, [results]);

  const columns = useMemo<ColumnDef<TargetedPracticeAssignListItem, any>[]>(
    () => [
      {
        accessorKey: 'studentName',
        cell: (info) => info.getValue(),
        header: () => <span>Name</span>,
        meta: {
          className: 'sticky left-0 shadow-[rgba(0,0,10,0.1)_5px_0px_4px_0px]',
        },
        enableSorting: false,
      },
      ...Array.from({
        length: columnsLength,
      })
        .map((_, index) => ({
          accessorKey: `past_${index}`,
          cell: (info: any) => {
            const data: AssignedTargetedPracticeListItem = info.row.original;
            const studentResults = results?.find(
              (r) => r.studentId === data.studentId,
            );
            const targetePracticeResult = studentResults?.results[index];
            const missedPractice = studentResults?.missed[index];

            return targetePracticeResult ? (
              <div className="flex gap-4 justify-between items-center">
                <p>{targetePracticeResult.quizName}</p>
                <p
                  className={`font-bold whitespace-nowrap ${getTextColor(
                    targetePracticeResult.result * 100,
                  )}`}
                >
                  {targetePracticeResult.result * 100} %
                </p>
              </div>
            ) : missedPractice ? (
              <div className="flex gap-4 justify-between items-center">
                <p>{missedPractice?.quizName}</p>
                <p className={`font-bold whitespace-nowrap`}>❌</p>
              </div>
            ) : (
              <></>
            );
          },
          header: () => <div className="text-center">Recent {index + 1}</div>,
          meta: { className: 'min-w-[112px]' }, // min-w-28
          enableSorting: false,
        }))
        .reverse(),
      ...Array.from({
        length: 3,
      }).map((_, index) => ({
        accessorKey: `pending_${index}`,
        cell: (info: any) => {
          const data: AssignedTargetedPracticeListItem = info.row.original;
          return (
            <p className="text-center">
              {data.targetedPractices[index]?.quizName}
            </p>
          );
        },
        header: () => <div className="text-center">Pending {index + 1}</div>,
        meta: {
          className: `sticky min-w-[112px] ${
            index === 0
              ? `border-l border-dashed shadow-[rgba(0,0,10,0.1)_-5px_0px_4px_0px]`
              : ''
          }
          ${index === 0 ? `right-48` : index === 1 ? `right-24` : `right-0`}`,
        },
        enableSorting: false,
      })),
    ],
    [filteredAssignedPractices, results],
  );

  useEffect(() => {
    if (
      scrollReference.current &&
      !(isFetching || isFetchingTargetedPractices || isFetchingResults)
    ) {
      scrollReference.current.scrollLeft = 9999;
    }
  }, [isFetching, isFetchingTargetedPractices, isFetchingResults]);

  return (
    <div
      ref={scrollReference}
      className="grid justify-items-center w-full overflow-x-auto"
    >
      <Table<AssignedTargetedPracticeListItem>
        columns={columns}
        data={filteredAssignedPractices}
        isLoading={
          isFetching || isFetchingTargetedPractices || isFetchingResults
        }
      />
    </div>
  );
};

export default TargetedPracticeResultsTable;

import { ReactNode } from 'react';

interface OptionProperties {
  dataTestId: string;
  isSelected: boolean;
  isRadio?: boolean;
  onClick: () => void;
  children: ReactNode;
  value: string | number;
}

const Option = ({
  dataTestId,
  children,
  isSelected,
  isRadio = true,
  onClick,
  value,
}: OptionProperties) => {
  return (
    <div
      data-testid={dataTestId}
      onClick={onClick}
      className={`
        flex items-center gap-3 rounded-xl border border-gray-300 p-2 md:p-4 cursor-pointer
        transition duration-300 ease-in-out
        hover:shadow-md hover:outline hover:outline-tttDefault hover:outline-[1px] hover:bg-tttDefault hover:bg-opacity-5
        ${
          isSelected
            ? 'shadow-md outline outline-tttDefault outline-[1px] bg-tttDefault bg-opacity-5'
            : ''
        }
      `}
    >
      {isRadio && (
        <input
          type="radio"
          value={value}
          checked={isSelected}
          onChange={onClick}
          className="w-4 md:w-5 h-4 md:h-5 ml-1 md:ml-0 cursor-pointer"
        />
      )}
      {children}
    </div>
  );
};

export default Option;

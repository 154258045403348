interface SwitchButtonProperties {
  checked?: boolean;
  disabled?: boolean;
  loading?: boolean;
  title?: string;
  shouldHaveText?: boolean;
  disabledText?: string;
  enabledText?: string;
  onChange: (enabled: boolean) => void;
  id: string;
  classNames?: string;
}

const SwitchButton = ({
  checked = false,
  disabled,
  loading,
  title,
  shouldHaveText = true,
  disabledText,
  enabledText,
  onChange,
  id,
  classNames,
}: SwitchButtonProperties) => {
  const toggleEnabled = () => {
    if (disabled || loading) {
      return;
    }
    onChange(!checked);
  };

  return (
    <label
      title={title}
      data-testid={id}
      className={`grid ${
        shouldHaveText ? 'grid-cols-2' : 'grid-cols-1'
      } items-center content-center ${
        disabled || loading ? 'cursor-not-allowed' : 'cursor-pointer'
      } ${classNames ?? ''}`}
      htmlFor={id}
    >
      <div
        onClick={(event) => {
          event?.stopPropagation();
          toggleEnabled();
        }}
        className={`relative w-12 h-6 ${
          checked ? 'bg-tttDefault bg-opacity-50' : 'bg-gray-300'
        } rounded-full ${loading && 'animate-pulse'}`}
      >
        <div
          className={`w-6 h-6 bg-tttDefault rounded-full absolute top-0 ${
            checked ? 'right-0' : 'left-0'
          } transition-all duration-2000 ease-in-out ${
            loading && 'animate-pulse bg-opacity-50'
          }`}
        ></div>
      </div>
      {shouldHaveText && (
        <span className="font-medium">
          {checked ? enabledText ?? 'On' : disabledText ?? 'Off'}
        </span>
      )}
    </label>
  );
};

export default SwitchButton;

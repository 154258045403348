/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FC, useEffect, useState } from 'react';
import { LuUser2 } from 'react-icons/lu';

import { StudentResultOverview, StudentTrackWithSchedule } from '../types';
import { ROLES, Subject } from '../constants';

import {
  apiSlice,
  useGetStudentOrThinkerGAPSResultsQuery,
} from '../services/apiSlice';

import LearningTrackProgress from './LearningTrackProgress';
import BreakoutCircular from '../components/BreakoutCircular';
import { Loading } from '../components/Loading';
import Chart from '../components/Chart';
import useResultAndSubmissionChartData from '../hooks/useResultAndSubmissionChartData';
import SubjectSkillResults from '../components/SubjectSkillResults';
import { PencilAltIcon } from '@heroicons/react/outline';
import { useAppSelector } from '../stores/AppStore';
import { selectHasOneOfTheRoles } from '../features/user/userSlice';
import ThinkerEmailChangeForm from './ThinkerEmailChange';

interface Properties {
  isLoading: boolean;
  isOpen: boolean;
  result?:
    | StudentResultOverview
    | (StudentTrackWithSchedule & StudentResultOverview);
  shouldScrollIntoView?: boolean;
  chartEmptyText?: string;
}

const StudentDrillDown: FC<Properties> = ({
  isLoading,
  isOpen,
  result: resultProperty,
  shouldScrollIntoView = true,
  chartEmptyText,
}) => {
  const isMentor = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.mentor]),
  );
  const [isChangingEmail, setIsChangingEmail] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState<Subject>(
    Subject.geometry,
  );
  const [studentResult, setResult] = useState<StudentResultOverview>();
  const [skillsData, setSkillsData] = useState<
    {
      skill: string;
      description: string;
      overallResult: number;
      tutorialsLink: string;
    }[]
  >();
  const chartData = useResultAndSubmissionChartData(studentResult);
  const { data: subjectResult, isFetching } =
    useGetStudentOrThinkerGAPSResultsQuery(
      {
        userId: studentResult?.student?._id ?? '',
        subject: selectedSubject,
      },
      {
        skip: !studentResult?.student?._id,
      },
    );
  const hasTrackDetails =
    resultProperty && 'currentAssignments' in resultProperty;

  const prefetchStudentSkillsData = apiSlice.usePrefetch(
    'getStudentOrThinkerGAPSResults',
  );

  const handleViewDetails = () => {
    const targetElement = document.querySelector('#thinker-score-chart');
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  useEffect(() => {
    setResult(resultProperty);
  }, [resultProperty]);

  useEffect(() => {
    if (subjectResult && subjectResult.skillResults) {
      // TODO: merge and replace string data with rich object containing description and subject
      const mergedSkills = subjectResult.skillResults
        .map((item) => ({
          skill: item.skill,
          description: item.description,
          overallResult: item.overallResult,
          tutorialsLink: `/tutorials/${item.subject}?skills=${item.skill}`,
        }))
        .sort((a, b) => a.overallResult - b.overallResult);
      setSkillsData(mergedSkills);
    }
  }, [subjectResult]);

  useEffect(() => {
    const scroll = async () => {
      if (shouldScrollIntoView && isOpen) {
        // wait for initial height calculation for accurate scrolling
        await new Promise((resolve) => setTimeout(resolve, 50));
        const targetElement = document.querySelector(
          '#studentDrillDownSection',
        );
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    };
    scroll();
  }, [shouldScrollIntoView, isOpen, resultProperty]);

  return (
    <div
      className={`grid gap-2 md:gap-4 w-full ${!isOpen && 'hidden'}`}
      id="studentDrillDownSection"
      data-testid="student-drilldown"
    >
      <div
        data-testid={'overview'}
        className="flex flex-col md:flex-row gap-3 md:gap-6 rounded-xl md:bg-white p-0 md:p-6 lg:p-8"
      >
        {isLoading || !studentResult ? (
          <div className="flex h-64 mx-auto items-center">
            <Loading />
          </div>
        ) : (
          <>
            <div
              data-testid="overview-section"
              className="
                flex flex-col gap-4 basis-3/5 
                bg-white md:bg-transparent
                p-4 md:p-0
                rounded-xl md:rounded-none
              "
            >
              <div
                data-testid="personal-details"
                className="flex gap-4 items-center"
              >
                <LuUser2 className="text-6xl text-tttDefault" />
                <div className="flex flex-col gap-1">
                  <h2
                    data-testid="name"
                    className="text-lg md:text-xl font-medium"
                  >
                    {studentResult.student.name}
                  </h2>
                  {isMentor && isChangingEmail ? (
                    <ThinkerEmailChangeForm
                      userId={String(studentResult.student._id)}
                      onClose={() => setIsChangingEmail(false)}
                    />
                  ) : (
                    <div className="text-sm text-gray-500">
                      {studentResult.student.email}
                      {isMentor ? (
                        <div
                          className="inline-block ml-1 mb-[-2px] self-center justify-self-end h-5 w-5 text-gray-200 hover:text-tttDefault cursor-pointer"
                          title="Change the email address of your Thinker"
                          onClick={() => setIsChangingEmail(true)}
                        >
                          <PencilAltIcon className="h-5 w-5" />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                  <p
                    data-testid="track"
                    className="rounded-full bg-blue-200 text-tttDefault text-xs md:text-sm py-1 px-3 uppercase w-max"
                  >
                    {studentResult.track}
                  </p>
                </div>
              </div>
              {hasTrackDetails && (
                <LearningTrackProgress
                  result={resultProperty}
                  variety="mentor"
                />
              )}
            </div>

            <div
              data-testid="scores-section"
              className="
                grid grid-cols-2 gap-3 md:gap-8 md:basis-2/5
                rounded-xl md:rounded-none
              "
            >
              <div
                data-testid="review-score"
                className="
                  flex flex-col gap-4 w-full h-full
                  text-center items-center justify-between 
                  bg-white md:bg-transparent rounded-xl md:rounded-none
                  p-6 md:p-0
                "
              >
                <h2 className="md:text-lg mb-auto">Review Score</h2>
                <BreakoutCircular
                  data={[
                    {
                      label: 'Review',
                      labelContent: (
                        <button
                          onClick={handleViewDetails}
                          className="text-tttDefault hover:underline cursor-pointer"
                        >
                          View Details
                        </button>
                      ),
                      value: studentResult.reviewResult ?? undefined,
                    },
                  ]}
                  isSelectable={false}
                />
              </div>
              <div
                data-testid="cumulative-score"
                className="
                  flex flex-col gap-4 w-full 
                  text-center items-center justify-between 
                  bg-white md:bg-transparent rounded-xl md:rounded-none
                  p-6 md:p-0
                "
              >
                <h2 className="md:text-lg mb-auto">Cumulative Score</h2>
                <BreakoutCircular
                  data={[
                    {
                      label: 'Cumulative',
                      labelContent: (
                        <button
                          onClick={handleViewDetails}
                          className="text-tttDefault hover:underline cursor-pointer"
                        >
                          View Details
                        </button>
                      ),
                      value: studentResult.overallResult ?? undefined,
                    },
                  ]}
                  isSelectable={false}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <Chart
        id={'thinker-score-chart'}
        title="Quiz Results"
        emptyText={chartEmptyText}
        loading={isLoading}
        data={chartData}
        baseType="bar"
      />
      <div className="p-2" />
      <SubjectSkillResults
        title={`Cumulative Score - ${studentResult?.track}`}
        subjectResults={resultProperty?.subjectResults ?? []}
        skillResults={skillsData ?? []}
        isSkillResultsLoading={isFetching}
        onSubjectSelect={(subject) => {
          setSelectedSubject(subject);
        }}
        onSubjectHover={(subject) => {
          prefetchStudentSkillsData({
            userId: studentResult?.student?._id ?? '',
            subject,
          });
        }}
      />
    </div>
  );
};

export default StudentDrillDown;

import { FC } from 'react';
import Select, {
  ActionMeta,
  GroupBase,
  OptionsOrGroups,
  SingleValue,
} from 'react-select';

export interface SelectorOption {
  value: any;
  label: string;
}

interface QuizSelectorFilterProperties {
  isActive: boolean;
  selectFn: (
    newValue: SingleValue<SelectorOption>,
    actionMeta: ActionMeta<SelectorOption>,
  ) => void;
  selectOptions?: OptionsOrGroups<SelectorOption, GroupBase<SelectorOption>>;
  value?: SelectorOption | null;
}

const QuizSelectorFilter: FC<QuizSelectorFilterProperties> = ({
  isActive,
  selectFn,
  selectOptions,
  value,
}) => {
  return (
    <div data-testid="filter-quiz">
      <Select
        value={value}
        onChange={selectFn}
        placeholder={'Click to filter by Quiz'}
        options={selectOptions}
        className="w-full text-left"
        components={{
          // eslint-disable-next-line unicorn/no-null
          IndicatorSeparator: () => null,
        }}
        styles={{
          control: (base) => ({
            ...base,
            borderWidth: 0,
            boxShadow: 'none',
            borderRadius: '0.5rem',
            backgroundColor: isActive ? 'rgb(191 219 254)' : 'rgb(229 231 235)',
            '&:hover': {
              cursor: 'pointer',
              backgroundColor: isActive ? '' : 'rgb(239 246 255)',
            },
            height: '100%',
          }),
          dropdownIndicator: (base) => ({
            ...base,
            color: '#121212',
            opacity: isActive ? 0.7 : 0.3,
            '&:hover': {
              cursor: 'pointer',
            },
          }),
          input: (baseStyles) => ({
            ...baseStyles,
            color: 'transparent',
          }),
          container: (base) => ({
            ...base,
            backgroundColor: 'rgb(229 231 235)',
            '@media (max-width: 767px)': {
              borderRadius: '0.5rem',
            },
            '@media (min-width: 768px)': {
              borderTopRightRadius: '0.5rem',
              borderBottomRightRadius: '0.5rem',
            },
          }),
        }}
      />
    </div>
  );
};

export default QuizSelectorFilter;

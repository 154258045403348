import { Skeleton } from './ui/skeleton';

interface BadgeProperties {
  text?: string;
  title?: string;
  children?: React.ReactNode;
  loading?: boolean;
}

const Badge = ({ text, title, children, loading }: BadgeProperties) => {
  return (
    <span
      className={`
        border-tttDefault border-[1px]
        bg-tttDefault bg-opacity-20
        text-xs text-tttDefault font-medium
        rounded-xl ${!loading && 'p-1'}
      `}
      title={title}
    >
      {loading ? (
        <Skeleton className="w-10 h-full rounded-full bg-tttDefault bg-opacity-50" />
      ) : (
        <>
          {text} {children}
        </>
      )}
    </span>
  );
};

export default Badge;

import { useEffect, useState } from 'react';
import { ImportDocument, ImportState } from '../types';
import { ImportStatus } from '../constants';

interface ImportStatusInterface {
  isImportProcessing: boolean;
  didImportFail: boolean;
  isImportSuccessful: boolean;
  isImportUploading: boolean;
  isImportAvailable: boolean;
  shouldShowWarnings: boolean;
  shouldShowErrors: boolean;
  shouldShowImportHistory: boolean;
}

const useImportStatus = (
  importState: ImportState,
  importHistory?: ImportDocument[],
): ImportStatusInterface => {
  const [importStatus, setImportStatus] = useState<ImportStatusInterface>({
    isImportProcessing: false,
    didImportFail: false,
    isImportSuccessful: false,
    isImportUploading: false,
    isImportAvailable: false,
    shouldShowWarnings: false,
    shouldShowErrors: false,
    shouldShowImportHistory: false,
  });

  useEffect(() => {
    const { details: status, uploadPercentage } = importState;

    setImportStatus({
      isImportProcessing: status.status === ImportStatus.processing,
      didImportFail: status.status === ImportStatus.failed,
      isImportSuccessful: status.status === ImportStatus.success,
      isImportUploading:
        status.status === ImportStatus.init && uploadPercentage > 0,
      isImportAvailable:
        [ImportStatus.init, ImportStatus.success, ImportStatus.failed].includes(
          status.status,
        ) && uploadPercentage === 0,
      shouldShowWarnings:
        Array.isArray(status.warnings) &&
        status.warnings.length > 0 &&
        (status.status !== ImportStatus.init || uploadPercentage === 0),
      shouldShowErrors:
        ((Array.isArray(status.importErrors) &&
          status.importErrors.length > 0) ||
          status.status === ImportStatus.failed) &&
        (status.status !== ImportStatus.init || uploadPercentage === 0),
      shouldShowImportHistory: !!(importHistory && importHistory.length > 0),
    });
  }, [importState, importHistory]);

  return importStatus;
};

export default useImportStatus;

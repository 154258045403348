import { ChangeEvent, FC, useEffect } from 'react';
import Upload from 'rc-upload';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import {
  RcFile,
  UploadProps,
  UploadProgressEvent,
} from 'rc-upload/lib/interface';
import { Line } from 'rc-progress';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useQueryClient } from '@tanstack/react-query';

import { ImportStatus } from '../constants';
import {
  useGetImportHistoryQuery,
  useGetImportDetailsQuery,
  useLazyGetImportHistoryQuery,
} from '../services/apiSlice';
import { apiService } from '../services/APIService';
import { useAppSelector, store } from '../stores/AppStore';
import {
  resetImportState,
  setImportState,
  setImportStatus,
  setImportUploadPercentage,
  setImportYear,
} from '../features/global/globalSlice';
import useImportStatus from '../hooks/useImportStatus';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
const localTZ = dayjs.tz.guess();

type Request = {
  data: any;
  file: RcFile;
  filename: string;
  onProgress: (event: UploadProgressEvent, file: RcFile) => void;
};

const DataImport: FC = () => {
  const queryClient = useQueryClient();
  const { importState } = useAppSelector((state) => state.global);
  const { data: importHistory } = useGetImportHistoryQuery();
  useGetImportDetailsQuery(importState.details._id, {
    skip: !importState.details._id,
    pollingInterval:
      importState.details.status === ImportStatus.processing ? 6000 : 0,
  });
  const {
    isImportProcessing,
    didImportFail,
    isImportSuccessful,
    isImportUploading,
    isImportAvailable,
    shouldShowWarnings,
    shouldShowErrors,
    shouldShowImportHistory,
  } = useImportStatus(importState, importHistory);
  const [getImportHistory] = useLazyGetImportHistoryQuery();

  useEffect(() => {
    if (!isImportProcessing) {
      getImportHistory();
      if (!(isImportSuccessful || didImportFail)) {
        store.dispatch(resetImportState());
      }
    }
  }, [isImportProcessing]);

  const uploadProperties = {
    type: 'drag',
    multiple: false,
    data: { year: importState.importYear },
    onStart() {
      store.dispatch(setImportUploadPercentage(1));
    },
    onProgress(event: UploadProgressEvent) {
      store.dispatch(setImportUploadPercentage(event.percent ?? 0));
    },
    async customRequest({ data, file, filename, onProgress }: Request) {
      const formData = new FormData();
      if (data) {
        for (const key of Object.keys(data)) {
          formData.append(key, data[key]);
        }
      }
      formData.append(filename, file);
      store.dispatch(setImportStatus(ImportStatus.init));

      const options = {
        onUploadProgress: ({
          total,
          loaded,
        }: {
          total: number;
          loaded: number;
        }) => {
          onProgress(
            { percent: Math.round((loaded / total) * 100 * 1e2) / 1e2 }, // toFixed(2)
            file,
          );
        },
      };
      try {
        const importResponse = await apiService.post(
          '/import',
          formData,
          options,
        );
        if (importResponse?.data) {
          toast.success(
            'Upload was successful and passed the preliminary checks. Processing the data...',
          );
          store.dispatch(
            setImportState({
              ...importState,
              details: {
                ...importState.details,
                _id: importResponse.data._id,
                status: ImportStatus.processing,
                progress: 1,
              },
            }),
          );
          queryClient.invalidateQueries({ queryKey: ['ImportDetails'] });
        } else {
          toast.error('Sorry, the upload failed.');
        }
        store.dispatch(setImportUploadPercentage(0));
      } catch (error: any) {
        store.dispatch(setImportStatus(ImportStatus.failed));
        store.dispatch(
          setImportState({
            ...importState,
            details: {
              ...importState.details,
              importErrors: [
                ...importState.details.importErrors,
                error.response?.data?.message || error.message,
              ],
            },
          }),
        );
      }

      return {
        abort() {
          toast.error('Upload progress is aborted');
          store.dispatch(setImportUploadPercentage(0));
        },
      };
    },
    style: { display: 'inline-block', background: '#eee' },
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const handleSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    store.dispatch(setImportYear(event.target.value));
  };

  return (
    <div className="grid gap-6 px-6 pb-6 pt-3 shadow-2xl rounded-xl border-tttDefault border-[1px]">
      <div className="grid justify-self-center max-w-[90%] justify-items-center items-center text-center overflow-hidden uppercase text-white font-bold md:text-xl lg:text-2xl p-3 -mt-7 shadow-md rounded-xl bg-tttDefault">
        Data import
      </div>
      <div className="grid justify-self-center w-[80%]">
        Data import overwrites most of the data in the database. Make sure you
        select the correct academic year and the import ZIP file is correct.
        Only the owner users will be kept, all other users will be imported from
        the spreadsheet. Existing passwords won't change, new ones are set by
        student ID or "password" + the user part of the email. All existing
        results will be kept.
      </div>
      {isImportAvailable && (
        <>
          <div className="grid grid-flow-row md:grid-flow-col gap-3 justify-self-center items-center">
            Select the academic year:
            <select
              onChange={handleSelect}
              className="border-gray-500 border-[1px] rounded-lg p-1"
            >
              <option value="2023/2024">2023/2024</option>
              <option value="2024/2025">2024/2025</option>
            </select>
          </div>
          <Upload
            {...(uploadProperties as UploadProps)}
            className="grid justify-self-center justify-items-center w-[80%] min-h-[300px] border-gray-500 border-[1px] rounded-xl"
          >
            <button
              type="button"
              className="justify-self-center w-full h-full text-gray-500"
            >
              Click to select file or drop the ZIP on the upload area
            </button>
          </Upload>
        </>
      )}
      <div className="grid grid-flow-col justify-self-center justify-items-center w-[80%]">
        {isImportUploading || isImportProcessing ? (
          <div className="grid justify-self-center items-center text-center w-[50%]">
            <Line
              percent={
                isImportUploading
                  ? importState.uploadPercentage
                  : importState.details.progress
              }
              strokeWidth={1.75}
              strokeColor={'#0069d9'}
              className="mb-2"
            />
            {isImportUploading ? 'Uploading...' : 'Processing...'}
          </div>
        ) : (
          isImportSuccessful && (
            <div className="grid grid-flow-row justify-self-start gap-3">
              <div className="grid max-w-max items-center text-emerald-500 font-bold">
                Import was successful 🎉
              </div>
              <div>The following number of items are in the DB:</div>
              <div className="grid grid-flow-row grid-cols-[auto_auto] capitalize pl-3">
                {Object.keys(importState.details.stats).map((statKey) => {
                  const statValue =
                    importState.details.stats[
                      statKey as keyof typeof importState.details.stats
                    ];

                  return (
                    <div
                      key={statKey}
                      className="grid grid-cols-[auto,1fr] px-4 py-1"
                    >
                      <div className="mr-2">{statKey}:</div>
                      <div className="font-medium">{statValue}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          )
        )}
      </div>
      {shouldShowErrors && (
        <div className="grid justify-self-center justify-items-center w-[80%] min-h-[52px] max-h-[400px] bg-gray-100 border-gray-500 border-[1px] rounded-lg select-text p-3 pr-0">
          <p className="w-full px-2 text-red font-medium pb-2 uppercase">
            Errors
          </p>
          <div className="grid justify-self-center self-center h-[95%] w-full px-2 overflow-y-auto text-gray-500">
            {importState.details?.importErrors?.map((error, index) => (
              <div className="break-words" key={`${error}-${index}`}>
                {error}
              </div>
            ))}
          </div>
        </div>
      )}
      {shouldShowWarnings && (
        <div className="grid justify-self-center justify-items-center w-[80%] min-h-[52px] max-h-[400px] bg-gray-100 border-gray-500 border-[1px] rounded-lg select-text p-3 pr-0">
          <p className="w-full px-2 text-amber-500 font-medium pb-2 uppercase">
            Warnings
          </p>
          <div className="grid justify-self-center self-center h-[95%] w-full px-2 overflow-y-auto text-gray-500">
            {importState.details.warnings.map((warning, index) => (
              <div className="break-words" key={`${warning}-${index}`}>
                {warning}
              </div>
            ))}
          </div>
        </div>
      )}
      {shouldShowImportHistory && (
        <div className="grid justify-self-center justify-items-center w-[80%] min-h-[100px] max-h-[400px] bg-gray-100 border-gray-500 border-[1px] rounded-lg select-text p-3 pr-0">
          <div className="grid justify-self-center self-center h-[95%] w-full px-2 overflow-y-auto text-gray-500">
            <p className="break-all font-medium pb-2 uppercase">
              Import history
            </p>
            <ul className="list-disc ml-4">
              {importHistory?.map((item, index) => (
                <li className="break-words" key={index}>
                  <span
                    className={
                      item.status === 'failed'
                        ? 'text-red'
                        : item.status === 'success'
                        ? 'text-emerald-500'
                        : 'text-gray-500'
                    }
                  >
                    {item.status}{' '}
                  </span>
                  {dayjs(item.createdAt).utc().tz(localTZ).from(dayjs())}
                  &nbsp;
                  <span className="text-sm">
                    <span className="italic font-mono">{item._id}</span>
                    &nbsp;by&nbsp;
                    {item.user?.firstName} {item.user?.lastName}
                  </span>
                  {Array.isArray(item.importErrors) &&
                    item.importErrors.length > 0 && (
                      <div className="text-xs text-red">
                        {item.importErrors.length > 1
                          ? 'Multiple errors occured'
                          : item.importErrors[item.importErrors.length - 1]}
                        . Check the{' '}
                        <Link
                          to={`/system-logs/${importState.details._id}`}
                          className="underline"
                        >
                          System Logs
                        </Link>{' '}
                        for more information.
                      </div>
                    )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default DataImport;

/* eslint-disable unicorn/consistent-function-scoping */
import { useEffect, useRef } from 'react';
import { XIcon } from 'lucide-react';
import {
  PiCheckSquareOffsetLight,
  PiDownloadSimpleLight,
} from 'react-icons/pi';

import { downloadWorkbook, getCheckpointTestInfo } from '../../util';
import { Quiz } from '../../types';
import { useGetCheckpointTestsQuery } from '../../services/apiSlice';

import LoadingSnake from '../LoadingSnake';

interface CheckpointTestsModalProperties {
  studentId: string;
  trackId: string;
  isOpen: boolean;
  onClose: () => void;
}

const CheckpointTestsModal = ({
  studentId,
  trackId,
  isOpen,
  onClose,
}: CheckpointTestsModalProperties) => {
  const modalReference = useRef<HTMLDivElement>(null);
  const { data: checkpointTests, isFetching: isFetchingCheckpointTests } =
    useGetCheckpointTestsQuery(
      {
        trackId,
        studentId,
      },
      { skip: !isOpen || !trackId || !studentId },
    );

  const handleDownloadWorkbook = (checkpointTest: Quiz) => {
    downloadWorkbook({
      quizId: checkpointTest.id,
      url: checkpointTest.checkpointTestUrl,
      isCheckpointWorkbook: true,
    });
    downloadWorkbook({
      quizId: checkpointTest.id,
      url: checkpointTest.checkpointTestAnswerKeysUrl,
    });
  };

  const handleDownloadAllWorkbooks = () => {
    if (checkpointTests) {
      for (const checkpointTest of checkpointTests) {
        downloadWorkbook({
          quizId: checkpointTest.id,
          url: checkpointTest.checkpointTestUrl,
          isCheckpointWorkbook: true,
        });
        downloadWorkbook({
          quizId: checkpointTest.id,
          url: checkpointTest.checkpointTestAnswerKeysUrl,
        });
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalReference.current &&
        !modalReference.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) {
    return <></>;
  }

  if (isFetchingCheckpointTests) {
    return (
      <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex z-50">
        <div
          ref={modalReference}
          className="relative flex items-center justify-center bg-white md:rounded-xl h-80 w-full md:w-[80%] lg:w-[60%] xl:w-[50%] my-auto mx-auto"
        >
          <XIcon
            className="absolute right-4 top-4 h-7 w-7 p-1 text-gray-500 border border-gray-400 rounded-full cursor-pointer hover:bg-gray-200"
            onClick={onClose}
          />
          <LoadingSnake />
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex z-50">
      <div
        ref={modalReference}
        className="
          flex flex-col gap-4 
          bg-white md:rounded-xl
          w-full md:w-[80%] lg:w-[60%] xl:w-[50%]
          h-full md:h-max
          p-4 md:p-6
          my-auto mx-auto overflow-y-auto
        "
      >
        <div className="flex justify-between items-center">
          <h2 className="flex gap-2 text-xl md:text-2xl font-medium">
            <PiCheckSquareOffsetLight className="w-8 h-8 text-tttDefault" />
            Checkpoint Tests
          </h2>
          <XIcon
            className="h-7 w-7 p-1 ml-auto text-gray-500 border border-gray-400 rounded-full cursor-pointer hover:bg-gray-200"
            onClick={onClose}
          />
        </div>
        <p className="text-gray-500">
          Checkpoint tests are offline assesments designed to re-test knowledge
          from previously completed quizzes. You can download the specific tests
          and their answer keys below.
        </p>
        {checkpointTests?.length ? (
          <div className="flex flex-col">
            {checkpointTests.map((checkpointTest, index) => (
              <div
                key={checkpointTest.id}
                className="flex flex-col md:flex-row gap-2 md:gap-4 border-b border-gray-300 py-3 justify-between"
              >
                <div className="flex flex-col md:flex-row gap-2 md:gap-4 font-medium md:items-center">
                  Checkpoint Test {index + 1}
                  <p className="text-gray-500 font-normal">
                    {getCheckpointTestInfo(checkpointTest)}
                  </p>
                </div>
                <div
                  onClick={() => handleDownloadWorkbook(checkpointTest)}
                  className="flex gap-2 text-tttDefault items-center whitespace-nowrap hover:underline cursor-pointer"
                >
                  <PiDownloadSimpleLight className="w-5 h-5" />
                  Download Workbook
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-gray-500">No checkpoint tests available.</p>
        )}
        <button
          onClick={handleDownloadAllWorkbooks}
          className="
            px-5 py-2 my-2 rounded-full w-max h-max ml-auto
            font-medium text-white bg-tttDefault
            transition-all duration-300 ease-in-out
            hover:shadow hover:bg-opacity-90
          "
        >
          Download All
        </button>
      </div>
    </div>
  );
};

export default CheckpointTestsModal;

import { CSSProperties } from 'react';

import { useAppSelector } from '../../stores/AppStore';
import {
  selectNumberOfLicenses,
  selectStep,
} from '../../features/signUp/signUpSlice';

const ProgressBar = () => {
  const currentStep = useAppSelector(selectStep) - 1; // starting from license selector
  const numberOfLicenses = useAppSelector(selectNumberOfLicenses);
  const coreSteps = 4; // Payment, Mentor Details, Thinker Details, Questionnaire
  // TODO: handle track recommendation substeps
  // const trackRecommendationSubSteps = 3; // Each track recommendation step includes 3 sub-steps
  // const totalSubSteps = numberOfLicenses * trackRecommendationSubSteps; // all "substeps", i.e. 3 steps for each license * 2 licenses = 6 "substeps"
  const steps = coreSteps + numberOfLicenses * 2; // Each license has 2 main steps: Thinker Details, Track Recommendation

  const getBarStyle = (index: number) => {
    index += 1; // index starts from 1 to match the step number

    // Steps 1, 2, 3 and 5 are either fully filled or unfilled
    if (
      (index === 1 && currentStep >= 1) ||
      (index === 2 && currentStep >= 2) ||
      (index === 4 && currentStep >= steps - 1)
    ) {
      return 'bg-tttDefault'; // fully filled step
    }

    // Step 4
    if (index === 3) {
      if (currentStep < 3) {
        return 'bg-gray-300'; // unfilled step
      } else if (currentStep >= 3 && currentStep < steps - 1) {
        // FIXME
        const percentage = ((currentStep - 2) / (numberOfLicenses * 2)) * 100; // FIXME
        return {
          backgroundImage: `linear-gradient(to right, #0069D9 ${percentage}%, #D1D5DB ${percentage}%)`,
        }; // partially filled step
      } else {
        return 'bg-tttDefault'; // fully filled step
      }
    }

    return 'bg-gray-300'; // unfilled step
  };

  return (
    <div className="flex space-x-2 pt-4 md:pt-8 w-full mt-auto">
      {Array.from({ length: coreSteps }, (_, index) => (
        <div
          key={index}
          className={`h-1 w-full rounded-full ${
            typeof getBarStyle(index) === 'string' ? getBarStyle(index) : ''
          }`}
          style={
            typeof getBarStyle(index) === 'string'
              ? {}
              : (getBarStyle(index) as CSSProperties)
          }
        />
      ))}
    </div>
  );
};

export default ProgressBar;

import { FC, useState } from 'react';
import toast from 'react-hot-toast';

import { useSendPasswordResetLinkMutation } from '../services/apiSlice';

import Input from '../components/Input';
import Form from '../components/Form';
import { Loading } from '../components/Loading';

const PasswordReset: FC = () => {
  const [linkSent, setLinkSent] = useState(false);
  const [sendPasswordResetLink, { isLoading }] =
    useSendPasswordResetLinkMutation();

  const onSubmit = async (data: { email: string }) => {
    try {
      await sendPasswordResetLink(data.email).unwrap();
    } catch {
      toast.error(
        'Some unexpected error happened while trying to send the password reset link. Please try again later!',
      );
      return;
    }
    setLinkSent(true);
    toast.success(
      'If the given email is correct, then a password reset link has been sent now.',
    );
  };

  return (
    <>
      <div className="grid overflow-hidden py-10 px-12 m-3 rounded-lg shadow-xl border-tttDefault border-[1px] w-80">
        <div className="box mb-3">
          <h1 className="text-xl font-bold text-center">Reset your password</h1>
        </div>
        <div className="box">
          {isLoading ? (
            <div className="flex justify-center items-center h-[160px]">
              <Loading />
            </div>
          ) : linkSent ? (
            <div className="px-1 py-4 font-medium">
              Please follow the instructions in the email.
            </div>
          ) : (
            <Form onSubmit={onSubmit}>
              <div className="font-medium">Email</div>
              <Input type="email" name="email" />
              <input
                type="submit"
                value="Send reset link"
                className="justify-self-center max-w-[90%] mt-6 py-2.5 px-2 bg-tttDefault text-white font-bold text-xs uppercase rounded shadow-md hover:bg-blue-600 hover:shadow-lg cursor-pointer"
              />
            </Form>
          )}
        </div>
      </div>
    </>
  );
};

export default PasswordReset;

import { midPercentageThreshold, topPercentageThreshold } from '../constants';

function ColorLegend() {
  const colorMenuItems = [
    {
      color: 'bg-green',
      text: 'GAPS skills you are mastering',
      percentage: `${topPercentageThreshold}% - 100%`,
    },
    {
      color: 'bg-yellow',
      text: 'Strong GAPS skills with room to improve',
      percentage: `${midPercentageThreshold}% - ${topPercentageThreshold}%`,
    },
    {
      color: 'bg-orange',
      text: 'GAPS skills that need practice',
      percentage: `0% - ${midPercentageThreshold}%`,
    },
  ];

  return (
    <div
      className={`w-full border-gray-300 border-[1px] text-xs rounded-xl bg-white p-4`}
    >
      <p className="text-gray-500 uppercase">Legend</p>
      {colorMenuItems.map((item) => {
        return (
          <div
            key={item.text}
            className="grid grid-flow-col grid-cols-[auto_1fr] items-center gap-3 py-2"
          >
            <div
              className={`w-6 h-6 ${item.color} shadow-md shadow-${item.color} rounded-full`}
            />
            <div className="text-gray-700 grid gap-1 items-start">
              <div>{item.percentage}</div>
              <div className="text-gray-500">{item.text}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ColorLegend;

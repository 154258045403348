/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FILTERS, Subject } from '../constants';

import useResultAndSubmissionChartData from '../hooks/useResultAndSubmissionChartData';
import { useAppSelector } from '../stores/AppStore';
import {
  apiSlice,
  useGetClassResultOverviewQuery,
  useGetClassStudentResultsQuery,
  useGetClassTotalsForTeacherQuery,
  useGetClassesTrackDetailsQuery,
  useGetStudentOrThinkerSummaryDataQuery,
} from '../services/apiSlice';
import useFilteredResults from '../hooks/useFilteredResults';

import BreakoutCircular from '../components/BreakoutCircular';
import Chart from '../components/Chart';
import { FilterValue } from '../components/Filter';
import { Loading, LoadingBlock } from '../components/Loading';
import TeacherClassesHeader from '../partials/TeacherClassesHeader';
import StudentDrillDown from '../partials/StudentDrillDown';

type Filters = {
  class: string;
  student: string;
  subject: Subject;
};

const StudentScores: FC = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<Filters>({
    class: '',
    student: '',
    subject: Subject.geometry,
  });
  const [
    cumulativeOrReviewOrQuizFilter,
    setCumulativeOrReviewOrQuizFilterFilter,
  ] = useState<FilterValue | null>({
    label: 'Cumulative Score',
    value: FILTERS.overall,
  });
  const [isQuizFilter, setIsQuizFilter] = useState(false);
  const [studentScoresTitle, setStudentScoresTitle] = useState('');
  const getClassDocument = (period: string) => {
    if (!classes) {
      return;
    }
    return classes.find((d) => d.class === period) ?? classes[0];
  };
  const getStudentDocument = (studentName: string) =>
    classStudentResult?.find((student) => student.name === studentName);
  const user = useAppSelector((state) => state.user.user);
  if (!user || !user.teachesClasses) {
    return <></>;
  }
  const {
    data: classes,
    isFetching: isFetchingClassesDetails,
    isError: isErrorClassesDetails,
  } = useGetClassesTrackDetailsQuery({
    classes: user.teachesClasses,
  });
  const {
    data: classTotals,
    isFetching: isFetchingClassTotals,
    isError: isErrorClassTotals,
  } = useGetClassTotalsForTeacherQuery({ userId: user._id });

  const {
    data: classResultOverview,
    isFetching: isFetchingClassResultOverview,
  } = useGetClassResultOverviewQuery(
    {
      teacherId: user?._id,
      classId: getClassDocument(filters.class)?._id ?? '',
    },
    { skip: !classes },
  );
  const {
    data: classStudentResult,
    isLoading: isLoadingClassStudentResult,
    isFetching: isFetchingClassStudentResult,
  } = useGetClassStudentResultsQuery(
    {
      classId: getClassDocument(filters.class)?._id ?? '',
    },
    { skip: !classes },
  );
  const {
    data: studentResult,
    isLoading: isLoadingStudentResult,
    isFetching: isFetchingStudentResult,
  } = useGetStudentOrThinkerSummaryDataQuery(
    {
      studentId: getStudentDocument(filters.student)?.student ?? '',
      shouldFetchTrack: false,
    },
    { skip: !classes || getStudentDocument(filters.student) === undefined },
  );

  const prefetchClassStudentResults = apiSlice.usePrefetch(
    'getClassStudentResults',
  );
  const prefetchStudentResult = apiSlice.usePrefetch(
    'getStudentOrThinkerSummaryData',
  );

  const chartData = useResultAndSubmissionChartData(classResultOverview);
  const filteredStudentResults = useFilteredResults({
    filter: cumulativeOrReviewOrQuizFilter?.value,
    result: classStudentResult,
    label: 'name',
    sortBy: 'value',
  });

  const shouldShowTeacherOverview =
    Array.isArray(classTotals) && classTotals.length > 0;

  useEffect(() => {
    setStudentScoresTitle(
      `Period ${filters.class} Student - ${
        cumulativeOrReviewOrQuizFilter?.value
      } Scores - ${getClassDocument(filters.class)?.trackDetails?.track}`,
    );
    setFilters((previousState) => ({ ...previousState, student: '' }));
  }, [cumulativeOrReviewOrQuizFilter, filters.class]);

  useEffect(() => {
    if (filters.class) {
      navigate(`/teacher/student-scores/${filters.class}`);
    }
  }, [filters.class]);

  useEffect(() => {
    if (classes && classes[0]) {
      setFilters((previousState) => ({
        ...previousState,
        class: classes[0].class,
      }));
    }
  }, [classes]);

  if (isFetchingClassesDetails || isFetchingClassTotals) {
    return <LoadingBlock />;
  } else if (isErrorClassesDetails || isErrorClassTotals) {
    throw new Error('Failed to load class students data');
  } else if (!classes || !classTotals || !classResultOverview) {
    return <div>You don't have any data to display yet.</div>;
  }

  return (
    <div className="grid gap-12">
      <TeacherClassesHeader
        circularOnSelect={(period) => {
          setFilters((previousState) => ({
            ...previousState,
            class: period,
          }));
          navigate(`/teacher/student-scores/${period}`);
        }}
        circularOnHover={(period) => {
          prefetchClassStudentResults({
            classId: getClassDocument(period)?._id ?? '',
          });
        }}
        circularSelected={filters.class}
        classResultOverview={classResultOverview}
        classTotals={classTotals}
        isRefreshing={isFetchingClassResultOverview}
        isQuizFilter={isQuizFilter}
        setIsQuizFilter={setIsQuizFilter}
        filter={cumulativeOrReviewOrQuizFilter}
        setFilter={setCumulativeOrReviewOrQuizFilterFilter}
        shouldShowTeacherOverview={shouldShowTeacherOverview}
        title={`Classes - ${user.school}`}
      />
      {chartData && (
        <Chart
          loading={isFetchingClassTotals}
          data={chartData}
          baseType="bar"
          styledTitle={`Period ${filters.class} Quiz results and submission rates`}
        />
      )}
      {classStudentResult && (
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-6 px-6 pb-6 pt-3 shadow-xl rounded-xl border-tttDefault border-[1px]">
          {isLoadingClassStudentResult ? (
            <LoadingBlock width={6} height={32} />
          ) : (
            <>
              <div className="grid justify-self-center text-center col-span-2 lg:col-span-4 max-w-[90%] justify-items-center items-center overflow-hidden uppercase text-white font-bold md:text-xl lg:text-2xl p-3 px-6 -mt-7 shadow-md rounded-xl bg-tttDefault">
                {studentScoresTitle}
              </div>
              <div
                data-testid={'student-list-section'}
                className="box grid col-span-2 lg:col-span-4 overflow-hidden font-medium"
              >
                <div className="absolute right-8">
                  {isFetchingClassStudentResult && <Loading />}
                </div>
                {filteredStudentResults && filteredStudentResults.length > 0 ? (
                  <div className="grid grid-flow-row gap-12 p-6 auto-cols-auto grid-cols-2 md:grid-cols-4 w-full justify-self-center text-sm md:text-base">
                    <BreakoutCircular
                      data={filteredStudentResults}
                      selected={filters.student}
                      isSelectable={true}
                      onSelect={(student) => {
                        setFilters((previousState) => ({
                          ...previousState,
                          student,
                        }));
                      }}
                      onHover={(student) => {
                        prefetchStudentResult({
                          studentId: getStudentDocument(student)!.student,
                          shouldFetchTrack: false,
                        });
                      }}
                    />
                  </div>
                ) : (
                  <div className="col-span-6 justify-self-center text-center p-6 text-gray-500">
                    No student results available for your selected class.
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      )}
      <StudentDrillDown
        isLoading={isLoadingStudentResult || isFetchingStudentResult}
        isOpen={!!filters.student}
        result={studentResult}
      />
    </div>
  );
};

export default StudentScores;

import { useNavigate } from 'react-router-dom';

import { selectTasks } from '../features/user/userSlice';
import { useAppSelector } from '../stores/AppStore';
import { useUpdateThinkerTaskMutation } from '../services/apiSlice';

export type Activity =
  | 'takeQuiz'
  | 'reviewQuiz'
  | 'reviewScores'
  | 'watchVideo';
export type TaskStatus = 'todo' | 'inProgress' | 'completed';

export type Task = {
  id: string;
  activity: Activity;
  name: string;
  description: string;
  icon: string;
  link: string;
  status: TaskStatus;
  quiz?: string;
};

const Task = ({ data }: { data: Task }) => {
  const navigate = useNavigate();
  const [updateThinkerTask] = useUpdateThinkerTaskMutation();
  return (
    <div
      className="flex flex-col justify-between items-left gap-2 w-full"
      data-testid="task"
    >
      <div
        className="flex items-center gap-2 cursor-pointer"
        onClick={() => {
          if (['reviewScores', 'watchVideo'].includes(data.activity)) {
            updateThinkerTask({ taskId: data.id, status: 'inProgress' });
          }
          navigate(data.link);
        }}
      >
        <div className="Group51 w-14 h-14 relative">
          <div className="Rectangle55 w-14 h-14 left-0 top-0 absolute bg-sky-100 rounded-lg" />
          <div className="Frame w-7 h-7 px-0.5 py-0.5 left-[14px] top-[14px] absolute justify-center items-center inline-flex">
            <img
              src={`/images/icons/${data.icon}.svg`}
              alt={`${data.icon}-icon`}
              className="text-tttDefault h-full pt-1"
            />
          </div>
        </div>
        <div className="w-full pl-4">
          <p className="text-lg font-medium" data-testid="task-name">
            {data.name}
          </p>
          <p className="text-sm text-gray-500" data-testid="task-description">
            {data.description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default function ThinkerTasks({}) {
  const tasks = useAppSelector(selectTasks) as Task[];
  return (
    <div
      data-testid="thinker-tasks"
      className="gap-4 items-start mt-auto bg-white rounded-xl grid md:flex md:flex-row p-8 h-full"
    >
      <div className="gap-2 w-full">
        <div className="gap-2 w-full">
          <h2 className="md:text-xl font-medium block w-full mb-4">
            Your Tasks
          </h2>
          <div className="flex gap-4 w-full h-52 flex-col overflow-y-auto">
            {tasks.map((t) => (
              <Task data={t} key={t.name} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

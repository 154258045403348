import { FC } from 'react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { SingleValue, Options } from 'react-select';

interface CustomSelectProperties {
  dataTestId?: string;
  selectFn: (newValue: SingleValue<{ value: any; label: string }>) => void;
  selectOptions?: Options<{ value: any; label: string }> | undefined;
  selectPlaceholder?: string;
  selected?: {
    value: any;
    label: string;
  };
}

const Select: FC<CustomSelectProperties> = ({
  dataTestId,
  selectFn,
  selectOptions,
  selectPlaceholder,
  selected,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    const selectedOption = selectOptions?.find(
      (option) => JSON.stringify(option.value) === selectedValue,
    );
    if (selectedOption) {
      selectFn({
        value: selectedOption.value,
        label: selectedOption.label,
      });
    }
  };

  return (
    <div className="relative">
      <select
        data-testid={dataTestId}
        value={JSON.stringify(selected?.value)}
        onChange={handleChange}
        placeholder={selectPlaceholder}
        className="w-full bg-transparent border-2 border-white text-white p-2 pr-8 rounded-md cursor-pointer"
        style={{
          WebkitAppearance: 'none',
          appearance: 'none',
        }}
      >
        {selectOptions?.map((option, index) => (
          <option
            key={index}
            label={option.label}
            value={JSON.stringify(option.value)}
          >
            {option.label}
          </option>
        ))}
      </select>
      <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
        <ChevronDownIcon className="h-5 w-5 text-white" />
      </div>
    </div>
  );
};

export default Select;

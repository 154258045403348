import { useEffect, useState } from 'react';
import { chain } from 'lodash';

import { ChartData } from '../types';

interface ResultAndSubmissionChartData {
  quizResults?: { quiz: string; isReview?: boolean; result: number }[];
  submissionRates?: { [quizId: string]: number };
}

const useResultAndSubmissionChartData = (
  resultProperty: ResultAndSubmissionChartData | undefined,
  // currentSchedules: QuizSchedule[],
): ChartData | undefined => {
  const [result, setResult] = useState<
    ResultAndSubmissionChartData | undefined
  >(resultProperty);

  useEffect(() => {
    if (resultProperty) {
      setResult(resultProperty);
    }
  }, [resultProperty]);

  if (!result) {
    return;
  }

  const { quizResults, submissionRates } = result;

  let hadASubmission = false;
  const quizIds = submissionRates
    ? chain(submissionRates)
        .omit(['cumulative', 'review'])
        .map((submissionRate, key) => ({ [key]: submissionRate }))
        // filter out the 0 submissions from the end of the dataset
        .reverse()
        .filter((submissionRate) => {
          if (Object.values(submissionRate)[0] > 0) {
            hadASubmission = true;
          }
          return hadASubmission;
        })
        .reverse()
        .map((submissionRate) => Object.keys(submissionRate)[0])
        .value()
    : quizResults
    ? chain(quizResults).map('quiz').reverse().value()
    : [];
  const labels = quizIds.map((quizId) => {
    // FIXME
    // for (const schedule of currentSchedules) {
    //   if (schedule.quiz === quizId) {
    //     return `${quizId} (current)`;
    //   }
    // }
    return quizId;
  });

  if (quizResults || result) {
    const data: ChartData = { labels, datasets: [] };

    const hasQuizResult = quizResults?.some(
      (quizResult) =>
        !quizResult.isReview && Number.isFinite(quizResult.result),
    );
    if (hasQuizResult) {
      data.datasets.push({
        label: 'Regular Quiz Result',
        data: quizIds.map((quizId) => {
          const foundResult = quizResults?.find(
            (quizResult) => quizResult.quiz === quizId && !quizResult.isReview,
          );
          return foundResult
            ? Number.parseFloat((foundResult.result * 100).toFixed(2))
            : undefined;
        }),
        type: 'line',
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235)',
        trendlineLinear: {
          color: 'rgba(53, 162, 235, 0.5)',
          lineStyle: 'dotted' as any,
          width: 2,
          style: {},
        },
      });
    }

    const hasReviewResult = quizResults?.some(
      (quizResult) => quizResult.isReview && Number.isFinite(quizResult.result),
    );
    if (hasReviewResult) {
      data.datasets.push({
        label: 'Review Quiz Result',
        data: quizIds.map((quizId) => {
          const foundResult = quizResults?.find(
            (quizResult) => quizResult.quiz === quizId && quizResult.isReview,
          );
          return foundResult
            ? Number.parseFloat((foundResult.result * 100).toFixed(2))
            : undefined;
        }),
        pointStyle: 'star',
        type: 'scatter',
        borderColor: 'rgb(0, 105, 217)',
        backgroundColor: 'rgb(0, 105, 217)',
        borderDash: [6, 2] as [number, number],
      });
    }

    if (submissionRates) {
      const hasSubmissionRate = quizIds.some(
        (quizId) => submissionRates[quizId] > 0,
      );
      if (hasSubmissionRate) {
        data.datasets.push({
          label: 'Submission rate',
          data: quizIds.map((quizId) =>
            Number.parseFloat((submissionRates[quizId] * 100).toFixed(2)),
          ),
          borderColor: 'rgb(228, 125, 0)',
          backgroundColor: 'rgba(228, 125, 0, 0.5)',
        });
      }
    }
    return data;
  }
};

export default useResultAndSubmissionChartData;

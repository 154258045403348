import { FC } from 'react';

export const Maintenance: FC = () => {
  return (
    <div className="grid grid-flow-row place-content-center text-center auto-rows-auto p-6">
      <div className="text-xl font-bold">Maintenance</div>
      The application is currently under maintenance. We are working on making
      things even better!
      <div className="text-lg md:text-xl lg:text-2xl p-8">
        We keep trying to reach the updated system...
      </div>
      <div>
        Please do not close or reload the page if you have any unsaved changes.{' '}
        <br />
        The app will load automatically and save your changes when the
        maintenance is done.
      </div>
      <div className="justify-self-center max-w-[70%] md:max-w-[50%] mt-16">
        <img src={'/maintenanceLogo.gif'} alt="Maintenance..." />
      </div>
    </div>
  );
};

/* eslint-disable unicorn/consistent-function-scoping */
import { useEffect, useState } from 'react';
import { chain } from 'lodash';
import { push } from 'redux-first-history';

import {
  ClassTotal,
  ExtendedSkillResult,
  ExtendedSubjectResult,
  ExtendedStudentResult,
  StudentResultOverview,
  TeacherResult,
} from '../types';
import { FILTERS } from '../constants';
import { store } from '../stores/AppStore';
import { CircularData } from '../components/BreakoutCircular';

type Result =
  | ClassTotal
  | ExtendedSkillResult
  | ExtendedSubjectResult
  | ExtendedStudentResult
  | StudentResultOverview
  | TeacherResult;

type LabelProperty<T> = Extract<
  {
    [K in keyof T]: T[K] extends string ? K : never;
  }[keyof T],
  string
>;

const useFilteredResults = <T extends Result>({
  filter,
  result,
  label,
  removeUndefined = false,
  sortBy,
}: {
  filter?: string | null | undefined;
  result: T[] | undefined;
  label: LabelProperty<T>;
  removeUndefined?: boolean;
  sortBy?: 'label' | 'value';
}): CircularData[] | undefined => {
  const [results, setResults] = useState<T[] | undefined>(result);

  useEffect(() => {
    if (result) {
      setResults(result);
    }
  }, [result]);

  if (!results || !Array.isArray(results)) {
    return;
  }

  const getValue = (result: T) => {
    if (!filter) {
      return result.overallResult;
    }
    if (filter === FILTERS.overall) {
      return result.overallResult;
    } else if (filter === FILTERS.reviewOverall) {
      return result.reviewResult === null ? undefined : result.reviewResult;
    } else {
      return result.quizResults?.find((r) => r.quiz === filter)?.result;
    }
  };

  let valueFilterFunction: (skill: CircularData) => boolean = () => true;

  if (removeUndefined) {
    valueFilterFunction = (skillResult) => skillResult.value !== undefined;
  }

  return chain(results as T[])
    .map((result) => {
      return {
        label: result[label] as unknown as string, // necessary for type compliance, eventhough custom type ensures that it's a string
        value: getValue(result),
        tooltip:
          result.hasOwnProperty('subject') &&
          result.hasOwnProperty('description')
            ? {
                actionName: 'Show tutorial',
                description: (result as any).description,
                action: () =>
                  store.dispatch(
                    push({
                      pathname: `/tutorials/${(result as any).subject}`,
                      search: `${new URLSearchParams({
                        skills: result[label] as unknown as string,
                      })}`,
                    }),
                  ),
              }
            : undefined,
      };
    })
    .filter((element) => valueFilterFunction(element as CircularData))
    .sortBy(sortBy ?? '')
    .value();
};

export default useFilteredResults;

import { PiCalendarXLight, PiCalendarBlankLight, PiLock } from 'react-icons/pi';
import { Link } from 'react-router-dom';

import { CurrentQuiz, StudentTrackWithSchedule } from '../../types';

interface TodoAssignmentProperties {
  assignment: CurrentQuiz;
  track?: StudentTrackWithSchedule;
}

const TodoAssignment = ({ assignment, track }: TodoAssignmentProperties) => {
  return (
    <div
      data-testid={`todo-assignment-${assignment.id}`}
      className={`flex flex-col md:flex-row gap-3 bg-white rounded-xl border border-1-gray p-4 ${
        assignment.isReview && 'border border-tttDefault'
      }`}
    >
      <div className="flex gap-3 items-center">
        <p className="font-medium">{assignment.id}</p>
        <div className="flex gap-2 items-center">
          {assignment.isOverdue ? (
            <PiCalendarXLight className="w-4 h-4 text-rose-500" />
          ) : (
            <PiCalendarBlankLight className="w-4 h-4 text-tttDefault" />
          )}
          <p className="text-sm text-gray-700">
            {assignment.isOverdue ? 'Overdue' : `Due ${assignment.dueDate}`}
          </p>
        </div>
      </div>
      {assignment.canStart ? (
        <Link
          to={track ? `/quiz/${track.serialNum}/${assignment.id}/solve` : ''}
          className="
            px-4 py-1.5 rounded-full w-max h-max md:ml-auto md:my-auto
            text-sm md:text-base
            font-medium text-white bg-tttDefault
            transition-all duration-300 ease-in-out
            hover:shadow hover:bg-opacity-90
          "
        >
          Take Quiz
        </Link>
      ) : (
        <div className="flex gap-2 md:ml-auto md:my-auto items-center text-sm">
          <PiLock className="w-4 h-4 text-tttDefault" />
          Unlock after the previous quiz
        </div>
      )}
    </div>
  );
};

export default TodoAssignment;

import Vimeo from '@u-wave/react-vimeo';
import { Link } from 'react-router-dom';

import { Tutorial } from '../types';
import { Subject } from '../constants';

export default function TutorialCard({ problemNum, skills, url }: Tutorial) {
  return (
    <div
      key={problemNum}
      className={`grid bg-white rounded-xl w-full min-h-[200px] overflow-hidden text-left p-3`}
    >
      <div className="w-full rounded-xl overflow-hidden">
        <Vimeo
          video={url}
          controls={true}
          speed={true}
          responsive
          className="z-0"
        />
      </div>
      <div className="mt-2 p-2 text-lg font-bold self-end">
        Video {`${problemNum}`}
      </div>
      <div className="h-24">
        {skills.map((s) => (
          <div
            key={s.skill}
            className="m-1 px-2.5 py-1 bg-[#e6f0fb] text-xs text-tttDefault rounded-full inline-block"
          >
            <Link
              to={`/tutorials/${
                s.subject === 'Algebra 1' ? Subject.algebra : s.subject
              }?skills=${s.skill}`}
            >{`${s.skill}`}</Link>
          </div>
        ))}
      </div>
    </div>
  );
}

import { SubjectResult } from '../types';
import { CircularProgressbar } from 'react-circular-progressbar';
import { getColorHex } from '../util';

interface Properties {
  subjectResults: SubjectResult[];
  mainScore: number;
  mainScoreLabel?: string;
  hideHeader?: boolean;
}

const getDisplayedValueText = (value?: number | undefined) =>
  typeof value === 'number' && Number.isFinite(value)
    ? Math.round(value * 100) + '%'
    : '%';

const scrollToSkillsBreakout = () => {
  const targetElement = document.querySelector('#skill-breakout');
  if (targetElement) {
    targetElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};

export default function GapsSummary({
  subjectResults,
  mainScore,
  mainScoreLabel,
  hideHeader,
}: Properties) {
  const subjectScores = {
    Geometry: subjectResults.find((r) => r.subject === 'Geometry')?.result,
    Algebra: subjectResults.find((r) => r.subject === 'Algebra')?.result,
    'Pre-Algebra': subjectResults.find((r) => r.subject === 'Pre-Algebra')
      ?.result,
    Statistics: subjectResults.find((r) => r.subject === 'Statistics')?.result,
  };
  const colors = {
    Geometry: getColorHex(100 * (subjectScores['Geometry'] ?? 0)),
    Algebra: getColorHex(100 * (subjectScores['Algebra'] ?? 0)),
    'Pre-Algebra': getColorHex(100 * (subjectScores['Pre-Algebra'] ?? 0)),
    Statistics: getColorHex(100 * (subjectScores['Statistics'] ?? 0)),
  };

  return (
    <div
      data-testid="gaps-summary"
      className="gap-4 items-start mt-auto bg-white rounded-xl grid md:flex md:flex-row p-8"
    >
      <div className="relative flex flex-col justify-between items-center gap-2 w-full">
        {!hideHeader && (
          <div className="flex justify-between items-center gap-2 w-full">
            <h2 className="md:text-xl font-medium mb-4">Your Skills</h2>
            <div
              className="ViewDetailedBreakout text-sky-600 text-sm font-normal hover:underline hover:cursor-pointer"
              onClick={scrollToSkillsBreakout}
            >
              View Detailed Breakout
            </div>
          </div>
        )}
        <div
          className={`absolute ${
            hideHeader ? 'top-0' : 'top-[48px] md:top-[52px]'
          } left-auto right-auto mt-4 w-44 sm:w-52 h-44 sm:h-52`}
        >
          <div className="absolute w-full h-full">
            <CircularProgressbar
              value={100 * (subjectScores['Geometry'] ?? 0)}
              strokeWidth={4}
              circleRatio={0.23} /* quarter circle */
              styles={{
                trail: {
                  stroke: '#d1d5db',
                  strokeLinecap: 'round',
                  transform: 'rotate(273deg)',
                  transformOrigin: 'center center',
                },
                path: {
                  stroke: colors['Geometry'],
                  strokeLinecap: 'round',
                  transform: 'rotate(273deg)',
                  transformOrigin: 'center center',
                },
              }}
            />
          </div>
          <div className="absolute w-full h-full">
            <CircularProgressbar
              value={100 * (subjectScores['Algebra'] ?? 0)}
              strokeWidth={4}
              circleRatio={0.23} /* quarter circle */
              styles={{
                trail: {
                  stroke: '#d1d5db',
                  strokeLinecap: 'round',
                  transform: 'rotate(3deg)',
                  transformOrigin: 'center center',
                },
                path: {
                  stroke: colors['Algebra'],
                  strokeLinecap: 'round',
                  transform: 'rotate(3deg)',
                  transformOrigin: 'center center',
                },
              }}
            />
          </div>
          <div className="absolute w-full h-full">
            <CircularProgressbar
              value={100 * (subjectScores['Pre-Algebra'] ?? 0)}
              strokeWidth={4}
              circleRatio={0.23} /* quarter circle */
              styles={{
                trail: {
                  stroke: '#d1d5db',
                  strokeLinecap: 'round',
                  transform: 'rotate(93deg)',
                  transformOrigin: 'center center',
                },
                path: {
                  stroke: colors['Pre-Algebra'],
                  strokeLinecap: 'round',
                  transform: 'rotate(93deg)',
                  transformOrigin: 'center center',
                },
              }}
            />
          </div>
          <div className="absolute w-full h-full">
            <CircularProgressbar
              value={100 * (subjectScores['Statistics'] ?? 0)}
              strokeWidth={4}
              circleRatio={0.23} /* quarter circle */
              styles={{
                trail: {
                  stroke: '#d1d5db',
                  strokeLinecap: 'round',
                  transform: 'rotate(183deg)',
                  transformOrigin: 'center center',
                },
                path: {
                  stroke: colors['Statistics'],
                  strokeLinecap: 'round',
                  transform: 'rotate(183deg)',
                  transformOrigin: 'center center',
                },
              }}
            />
          </div>
        </div>
        <div className="DonutChart w-72 sm:w-96 h-52 relative">
          <div
            className={`left-0 top-[69px] sm:top-20 w-full absolute text-4xl font-medium ${
              Number.isFinite(mainScore) ? 'text-zinc-800' : 'text-blue-300'
            }`}
          >
            <div className="w-full text-center">
              {getDisplayedValueText(mainScore)}
            </div>
          </div>
          <div className="CumulativeScore left-0 top-[119px] sm:top-32 w-full absolute text-gray-500 text-sm font-normal sm:text-base">
            <div className="w-full text-center">
              {mainScoreLabel ?? 'Cumulative Score'}
            </div>
          </div>
          <div className="Group59 w-16 h-11 sm:left-[10px] top-[10px] absolute">
            <div className="Geometry left-0 top-0 absolute text-center text-zinc-800 text-base font-normal">
              Geometry
            </div>
            <div
              className={`left-[12px] top-[22px] absolute text-center text-xl font-medium ${
                Number.isFinite(subjectScores['Geometry'])
                  ? 'text-zinc-800'
                  : 'text-blue-300'
              }`}
            >
              {getDisplayedValueText(subjectScores['Geometry'])}
            </div>
          </div>
          <div className="Group57 w-16 h-11 right-0 sm:right-auto sm:left-[311px] top-[10px] absolute">
            <div className="Algebra left-0 top-0 absolute text-center text-zinc-800 text-base font-normal">
              Algebra
            </div>
            <div
              className={`left-[14px] top-[22px] absolute text-center text-xl font-medium ${
                Number.isFinite(subjectScores['Algebra'])
                  ? 'text-zinc-800'
                  : 'text-blue-300'
              }`}
            >
              {getDisplayedValueText(subjectScores['Algebra'])}
            </div>
          </div>
          <div className="Group58 w-24 h-11 right-[-20px] sm:right-auto sm:left-[300px] top-[159px] absolute">
            <div className="PreAlgebra left-0 top-0 absolute text-center text-zinc-800 text-base font-normal">
              Pre-Algebra
            </div>
            <div
              className={`left-[26px] top-[22px] absolute text-center text-xl font-medium ${
                Number.isFinite(subjectScores['Pre-Algebra'])
                  ? 'text-zinc-800'
                  : 'text-blue-300'
              }`}
            >
              {getDisplayedValueText(subjectScores['Pre-Algebra'])}
            </div>
          </div>
          <div className="Group60 w-16 h-11 left-2 top-[159px] absolute">
            <div className="Statistics left-0 top-0 absolute text-center text-zinc-800 text-base font-normal whitespace-nowrap">
              Statistics
            </div>
            <div
              className={`left-[16px] top-[22px] absolute text-center text-xl font-medium ${
                Number.isFinite(subjectScores['Statistics'])
                  ? 'text-zinc-800'
                  : 'text-blue-300'
              }`}
            >
              {getDisplayedValueText(subjectScores['Statistics'])}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

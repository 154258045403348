import Vimeo, { ProgressEvent } from '@u-wave/react-vimeo';

import { store, useAppSelector } from '../stores/AppStore';
import {
  getPopupVideoWidth,
  thinkerMentorWelcomeVideoUrl,
  thinkerMentorWelcomeVideoMinimumWatchPercentage,
} from '../constants';
import {
  selectWelcomeVideoWatchedPct,
  setWelcomeVideoWatchedPct,
  selectShowWelcomeVideoPopup,
  setShowWelcomeVideoPopup,
} from '../features/global/globalSlice';
import Modal from 'react-modal';
import { XIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { debounce } from 'lodash';

const onVideoProgress = debounce(
  (event: ProgressEvent) => {
    const percentage = Math.round(event.percent * 100);
    store.dispatch(setWelcomeVideoWatchedPct(percentage));
    if (percentage >= thinkerMentorWelcomeVideoMinimumWatchPercentage) {
      // NOTE: video won't come up again if they watched the minimum % of it
      store.dispatch(setShowWelcomeVideoPopup(false));
    }
  },
  1000,
  { trailing: true },
);

const WelcomeVideoPopup = () => {
  const welcomeVideoWatchedPct = useAppSelector(selectWelcomeVideoWatchedPct);
  const didWatchWelcomeVideo =
    (welcomeVideoWatchedPct ?? 0) >=
    thinkerMentorWelcomeVideoMinimumWatchPercentage;
  const showWelcomeVideoPopup = useAppSelector(selectShowWelcomeVideoPopup);
  const [isPopupTemporarilyHidden, setPopupTemporarilyHidden] =
    useState<boolean>(false);
  const isDevelopmentOrTestEnvironment =
    process.env.REACT_APP_NODE_ENV === 'test' ||
    process.env.REACT_APP_NODE_ENV === 'development';

  const videoModalStyles: Modal.Styles = {
    content: {
      zIndex: 55,
      overflow: 'hidden',
      borderRadius: '12px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
    },
    overlay: {
      zIndex: 50,
    },
  };

  const dismissPopup = () => {
    const confirmed = window.confirm(
      'To ensure you have a great experience, we encourage all users to watch this short video. If this is not a convenient time, you may dismiss it now and it will appear next time for your review.',
    );
    if (confirmed) {
      setPopupTemporarilyHidden(true);
    }
  };

  return (
    <Modal
      isOpen={
        Boolean(!didWatchWelcomeVideo || showWelcomeVideoPopup) &&
        !isPopupTemporarilyHidden &&
        !isDevelopmentOrTestEnvironment
      }
      onRequestClose={dismissPopup}
      style={videoModalStyles}
    >
      <div className="">
        <div
          className="absolute grid justify-items-end w-full top-2 pr-6 cursor-pointer"
          onClick={dismissPopup}
        >
          <XIcon className="h-5 w-5" />
        </div>
        <div className="grid justify-items-center items-center mt-3">
          <Vimeo
            video={thinkerMentorWelcomeVideoUrl}
            width={getPopupVideoWidth()}
            controls={true}
            speed={true}
            // onReady={onReady}
            autoplay={true}
            onProgress={onVideoProgress}
          />
        </div>
      </div>
    </Modal>
  );
};

export default WelcomeVideoPopup;

/* eslint-disable unicorn/no-useless-undefined */
import { FC, useEffect, useRef, useState } from 'react';
import { ChevronUp, ChevronDown } from 'lucide-react';
import { PiCheckSquareOffsetLight } from 'react-icons/pi';

import { FilterType } from '../constants';
import {
  QuizResult,
  StudentQuizStats,
  StudentResult,
  StudentTrackWithSchedule,
} from '../types';

import { useAppSelector } from '../stores/AppStore';
import {
  useGetThinkerResultsForMentorQuery,
  useGetStudentQuizStatsQuery,
  apiSlice,
  useGetTracksForStudentQuery,
} from '../services/apiSlice';
import useUpdatedStudentAssignments from '../hooks/useStudentUpdatedAssignments';
import useScreenSize from '../hooks/useScreenResize';

import { LoadingBlock } from '../components/Loading';
import Filter, { FilterValue } from '../components/Filter';
import { QuizScores } from '../partials/QuizScores';
import CompletedAssignment from '../components/Assignment/CompletedAssignment';
import QuizResultDetailsModal from '../components/Assignment/QuizResultDetailsModal';
import LoadingSnake from '../components/LoadingSnake';
import UpcomingAssignment from '../components/Assignment/UpcomingAssignment';
import CheckpointTestsModal from '../components/Assignment/CheckpointTestsModal';

const MentorAssignments: FC = () => {
  const [studentFilter, setStudentFilter] = useState<FilterValue<
    StudentResult & { name: string; quizResults: QuizResult[] }
  > | null>();
  const [shouldShowCheckpointTestsModal, setShouldShowCheckpointTestsModal] =
    useState(false);
  const user = useAppSelector((state) => state.user.user);
  if (!user) {
    return <></>;
  }
  const quizScoresReference = useRef<HTMLDivElement>(null);
  const { data: thinkerResults, isLoading: isLoadingThinkerResults } =
    useGetThinkerResultsForMentorQuery();
  const { data: track, isFetching: isFetchingTrack } =
    useGetTracksForStudentQuery(
      {
        studentId: studentFilter?.value?.student ?? '',
        shouldFetchWorkbookUrls: true,
      },
      {
        skip: !studentFilter?.value?.student,
      },
    );

  const prefetchQuizTrack = apiSlice.usePrefetch('getTracksForStudent');

  const thinkerFilterOptions: FilterValue<
    StudentResult & { name: string; quizResults: QuizResult[] }
  >[] = [];

  if (thinkerResults && thinkerResults?.length > 0) {
    for (const result of thinkerResults) {
      thinkerFilterOptions.push({ label: result.name, value: result });
    }
  }

  useEffect(() => {
    if (thinkerResults && thinkerResults[0]) {
      setStudentFilter({
        label: thinkerResults[0].name,
        value: thinkerResults[0],
      });
    }
  }, [thinkerResults]);

  if (isLoadingThinkerResults) {
    return <LoadingBlock />;
  }

  return (
    <div
      className="
        relative
        flex flex-col gap-8 p-4 md:p-6
        w-full h-full items-start
        bg-gray-100 rounded-xl
      "
    >
      <CheckpointTestsModal
        isOpen={shouldShowCheckpointTestsModal}
        studentId={studentFilter?.value.student ?? ''}
        trackId={studentFilter?.value.track ?? ''}
        onClose={() => setShouldShowCheckpointTestsModal(false)}
      />
      <div className="flex flex-col md:flex-row gap-4 md:items-center justify-between w-full">
        <h1 className="text-3xl font-medium">Assignments</h1>
        <button
          onClick={() => setShouldShowCheckpointTestsModal(true)}
          className="
            flex items-center justify-center gap-2
            px-4 py-1.5 w-full md:w-max h-max md:my-auto
            rounded-full border border-tttDefault
            text-sm lg:text-base
            font-medium text-tttDefault whitespace-nowrap
            transition-all duration-100 ease-in-out
            hover:shadow hover:bg-tttDefault hover:text-white
          "
        >
          <PiCheckSquareOffsetLight className="w-5 h-5" />
          Checkpoint Tests
        </button>
      </div>
      {Array.isArray(thinkerResults) && thinkerResults.length > 1 && (
        <Filter
          filterType={'thinker' as FilterType}
          options={thinkerFilterOptions ?? []}
          selected={studentFilter as FilterValue}
          onSelect={(selectedFilter: FilterValue) => {
            setStudentFilter(selectedFilter);
          }}
          onHover={(selectedFilter: FilterValue) => {
            prefetchQuizTrack({
              studentId: selectedFilter.value?.student ?? '',
            });
          }}
        />
      )}
      {isFetchingTrack && (
        <div className="my-auto mx-auto p-8">
          <LoadingSnake />
        </div>
      )}
      {!isFetchingTrack && track && studentFilter && (
        <Assignments
          track={track}
          studentId={studentFilter.value.student}
          quizScoresReference={quizScoresReference}
        />
      )}
      <div data-testid="assignment-results" className="w-full pt-4">
        {studentFilter && (
          // Quiz scores by student
          <QuizScores
            id={studentFilter.label}
            caption="Detailed results by quiz and problem"
            dataQuery={{ studentId: studentFilter.value.student }}
            isStudentList={false}
            scoresRef={quizScoresReference}
            showPracticeDetails={true}
            showReviewColumn
            showTime
          />
        )}
      </div>
    </div>
  );
};

const Assignments = ({
  track,
  studentId,
  quizScoresReference,
}: {
  track: StudentTrackWithSchedule;
  studentId: string;
  quizScoresReference: React.RefObject<HTMLDivElement>;
}) => {
  const { width } = useScreenSize();
  const isOverMd = width > 768;
  const [shouldShowCompletedAssignments, setShouldShowCompletedAssignments] =
    useState(true);
  const [shouldShowTodoAssignments, setShouldShowTodoAssignments] =
    useState(true);
  const [selectedQuiz, setSelectedQuiz] = useState<string>();
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const { completedAssignments, todoAssignments } =
    useUpdatedStudentAssignments(track);

  const { data: quizStats, isFetching: isFetchingQuizStats } =
    useGetStudentQuizStatsQuery(
      { studentId, quizId: selectedQuiz ?? '' },
      { skip: !selectedQuiz },
    );

  const handleViewQuizDetails = (quiz: string) => {
    setSelectedQuiz(quiz);
    setShouldShowModal(true);
  };

  const handleSeeAllQuizResults = () => {
    if (quizScoresReference.current) {
      quizScoresReference.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (isOverMd) {
      setShouldShowCompletedAssignments(true);
      setShouldShowTodoAssignments(true);
    }
  }, [isOverMd]);

  return (
    <div
      data-testid={'assignments-container'}
      className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full mb-auto"
    >
      <QuizResultDetailsModal
        quizStats={quizStats as StudentQuizStats | undefined}
        isLoadingQuizStats={isFetchingQuizStats}
        showReviewStatus
        isOpen={shouldShowModal}
        onClose={() => setShouldShowModal(false)}
      />
      <div
        data-testid={'completed-assignments'}
        className="flex flex-col gap-4"
      >
        <div
          data-testid={'completed-container'}
          className="w-full flex gap-4 items-center"
        >
          <div
            data-testid={'title-container'}
            className="w-full grid md:flex md:flex-wrap justify-between gap-4 md:items-center"
          >
            <div className="flex gap-2 row-start-1">
              <h2 className="text-xl font-medium">Completed</h2>
              <div className="flex gap-1 text-sm text-gray-500 items-center whitespace-nowrap">
                {completedAssignments.length} Quizzes
              </div>
            </div>
            <button
              onClick={handleSeeAllQuizResults}
              className="
                row-start-2 col-span-2
                px-4 py-1.5 w-full md:w-max h-max md:my-auto
                rounded-full border border-tttDefault
                text-sm lg:text-base
                font-medium text-tttDefault whitespace-nowrap
                transition-all duration-100 ease-in-out
                hover:shadow hover:bg-tttDefault hover:text-white
              "
            >
              See All Quiz Results
            </button>
            {isOverMd ? (
              <></>
            ) : shouldShowCompletedAssignments ? (
              <ChevronUp
                className="row-start-1 h-8 w-8 p-1 ml-auto text-gray-500 border border-gray-400 rounded-full cursor-pointer hover:bg-gray-200"
                onClick={() =>
                  setShouldShowCompletedAssignments((previous) => !previous)
                }
              />
            ) : (
              <ChevronDown
                className="row-start-1 h-8 w-8 p-1 ml-auto text-gray-500 border border-gray-400 rounded-full cursor-pointer hover:bg-gray-200"
                onClick={() =>
                  setShouldShowCompletedAssignments((previous) => !previous)
                }
              />
            )}
          </div>
        </div>
        {shouldShowCompletedAssignments ? (
          completedAssignments.length > 0 ? (
            <div
              data-testid={'assignment-list'}
              className="flex flex-col gap-2"
            >
              {completedAssignments.map((assignment) => (
                <CompletedAssignment
                  key={assignment.id}
                  assignment={assignment}
                  track={track}
                  onClickViewDetails={handleViewQuizDetails}
                  canDownloadWorkbook
                />
              ))}
            </div>
          ) : (
            <p className="text-gray-500">No completed assignments yet.</p>
          )
        ) : (
          <></>
        )}
      </div>
      <div data-testid={'upcoming-assignments'} className="flex flex-col gap-4">
        <div
          data-testid={'completed-container'}
          className="w-full flex gap-4 items-center"
        >
          <div
            data-testid={'title-container'}
            className="w-full grid md:flex md:flex-wrap justify-between gap-4 md:items-center"
          >
            <div className="flex gap-2 row-start-1">
              <h2 className="text-xl font-medium">Upcoming</h2>
              <div className="flex gap-1 text-sm text-gray-500 items-center whitespace-nowrap">
                {todoAssignments.length} Quizzes
              </div>
            </div>
            {isOverMd ? (
              <></>
            ) : shouldShowTodoAssignments ? (
              <ChevronUp
                className="row-start-1 h-8 w-8 p-1 ml-auto text-gray-500 border border-gray-400 rounded-full cursor-pointer hover:bg-gray-200"
                onClick={() =>
                  setShouldShowTodoAssignments((previous) => !previous)
                }
              />
            ) : (
              <ChevronDown
                className="row-start-1 h-8 w-8 p-1 ml-auto text-gray-500 border border-gray-400 rounded-full cursor-pointer hover:bg-gray-200"
                onClick={() =>
                  setShouldShowTodoAssignments((previous) => !previous)
                }
              />
            )}
          </div>
        </div>
        {shouldShowTodoAssignments ? (
          todoAssignments.length > 0 ? (
            <div
              data-testid={'assignment-list'}
              className="flex flex-col gap-2"
            >
              {todoAssignments.map((assignment) => (
                <UpcomingAssignment
                  key={assignment.id}
                  assignment={assignment}
                  track={track}
                  canDownloadWorkbook
                />
              ))}
            </div>
          ) : (
            <p className="text-gray-500">No upcoming assignments.</p>
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default MentorAssignments;

import { FC } from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

import { getColorHex } from '../util';

type CircularTooltip = {
  actionName: string;
  action: (itemLabel?: string) => void;
  description: string;
};

export type CircularData = {
  label: string;
  value?: number | undefined;
  tooltip?: CircularTooltip;
  labelContent?: JSX.Element; // Label to be displayed below the circle
};

interface Properties {
  data: CircularData[];
  hasTooltips?: boolean;
  isSelectable: boolean;
  labelPrefix?: string;
  selected?: string;
  onSelect?: (value: string) => void;
  onHover?: (value: string) => void;
}

const getDisplayedValueCircle = (value?: number | undefined) =>
  typeof value === 'number' && Number.isFinite(value)
    ? Math.max(value * 100, 1)
    : 0;
const getDisplayedValueText = (value?: number | undefined) =>
  typeof value === 'number' && Number.isFinite(value)
    ? Math.round(value * 100) + '%'
    : '';

const BreakoutCircular: FC<Properties> = ({
  data,
  hasTooltips,
  isSelectable,
  labelPrefix,
  selected,
  onSelect,
  onHover,
}) => {
  const getValueOrIcon = (data: CircularData) => {
    if (data.value !== undefined) {
      return getDisplayedValueText(data.value);
    }
    switch (data.label) {
      case 'Geometry': {
        return (
          <img src="/images/icons/subject-geometry.svg" alt="Geometry icon" />
        );
      }
      case 'Algebra': {
        return (
          <img src="/images/icons/subject-algebra.svg" alt="Algebra icon" />
        );
      }
      case 'Pre-Algebra': {
        return (
          <img
            src="/images/icons/subject-pre-algebra.svg"
            alt="Pre-Algebra icon"
          />
        );
      }
      case 'Statistics': {
        return (
          <img
            src="/images/icons/subject-statistics.svg"
            alt="Statistics icon"
          />
        );
      }
      default: {
        return <p className="text-tttDefault">%</p>;
      }
    }
  };
  const content = (data: CircularData, index: number) => {
    const percentageValue = getDisplayedValueCircle(data.value);
    const color = getColorHex(percentageValue);
    return (
      <div
        data-testid={'circular-breakout'}
        key={index}
        className={`grid text-center gap-5 text-3xl w-[100%] min-w-[140px] max-w-[180px] p-4 md:p-6 md:-mb-1 md:mt-1 items-center ${
          isSelectable && data.label === selected
            ? 'bg-white rounded-xl md:rounded-none md:rounded-t-xl overflow-visible'
            : ''
        } ${
          isSelectable
            ? 'md:hover:-translate-y-1 hover:cursor-pointer duration-200'
            : ''
        }`}
        onMouseOver={() => {
          if (isSelectable && typeof onHover === 'function') {
            onHover(data.label);
          }
        }}
        onClick={() => {
          if (isSelectable && typeof onSelect === 'function') {
            onSelect(data.label);
          }
          if (hasTooltips && window.innerWidth >= 768) {
            data.tooltip?.action(data.label);
          }
        }}
      >
        <CircularProgressbarWithChildren
          value={percentageValue}
          strokeWidth={6}
          styles={{
            text: { fill: color, textShadow: '1px 2px 2px #d1d5db' },
            path: { stroke: color },
            trail: { stroke: '#d1d5db' },
          }}
          className="h-full w-full"
        >
          {getValueOrIcon(data)}
        </CircularProgressbarWithChildren>
        <div
          data-testid={'circular-breakout-label'}
          className={'place-items-center text-lg font-medium'}
        >
          {data.labelContent ?? (
            <>
              {labelPrefix}
              {data.label}
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {data.map((item, index) =>
        hasTooltips ? (
          <Tooltip
            placement="bottom"
            overlay={<TooltipOverlay item={item} />}
            overlayClassName="max-w-[30%]"
            destroyTooltipOnHide={true}
          >
            {content(item, index)}
          </Tooltip>
        ) : (
          content(item, index)
        ),
      )}
    </>
  );
};

const TooltipOverlay = ({ item }: { item: CircularData }) => {
  return (
    <div className="grid grid-flow-row gap-3 justify-between w-full py-2 items-center font-medium">
      <div
        className="flex md:hidden items-center cursor-pointer font-bold underline"
        onClick={() => item.tooltip?.action(item.label)}
      >
        {item.tooltip?.actionName}
      </div>
      <div>{item.tooltip?.description}</div>
    </div>
  );
};

export default BreakoutCircular;

import { useEffect } from 'react';

import { useAppSelector } from '../../stores/AppStore';
import {
  selectMentor,
  selectPaymentIntentId,
  selectThinkers,
} from '../../features/signUp/signUpSlice';
import {
  useGetMentorPasswordResetLinkByPaymentIdQuery,
  useIndividualThinkerSignupMutation,
} from '../../services/apiSlice';

import LoadingSnake from '../LoadingSnake';

const SubscriptionConfirm = () => {
  const thinkers = useAppSelector(selectThinkers);
  const mentor = useAppSelector(selectMentor);
  const paymentIntentId = useAppSelector(selectPaymentIntentId);
  const [
    individualThinkerSignup,
    { data: mentorUserId, isLoading, error: signUpError },
  ] = useIndividualThinkerSignupMutation();
  const { data: passwordResetLink } =
    useGetMentorPasswordResetLinkByPaymentIdQuery(
      {
        paymentIntentId: paymentIntentId ?? '',
        mentorUserId: mentorUserId ?? '',
      },
      {
        skip: !paymentIntentId || !mentorUserId,
      },
    );

  useEffect(() => {
    if (!paymentIntentId || thinkers.length === 0) {
      return;
    }
    individualThinkerSignup({
      paymentIntentId,
      mentor,
      thinkers,
    });
  }, [paymentIntentId, mentor, thinkers]);

  if (isLoading) {
    return (
      <div className="flex flex-col gap-8 justify-center items-center h-full w-full">
        <img src="/images/loading.svg" alt="Subscription Loading" />
        <p className="mb-4 text-center font-medium">
          Hang tight! We're finalizing everything for your{' '}
          {thinkers.length === 1 ? "child's" : "children's"} learning adventure.
          This will just take a moment...
        </p>
        <LoadingSnake />
      </div>
    );
  }

  if (signUpError) {
    const errorMessage =
      'status' in signUpError && signUpError.status === 409
        ? `It looks like you have already subscribed to our service. If you
    believe this is an error, `
        : `We encountered an error while processing your subscription. Please try
    again later or `;
    return (
      <div className="flex flex-col gap-8 justify-center items-center h-48 w-full">
        <h1 className="text-xl xl:text-2xl font-bold w-full">
          Subscription Failed 😢
        </h1>
        <p className="mb-4">
          {errorMessage}please&nbsp;
          <a
            href="mailto:help@thinktankprep.com?subject=Subscription Issue"
            target="_blank"
            className="text-tttDefault hover:underline"
            rel="noreferrer"
          >
            contact us.
          </a>
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col md:flex-row gap-2 md:gap-6">
      <div
        data-testid="image-grid"
        className="grid grid-rows-2 grid-cols-1 gap-2 md:gap-4 md:w-1/2 order-2 md:order-1"
      >
        <div className="grid grid-cols-[55%_45%]">
          <div className="row-span-2 col-span-1 pr-2 md:pr-4">
            <img
              src="/images/matt.png"
              alt="Matt Miklius"
              className="w-full h-full rounded-xl object-cover"
            />
          </div>
          <div className="row-start-1 col-start-2 pb-2 md:pb-4">
            <img
              src="/images/window-desk.png"
              alt="Children at window Desk"
              className="w-full h-full rounded-xl object-cover"
            />
          </div>
          <div className="row-start-2 col-start-2">
            <img
              src="/images/garage-door.png"
              alt="Children front of garage door"
              className="w-full h-full rounded-xl object-cover"
            />
          </div>
        </div>
        <div className="grid grid-cols-[45%_55%]">
          <div className="row-start-1 col-start-1 pb-2 md:pb-4">
            <img
              src="/images/cap.png"
              alt="Cap"
              className="h-full w-full rounded-xl object-cover"
            />
          </div>
          <div className="row-start-2 col-start-1">
            <img
              src="/images/tutor.png"
              alt="Tutoring"
              className="h-full w-full rounded-xl object-cover"
            />
          </div>
          <div className="row-start-1 col-start-2 row-span-2 pl-2 md:pl-4">
            <img
              src="/images/office.png"
              alt="Office"
              className="h-full w-full rounded-xl object-cover"
            />
          </div>
        </div>
      </div>

      <div
        data-testid="welcome-message"
        className="flex flex-col gap-4 bg-gray-100 p-4 md:p-8 lg:p-12 rounded-xl md:w-1/2 order-1 md:order-2"
      >
        <img
          src="/images/logo-transparent.png"
          alt="The Think Tank Logo"
          className="hidden md:block md:w-[130px] lg:w-[160px] lg:pb-2"
        />
        <h1 className="text-2xl md:text-3xl font-medium">
          Congratulations and Welcome <br />
          to Fill the Math GAPS Program!
        </h1>
        <p className="text-sm md:text-base text-gray-500">
          Your subscription is complete! You've taken an important step in
          empowering your child to excel in math.
        </p>
        <p className="text-sm md:text-base text-gray-500">
          We're excited to have you on board and can't wait for your child to
          start exploring, and growing with us.
        </p>
        <img
          src="/images/Matt-signature.png"
          alt="Signature"
          className="h-[50px] md:h-[70px] w-max"
        />
        <p className="text-sm md:text-base text-gray-500">
          Matt Miklius, PhD, Founder
        </p>
        <div className="flex flex-col md:flex-row flex-wrap gap-4 pt-4 mt-auto md:mt-16 justify-between items-center">
          <h1 className="text-lg md:text-xl font-medium">
            Start Exploring the App Now
          </h1>
          <button
            data-testid={`continue-button`}
            disabled={!passwordResetLink}
            onClick={() => {
              if (passwordResetLink) {
                window.location.href = passwordResetLink;
              }
            }}
            title={
              passwordResetLink
                ? 'Click to set your password and begin your journey'
                : 'Please check your welcome email or contact us at help@thinktankprep.com if you have not received one'
            }
            className="
              px-8 py-2 rounded-full w-full md:w-max
              font-medium text-white bg-tttDefault
              transition-all duration-300 ease-in-out
              hover:shadow hover:bg-opacity-90
            "
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionConfirm;

import { useEffect, useState } from 'react';
import { sortBy } from 'lodash';

import { getTextColor } from '../util';
import { Tag } from '../types';

const useSkillCloudData = ({
  skills,
  results,
}: {
  skills?: (string | undefined)[];
  results?: {
    skill?: string;
    result: number;
  }[];
}) => {
  const result: Record<string, Tag> = {};
  const [problemSkills, setProblemSkills] = useState<string[]>();
  const [quizResults, setQuizResults] = useState<
    {
      skill?: string;
      result: number;
    }[]
  >();

  useEffect(() => {
    setProblemSkills(
      skills ? (skills.filter((skill) => !!skill) as string[]) : [],
    );
    setQuizResults(results);
  }, [skills, results]);

  if (!problemSkills || !quizResults) {
    return;
  }

  for (const skill of problemSkills) {
    const foundSkillResult = quizResults.find((s) => s.skill === skill);
    // Check if skill already exists in result
    if (result.hasOwnProperty(skill)) {
      // Increment occurrence count
      result[skill].count++;
      result[skill].color = getTextColor(
        foundSkillResult?.result ? foundSkillResult.result * 100 : 0,
      );
    } else {
      result[skill] = {
        value: skill,
        count: 1,
        key: skill,
        color: getTextColor(
          foundSkillResult?.result ? foundSkillResult.result * 100 : 0,
        ),
      };
    }
  }

  // Transform result into array of objects for react-tagcloud
  const tagCloudData: Tag[] = [];
  for (const skill in result) {
    if (result.hasOwnProperty(skill) && skill !== 'null') {
      tagCloudData.push({
        value: skill,
        count: result[skill].count,
        key: result[skill].key,
        color: result[skill].color,
        props: { padding: '2px' },
      });
    }
  }

  return sortBy(tagCloudData, (data) => data.value);
};

export default useSkillCloudData;

import { useMemo, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { XIcon } from '@heroicons/react/outline';
import Modal from 'react-modal';
import { toast } from 'react-hot-toast';
import dayjs from 'dayjs';

import { UsersListItem } from '../types';

import Button from '../components/Button';
import { LoadingBlock } from '../components/Loading';
import Table, {
  type PaginationState,
  type SortingState,
} from '../components/Table';
import AddThinkerForm from '../partials/AddThinkerForm';
import UserDetailsModal from '../partials/UserDetailsModal';
import { useGetUsersListQuery } from '../services/apiSlice';

export default function UsersPage() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState<UsersListItem>();
  const [showAddThinkerModal, setShowAddThinkerModal] = useState(false);
  const addThinkerSuccessHandler = () => {
    setShowAddThinkerModal(false);
    toast.success('Individual Thinker successfully added!');
    setSearchTerm('thinker');
  };

  const columns = useMemo<ColumnDef<UsersListItem, any>[]>(
    () => [
      {
        accessorKey: '_id',
        cell: (info) => info.getValue(),
        header: () => <span>ID</span>,
      },
      {
        accessorKey: 'firstName',
        cell: (info) => info.getValue(),
        header: () => <span>First Name</span>,
      },
      {
        accessorKey: 'lastName',
        cell: (info) => info.getValue(),
        header: () => <span>Last Name</span>,
      },
      {
        accessorKey: 'school',
        cell: (info) => info.getValue(),
        header: () => <span>School</span>,
        enableSorting: false,
      },
      {
        accessorKey: 'class',
        cell: (info) => info.getValue(),
        header: () => <span>Class</span>,
        enableSorting: false,
      },
      {
        accessorKey: 'email',
        cell: (info) => info.getValue(),
        header: () => <span>Email</span>,
      },
      {
        accessorKey: 'roles',
        cell: (info) => info.getValue(),
        header: () => <span>Roles</span>,
      },
      {
        accessorKey: 'createdAt',
        cell: (info) => dayjs(info.getValue()).format('MM/DD/YYYY HH:mm'),
        header: () => <span>Created at</span>,
        isSorted: true,
      },
    ],
    [],
  );

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'createdAt', desc: true },
  ]);
  const { data, isLoading, isFetching, refetch } = useGetUsersListQuery({
    skip: pagination.pageSize * pagination.pageIndex,
    limit: pagination.pageSize,
    sortBy: sorting[0].id,
    desc: sorting[0].desc,
    search: searchTerm.length > 1 ? searchTerm : undefined,
  });

  const customStyles = {
    content: {
      overflow: 'visible',
      borderRadius: '12px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      outerWidth: '50px',
      transform: 'translate(-50%, -50%)',
    },
  };

  if (isLoading) {
    return (
      <div>
        <LoadingBlock />
        <div className="text-center text-sm text-slate-500 mx-12">
          Fetching user data, this might take a while...
        </div>
      </div>
    );
  }

  return (
    <div
      className="grid gap-6 px-6 pb-6 pt-3 shadow-2xl rounded-xl border-tttDefault border-[1px]"
      data-testid={'users-page'}
    >
      <div className="grid justify-self-center max-w-[90%] justify-items-center items-center text-center overflow-hidden uppercase text-white font-bold md:text-xl lg:text-2xl p-3 -mt-7 shadow-md rounded-xl bg-tttDefault">
        Users list
      </div>
      <div className="my-[-1rem] md:mb-[-2rem] mx-auto sm:mx-2">
        <Button
          data-testid={'add-thinker-button'}
          action={() => setShowAddThinkerModal(true)}
          bgColor={'bg-blue-300'}
          buttonText={'Add new Thinker'}
        />
      </div>
      <Table<UsersListItem>
        columns={columns}
        data={data}
        isLoading={isLoading || isFetching}
        onSelect={(user: UsersListItem) => setSelectedUser(user)}
        globalFilter={searchTerm}
        onGlobalFilterChange={(newGlobalFilter) =>
          setSearchTerm(newGlobalFilter)
        }
        pagination={pagination}
        onPaginationChange={(newPagination) => setPagination(newPagination)}
        sorting={sorting}
        onSortingChange={(newSorting) => setSorting(newSorting)}
      />
      <Modal
        key={'user-details-modal'}
        ariaHideApp={false}
        isOpen={!!selectedUser}
        onRequestClose={() => setSelectedUser(undefined)}
        style={customStyles}
      >
        <UserDetailsModal
          user={selectedUser as UsersListItem}
          setChangedPasswordAt={async (updatedAt: string) => {
            if (selectedUser) {
              setSelectedUser(() => ({
                ...selectedUser,
                changedPasswordAt: updatedAt,
              }));
            }
            refetch();
          }}
          setToggledFeature={async (featureFlags?: string[]) => {
            if (selectedUser) {
              setSelectedUser(() => ({
                ...selectedUser,
                featureFlags,
              }));
            }
            refetch();
          }}
          showUserDetailsModal={(show) => !show && setSelectedUser(undefined)}
        />
      </Modal>
      {/* Add Individual Thinker Modal */}
      <Modal
        key={'add-thinker-modal'}
        ariaHideApp={false}
        isOpen={showAddThinkerModal}
        onRequestClose={() => setShowAddThinkerModal(false)}
        style={customStyles}
      >
        <>
          <div
            className="absolute grid justify-items-end w-full pr-8 cursor-pointer"
            onClick={() => setShowAddThinkerModal(false)}
          >
            <XIcon className="h-5 w-5" />
          </div>
          <AddThinkerForm onSuccess={addThinkerSuccessHandler} />
        </>
      </Modal>
    </div>
  );
}

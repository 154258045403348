import { InformationCircleIcon } from '@heroicons/react/outline';
import { Line } from 'rc-progress';

import { StudentQuizStatSkillResult } from '../../types';
import { getColorHex } from '../../util';
import useScreenSize from '../../hooks/useScreenResize';
import Tooltip from 'rc-tooltip';

const QuizSkillResults = ({
  skillResults,
}: {
  skillResults: StudentQuizStatSkillResult[];
}) => {
  const { width } = useScreenSize();
  const isOverMd = width > 768;

  if (isOverMd) {
    return (
      <table>
        <thead>
          <tr className="justify-between whitespace-nowrap text-gray-500">
            <th className="text-start flex gap-1 items-center pb-2 pr-4">
              <SkillFrequency />
            </th>
            <th className="text-start pb-2">Skill</th>
            <th className="text-start flex gap-1 items-center pb-2">
              <SkillSuccess />
            </th>
          </tr>
        </thead>
        <tbody>
          {skillResults.map((skillResult) => (
            <tr
              key={`${skillResult.skill}`}
              className="w-full border-b border-gray-200"
            >
              <td className="py-1">
                <img
                  src={
                    skillResult.frequency === 3
                      ? '/images/icons/level3.svg'
                      : skillResult.frequency === 2
                      ? '/images/icons/level2.svg'
                      : '/images/icons/level1.svg'
                  }
                  alt="level-icon"
                  className="w-5 h-5"
                />
              </td>
              <td className="py-1 pr-4">{skillResult.skillName}</td>
              <td className="py-1">
                <Line
                  percent={skillResult.result * 100}
                  strokeWidth={3}
                  trailWidth={2}
                  strokeColor={getColorHex(skillResult.result * 100)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  return (
    <table>
      <tbody>
        {skillResults.map((skillResult) => (
          <tr
            key={`${skillResult.skill}`}
            className="flex flex-row h-full w-full gap-2 border-b border-gray-200 py-2"
          >
            <td className="flex flex-col gap-2 py-2 w-full justify-between whitespace-nowrap text-gray-500 text-sm ">
              <div className="text-start flex gap-1 items-center">
                <SkillFrequency />
              </div>
              <div className="text-start ">Skill</div>
              <div className="text-start flex gap-1 items-center">
                <SkillSuccess />
              </div>
            </td>
            <td className="flex flex-col gap-2 py-2 w-full justify-between">
              <div>
                <img
                  src={
                    skillResult.frequency === 3
                      ? '/images/icons/level3.svg'
                      : skillResult.frequency === 2
                      ? '/images/icons/level2.svg'
                      : '/images/icons/level1.svg'
                  }
                  alt="level-icon"
                  className="w-5 h-5"
                />
              </div>
              <div>{skillResult.skill}</div>
              <div>
                <Line
                  percent={skillResult.result * 100}
                  strokeWidth={3}
                  trailWidth={2}
                  strokeColor={getColorHex(skillResult.result * 100)}
                />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const SkillSuccess = () => {
  return (
    <>
      Skill Success
      <Tooltip
        placement="bottom"
        overlay={<p>Your result for a specific skill in the quiz.</p>}
        overlayClassName="max-w-[30%]"
        destroyTooltipOnHide={true}
        trigger={['click', 'hover']}
      >
        <InformationCircleIcon className="h-4 w-4" />
      </Tooltip>
    </>
  );
};

const SkillFrequency = () => {
  return (
    <>
      Skill Frequency
      <Tooltip
        placement="bottom"
        overlay={<p>How many times a skill appeared in a quiz.</p>}
        overlayClassName="max-w-[30%]"
        destroyTooltipOnHide={true}
        trigger={['click', 'hover']}
      >
        <InformationCircleIcon className="h-4 w-4" />
      </Tooltip>
    </>
  );
};

export default QuizSkillResults;

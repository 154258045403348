import { FC, useEffect, useRef, useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import Tooltip from 'rc-tooltip';
import { InformationCircleIcon } from '@heroicons/react/outline';

import { StudentQuizStats, StudentTrackWithSchedule } from '../types';
import {
  useGetStudentQuizStatsQuery,
  useGetTracksForStudentQuery,
} from '../services/apiSlice';
import useUpdatedStudentAssignments from '../hooks/useStudentUpdatedAssignments';
import { useAppSelector } from '../stores/AppStore';

import TodoAssignment from '../components/Assignment/TodoAssignment';
import CompletedAssignment from '../components/Assignment/CompletedAssignment';
import ColorLegend from '../components/ColorLegend';
import LoadingSnake from '../components/LoadingSnake';
import QuizResultDetailsModal from '../components/Assignment/QuizResultDetailsModal';
import { QuizScores } from '../partials/QuizScores';
import { useParams } from 'react-router-dom';
import { ROLES } from '../constants';
import MissedAssignment from '../components/Assignment/MissedAssignment';

const AssignmentsPage: FC = () => {
  const quizScoresReference = useRef<HTMLDivElement>(null);
  const user = useAppSelector((state) => state.user.user);
  const shouldShowMissedBlock = useAppSelector((state) =>
    state.user.user?.roles.includes(ROLES.student),
  );
  const { quizId } = useParams();
  const { data: track, isFetching: isFetchingTrack } =
    useGetTracksForStudentQuery(
      { studentId: user?._id ?? '' },
      {
        skip: !user?._id,
      },
    );

  const handleSeeAllQuizResults = () => {
    if (quizScoresReference.current) {
      quizScoresReference.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  return (
    <div
      className="
        relative
        flex flex-col gap-8 p-4 md:p-6
        w-full h-full items-start
        bg-gray-100 rounded-xl
      "
    >
      <div className="flex flex-col md:flex-row gap-4 md:items-center justify-between w-full">
        <div className="flex gap-8 justify-between items-start">
          <h1 className="text-3xl font-medium">Your Assignments</h1>
          <Tooltip
            placement="bottomLeft"
            overlay={<ColorLegend />}
            overlayClassName="max-w-[250px] !opacity-[100%]"
            destroyTooltipOnHide={true}
            trigger={['click', 'hover']}
            zIndex={5}
          >
            <p className="block md:hidden flex gap-1 items-center text-tttDefault">
              <InformationCircleIcon className="h-4 w-4" />
              Legend
            </p>
          </Tooltip>
        </div>
        {track && (
          <button
            onClick={handleSeeAllQuizResults}
            className="
              px-4 py-1.5 w-full md:w-max h-max md:my-auto
              rounded-full border border-tttDefault
              text-sm md:text-base
              font-medium text-tttDefault whitespace-nowrap
              transition-all duration-100 ease-in-out
              hover:shadow hover:bg-tttDefault hover:text-white
            "
          >
            See All Quiz Results
          </button>
        )}
      </div>
      {isFetchingTrack && (
        <div className="my-auto mx-auto p-8">
          <LoadingSnake />
        </div>
      )}
      {!isFetchingTrack && track && (
        <Assignments
          track={track}
          quizId={quizId}
          shouldShowMissedBlock={shouldShowMissedBlock}
        />
      )}
      <div data-testid="assignment-results" className="w-full pt-4">
        <QuizScores
          caption="Detailed results by quiz and problem"
          dataQuery={{
            studentId: user?._id,
          }}
          isStudentList={false}
          scoresRef={quizScoresReference}
          showReviewColumn
        />
      </div>
    </div>
  );
};

const Assignments = ({
  track,
  quizId,
  shouldShowMissedBlock,
}: {
  track: StudentTrackWithSchedule;
  quizId?: string;
  shouldShowMissedBlock?: boolean;
}) => {
  const user = useAppSelector((state) => state.user.user);
  const [shouldShowTodoAssignments, setShouldShowTodoAssignments] =
    useState(true);
  const [shouldShowCompletedAssignments, setShouldShowCompletedAssignments] =
    useState(true);
  const [shouldShowMissedAssignments, setShouldShowMissedAssignments] =
    useState(true);
  const [selectedQuiz, setSelectedQuiz] = useState<string>();
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const { todoAssignments, completedAssignments, missedAssignments } =
    useUpdatedStudentAssignments(track);

  const { data: quizStats, isFetching: isFetchingQuizStats } =
    useGetStudentQuizStatsQuery(
      {
        studentId: user?._id ?? '',
        quizId: selectedQuiz ?? '',
      },
      { skip: !user || !selectedQuiz },
    );

  const numberOfOverdueAssignments = todoAssignments.filter(
    (assignment) => assignment.isOverdue,
  ).length;

  const todoSubTitle =
    `${todoAssignments.length} Quizzes` +
    `${
      numberOfOverdueAssignments > 0
        ? ` (${numberOfOverdueAssignments} Overdue)`
        : ''
    }`;

  const handleViewQuizDetails = (quiz: string) => {
    setSelectedQuiz(quiz);
    setShouldShowModal(true);
  };

  const toggleMissedAssigments = () =>
    setShouldShowMissedAssignments((previous) => !previous);

  useEffect(() => {
    if (quizId) {
      handleViewQuizDetails(quizId);
    }
  }, [quizId]);

  return (
    <div
      data-testid={'assignments-container'}
      className="flex flex-col gap-6 w-full md:w-[80%] xl:w-[70%] mx-auto mb-auto"
    >
      <QuizResultDetailsModal
        quizStats={quizStats as StudentQuizStats | undefined}
        isLoadingQuizStats={isFetchingQuizStats}
        isOpen={shouldShowModal}
        onClose={() => setShouldShowModal(false)}
      />
      <div data-testid={'todo-assignments'} className="flex flex-col gap-4">
        <div
          data-testid={'title-container'}
          className="w-full flex gap-4 items-center"
        >
          <h2 className="text-xl font-medium">To-Do</h2>
          <div className="flex gap-1 text-sm text-gray-500 items-center">
            {todoSubTitle}
          </div>
          {shouldShowTodoAssignments ? (
            <ChevronUp
              className="h-8 w-8 p-1 ml-auto text-gray-500 border border-gray-400 rounded-full cursor-pointer hover:bg-gray-200"
              onClick={() =>
                setShouldShowTodoAssignments((previous) => !previous)
              }
            />
          ) : (
            <ChevronDown
              className="h-8 w-8 p-1 ml-auto text-gray-500 border border-gray-400 rounded-full cursor-pointer hover:bg-gray-200"
              onClick={() =>
                setShouldShowTodoAssignments((previous) => !previous)
              }
            />
          )}
        </div>
        {shouldShowTodoAssignments ? (
          todoAssignments.length > 0 ? (
            <div
              data-testid={'assignment-list'}
              className="flex flex-col gap-2"
            >
              {todoAssignments.slice(0, 3).map((assignment) => (
                <TodoAssignment
                  key={assignment.id}
                  track={track}
                  assignment={assignment}
                />
              ))}
            </div>
          ) : (
            <p className="text-gray-500">No outstanding assignments.</p>
          )
        ) : (
          <></>
        )}
      </div>
      <div
        data-testid={'completed-assignments'}
        className="flex flex-col gap-4"
      >
        <div
          data-testid={'title-container'}
          className="w-full flex gap-4 items-center"
        >
          <h2 className="text-xl font-medium">Completed</h2>
          <p className="text-sm text-gray-500">
            {completedAssignments.length} Quizzes
          </p>
          {shouldShowCompletedAssignments ? (
            <ChevronUp
              className="h-8 w-8 p-1 ml-auto text-gray-500 border border-gray-400 rounded-full cursor-pointer hover:bg-gray-200"
              onClick={() =>
                setShouldShowCompletedAssignments((previous) => !previous)
              }
            />
          ) : (
            <ChevronDown
              className="h-8 w-8 p-1 ml-auto text-gray-500 border border-gray-400 rounded-full cursor-pointer hover:bg-gray-200"
              onClick={() =>
                setShouldShowCompletedAssignments((previous) => !previous)
              }
            />
          )}
        </div>
        {shouldShowCompletedAssignments ? (
          completedAssignments.length > 0 ? (
            <div
              data-testid={'assignment-list'}
              className="flex flex-col gap-2"
            >
              {completedAssignments.map((assignment) => (
                <CompletedAssignment
                  key={assignment.id}
                  assignment={assignment}
                  track={track}
                  onClickViewDetails={handleViewQuizDetails}
                />
              ))}
            </div>
          ) : (
            <p className="text-gray-500">No completed assignments yet.</p>
          )
        ) : (
          <></>
        )}
      </div>
      {shouldShowMissedBlock ? (
        <div data-testid={'missed-assignments'} className="flex flex-col gap-4">
          <div
            data-testid={'title-container'}
            className="w-full flex gap-4 items-center"
          >
            <h2 className="text-xl font-medium">Missed</h2>
            <p className="text-sm text-gray-500">
              {missedAssignments.length} Quizzes
            </p>
            {shouldShowCompletedAssignments ? (
              <ChevronUp
                className="h-8 w-8 p-1 ml-auto text-gray-500 border border-gray-400 rounded-full cursor-pointer hover:bg-gray-200"
                onClick={toggleMissedAssigments}
              />
            ) : (
              <ChevronDown
                className="h-8 w-8 p-1 ml-auto text-gray-500 border border-gray-400 rounded-full cursor-pointer hover:bg-gray-200"
                onClick={toggleMissedAssigments}
              />
            )}
          </div>
          {shouldShowMissedAssignments ? (
            missedAssignments.length > 0 ? (
              <div
                data-testid={'assignment-list'}
                className="flex flex-col gap-2"
              >
                {missedAssignments.map((assignment) => (
                  <MissedAssignment
                    key={assignment.id}
                    assignment={assignment}
                    track={track}
                  />
                ))}
              </div>
            ) : (
              <p className="text-gray-500">No completed assignments yet.</p>
            )
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AssignmentsPage;

import {
  PreloadedState,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { createReduxHistoryContext } from 'redux-first-history';

import { apiSlice } from '../services/apiSlice';
import globalReducer from '../features/global/globalSlice';
import quizReducer from '../features/quiz/quizSlice';
import userReducer from '../features/user/userSlice';
import signUpReducer from '../features/signUp/signUpSlice';
import { persisterMiddleware } from '../services/LocalStorageService';

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() });

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  global: globalReducer,
  quiz: quizReducer,
  router: routerReducer,
  user: userReducer,
  signUp: signUpReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    persisterMiddleware,
    ...getDefaultMiddleware(),
    apiSlice.middleware,
    routerMiddleware,
  ],
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const history = createReduxHistory(store);

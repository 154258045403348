import { FC } from 'react';
import { ClipLoader } from 'react-spinners';

interface Properties {
  height?: number;
  width?: number;
}

const Loading: FC = () => <ClipLoader color="#0069d9" />;

const LoadingBlock: FC<Properties> = ({ height, width }) => (
  <div
    className={`grid ${width ? `col-span-${width}` : 'col-span-4'} ${
      height === 32 ? 'my-32' : 'my-48'
    } justify-items-center items-center gap-1`}
  >
    <Loading />
  </div>
);

export { Loading, LoadingBlock };

'use client';

import * as React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { cn } from '../../lib/utils';

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...properties }, reference) => (
  <TooltipPrimitive.Content
    ref={reference}
    sideOffset={sideOffset}
    className={cn(
      'w-64 z-50 overflow-hidden rounded-md border border-primary bg-popover px-4 py-2 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      className,
    )}
    {...properties}
  />
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

const TooltipArrow = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Arrow>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Arrow>
>(({ className, ...properties }, reference) => (
  <TooltipPrimitive.Arrow
    ref={reference}
    className={cn('', className)}
    asChild={true}
    {...properties}
  >
    <svg
      width="10"
      height="5"
      viewBox="0 0 30 10"
      preserveAspectRatio="none"
      style={{ display: 'block' }}
    >
      <polygon points="0,0 30,0 15,10" fill="#0069D9"></polygon>
    </svg>
  </TooltipPrimitive.Arrow>
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
};

import { useState } from 'react';

import { SubjectResult } from '../types';
import { Subject } from '../constants';

import BreakoutCircular from './BreakoutCircular';
import SkillResult from './SkillResult';
import LoadingSnake from './LoadingSnake';

interface SubjectSkillResultsProperties {
  subjectResults: SubjectResult[];
  skillResults: {
    skill: string;
    description: string;
    overallResult: number;
    tutorialsLink: string;
  }[];
  isSkillResultsLoading: boolean;
  title: string;
  onSubjectSelect: (subject: Subject) => void;
  onSubjectHover: (subject: Subject) => void;
}

const SubjectSkillResults = ({
  subjectResults,
  skillResults,
  isSkillResultsLoading,
  title,
  onSubjectSelect,
  onSubjectHover,
}: SubjectSkillResultsProperties) => {
  const [selectedSubject, setSelectedSubject] = useState<Subject>(
    Subject.geometry,
  );
  const subjectData =
    subjectResults.length > 0
      ? subjectResults.map((subject) => ({
          label: subject.subject,
          value: subject.result,
        }))
      : Object.values(Subject).map((subject) => ({
          label: subject as Subject,
        }));
  const firstSubjectSelected = selectedSubject === subjectData[0].label;
  const lastSubjectSelected =
    selectedSubject === subjectData[subjectData.length - 1].label;

  const handleSubjectSelect = (subject: Subject) => {
    setSelectedSubject(subject);
    onSubjectSelect(subject);
  };

  return (
    <div
      data-testid="subject-skill-results-section"
      className="flex flex-col overflow-x-hidden"
    >
      <h1 data-testid="title" className="text-2xl font-medium mb-4 md:mb-6">
        {title}
      </h1>
      <div
        data-testid="subjects"
        className="relative flex gap-2 overflow-x-auto no-scrollbar md:justify-between items-stretch"
      >
        <BreakoutCircular
          data={subjectData}
          selected={selectedSubject}
          isSelectable={true}
          onSelect={(subject) => {
            handleSubjectSelect(subject as Subject);
          }}
          onHover={(subject) => onSubjectHover(subject as Subject)}
        />
      </div>
      <div
        className={`flex flex-col bg-transparent md:bg-white p-0 md:p-6 lg:p-8 mt-4 md:mt-0 rounded-b-xl ${
          !firstSubjectSelected && 'md:rounded-tl-xl'
        } ${!lastSubjectSelected && 'md:rounded-tr-xl'}`}
      >
        <h1
          data-testid="subtitle"
          className="hidden md:block text-xl font-medium mb-4 md:mb-6"
        >
          {selectedSubject} Subskills - Cumulative Score
        </h1>
        {isSkillResultsLoading ? (
          <div className="my-auto mx-auto p-16">
            <LoadingSnake />
          </div>
        ) : (
          <div data-testid="skill-results" className="flex flex-col gap-4">
            {skillResults.map((result, index) => (
              <SkillResult key={index} result={result} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SubjectSkillResults;

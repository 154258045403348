import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { CurrentQuiz, ExtendedTrack, QuizWithInfo } from '../types';
import { quizscheduleTimeZone } from '../constants';

const useTeacherAndSchooladminUpdatedAssignments = (
  track: ExtendedTrack | undefined,
) => {
  const localTZ = dayjs.tz.guess();
  const [updatedCurrentAssignments, setUpdatedCurrentAssignments] = useState<
    CurrentQuiz[]
  >([]);
  const [updatedUpcomingAssignments, setUpdatedUpcomingAssignments] = useState<
    QuizWithInfo[]
  >([]);
  const [updatedPastAssignments, setUpdatedPastAssignments] = useState<
    QuizWithInfo[]
  >([]);

  useEffect(() => {
    if (track) {
      const updatedCurrentAssignments = track.currentAssignments?.map(
        (currentAssignment) => {
          const currentSchedule = track.currentSchedules.find((schedule) => {
            return schedule.quiz === currentAssignment.id;
          });
          return {
            ...currentAssignment,
            info: `Due ${dayjs().to(
              dayjs
                .tz(currentSchedule?.dueDate, quizscheduleTimeZone)
                .tz(localTZ),
            )}`,
          };
        },
      );

      if (updatedCurrentAssignments) {
        setUpdatedCurrentAssignments(updatedCurrentAssignments);
      }

      const updatedUpcomingAssignments = track.upcomingAssignments?.map(
        (assignment) => {
          const schedule = track.upcomingSchedules?.find(
            (schedule) => schedule.quiz === assignment.id,
          );
          return {
            ...assignment,
            info: `(Starts on ${dayjs
              .tz(schedule?.startDate, quizscheduleTimeZone)
              .tz(localTZ)
              .format('MM/DD/YYYY')})`,
          };
        },
      );

      if (updatedUpcomingAssignments) {
        setUpdatedUpcomingAssignments(updatedUpcomingAssignments);
      }

      const updatedPastAssignments = track.pastAssignments?.map(
        (pastAssignment) => {
          const schedule = track.pastSchedules?.find(
            (schedule) => schedule.quiz === pastAssignment.id,
          );
          return {
            ...pastAssignment,
            info: `(Ended on ${dayjs
              .tz(schedule?.dueDate, quizscheduleTimeZone)
              .tz(localTZ)
              .format('MM/DD/YYYY')})`,
          };
        },
      );

      if (updatedPastAssignments) {
        setUpdatedPastAssignments(updatedPastAssignments);
      }
    }
  }, [track]);

  return {
    currentAssignments: updatedCurrentAssignments,
    pastAssignments: updatedPastAssignments,
    upcomingAssignments: updatedUpcomingAssignments,
  };
};

export default useTeacherAndSchooladminUpdatedAssignments;

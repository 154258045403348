import { useEffect, useRef, useState } from 'react';
import { MathJax } from 'better-react-mathjax';
import Vimeo, { type PauseEvent, type PlayEvent } from '@u-wave/react-vimeo';
import { Player } from '@vimeo/player';
import * as Sentry from '@sentry/react';

import { getVideoMinWatchedPercentage, VideoMaxSpeed } from '../constants';
import { ProblemDocument, SolvedProblem } from '../types';

import { store, useAppSelector } from '../stores/AppStore';
import {
  selectAnswer,
  selectGivenAnswerKey,
  selectIsLastProblem,
  selectIsPractice,
  selectIsUnderReview,
  setVideoWatched,
} from '../features/quiz/quizSlice';
import {
  useSendQuizAnswerMutation,
  useUpdateReviewProgressMutation,
} from '../services/apiSlice';
import { handleError } from '../services/ErrorService';

import List from './List';

interface Properties {
  currentProblem: ProblemDocument;
  currentProblemSkills?: string[];
  solvedProblem?: SolvedProblem;
}

function logWatchProgress(
  event: PlayEvent | PauseEvent,
  watchedSeconds: Set<number>,
  didReachRequired?: boolean,
) {
  console.log(
    `watch ${didReachRequired ? 'DONE' : 'progress'}: ${
      watchedSeconds.size
    } of ${Number.parseInt(
      String(event.duration),
    )} seconds (current position: ${Number.parseInt(String(event.seconds))})`,
  );
}

export default function Problem({
  currentProblem,
  currentProblemSkills,
  solvedProblem,
}: Properties) {
  const [didReportPlaybackStarted, setDidReportPlaybackStarted] =
    useState<boolean>(false);
  const watchedSecondsReference = useRef(new Set<number>());
  const userRoles = useAppSelector((state) => state.user.user?.roles);
  const currentQuiz = useAppSelector((state) => state.quiz.selectedQuiz);
  const selectedProblemIndex = useAppSelector(
    (state) => state.quiz.selectedProblemIndex ?? 0,
  );
  const givenAnswerKey = useAppSelector(selectGivenAnswerKey);
  const isLastProblem = useAppSelector(selectIsLastProblem);
  const isPractice = useAppSelector(selectIsPractice);
  const isUnderReview = useAppSelector(selectIsUnderReview);
  const tutorials = useAppSelector((state) => state.quiz.tutorials);
  const { user } = useAppSelector((state) => state.user);
  const lastQuiz =
    user?.reviewedQuizzes &&
    user?.reviewedQuizzes[user?.reviewedQuizzes.length - 1];

  const [sendQuizAnswer] = useSendQuizAnswerMutation();
  const [updateReviewProgress] = useUpdateReviewProgressMutation();

  const playerReference = useRef<Player | null>(null);
  const onReady = (player: Player) => {
    playerReference.current = player;
  };
  const setPlaybackRate = async (newPlaybackRate: number) => {
    if (playerReference.current) {
      await playerReference.current.setPlaybackRate(newPlaybackRate);
    }
  };
  const previousProblemIndexReference = useRef<number>(0);
  const hasAdditionalImages =
    Array.isArray(currentProblem?.additionalImages) &&
    currentProblem?.additionalImages.length > 0;
  const answerKeys = ['A', 'B', 'C', 'D', 'E'];
  const tutorial = tutorials?.find((t) => t.problemNum === currentProblem.id);
  if (isUnderReview && !tutorial) {
    handleError(new Error('Tutorial is missing for problem'), {
      tags: {
        problemId: currentProblem.id,
      },
    });
  }

  useEffect(() => {
    previousProblemIndexReference.current = selectedProblemIndex;

    // cleanup
    return () => {
      watchedSecondsReference.current = new Set<number>();
      setDidReportPlaybackStarted(false);
    };
  }, [selectedProblemIndex]);

  return (
    <div
      key={currentProblem.id}
      className={`grid grid-cols-4 justify-items-center items-center gap-8 w-full bg-white rounded-xl p-2 sm:p-8 ${
        previousProblemIndexReference.current < selectedProblemIndex
          ? 'animate-slideInFromRight'
          : previousProblemIndexReference.current > selectedProblemIndex
          ? 'animate-slideInFromLeft'
          : ''
      }`}
    >
      {isUnderReview && currentQuiz && (
        <div className="grid grid-flow-row xl:grid-flow-col xl:grid-cols-2 justify-items-center items-center gap-3 row-start-1 row-span-1 col-start-1 col-span-4 w-full md:w-[90%] xl:w-full">
          <div className="w-full rounded-xl overflow-hidden z-10">
            <Vimeo
              video={tutorial?.url ?? ''}
              responsive
              controls={true}
              speed={true}
              onReady={onReady}
              autoplay={
                !solvedProblem?.isStudentCorrect &&
                !solvedProblem?.isVideoWatched
              }
              onLoaded={(event) => {
                if (!isPractice) {
                  Sentry.addBreadcrumb({
                    category: 'Review',
                    message: 'video loaded',
                    data: {
                      problem: currentProblem.id,
                      quiz: currentQuiz.id,
                      videoId: event.id,
                    },
                  });
                }
              }}
              onError={(error) => {
                Sentry.captureException(error, {
                  tags: {
                    problem: currentProblem.id,
                    quiz: currentQuiz.id,
                    watchedSeconds: watchedSecondsReference.current.size,
                  },
                });
              }}
              onSeeked={(event) => {
                const videoPosition = Number.parseInt(String(event.seconds));
                const videoDuration = Number.parseInt(String(event.duration));
                if (
                  !isPractice &&
                  !solvedProblem?.isStudentCorrect &&
                  !solvedProblem?.isVideoWatched
                ) {
                  setDidReportPlaybackStarted(true);
                  Sentry.addBreadcrumb({
                    category: 'Review',
                    message: 'seeked in video',
                    data: {
                      problem: currentProblem.id,
                      quiz: currentQuiz.id,
                      videoDuration,
                      videoPosition,
                      watchedSeconds: watchedSecondsReference.current.size,
                    },
                  });
                }
              }}
              onTimeUpdate={(event) => {
                const videoPosition = Number.parseInt(String(event.seconds));
                const videoDuration = Number.parseInt(String(event.duration));
                watchedSecondsReference.current.add(videoPosition);

                const didWatchRequiredPercentage =
                  watchedSecondsReference.current.size / event.duration >=
                  getVideoMinWatchedPercentage(userRoles);

                if (
                  !isPractice &&
                  !solvedProblem?.isStudentCorrect &&
                  !solvedProblem?.isVideoWatched &&
                  !didReportPlaybackStarted
                ) {
                  setDidReportPlaybackStarted(true);
                  Sentry.addBreadcrumb({
                    category: 'Review',
                    message: 'playback started',
                    data: {
                      problem: currentProblem.id,
                      quiz: currentQuiz.id,
                      videoDuration,
                      videoPosition,
                      watchedSeconds: watchedSecondsReference.current.size,
                    },
                  });
                }

                if (didWatchRequiredPercentage) {
                  if (!isPractice && !solvedProblem?.isVideoWatched) {
                    logWatchProgress(
                      event,
                      watchedSecondsReference.current,
                      true,
                    );
                    Sentry.addBreadcrumb({
                      category: 'Review',
                      message: 'watched required percentage of the video',
                      data: {
                        problem: currentProblem.id,
                        quiz: currentQuiz.id,
                        videoDuration,
                        videoPosition,
                        watchedSeconds: watchedSecondsReference.current.size,
                      },
                    });
                    if (
                      !lastQuiz?.progress ||
                      (lastQuiz?.progress &&
                        selectedProblemIndex > lastQuiz.progress.num)
                    ) {
                      updateReviewProgress({
                        problemId: currentProblem.id,
                        problemNum: selectedProblemIndex,
                        quizId: currentQuiz.id,
                        didFinishReview: isLastProblem,
                      });
                    }
                  }
                  store.dispatch(setVideoWatched());
                }
              }}
              onPause={(event) => {
                logWatchProgress(event, watchedSecondsReference.current);
              }}
              onPlay={(event) => {
                logWatchProgress(event, watchedSecondsReference.current);
              }}
              onPlaybackRateChange={(event) => {
                if (event.playbackRate > VideoMaxSpeed) {
                  setPlaybackRate(VideoMaxSpeed);
                }
              }}
            />
          </div>
          <List
            title={`Featured skill${
              currentProblemSkills?.length ?? 0 > 1 ? 's' : ''
            } in this video`}
            data={currentProblemSkills}
          />
        </div>
      )}
      <div className="flex col-span-4 grid-rows-1 text-lg md:text-xl">
        <MathJax dynamic>{currentProblem?.question}</MathJax>
      </div>
      {hasAdditionalImages ? (
        <div className="grid col-span-4 md:col-span-2 justify-items-center items-center">
          <img
            src={`data:image/png;base64,${currentProblem.additionalImages[0]}`}
            alt=""
          />
        </div>
      ) : (
        <></>
      )}
      <div
        className={`grid gap-2 ${
          hasAdditionalImages
            ? 'col-start-1 md:col-start-3 col-span-4 md:col-span-2'
            : 'col-span-4'
        }`}
      >
        {isUnderReview && !givenAnswerKey && (
          <div className="text-rose-500 italic text-center">
            You didn't answer this question
          </div>
        )}
        {currentProblem?.answers.map((answer, index: number) => (
          <div
            key={`answer-${index}`}
            className={`group grid grid-cols-[auto_1fr] gap-3 p-3 ${
              isUnderReview ? 'cursor-default' : 'cursor-pointer'
            } justify-items-start items-center text-lg md:text-xl`}
            onClick={() => {
              if (!isUnderReview) {
                store.dispatch(selectAnswer(answerKeys[index]));
                if (!isPractice && currentQuiz) {
                  sendQuizAnswer({
                    _id: currentQuiz._id,
                    quiz: {
                      id: currentQuiz.id,
                      problems: [
                        {
                          problemId: currentProblem.id,
                          answerKey: answerKeys[index],
                        },
                      ],
                    },
                  });
                }
              }
            }}
          >
            {isUnderReview ? (
              <p
                data-testid={'answerKey'}
                className={`font-bold w-10 h-10 text-center py-1 ${
                  solvedProblem?.correctAnswerKey === answerKeys[index] &&
                  'border-2 rounded-full border-emerald-500'
                } ${
                  givenAnswerKey === answerKeys[index]
                    ? solvedProblem?.isStudentCorrect
                      ? 'bg-emerald-500 rounded-full text-white'
                      : 'bg-rose-500 rounded-full text-white'
                    : ''
                }`}
              >
                {answerKeys[index]}
              </p>
            ) : (
              <p
                data-testid={'answerKey'}
                className={`font-bold w-10 h-10 text-center py-1 md:py-[2px] border-2 border-transparent border-dashed group-hover:border-2 group-hover:border-tttDefault group-hover:rounded-full duration-150  ${
                  givenAnswerKey === answerKeys[index]
                    ? 'bg-tttDefault rounded-full text-white duration-200'
                    : ''
                }`}
              >
                {answerKeys[index]}
              </p>
            )}
            {/* TODO: dynamic margin / gap */}
            <MathJax inline dynamic style={{ margin: '0.5em 0' }}>
              {answer}
            </MathJax>
          </div>
        ))}
      </div>
    </div>
  );
}

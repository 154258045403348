import { ChangeEvent, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { formatPhone, unformatPhone } from '../../util';
import {
  SignUpState,
  selectMentor,
  selectNumberOfLicenses,
  selectThinkers,
  setMentor,
} from '../../features/signUp/signUpSlice';
import { store, useAppSelector } from '../../stores/AppStore';
import { useLazyCheckEmailExistsQuery } from '../../services/apiSlice';
import { emailPattern, usCellPhonePattern } from '../../constants';
import ProgressBar from './ProgressBar';
import { FieldError } from '../ui/field-error';

interface MentorDetailsProperties {
  onNextStep: () => void;
  onPreviousStep: () => void;
}

const MentorDetails = ({ onNextStep }: MentorDetailsProperties) => {
  const [emailError, setEmailError] = useState('');
  const mentor = useAppSelector(selectMentor);
  const thinkers = useAppSelector(selectThinkers);
  const numberOfLicenses = useAppSelector(selectNumberOfLicenses);
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    setError,
  } = useForm<SignUpState['mentor']>({
    mode: 'all',
    defaultValues: mentor,
  });
  const [
    checkEmailExists,
    { error: isCheckingEmailError, isFetching: isCheckingEmailExists },
  ] = useLazyCheckEmailExistsQuery();

  const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const formatted = formatPhone(event.target.value);
    setValue('phone', formatted);
  };

  const handleContinue = async (data: SignUpState['mentor']) => {
    const phone = getValues('phone');
    if (phone) {
      setValue('phone', unformatPhone(phone));
      if (getValues('phone').length !== 10) {
        setError('phone', {
          message: 'Please enter a valid US cell phone number.',
        });
        return;
      }
    }
    const emailUsed = thinkers.some((t) => t.email === data.email);
    if (emailUsed) {
      setEmailError('Email already used for a thinker.');
      return;
    }
    const result = await checkEmailExists({ email: data.email });
    if (result.data === true || result.error) {
      setEmailError('Email already exists.');
      return;
    }
    store.dispatch(setMentor(getValues()));
    onNextStep();
  };

  const cantProceed =
    !isValid || isCheckingEmailExists || !!isCheckingEmailError || !!emailError;

  return (
    <form
      data-testid="mentor-details"
      onSubmit={handleSubmit(handleContinue)}
      className="flex flex-col gap-2 md:gap-4 w-full h-full"
    >
      <div className="flex flex-col gap-2 md:gap-4 w-full md:w-[70%] lg:w-[60%] mx-auto">
        <h1 className="text-3xl md:text-4xl font-medium w-full">
          Mentor Details
        </h1>
        <p className="text-gray-500 text-sm md:text-base">
          Please enter your personal details here so that we can create a Mentor
          account to track progress of your{' '}
          {numberOfLicenses === 1 ? 'Thinker' : 'Thinkers'}.
        </p>
        <p className="text-gray-500 text-sm md:text-base font-bold">
          As a mentor you will receive an email with login instructions once the
          sign-up process is complete.
        </p>
        <div className="flex flex-col gap-3 md:gap-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-6 items-start">
            <div className="flex flex-col gap-1 w-full">
              <label
                htmlFor="firstName"
                className="text-sm md:text-base font-medium text-gray-700"
              >
                First Name
              </label>
              <Controller
                render={({ field }) => (
                  <input
                    {...field}
                    className="border border-gray-300 rounded-md px-3 py-2 w-full"
                    placeholder="Enter First Name"
                    id="firstName"
                  />
                )}
                name={`firstName`}
                control={control}
                rules={{ required: true, minLength: 2, maxLength: 50 }}
              />
              <FieldError
                errors={errors}
                field="firstName"
                fieldName="First name"
              />
            </div>
            <div className="flex flex-col gap-1 w-full">
              <label
                htmlFor="lastName"
                className="text-sm md:text-base font-medium text-gray-700"
              >
                Last Name
              </label>
              <Controller
                render={({ field }) => (
                  <input
                    {...field}
                    className="border border-gray-300 rounded-md px-3 py-2 w-full"
                    placeholder="Enter Last Name"
                    id="lastName"
                  />
                )}
                name={`lastName`}
                control={control}
                rules={{ required: true, minLength: 2, maxLength: 50 }}
              />
              <FieldError
                errors={errors}
                field="lastName"
                fieldName="Last name"
              />
            </div>
            <div className="flex flex-col gap-1 w-full">
              <label
                htmlFor="email"
                className="text-sm md:text-base font-medium text-gray-700"
              >
                Email
              </label>
              <Controller
                render={({ field }) => (
                  <input
                    {...field}
                    className="border border-gray-300 rounded-md px-3 py-2 w-full"
                    placeholder="Enter Email"
                    id="email"
                    onChange={(event) => {
                      field.onChange(event.target.value);
                      setEmailError('');
                    }}
                  />
                )}
                name="email"
                control={control}
                rules={{ required: true, pattern: emailPattern }}
              />
              {emailError && (
                <p className="text-sm text-rose-600">{emailError}</p>
              )}
              <FieldError errors={errors} field="email" fieldName="Email" />
            </div>
            <div className="flex flex-col gap-1 w-full">
              <label
                htmlFor="phone"
                className="text-sm md:text-base font-medium text-gray-700"
              >
                Phone Number
                <span className="text-sm text-blue-400 italic pl-2">
                  optional
                </span>
              </label>
              <Controller
                render={({ field }) => (
                  <input
                    {...field}
                    className="border border-gray-300 rounded-md px-3 py-2 w-full"
                    placeholder="Enter your phone number"
                    id="phone"
                    onChange={handlePhoneNumberChange}
                    value={
                      field.value.length > 0 ? formatPhone(field.value) : ''
                    }
                  />
                )}
                name="phone"
                control={control}
                rules={{ required: false, pattern: usCellPhonePattern }}
              />
              <FieldError
                errors={errors}
                field="phone"
                fieldName="Cell phone"
              />
              <p className={'flex items-start gap-2 text-sm text-gray-500'}>
                By entering your phone number you agree to receive SMS updates
                regarding your account, your student
                {numberOfLicenses > 1 ? "s'" : "'s"} account
                {numberOfLicenses > 1 ? 's' : ''} and program related
                promotions. Data rates may apply.
              </p>
            </div>
          </div>
        </div>
      </div>

      <ProgressBar />

      {/* Action Buttons */}
      <div className="flex gap-2 pt-4 md:pt-6 w-full justify-between items-center">
        <button
          data-testid={`back-button`}
          type="button"
          className="
              px-10 py-2 w-max rounded-full border font-medium
              transition-all duration-300 ease-in-out
              hover:shadow hover:outline hover:outline-tttDefault hover:outline-[1px]
              disabled:opacity-50 disabled:cursor-not-allowed
            "
          disabled={true}
          title="You can't step back after a successful payment"
        >
          Back
        </button>
        <button
          data-testid={`continue-button`}
          type="submit"
          disabled={cantProceed}
          className="
            px-10 py-2 w-max rounded-full
            font-medium text-white bg-tttDefault
            transition-all duration-300 ease-in-out
            hover:shadow hover:bg-opacity-90
            disabled:opacity-50 disabled:cursor-not-allowed
          "
        >
          Next
        </button>
      </div>
    </form>
  );
};

export default MentorDetails;

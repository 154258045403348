import { FC, useEffect, useState } from 'react';
import Select from 'react-select';
import { useParams, useSearchParams } from 'react-router-dom';
import { push } from 'redux-first-history';

import { UserDocument } from '../types';
import { FilterType, ROLES, Subject } from '../constants';
import {
  apiSlice,
  useGetSkillsQuery,
  useGetStudentOrThinkerSummaryDataQuery,
  useGetThinkersOfMentorQuery,
  useGetTutorialsQuery,
} from '../services/apiSlice';
import { store, useAppSelector } from '../stores/AppStore';
import { selectHasOneOfTheRoles } from '../features/user/userSlice';

import Filter, { FilterValue } from '../components/Filter';
import InfiniteScroll from '../components/InfiniteScroll';
import { Loading, LoadingBlock } from '../components/Loading';
import TutorialCard from '../components/TutorialCard';

interface TutorialsState {
  skills: string[];
  skip: number;
  subject: Subject;
  thinker?: { label: string; value: UserDocument };
}

const Tutorials: FC = () => {
  const [searchParameters, setSearchParameters] = useSearchParams();
  const { subject: subjectParameter } = useParams();
  const searchParameterSkills = searchParameters.getAll('skills');
  const [tutorials, setTutorials] = useState<TutorialsState>({
    skills: searchParameterSkills ?? [],
    skip: 0,
    subject: (subjectParameter as Subject) ?? Subject.geometry,
  });
  const [areFiltersOpen, setFiltersOpen] = useState<boolean>(false);
  const { user } = useAppSelector((state) => state.user);
  const isStudentOrThinker = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.student, ROLES.thinker]),
  );
  const isMentor = useAppSelector((state) =>
    selectHasOneOfTheRoles(state, [ROLES.mentor]),
  );
  const {
    data: studentOverviewData,
    isFetching: isFetchingStudentResultOverview,
  } = useGetStudentOrThinkerSummaryDataQuery(
    { shouldFetchTrack: false },
    { skip: !isStudentOrThinker },
  );
  const { data: thinkers, isFetching: isFetchingThinkers } =
    useGetThinkersOfMentorQuery(
      { mentorId: user?._id ?? '' },
      { skip: !user || !isMentor },
    );

  const {
    data: skillsData,
    isError: isSkillsError,
    isFetching: isSkillsFetching,
    isLoading: isSkillsLoading,
  } = useGetSkillsQuery(
    {
      subject: tutorials.subject,
      trackId: tutorials.thinker?.value?.followsTracks?.[0].track,
    },
    { skip: isMentor && !tutorials.thinker?.value },
  );
  const {
    data,
    isError,
    isLoading,
    isFetching: isFetchingTutorials,
  } = useGetTutorialsQuery(
    {
      skills: tutorials.skills,
      trackId: tutorials.thinker?.value?.followsTracks?.[0].track,
      skip: tutorials.skip,
      subject: tutorials.subject,
      limit: window.innerWidth > 1200 ? 9 : 4,
    },
    {
      skip: isMentor && !tutorials.thinker?.value,
    },
  );
  const prefetchTutorials = apiSlice.usePrefetch('getTutorials');

  const areVideosAvailable =
    !isStudentOrThinker ||
    (studentOverviewData && studentOverviewData.subjectResults.length > 0) ||
    // fallback in case of no results but past quizzes provide access
    (!!data && data.total > 0);
  const thinkerFilterOptions =
    thinkers?.map((thinker) => ({
      label: `${thinker.firstName} ${thinker.lastName}`,
      value: thinker,
    })) ?? [];
  const subjectFilterOptions = Object.values(Subject);

  const studentSubjectData =
    !!studentOverviewData &&
    Array.isArray(studentOverviewData?.subjectResults) &&
    studentOverviewData.subjectResults.length > 0
      ? studentOverviewData.subjectResults.map((item) => ({
          label: item.subject,
          value: item.result,
        }))
      : // fallback in case of no results but past quizzes provide access
        subjectFilterOptions.map((option) => ({
          label: option,
          value: undefined,
        }));

  const skillsPlaceholder = isSkillsError
    ? 'Sorry, Skill filtering is temporarily unavailable'
    : isSkillsLoading
    ? 'Skills list is loading...'
    : 'Select...';

  useEffect(() => {
    if (!subjectParameter) {
      store.dispatch(push(`/tutorials/${Subject.geometry}`));
    }
  }, []);

  useEffect(() => {
    if (isError) {
      setTutorials((t) => ({
        skills: t.skills,
        skip: 0,
        thinker: t.thinker,
        subject: (subjectParameter as Subject) ?? Subject.geometry,
      }));
    }
  }, [isError]);

  useEffect(() => {
    if (
      JSON.stringify(searchParameterSkills) !== JSON.stringify(tutorials.skills)
    ) {
      setTutorials((t) => ({
        skills: searchParameterSkills ?? [],
        skip: 0,
        thinker: t.thinker,
        subject: t.subject,
      }));
    }

    if (subjectParameter !== tutorials.subject) {
      setTutorials((t) => ({
        skills: t.skills,
        skip: 0,
        subject: (subjectParameter as Subject) ?? Subject.geometry,
      }));
    }
  }, [subjectParameter, searchParameterSkills]);

  useEffect(() => {
    if (thinkers && thinkers.length > 0) {
      setTutorials((t) => ({
        skills: t.skills,
        skip: 0,
        subject: t.subject,
        thinker: {
          label: `${thinkers[0].firstName} ${thinkers[0].lastName}`,
          value: thinkers[0],
        },
      }));
    }
  }, [isFetchingThinkers]);

  if (isFetchingStudentResultOverview) {
    return <Loading />;
  } else if (isStudentOrThinker && !studentSubjectData) {
    return <div>Failed to fetch student subject overview!</div>;
  }

  return (
    <div className="grid gap-1 bg-gray-100 rounded-xl py-2">
      <div className="grid grid-cols-4 gap-2 px-6 pb-0 pt-3">
        <h1 className="text-3xl font-medium col-span-3 pl-4 pt-2">
          Video Tutorials
        </h1>
        <div className="absolute right-8">
          {(isLoading || isSkillsFetching || isFetchingTutorials) && (
            <Loading />
          )}
        </div>
        <div
          data-testid={'gaps-section'}
          className="box col-start-1 col-span-4 text-center justify-items-center"
        >
          {isMentor && thinkers && thinkers.length > 1 && (
            <div className="grid col-span-4 justify-items-center mt-0 p-2 md:p-4">
              <Filter
                filterType={'student-filter' as FilterType}
                options={thinkerFilterOptions}
                onSelect={(option) => {
                  setTutorials((t) => ({
                    skills: [],
                    skip: 0,
                    subject:
                      t.subject ??
                      (subjectParameter as Subject) ??
                      Subject.geometry,
                    thinker: option,
                  }));
                }}
                selected={tutorials.thinker ?? undefined}
              />
            </div>
          )}
          <div className="grid col-span-4 sm:justify-items-center p-2 md:p-4 overflow-x-auto">
            <Filter<Subject>
              filterType={FilterType.subjectFilter}
              options={subjectFilterOptions.map((option) => ({
                label: option,
                value: option,
                icon: `subject-${option.toLowerCase()}`,
                subLabel:
                  option === tutorials?.subject ? `${data?.total} videos` : '',
              }))}
              onSelect={(option) => {
                setTutorials({
                  skills: [],
                  skip: 0,
                  thinker: tutorials.thinker,
                  subject: option.value,
                });
                store.dispatch(push(`/tutorials/${option.value}`));
              }}
              onHover={(subject) => {
                prefetchTutorials({
                  skills: [],
                  skip: 0,
                  subject: subject.value,
                  limit: window.innerWidth > 1200 ? 9 : 4,
                  trackId: tutorials.thinker?.value?.followsTracks?.[0].track,
                });
              }}
              selected={
                {
                  label: tutorials.subject,
                  value: tutorials.subject,
                } as FilterValue
              }
              isStretched={true}
            />
          </div>

          {areVideosAvailable ? (
            <>
              <div className="w-full flex flex-row flex-wrap items-center p-4">
                <div
                  className="border border-gray-300 sm:border-none rounded-xl flex flex-row flex-1 items-center px-2 py-1 w-full sm:w-auto place-content-center"
                  onClick={() => setFiltersOpen(!areFiltersOpen)}
                >
                  <img
                    src="/images/icons/filter.svg"
                    alt="Filter icon"
                    className="mr-2"
                  ></img>
                  <div className="pr-4 md:pr-8 z-10">Filters</div>
                  {tutorials.skills.length > 0 && (
                    <div className="sm:hidden rounded-full w-6 h-6 p-1 bg-blue-200 text-tttDefault text-xs font-medium">
                      {tutorials.skills.length}
                    </div>
                  )}
                </div>
                <div
                  className={`${
                    areFiltersOpen
                      ? 'w-auto opacity-100 flex-1 mt-4 sm:mt-0 translate-y-0 pointer-events-auto'
                      : 'w-0 flex-0 sm:w-auto sm:flex-1 opacity-0 sm:opacity-100 mt-0 mb-[-150px] sm:mb-0 translate-y-[-150px] sm:translate-y-0 pointer-events-none sm:pointer-events-auto'
                  } flex flex-row basis-full sm:basis-96 items-center duration-500 ease-in-out z-10`}
                  style={{ transitionProperty: 'all' }}
                >
                  <div className="font-medium px-2">Skills</div>
                  <Select
                    value={tutorials.skills.map((s) => {
                      return { value: s, label: s };
                    })}
                    onChange={(selectedSkills) => {
                      const skills = selectedSkills.map((skill) => skill.value);
                      setTutorials((t) => ({
                        skills,
                        skip: 0,
                        thinker: t.thinker,
                        subject: t.subject,
                      }));
                      setSearchParameters({ skills });
                    }}
                    isMulti
                    placeholder={skillsPlaceholder}
                    options={skillsData?.map((s) => {
                      return { value: s.skill, label: s.skill };
                    })}
                    className="w-[90%] justify-self-center text-left"
                  />
                </div>
              </div>
            </>
          ) : (
            <div
              data-testid="no-tutorials"
              className="col-span-3 text-slate-600"
            >
              You don't have any tutorial videos to watch at the moment.
              <br />
              Come back each week as you make progress in the program.
            </div>
          )}
        </div>
      </div>
      {areVideosAvailable && (
        <div className="grid grid-cols-4 gap-6 px-6 pb-6 pt-0">
          {!isLoading && !!data ? (
            <InfiniteScroll
              loadMore={() => {
                console.log('Loading more tutorials...', {
                  skills: tutorials.skills,
                  skip: data.tutorials.length,
                  originalSkip: tutorials.skip,
                  thinker: tutorials.thinker,
                  subject: tutorials.subject,
                  trackId: tutorials.thinker?.value?.followsTracks?.[0].track,
                });
                setTutorials((t) => ({
                  skills: t.skills,
                  skip: data.tutorials.length,
                  thinker: t.thinker,
                  subject: t.subject,
                }));
              }}
              isLoading={isFetchingTutorials}
              hasMore={data.hasMore}
              loader={
                data.hasMore ? (
                  <div className="col-span-1 sm:col-span-2 xl:col-span-3">
                    Loading...
                  </div>
                ) : (
                  <></>
                )
              }
              className="grid col-start-1 col-span-4 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 w-full gap-2 p-2 sm:p-4 min-h-[500px] place-items-center"
            >
              {data.tutorials.length === 0 ? (
                <div className="col-span-3">
                  You don't have any {tutorials.subject} tutorial videos to
                  watch at the moment.
                  <br />
                  Come back each week as you make progress in the program.
                </div>
              ) : (
                data.tutorials.map((tutorial, index) => (
                  <TutorialCard {...tutorial} key={index} />
                ))
              )}
            </InfiniteScroll>
          ) : (
            <LoadingBlock />
          )}
        </div>
      )}
      <div className="hidden workaround w-[240px] w-[260px] w-[280px] w-[320px] w-[380px]" />
    </div>
  );
};

export default Tutorials;

import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FILTERS, Subject } from '../constants';

import { useAppSelector } from '../stores/AppStore';
import {
  apiSlice,
  useGetClassResultOverviewQuery,
  useGetClassSubjectResultsQuery,
  useGetClassTotalsForTeacherQuery,
  useGetClassesTrackDetailsQuery,
} from '../services/apiSlice';
import useFilteredResults from '../hooks/useFilteredResults';

import TeacherClassesHeader from '../partials/TeacherClassesHeader';

import BreakoutCircular from '../components/BreakoutCircular';
import { Loading, LoadingBlock } from '../components/Loading';
import { FilterValue } from '../components/Filter';

type ClassGapsScoresTitles = {
  classGapsTitle: string;
  classSkillsTitle: string;
};

type Filters = {
  class: string;
  subject: Subject;
};

const ClassGaps: FC = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<Filters>({
    class: '',
    subject: Subject.geometry,
  });
  const [titles, setTitles] = useState<ClassGapsScoresTitles>({
    classGapsTitle: '',
    classSkillsTitle: '',
  });
  const [
    cumulativeOrReviewOrQuizFilter,
    setCumulativeOrReviewOrQuizFilterFilter,
  ] = useState<FilterValue | null>({
    label: 'Cumulative Score',
    value: FILTERS.overall,
  });
  const [isQuizFilter, setIsQuizFilter] = useState(false);
  const getClassDocument = (period: string) => {
    if (!classes) {
      return;
    }
    return classes.find((d) => d.class === period) ?? classes[0];
  };
  const user = useAppSelector((state) => state.user.user);
  if (!user || !user.teachesClasses) {
    return <></>;
  }
  const {
    data: classes,
    isLoading: isLoadingClassesDetails,
    isError: isErrorClassesDetails,
  } = useGetClassesTrackDetailsQuery({
    classes: user.teachesClasses,
  });

  const { data: classTotals } = useGetClassTotalsForTeacherQuery({
    userId: user._id,
  });
  const {
    data: classResultOverview,
    isLoading: isLoadingClassResultOverview,
    isFetching: isFetchingClassResultOverview,
    isError: isErrorClassResultOverview,
  } = useGetClassResultOverviewQuery(
    {
      teacherId: user?._id,
      classId: getClassDocument(filters.class)?._id ?? '',
    },
    { skip: isLoadingClassesDetails },
  );
  const {
    data: classGapsResult,
    isLoading: isLoadingClassGapsResult,
    isFetching: isFetchingClassGapsResult,
  } = useGetClassSubjectResultsQuery(
    {
      subject: filters.subject,
      classId: getClassDocument(filters.class)?._id ?? '',
      teacherId: user._id,
    },
    { skip: isLoadingClassesDetails },
  );
  const prefetchClassGAPSData = apiSlice.usePrefetch('getClassSubjectResults');
  const displayedClassSubjectData = useFilteredResults({
    filter: cumulativeOrReviewOrQuizFilter?.value,
    result: classGapsResult?.subjectResults,
    label: 'subject',
  });
  const displayedClassSkillsData = useFilteredResults({
    filter: cumulativeOrReviewOrQuizFilter?.value,
    result: classGapsResult?.skillResults,
    label: 'skill',
    sortBy: 'value',
    removeUndefined: true,
  });

  const shouldShowTeacherOverview =
    Array.isArray(classTotals) && classTotals.length > 0;

  useEffect(() => {
    if (filters.class) {
      navigate(`/teacher/gaps-scores/${filters.class}`);
    }
  }, [filters.class]);

  useEffect(() => {
    if (classes && classes[0]) {
      setFilters((previousState) => ({
        ...previousState,
        class: classes[0].class,
      }));
    }
  }, [classes]);

  useEffect(() => {
    setTitles({
      classGapsTitle: `Period ${filters.class} GAPS ${
        cumulativeOrReviewOrQuizFilter?.value
      } Scores - ${getClassDocument(filters.class)?.trackDetails?.track}`,
      classSkillsTitle: `Period ${filters.class} ${filters.subject} ${cumulativeOrReviewOrQuizFilter?.value} sub-skills`,
    });
  }, [cumulativeOrReviewOrQuizFilter, filters]);

  if (isLoadingClassesDetails || isLoadingClassResultOverview) {
    return <LoadingBlock />;
  } else if (isErrorClassesDetails || isErrorClassResultOverview) {
    throw new Error('Failed to load class GAPS data');
  } else if (!classes || !classTotals || !classResultOverview) {
    return <div>You don't have any data to display yet.</div>;
  }

  return (
    <div className="grid gap-12">
      <TeacherClassesHeader
        circularOnSelect={(period) => {
          setFilters((previousState) => ({
            ...previousState,
            class: period,
          }));
        }}
        circularOnHover={(period) => {
          prefetchClassGAPSData({
            subject: Subject.geometry,
            classId: getClassDocument(period)?._id ?? '',
            teacherId: user._id,
          });
        }}
        circularSelected={filters.class}
        classResultOverview={classResultOverview}
        classTotals={classTotals}
        isRefreshing={isFetchingClassResultOverview}
        isQuizFilter={isQuizFilter}
        setIsQuizFilter={setIsQuizFilter}
        filter={cumulativeOrReviewOrQuizFilter}
        setFilter={setCumulativeOrReviewOrQuizFilterFilter}
        shouldShowTeacherOverview={shouldShowTeacherOverview}
        title={`Classes - ${user.school}`}
      />
      {shouldShowTeacherOverview && (
        <>
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-6 px-6 pb-6 pt-3 shadow-xl rounded-xl border-tttDefault border-[1px]">
            <div className="grid justify-self-center text-center col-span-2 lg:col-span-4 max-w-[90%] justify-items-center items-center overflow-hidden uppercase text-white font-bold md:text-xl lg:text-2xl p-3 -mt-7 shadow-md rounded-xl bg-tttDefault">
              {titles.classGapsTitle}
            </div>
            {isLoadingClassGapsResult ? (
              <LoadingBlock width={6} height={32} />
            ) : (
              <div
                data-testid={'gaps-section'}
                className="grid col-start-1 col-span-2 lg:col-span-4 grid-cols-2 md:grid-cols-4 gap-12 p-6 font-bold capitalize md:text-xl"
              >
                <div className="absolute right-8">
                  {isFetchingClassGapsResult && <Loading />}
                </div>
                {displayedClassSubjectData &&
                displayedClassSubjectData.length > 0 ? (
                  <BreakoutCircular
                    data={displayedClassSubjectData}
                    selected={filters.subject}
                    isSelectable={true}
                    onSelect={(subject) => {
                      setFilters((previousState) => ({
                        ...previousState,
                        subject: subject as Subject,
                      }));
                    }}
                    onHover={(subject) => {
                      prefetchClassGAPSData({
                        subject: subject as Subject,
                        classId: getClassDocument(filters.class)?._id ?? '',
                        teacherId: user._id,
                      });
                    }}
                  />
                ) : (
                  <div className="col-span-6 justify-self-center text-center normal-case p-6 font-medium text-sm md:text-base text-gray-500">
                    Your selected class does not have any results available.
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-6 px-6 pb-6 pt-3 shadow-xl rounded-xl border-tttDefault border-[1px]">
            <div className="grid justify-self-center text-center col-span-2 lg:col-span-4 max-w-[90%] justify-items-center items-center overflow-hidden uppercase text-white font-bold md:text-xl lg:text-2xl p-3 -mt-7 shadow-md rounded-xl bg-tttDefault">
              {titles.classSkillsTitle}
            </div>
            <div className="box grid col-span-2 lg:col-span-4 overflow-hidden font-medium">
              <div className="sm:hidden grid col-span-4 col-start-1 text-sm text-slate-400 text-center">
                Touch the skills to see their description.
              </div>
              {isLoadingClassGapsResult ? (
                <LoadingBlock width={6} height={32} />
              ) : (
                <div
                  data-testid={'skill-section'}
                  className="grid grid-flow-row gap-12 p-6 auto-cols-auto grid-cols-2 md:grid-cols-4 lg:grid-cols-6 w-full justify-self-center text-sm md:text-base"
                >
                  <div className="absolute right-8">
                    {isFetchingClassGapsResult && <Loading />}
                  </div>
                  {displayedClassSkillsData &&
                  displayedClassSkillsData.length > 0 ? (
                    <BreakoutCircular
                      data={displayedClassSkillsData}
                      isSelectable={false}
                      hasTooltips
                    />
                  ) : (
                    <div className="col-span-2 md:col-span-4 lg:col-span-6 justify-self-center text-center normal-case p-6 text-gray-500">
                      The selected class does not have any {filters.subject}{' '}
                      skill results available.
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ClassGaps;

import { FC } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../stores/AppStore';

import { Loading } from '../../components/Loading';
import { Subject } from '../../constants';
import Badge from '../../components/Badge';
import SwitchButton from '../../components/Switch';
import {
  useGetSkillsQuery,
  useGetTargetedPracticeSkillsInfoQuery,
  useToggleSkillTargetedPracticeAvailabilityMutation,
} from '../../services/apiSlice';
import { transformToPercentage } from '../../util';

const TargetedPracticeManager: FC = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.user);
  const [toggleSkillTargetedPracticeAvailability] =
    useToggleSkillTargetedPracticeAvailabilityMutation();
  const skillsQueries = Object.values(Subject).map((subject) =>
    useGetSkillsQuery({ subject: subject as Subject }),
  );
  const { data: skillsInfo, isFetching: isFetchingSkillsInfo } =
    useGetTargetedPracticeSkillsInfoQuery();
  const isFetchingSkillsData = skillsQueries.some((query) => query.isFetching);
  const skillsData = (
    skillsQueries.flatMap((query) => query.data) as {
      _id: string;
      skill: string;
      subject: string;
      isAvailableForTargetedPractice?: boolean;
    }[]
  ).filter(Boolean);

  const handleToggleSkillAvailability = async (skill: {
    _id: string;
    skill: string;
    subject: string;
    isAvailableForTargetedPractice?: boolean;
  }) => {
    const enabled = !skill.isAvailableForTargetedPractice;
    const confirmText = enabled
      ? `Are you sure you want to enable creating new targeted practices for ${skill.skill}?`
      : `Are you sure you want to disable creating further targeted practices for ${skill.skill}?`;
    const confirmed = window.confirm(confirmText);
    if (!confirmed) {
      return;
    }
    try {
      await toggleSkillTargetedPracticeAvailability({
        skillId: skill._id,
        enabled,
      }).unwrap();
      const subject =
        skill.subject === 'Algebra 1' ? Subject.algebra : skill.subject;
      const subjectQuery = skillsQueries.find(
        (query) => query.originalArgs?.subject === subject,
      );
      await subjectQuery?.refetch();
      const toastText = enabled
        ? `Enabled creating new targeted practices for ${skill.skill}.`
        : `Disabled creating new targeted practices for ${skill.skill}.`;
      toast.success(toastText);
    } catch {
      toast.error(
        `Failed to toggle targeted practice availability for ${skill.skill}. Please try again later.`,
      );
      return;
    }
  };

  if (!user) {
    return <></>;
  }

  return (
    <div
      data-testid="heading-section"
      className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-6 md:gap-y-8 justify-items-center"
    >
      {Object.values(Subject).map((subject) => {
        return (
          <div
            key={`subject-container-${subject}`}
            className="
              relative
              w-full
              p-3 
              shadow-xl rounded-xl border-tttDefault border-[1px]
            "
          >
            <div className="absolute top-0 right-0 mt-1 mr-1">
              {isFetchingSkillsData && <Loading />}
            </div>
            <div
              key={`subject-${subject}`}
              className="
                w-max mx-auto
                justify-items-center items-center text-center
                overflow-hidden 
                uppercase text-white font-bold text-xs sm:text-base md:text-lg lg:text-xl 
                shadow-md rounded-xl 
                p-2 -mt-7 bg-tttDefault
              "
            >
              {subject}
            </div>
            <div className="flex flex-col gap-2 mt-2 w-full">
              {skillsData.length > 0 ? (
                skillsData
                  .filter((skill) => {
                    if (skill.subject === 'Algebra 1') {
                      return Subject.algebra === subject;
                    }
                    return skill.subject === subject;
                  })
                  .map((skill) => {
                    const skillInfo = skillsInfo?.find(
                      (info) => info.skill === skill.skill,
                    );
                    return (
                      <div
                        key={`skill-${skill.skill}`}
                        className="
                          flex justify-between items-center
                          w-[99%] p-2 gap-4
                          font-medium text-xs md:text-base capitalize
                          shadow-sm rounded-md border-gray border-[1px]
                          cursor-pointer
                          hover:shadow-md hover:border-tttDefault hover:translate-y-[-2px]
                          transition-all duration-200 ease-in-out
                        "
                        onClick={() =>
                          navigate(
                            `/targeted-practice/problems?skill=${skill.skill}`,
                            { state: { skill: skill } },
                          )
                        }
                      >
                        <div className="grid auto-rows-auto gap-2 w-full max-h-min justify-start">
                          <p className="h-min">{skill.skill}</p>
                          <div className="flex gap-2 max-w-max h-full justify-end">
                            {!isFetchingSkillsInfo &&
                              skillInfo &&
                              skillInfo.numOfProblems !== 0 && (
                                <Badge
                                  text={`${skillInfo.numOfProblems} ${
                                    skillInfo.numOfProblems === 1
                                      ? 'Problem'
                                      : 'Problems'
                                  }`}
                                  title="Number of regular problems associated with this skill"
                                />
                              )}
                            {!isFetchingSkillsInfo &&
                              skillInfo &&
                              skillInfo.numOfTargetedPracticeProblems !== 0 && (
                                <Badge
                                  text={`${
                                    skillInfo.numOfTargetedPracticeProblems
                                  } ${
                                    skillInfo.numOfTargetedPracticeProblems ===
                                    1
                                      ? 'TP Problem'
                                      : 'TP Problems'
                                  }`}
                                  title="Number of Targeted Practice problems associated with this skill"
                                />
                              )}
                            {!isFetchingSkillsInfo &&
                              skillInfo &&
                              skillInfo.numOfOutstandingPractices !== 0 && (
                                <Badge
                                  text={`${
                                    skillInfo.numOfOutstandingPractices
                                  } New ${
                                    skillInfo.numOfOutstandingPractices === 1
                                      ? 'Practice'
                                      : 'Practices'
                                  }`}
                                  title="Number of outstanding Targeted Practices"
                                />
                              )}
                            {!isFetchingSkillsInfo &&
                              skillInfo?.averageResult && (
                                <Badge
                                  text={`Avg: ${transformToPercentage(
                                    skillInfo.averageResult,
                                  )}`}
                                  title="Average of results associated with this skill"
                                />
                              )}
                          </div>
                        </div>
                        <SwitchButton
                          id={`${skill.skill}-toggle`}
                          title={
                            isFetchingSkillsInfo
                              ? 'Loading...'
                              : skillInfo && skillInfo.numOfProblems < 10
                              ? 'At least 10 problems are required to enable the skill for targeted practice.'
                              : ''
                          }
                          shouldHaveText={false}
                          checked={skill.isAvailableForTargetedPractice}
                          disabled={
                            isFetchingSkillsInfo ||
                            (skillInfo && skillInfo.numOfProblems < 10)
                          }
                          loading={isFetchingSkillsInfo}
                          onChange={() => handleToggleSkillAvailability(skill)}
                          classNames="flex-shrink-0"
                        />
                      </div>
                    );
                  })
              ) : (
                <div className="text-center text-gray-500 p-4">
                  No skill available.
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TargetedPracticeManager;

import { FC, useEffect, useState } from 'react';
import Tooltip from 'rc-tooltip';
import { TagCloud } from 'react-tagcloud';

import { Tag } from '../types';

interface Properties {
  data: any;
  title?: string;
  className?: string;
}

const customRenderer = (tag: Tag, size: number, color: string) => (
  <span
    data-testid={tag.value}
    key={tag.key}
    className={`${color} p-1 lg:px-4`}
    style={{
      fontSize: `${size}px`,
      display: 'inline-block',
    }}
  >
    {tag.value}
  </span>
);

const SkillCloud: FC<Properties> = ({ data, className, title }) => {
  const [minSize, setMinSize] = useState(window.innerWidth <= 768 ? 8 : 16);
  const [maxSize, setMaxSize] = useState(window.innerWidth <= 768 ? 20 : 30);

  useEffect(() => {
    const handleResize = () => {
      setMinSize(window.innerWidth <= 768 ? 8 : 16);
      setMaxSize(window.innerWidth <= 768 ? 20 : 30);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div data-testid="sub-skill-breakout" className={`grid ${className}`}>
      <p className="font-bold text-center text-xl md:text-2xl py-3">{title}</p>
      <Tooltip
        placement="bottomRight"
        overlay={<TooltipOverlay />}
        overlayClassName="w-80"
        destroyTooltipOnHide={true}
      >
        <TagCloud
          tags={data}
          minSize={minSize}
          maxSize={maxSize}
          shuffle={false}
          renderer={customRenderer}
          className={`text-center`}
        />
      </Tooltip>
    </div>
  );
};

const TooltipOverlay: FC = () => {
  return (
    <div className="grid px-2 items-center font-medium">
      <ul className="list-disc px-3">
        <li key="skill-result">
          Here you see the results of the skills which appeared in the current
          quiz
        </li>
        <li key="skill-result-size">
          The larger the skill names, the more times they appeared in this quiz
        </li>
        <li key="skill-result-color">
          The colors represent how successfully the skill-related questions were
          answered
        </li>
      </ul>
    </div>
  );
};

export default SkillCloud;

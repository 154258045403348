import { makeAutoObservable } from 'mobx';
import { getPersistedStore, makePersistable } from 'mobx-persist-store';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import {
  Filter,
  midPercentageThreshold,
  topPercentageThreshold,
} from '../constants';
import { Quiz, emptyFunction } from '../types';
import { apiService } from '../services/APIService';

import type { RootStore } from './RootStore';
import { store } from './AppStore';
import { setClientVersion } from '../features/global/globalSlice';

dayjs.extend(utc);
dayjs.extend(timezone);

interface IKpiModal {
  show: boolean;
  action?: typeof emptyFunction;
  actionName?: string;
}

export class UIStore {
  localTZ = dayjs.tz.guess();
  rootStore: RootStore;
  clientVersion = '';
  clientVersionLoggedAt = '';
  apiError = false;
  apiNetworkError = false;
  didOpenSurvey = false;
  didWatchPopupVideo = false;
  highlightedStudent: string | undefined;
  isAuthenticating = false;
  isSidebarOpen = false;
  isUserMenuOpen = false;
  isStudentViewOpen = false;
  isTeacherViewOpen = false;
  loaded = false;
  filters: Filter[] = [];
  selectedQuiz: Quiz = {
    id: '',
    problems: [],
    startDate: '',
    dueAt: '',
    timeLimit: 0,
    _id: '',
    trackSerialNum: '',
  };
  selectedStudent: any = {};
  selectedTrack: any = {};
  showSurveyModal = false;
  showPopupVideo = false;
  KPIModal: IKpiModal = { show: false };
  isRefreshing = false;
  isRecalculating = false;
  isBenchmarking = false;
  redirectCount = 0;
  quiz = {
    showModal: true,
    activeProblemIndex: 0,
    finished: false,
    isEditing: false,
    progress: 0,
  };

  assignments = {
    showScoreMatrix: false,
  };

  passwordReseted = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'UIStore',
      properties: [
        'clientVersion',
        'clientVersionLoggedAt',
        'loaded',
        'quiz',
        'selectedStudent',
        'selectedTrack',
        'selectedQuiz',
        'filters',
        'didOpenSurvey',
        'redirectCount',
        'didWatchPopupVideo',
      ],
      storage: window.localStorage,
    });
    this.rootStore = rootStore;
  }

  getColor(number_: number) {
    number_ = Math.round(number_);
    return number_ >= topPercentageThreshold
      ? 'bg-green'
      : number_ < topPercentageThreshold && number_ >= midPercentageThreshold
      ? 'bg-yellow'
      : 'bg-orange';
  }

  getHighlightedStudent() {
    return this.highlightedStudent;
  }

  async getStoredData() {
    return getPersistedStore(this);
  }

  shouldShowKPIModal(
    show: boolean,
    action?: () => Promise<void>,
    actionName?: string,
  ) {
    this.KPIModal.show = show;
    if (action) {
      this.KPIModal.action = action;
      this.KPIModal.actionName = actionName ?? '';
    }
  }

  shouldShowScoreMatrix(show: boolean) {
    this.assignments.showScoreMatrix = show;
  }

  sendSentryClientVersion() {
    if (
      !this.clientVersionLoggedAt ||
      dayjs(this.clientVersionLoggedAt)
        .utc()
        .tz(this.localTZ)
        .diff(dayjs(), 'days') > 7
    ) {
      Sentry.addBreadcrumb({
        message: 'clientVersion',
        data: {
          clientVersion: this.clientVersion,
        },
      });
      this.setClientVersionLoggedAt(new Date().toISOString());
    }
  }

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  toggleUserMenu() {
    this.isUserMenuOpen = !this.isUserMenuOpen;
  }

  toggleStudentView() {
    this.isStudentViewOpen = !this.isStudentViewOpen;
  }

  toggleTeacherView() {
    this.isTeacherViewOpen = !this.isTeacherViewOpen;
  }

  hideStudentView() {
    this.isStudentViewOpen = false;
  }

  hideSidebar() {
    this.isSidebarOpen = false;
  }

  hideUserMenu() {
    this.isUserMenuOpen = false;
  }

  hideMenus() {
    this.hideUserMenu();
    this.hideSidebar();
  }

  didLoad(loaded: boolean) {
    this.loaded = loaded;
  }

  setClientVersionLoggedAt(date: string) {
    this.clientVersionLoggedAt = date;
  }

  setRefreshing(state: boolean) {
    this.isRefreshing = state;
  }

  setHighlightedStudent(student?: string) {
    this.highlightedStudent =
      this.highlightedStudent === student ? undefined : student;
  }

  increaseRedirectCount() {
    this.redirectCount++;
  }

  resetRedirectCount() {
    this.redirectCount = 0;
  }

  resetapiNetworkErrorState() {
    this.apiError = false;
    this.apiNetworkError = false;
  }

  async fetchClientVersion() {
    const resp = await apiService.get('client-version');
    if (resp?.data?.version) {
      this.clientVersion = resp.data.version;
      store.dispatch(setClientVersion(resp.data));
    } else {
      Sentry.captureException('Invalid version response from the API', {
        tags: {
          version: JSON.stringify(resp?.data),
        },
      });
    }
  }

  selectQuiz(quiz: Quiz) {
    this.selectedQuiz = quiz;
  }

  shouldShowSurveyModal(show: boolean) {
    this.showSurveyModal = show;
  }

  setDidOpenSurvey(didOpen: boolean) {
    this.didOpenSurvey = didOpen;
  }

  setDidWatchPopupVideo(didWatch: boolean) {
    this.didWatchPopupVideo = didWatch;
  }

  shouldShowPopupVideo(show: boolean) {
    this.showPopupVideo = show;
  }
}

import {
  PiCalendarXLight,
  PiCalendarBlankLight,
  PiDownloadSimpleLight,
} from 'react-icons/pi';
import { Link } from 'react-router-dom';

import { CurrentQuiz, StudentTrackWithSchedule } from '../../types';
import { downloadWorkbook } from '../../util';

interface UpcomingAssignmentProperties {
  assignment: CurrentQuiz;
  track?: StudentTrackWithSchedule;
  canDownloadWorkbook?: boolean;
}

const UpcomingAssignment = ({
  assignment,
  track,
  canDownloadWorkbook = false,
}: UpcomingAssignmentProperties) => {
  const handleDownloadWorkbook = () => {
    if (!canDownloadWorkbook || !track || !assignment.workbookUrl) {
      return;
    }
    downloadWorkbook({
      quizId: assignment.id,
      url: assignment.workbookUrl,
    });
  };

  return (
    <div
      data-testid={`upcoming-assignment-${assignment.id}`}
      className={`flex flex-col gap-3 bg-white rounded-xl border border-1-gray p-4 ${
        assignment.isReview && 'border border-tttDefault'
      }`}
    >
      <div className="flex gap-3 items-center">
        <p className="font-medium">{assignment.id}</p>
        <div className="flex gap-2 items-center">
          {assignment.isOverdue ? (
            <PiCalendarXLight className="w-4 h-4 text-rose-500" />
          ) : (
            <PiCalendarBlankLight className="w-4 h-4 text-tttDefault" />
          )}
          <p className="text-sm text-gray-700">
            {assignment.isOverdue ? 'Overdue' : `Due ${assignment.dueDate}`}
          </p>
        </div>
      </div>
      <Link
        to={track ? `/quiz/${track.serialNum}/${assignment.id}/practice` : ''}
        className="
          px-4 py-1.5 w-max h-max md:my-auto
          rounded-full border border-tttDefault
          text-sm md:text-base
          font-medium text-tttDefault whitespace-nowrap
          transition-all duration-100 ease-in-out
          hover:shadow hover:bg-tttDefault hover:text-white
        "
      >
        Practice
      </Link>
      {canDownloadWorkbook && (
        <div
          onClick={handleDownloadWorkbook}
          className="w-full flex gap-2 text-tttDefault items-center whitespace-nowrap hover:underline cursor-pointer"
        >
          <PiDownloadSimpleLight className="w-5 h-5" />
          Download Workbook
        </div>
      )}
    </div>
  );
};

export default UpcomingAssignment;

import React, { ReactElement, ReactNode } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';

interface Properties {
  defaultValues?: any;
  children: ReactNode;
  onSubmit: any;
}

export default function Form({
  defaultValues,
  children,
  onSubmit,
}: Properties) {
  const { control, handleSubmit, register } = useForm({ defaultValues });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="grid max-w-md mx-auto mt-8 mb-0 gap-2"
    >
      {Array.isArray(children)
        ? children.map((child: ReactElement) => {
            if (child.props['data-type'] === 'select') {
              return (
                <Controller
                  control={control}
                  name={child.props.name}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <Select
                        {...child.props}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                      />
                    );
                  }}
                />
              );
            } else if (child.props.name) {
              return React.createElement(child.type, {
                ...child.props,
                register,
                key: child.props.name,
              });
            } else {
              return child;
            }
          })
        : children}
    </form>
  );
}

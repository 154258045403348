import { ReactElement, FC } from 'react';

export const Footer: FC = (): ReactElement => {
  return (
    <footer className="grid w-full justify-items-center footer bg-white">
      <a href="https://www.thinktankprep.com/" target="_blank" rel="noreferrer">
        <img
          src="/logo.png"
          className="max-h-[40px] md:max-h-[40px] lg:max-h-[50px] m-1"
          alt="The Think Tank Logo"
        />
      </a>
      <div className="grid w-full bg-gray-500 justify-items-center p-2">
        <div className="text-white text-[0.65rem] sm:text-xs lg:text-sm uppercase text-center">
          {`COPYRIGHT © ${new Date().getFullYear()} | ALL RIGHTS RESERVED THE THINK TANK EDUCATION, LLC`}
        </div>
      </div>
    </footer>
  );
};

import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import toast from 'react-hot-toast';

import type { RootStore } from './RootStore';

import { apiService } from '../services/APIService';
import { EventSourceMessage } from '@microsoft/fetch-event-source';
import { store } from './AppStore';

type RecalcTime = {
  type: string;
  duration: number;
  time: Date;
};

type KPIData = {
  totalStudents: number;
  totalResults: number;
  studentRecalcTime: RecalcTime[];
  schoolClassStudentRecalcTime: RecalcTime[];
  fullRecalcTime: RecalcTime[];
  school: {
    overallResult?: number;
    submissionRates?: Record<string, number>;
  };
};

export type BackgroundProcessingStatus = {
  isLocked: boolean;
  progress: string;
  progressPercentage: number;
  elapsedTimeSec: number;
};

export class StatsStore {
  rootStore: RootStore;
  kpiData: KPIData = {
    totalStudents: 0,
    totalResults: 0,
    studentRecalcTime: [],
    schoolClassStudentRecalcTime: [],
    fullRecalcTime: [],
    school: {},
  };
  backgroundProcessingStatus: BackgroundProcessingStatus = {
    isLocked: false,
    progress: '',
    progressPercentage: 0,
    elapsedTimeSec: 0,
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'StatsStore',
      properties: [],
      storage: window.localStorage,
    });
    this.rootStore = rootStore;
  }

  async getKPIData(school: string) {
    const kpiDataResp = await apiService.get('stats/owner/kpi', {
      params: { school },
    });
    if (kpiDataResp?.data) {
      this.kpiData = kpiDataResp.data;
    }
  }

  async recalculateAllDashboardData() {
    const { uiStore } = this.rootStore;
    uiStore.setRefreshing(true);
    uiStore.isRecalculating = true;
    await apiService.listenToEvents(
      'database-views/recalculate',
      (event: EventSourceMessage) => {
        if (!event.data) {
          return;
        }
        try {
          this.backgroundProcessingStatus = JSON.parse(
            event.data,
          ) as BackgroundProcessingStatus;
          if (this.backgroundProcessingStatus.progressPercentage === 1) {
            toast.success('Successfully recalculated ALL dashboard data');
            uiStore.setRefreshing(false);
            uiStore.isRecalculating = false;
          }
        } catch (error) {
          console.error(error);
          if (event.data !== '') {
            toast.error('Unable to check recalculation status');
          }
        }
      },
    );
    uiStore.setRefreshing(false);
    uiStore.isRecalculating = false;
  }

  async flushAllRedisCache() {
    this.rootStore.uiStore.setRefreshing(true);
    await apiService.post('stats/flush-redis');
    this.rootStore.uiStore.setRefreshing(false);
  }

  async pushTroughAllReviews() {
    this.rootStore.uiStore.setRefreshing(true);
    const pushThroughResponse = await apiService.post(
      'users/reviews/push-through-all',
    );
    if (pushThroughResponse?.data?.modifiedåCount) {
      toast.success(
        `Successfully pushed ${pushThroughResponse.data.modifiedCount} students through their last reviews!`,
      );
    } else {
      toast.error(
        `Failed to push students through their last reviews: ${JSON.stringify(
          pushThroughResponse?.data,
        )}`,
      );
    }
    this.rootStore.uiStore.setRefreshing(false);
  }

  async startBenchmark(studentCount: number) {
    const { uiStore } = this.rootStore;
    uiStore.setRefreshing(true);
    uiStore.isBenchmarking = true;
    await apiService.listenToEvents(
      `db-benchmark/run?n=${studentCount}`,
      (event: EventSourceMessage) => {
        try {
          this.backgroundProcessingStatus = JSON.parse(
            event.data,
          ) as BackgroundProcessingStatus;
          if (this.backgroundProcessingStatus.progressPercentage === 1) {
            toast.success(
              `Successfully ran the benchmark with ${studentCount} students`,
            );
            uiStore.setRefreshing(false);
            uiStore.isBenchmarking = false;
          }
        } catch (error) {
          console.error(error);
          if (event.data !== '') {
            toast.error('Unable to check recalculation status');
          }
        }
      },
    );
    uiStore.setRefreshing(false);
    uiStore.isBenchmarking = false;
  }

  getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key: any, value: object | null) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };
  async sendErrorStats() {
    // NOTE: Converting circular structure to JSON (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value)
    return apiService.post('/stats/error', {
      clientVersion: this.rootStore.uiStore.clientVersion,
      errorTrace: JSON.stringify(
        {
          uiStore: this.rootStore.uiStore,
          statsStore: this,
          appState: store.getState(),
        },
        this.getCircularReplacer(),
      ),
    });
  }

  resetStore() {
    this.backgroundProcessingStatus = {
      isLocked: false,
      progress: '',
      progressPercentage: 0,
      elapsedTimeSec: 0,
    };
  }
}

import { FC, useEffect, useState } from 'react';

import { FILTERS, FilterType } from '../constants';
import {
  QuizResult,
  SchoolResultOverview,
  ExtendedSubjectResult,
  SchoolTrack,
} from '../types';

import { useAppSelector } from '../stores/AppStore';
import useFilteredResults from '../hooks/useFilteredResults';

import BreakoutCircular from '../components/BreakoutCircular';
import Card from '../components/Card';
import Filter, { FilterValue } from '../components/Filter';
import { Loading } from '../components/Loading';
import QuizSelectorFilter from '../components/QuizSelectorFilter';

interface Properties {
  circularOnSelect?: (period: string) => void;
  circularOnHover?: (period: string) => void;
  circularIsSelectable?: boolean;
  circularSelected?: string;
  resultOverview?: SchoolResultOverview;
  subjectResults?: ExtendedSubjectResult[];
  isRefreshing: boolean;
  isQuizFilter: boolean;
  setIsQuizFilter: (isQuizFilter: boolean) => void;
  cumulativeOrReviewOrQuizFilter: FilterValue | null;
  setCumulativeOrReviewOrQuizFilter: (newFilter: FilterValue | null) => void;
  tracks: SchoolTrack[];
  trackFilter: FilterValue | null;
  setTrackFilter: (newFilter: FilterValue | null) => void;
}

const SchoolAdminHeader: FC<Properties> = ({
  circularOnSelect,
  circularOnHover,
  circularIsSelectable = false,
  circularSelected,
  resultOverview,
  subjectResults,
  isRefreshing,
  isQuizFilter,
  setIsQuizFilter,
  cumulativeOrReviewOrQuizFilter,
  setCumulativeOrReviewOrQuizFilter,
  tracks,
  trackFilter,
  setTrackFilter,
}) => {
  const [schoolQuizResult, setSchoolQuizResult] = useState<QuizResult>();
  const user = useAppSelector((state) => state.user.user);
  if (!user?.adminOfSchool) {
    return <></>;
  }

  const shouldShowSubmissionRate = !!(
    resultOverview?.submissionRates &&
    resultOverview.submissionRates[cumulativeOrReviewOrQuizFilter?.value]
  );

  const trackFilterOptions: FilterValue[] = [
    { label: 'All tracks', value: FILTERS.overall },
  ];
  if (tracks?.length > 0) {
    for (const track of tracks) {
      trackFilterOptions.push({ label: track.track, value: track._id });
    }
  }

  const overallFilterOptions = [
    { label: 'Cumulative score', value: FILTERS.overall },
    { label: 'Review score', value: FILTERS.reviewOverall },
  ];

  const schoolGAPSData = useFilteredResults({
    filter: cumulativeOrReviewOrQuizFilter?.value,
    result: subjectResults,
    label: 'subject',
  });

  useEffect(() => {
    if (isQuizFilter) {
      const relevantResult = resultOverview?.quizResults?.find(
        (result) => result.quiz === cumulativeOrReviewOrQuizFilter?.value,
      );
      setSchoolQuizResult(relevantResult);
      setIsQuizFilter(true);
    }
  }, [cumulativeOrReviewOrQuizFilter, isQuizFilter, resultOverview]);

  return (
    <div
      data-testid={'heading-section'}
      className="relative grid grid-cols-2 lg:grid-cols-4 gap-6 px-6 pb-6 pt-3 shadow-xl rounded-xl border-tttDefault border-[1px]"
    >
      <div className="grid justify-self-center text-center col-span-2 lg:col-span-4 max-w-[90%] justify-items-center items-center overflow-hidden uppercase text-white font-bold md:text-xl lg:text-2xl p-3 -mt-7 shadow-md rounded-xl bg-tttDefault">
        {user.adminOfSchool}
      </div>
      <div className="absolute right-3 top-3">
        {isRefreshing && <Loading />}
      </div>
      {resultOverview ? (
        <>
          <div className="grid grid-cols-2 row-start-2 row-span-1 col-start-1 col-span-2 lg:col-start-1 lg:col-end-1 lg:grid-cols-1 lg:row-span-2 justify-items-center items-center gap-3">
            <div className="box w-[90%] xl:w-[70%] capitalize">
              <Card
                id="cumulative-card"
                title="Cumulative"
                body={
                  resultOverview.overallResult
                    ? resultOverview.overallResult * 100
                    : undefined
                }
              />
            </div>
            <div className="box w-[90%] xl:w-[70%] capitalize">
              <Card
                id="review-card"
                title="Review Score"
                body={
                  resultOverview.reviewResult
                    ? resultOverview.reviewResult * 100
                    : undefined
                }
              />
            </div>
            {isQuizFilter && (
              <div className="box w-[90%] xl:w-[70%] capitalize">
                <Card
                  id="quiz-card"
                  title={cumulativeOrReviewOrQuizFilter?.value}
                  body={
                    schoolQuizResult?.result
                      ? schoolQuizResult.result * 100
                      : undefined
                  }
                />
              </div>
            )}
            {shouldShowSubmissionRate && (
              <div className="box w-[90%] xl:w-[70%] capitalize">
                <Card
                  id="submission-rate-card"
                  title={`Submission rate - ${cumulativeOrReviewOrQuizFilter?.value}`}
                  body={
                    resultOverview.submissionRates
                      ? resultOverview.submissionRates[
                          cumulativeOrReviewOrQuizFilter?.value
                        ] * 100
                      : 0
                  }
                  tooltipText={`${
                    cumulativeOrReviewOrQuizFilter?.value
                  } submission rate for the ${
                    trackFilter?.value === FILTERS.overall
                      ? 'school'
                      : 'selected track'
                  }.`}
                />
              </div>
            )}
          </div>
          <div className="box grid gap-3 overflow-hidden row-start-3 lg:row-start-2 lg:row-span-2 col-start-1 lg:col-start-2 col-span-2 lg:col-span-3 font-medium justify-items-center">
            <div className="w-[80%] h-max">
              <Filter
                filterType={FilterType.trackFilter}
                options={trackFilterOptions}
                selected={trackFilter as FilterValue}
                onSelect={(selectedFilter: FilterValue) => {
                  setTrackFilter(selectedFilter);
                }}
              />
            </div>
            <div className="grid md:grid-cols-[2fr,1fr] items-center w-[80%] m-1 gap-3 md:gap-0 h-min">
              <Filter
                filterType={FilterType.quizOrReviewOrOverall}
                options={overallFilterOptions}
                selected={cumulativeOrReviewOrQuizFilter as FilterValue}
                onSelect={(selectedFilter: FilterValue) => {
                  setIsQuizFilter(false);
                  setCumulativeOrReviewOrQuizFilter(selectedFilter);
                }}
              />
              <QuizSelectorFilter
                key={String(isQuizFilter)}
                isActive={isQuizFilter}
                selectFn={(newValue) => {
                  setIsQuizFilter(true);
                  setCumulativeOrReviewOrQuizFilter(newValue);
                }}
                value={
                  isQuizFilter
                    ? {
                        value: cumulativeOrReviewOrQuizFilter?.value,
                        label: cumulativeOrReviewOrQuizFilter?.value,
                      }
                    : // eslint-disable-next-line unicorn/no-null
                      null
                }
                selectOptions={resultOverview.quizResults?.map((r) => ({
                  value: r.quiz,
                  label: r.quiz,
                }))}
              />
            </div>
            {schoolGAPSData && schoolGAPSData.length > 0 ? (
              <div className="grid grid-flow-row gap-12 p-6 auto-cols-auto grid-cols-2 md:grid-cols-4 justify-center font-bold capitalize md:text-xl w-full">
                <BreakoutCircular
                  data={schoolGAPSData}
                  selected={circularSelected}
                  isSelectable={circularIsSelectable}
                  onSelect={circularOnSelect}
                  onHover={circularOnHover}
                />
              </div>
            ) : (
              <div className="justify-self-center text-center p-6 text-gray-500">
                The selected track does not have any results available.
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="justify-self-center text-center p-6 text-gray-500">
          You don't have any data to display yet.
        </div>
      )}
    </div>
  );
};

export default SchoolAdminHeader;

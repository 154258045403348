/* eslint-disable unicorn/no-null */
import { ChangeEvent, FC, useMemo, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import toast from 'react-hot-toast';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import Select, { GroupBase, OptionsOrGroups, components } from 'react-select';

import { ExtendedSkillResult, TargetedPracticeAssignListItem } from '../types';
import { getTextColor, transformToPercentage } from '../util';
import { Subject, maxNumberOfTargetedPracticeSkills } from '../constants';
import {
  useAssignTargetedPracticesMutation,
  useGetAssignedTargetedPracticesQuery,
  useGetClassStudentSkillResultsQuery,
  useGetClassStudentsQuery,
  useGetClassWeakestSkillsQuery,
} from '../services/apiSlice';
import Button from './Button';
import { FilterValue } from './Filter';
import Table from './Table';
import { Loading } from './Loading';

type SelectedSkillsForStudent = {
  studentId: string;
  selectedSkills: string[]; // array of skillNums
};

type SkillOption = { skill: string; skillNum: string; result: number };

interface TargetedPracticeAssignTableProperties {
  selectedClass?: FilterValue;
  isFetching: boolean;
}

const TargetedPracticeAssignTable: FC<
  TargetedPracticeAssignTableProperties
> = ({ selectedClass, isFetching }) => {
  const [selectedSkills, setSelectedSkills] = useState<
    SelectedSkillsForStudent[]
  >([]);
  const [skillOptions, setSkillOptions] = useState<SkillOption[]>([]);

  const { data: students, isFetching: isFetchingStudents } =
    useGetClassStudentsQuery(
      { classId: selectedClass?.value._id as string },
      { skip: !selectedClass?.value?._id },
    );
  const { data: classWeakestSkills, isFetching: isFetchingClassWeakestSkills } =
    useGetClassWeakestSkillsQuery(
      { classId: selectedClass?.value._id as string },
      { skip: !selectedClass?.value?._id },
    );
  const {
    data: studentSkillResults,
    isFetching: isFetchingStudentSkillResults,
  } = useGetClassStudentSkillResultsQuery(
    {
      classId: selectedClass?.value._id as string,
      skills: skillOptions.map((skill) => skill.skill),
    },
    { skip: !selectedClass?.value?._id || skillOptions.length === 0 },
  );
  const [assignTargetedPractices, { isLoading }] =
    useAssignTargetedPracticesMutation();
  const { data: assignedPractices, refetch: refetchAssignedTargetedPractices } =
    useGetAssignedTargetedPracticesQuery(
      { classId: selectedClass?.value._id as string },
      { skip: !selectedClass?.value?._id },
    );

  const selectStyles = {
    control: (baseStyles: any) => ({
      ...baseStyles,
      minHeight: 0,
    }),
    dropdownIndicator: (baseStyles: any) => ({
      ...baseStyles,
      padding: 2,
    }),
    groupHeading: (baseStyles: any) => ({
      ...baseStyles,
      fontWeight: 'bold',
      fontSize: '15px',
      color: '#0069D9', // tttDefault
    }),
    placeholder: (baseStyles: any) => ({
      ...baseStyles,
      textWrap: 'nowrap',
    }),
    valueContainer: (baseStyles: any) => ({
      ...baseStyles,
      fontWeight: 'normal',
      padding: '0px',
      paddingLeft: '4px',
    }),
    singleValue: (baseStyles: any) => ({
      ...baseStyles,
      whiteSpace: 'pre-wrap',
      textWrap: 'no-wrap',
    }),
    option: (baseStyles: any) => ({
      ...baseStyles,
      fontWeight: 'normal',
    }),
  };

  const selectOptions: OptionsOrGroups<
    {
      label: string;
      value: SkillOption | null;
    },
    GroupBase<{
      label: string;
      value: SkillOption | null;
    }>
  > = classWeakestSkills
    ? [
        { label: 'Clear selection...', value: null },
        ...Object.values(Subject).flatMap((subject) => {
          return {
            label: subject,
            options: classWeakestSkills
              .filter((skill) => skill.subject === subject)
              .map((skill) => ({
                label: `${skill.skill} (${transformToPercentage(
                  skill.overallResult,
                )})`,
                value: {
                  skill: skill.skill,
                  skillNum: skill.skillNum,
                  result: skill.overallResult,
                },
              })),
          };
        }),
      ]
    : [];

  const canSelectSkillMoreSkills = (studentId: string, shouldToast = true) => {
    const studentSelectedSkills = selectedSkills.find(
      (student) => student.studentId === studentId,
    );
    const studentAssignedPractices = assignedPractices?.items?.find(
      (r) => r.studentId === studentId,
    );
    const pendingPractices =
      studentAssignedPractices?.targetedPractices?.filter((practice) =>
        dayjs(practice.dueAt).isAfter(dayjs()),
      );
    if (
      studentSelectedSkills?.selectedSkills?.length ===
      maxNumberOfTargetedPracticeSkills
    ) {
      if (shouldToast) {
        toast.error(
          `You can only select ${maxNumberOfTargetedPracticeSkills} skills for each student.`,
          { id: `maxSelectable_${studentId}` },
        );
      }
      return false;
    }
    const selectedSkillsCount =
      studentSelectedSkills?.selectedSkills?.length || 0;
    if (
      pendingPractices?.length &&
      pendingPractices.length + selectedSkillsCount >=
        maxNumberOfTargetedPracticeSkills
    ) {
      const maxSelectable =
        maxNumberOfTargetedPracticeSkills - pendingPractices.length;
      if (shouldToast) {
        toast.error(
          `The student already has ${pendingPractices.length} targeted ${
            pendingPractices.length === 1 ? 'practice' : 'practices'
          } pending. You can only select ${maxSelectable} more ${
            maxSelectable === 1 ? 'skill' : 'skills'
          } for this student.`,
          { id: `maxSelectable_existing_${studentId}` },
        );
      }
      return false;
    }
    return true;
  };

  const handleSelect = (
    event: ChangeEvent<HTMLInputElement>,
    studentId: string,
    skillOption: SkillOption,
  ) => {
    if (event.target.checked) {
      if (!canSelectSkillMoreSkills(studentId)) {
        event.target.checked = false;
        return;
      }

      if (selectedSkills.some((student) => student.studentId === studentId)) {
        const newSelectedSkills = selectedSkills.map((student) =>
          student.studentId === studentId
            ? {
                studentId: student.studentId,
                selectedSkills: [
                  ...student.selectedSkills,
                  skillOption?.skillNum,
                ],
              }
            : student,
        );
        setSelectedSkills(newSelectedSkills);
      } else {
        setSelectedSkills((previous) => [
          ...previous,
          { studentId, selectedSkills: [skillOption?.skillNum] },
        ]);
      }
    } else {
      const newSelectedSkills = selectedSkills
        .map((student) => {
          if (student.studentId === studentId) {
            const updatedSelectedSkills = student.selectedSkills.filter(
              (skillNumber) => skillNumber !== skillOption?.skillNum,
            );
            // remove student from selectedSkills if no skills are selected
            return updatedSelectedSkills.length === 0
              ? undefined
              : {
                  studentId: student.studentId,
                  selectedSkills: updatedSelectedSkills,
                };
          }
          return student;
        })
        .filter(Boolean) as SelectedSkillsForStudent[];
      setSelectedSkills(newSelectedSkills);
    }
  };

  const handleSelectAll = (selectedSkill: {
    skill: string;
    skillNum: string;
  }) => {
    let newSelectedSkills: SelectedSkillsForStudent[] = [];
    newSelectedSkills =
      selectedSkills.length === students?.length &&
      selectedSkills.every((studentSkills) =>
        studentSkills.selectedSkills.includes(selectedSkill?.skillNum),
      )
        ? selectedSkills.map((student) => ({
            studentId: student.studentId,
            selectedSkills: student.selectedSkills.filter(
              (skill) => skill !== selectedSkill?.skillNum,
            ),
          }))
        : (students
            ?.map((student) => {
              const studentSelectedSkills = selectedSkills.find(
                (selectedSkill) => selectedSkill.studentId === student._id,
              );
              const studentSelectedSkillsSet = new Set(
                studentSelectedSkills?.selectedSkills ?? [],
              );
              if (canSelectSkillMoreSkills(student._id, false)) {
                studentSelectedSkillsSet.add(selectedSkill?.skillNum);
              }
              return {
                studentId: student._id,
                selectedSkills: [...studentSelectedSkillsSet],
              };
            })
            .filter(Boolean) as SelectedSkillsForStudent[]) ?? [];
    setSelectedSkills(() => [...newSelectedSkills]);
  };

  const handleAssignAllSelected = async () => {
    if (!selectedClass?.value?._id) {
      return;
    }
    const selectedCount = selectedSkills.flatMap(
      (student) => student.selectedSkills,
    ).length;
    if (selectedCount === 0) {
      toast.error('At least one skill needs to be selected.');
      return;
    }

    const confirmation = window.confirm(
      `Are you sure you want to assign ${selectedCount} Targeted ${
        selectedCount === 1 ? 'Practice' : 'Practices'
      } to your class?`,
    );
    if (!confirmation) {
      return;
    }

    try {
      await assignTargetedPractices({
        classId: selectedClass.value._id,
        payload: selectedSkills.flatMap((student) =>
          student.selectedSkills.map((skill) => ({
            studentId: student.studentId,
            skill,
          })),
        ),
      }).unwrap();
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          request: 'assignTargetedPractices',
        },
      });
      toast.error(
        'Some unexpected error happened while assigning the targeted practices. Please try again later!',
      );
      return;
    }
    await refetchAssignedTargetedPractices();
    setSelectedSkills([]);
    toast.success('Targeted practices assigned successfully!');
  };

  const columns = useMemo<ColumnDef<TargetedPracticeAssignListItem, any>[]>(
    () => [
      {
        accessorKey: 'studentName',
        cell: (info) => info.getValue(),
        header: () => <span>Name</span>,
        enableSorting: false,
      },
      ...Array.from({ length: 3 }).map((_, index) => ({
        accessorKey: `skillResults_${index}`,
        cell: (info: any) => {
          const skillOption = skillOptions[index];
          const skillResult: ExtendedSkillResult =
            info.row.original.skillresults.find(
              (skillResult: ExtendedSkillResult) =>
                skillResult.skillNum === skillOption?.skillNum,
            );
          const studentId = info.row.original.studentId;
          const studentSelectedSkills = selectedSkills.find(
            (student) => student.studentId === studentId,
          );
          const isSkillSelected =
            studentSelectedSkills?.selectedSkills.includes(
              skillOption?.skillNum,
            );

          if (!skillOptions[index]) {
            return <></>;
          }

          return (
            <div className="flex items-center px-1">
              <input
                type="checkbox"
                disabled={!skillOptions[index]}
                checked={isSkillSelected}
                onChange={(event) =>
                  handleSelect(event, studentId, skillOption)
                }
              />
              <div className="w-16 text-right">
                <span
                  className={`font-bold ${
                    skillResult
                      ? getTextColor(skillResult.overallResult * 100)
                      : 'text-gray-500'
                  }`}
                >
                  {skillResult
                    ? transformToPercentage(skillResult.overallResult)
                    : 'N/A'}
                </span>
              </div>
            </div>
          );
        },
        header: () => {
          const selectedSkill = skillOptions[index];
          const setSelectedSkill = (skill?: string) => {
            if (!skill) {
              setSkillOptions((previous) =>
                previous.filter((_, index_) => index_ !== index),
              );
              return;
            }
            const newSelectedSkills = [...skillOptions];
            const newSelectedSkill = classWeakestSkills?.find(
              (skillResult) => skillResult.skill === skill,
            );
            if (!newSelectedSkill) {
              return;
            }
            newSelectedSkills[index] = {
              skill: newSelectedSkill.skill,
              skillNum: newSelectedSkill.skillNum,
              result: newSelectedSkill.overallResult,
            };
            setSkillOptions(newSelectedSkills);
          };

          return (
            <div className="flex flex-row justify-between gap-2 text-center items-center px-1">
              {skillOptions[index] && (
                <input
                  className="w-5 h-5"
                  type="checkbox"
                  checked={
                    selectedSkills.length === students?.length &&
                    selectedSkills.every((studentSkills) =>
                      studentSkills.selectedSkills.includes(
                        selectedSkill?.skillNum,
                      ),
                    )
                  }
                  onChange={() => handleSelectAll(selectedSkill)}
                />
              )}
              <Select
                placeholder={'Click to select a skill'}
                value={
                  selectedSkill
                    ? {
                        label: `${selectedSkill.skill} (${transformToPercentage(
                          selectedSkill.result,
                        )})`,
                        value: selectedSkill,
                      }
                    : null
                }
                onChange={(newValue) =>
                  setSelectedSkill(newValue?.value?.skill)
                }
                isClearable
                backspaceRemovesValue
                options={selectOptions}
                components={{
                  IndicatorSeparator: () => null,
                  ClearIndicator: () => null,
                  NoOptionsMessage,
                }}
                styles={selectStyles}
                className="text-left text-black text-sm w-full"
              />
            </div>
          );
        },
        enableSorting: false,
      })),
    ],
    [skillOptions, selectedSkills, assignedPractices, classWeakestSkills],
  );

  return (
    <div className="grid justify-items-center relative w-full">
      <div className="absolute top-0 right-0 -mt-12 mr-3">
        {isFetchingStudentSkillResults && <Loading />}
      </div>
      <Table<TargetedPracticeAssignListItem>
        columns={columns}
        data={{
          total: students?.length ?? 0,
          items:
            students?.map((student) => ({
              studentName: `${student.firstName} ${student.lastName}`,
              studentId: student._id,
              skillresults:
                studentSkillResults?.items?.find(
                  (result) => result.studentId === student._id,
                )?.skillresults ?? [],
            })) ?? [],
        }}
        isLoading={
          isFetching || isFetchingStudents || isFetchingClassWeakestSkills
        }
        classNames={'table-fixed'}
      />
      <Button
        action={handleAssignAllSelected}
        disabled={isLoading}
        bgColor={'bg-blue-300'}
      >
        {isLoading ? 'Assigning...' : 'Assign All Selected'}
      </Button>
    </div>
  );
};

const NoOptionsMessage = (properties: any) => {
  return (
    <components.NoOptionsMessage {...properties}>
      <span>No skills available for Targeted Practice</span>
    </components.NoOptionsMessage>
  );
};

export default TargetedPracticeAssignTable;

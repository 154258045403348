import Vimeo, { ProgressEvent } from '@u-wave/react-vimeo';

import { store, useAppSelector } from '../stores/AppStore';
import {
  ROLES,
  getHelpVideos,
  thinkerMentorWelcomeVideoMinimumWatchPercentage,
} from '../constants';
import {
  selectWelcomeVideoWatchedPct,
  setWelcomeVideoWatchedPct,
  setShowWelcomeVideoPopup,
} from '../features/global/globalSlice';
import WelcomeVideoPopup from './WelcomeVideoPopup';
import { debounce } from 'lodash';

const onVideoProgress = debounce(
  (event: ProgressEvent) => {
    const percentage = Math.round(event.percent * 100);
    store.dispatch(setWelcomeVideoWatchedPct(percentage));
    if (percentage >= thinkerMentorWelcomeVideoMinimumWatchPercentage) {
      // NOTE: video won't come up again if they watched the minimum % of it
      store.dispatch(setShowWelcomeVideoPopup(false));
    }
  },
  1000,
  { trailing: true },
);

const Welcome = () => {
  const user = useAppSelector((state) => state.user.user);
  const welcomeVideoWatchedPct = useAppSelector(selectWelcomeVideoWatchedPct);
  const didWatchWelcomeVideo =
    (welcomeVideoWatchedPct ?? 0) >=
    thinkerMentorWelcomeVideoMinimumWatchPercentage;

  const welcomeText = user?.firstName
    ? `Welcome, ${user.firstName}!`
    : 'Welcome!';
  return (
    <div
      data-testid="welcome-video"
      className="
        grid grid-cols-1 md:grid-cols-2
        gap-2 md:gap-4 p-4 md:p-6
        w-full h-full items-center
        bg-gray-100 rounded-xl
      "
    >
      <div className="flex flex-col md:flex-row gap-2 md:gap-4 w-full items-center">
        <img
          src="/images/icons/lightbulb.svg"
          alt="lightbulb"
          className="px-2 md:px-4 h-20 md:h-24 lg:h-32"
        />
        <div className="flex flex-col gap-2 text-center md:text-start">
          <h1 className="text-2xl font-bold">{welcomeText}</h1>
          <p className="text-gray-500">
            {didWatchWelcomeVideo
              ? `You can re-watch this video anytime to ensure you get the most out of the Fill the
            Math GAPS program.`
              : `Watch this video to discover how to get the most out of the Fill the
            Math GAPS program.`}
          </p>
          <p className="hidden md:block font-medium">
            Have more questions?&nbsp;
            <a
              href="mailto:help@thinktankprep.com?subject=General Inquiry"
              target="_blank"
              className="text-tttDefault hover:underline"
              rel="noreferrer"
            >
              Contact us.
            </a>
          </p>
        </div>
      </div>
      <div className="w-full md:w-[80%] lg:w-[90%] xl:w-[70%] justify-self-end">
        <Vimeo
          video={getHelpVideos([ROLES.mentor])[0]}
          responsive
          controls={true}
          speed={true}
          onProgress={onVideoProgress}
        />
      </div>
      <p className="block md:hidden text-center">
        Have more questions?&nbsp;
        <a
          href="mailto:help@thinktankprep.com?subject=General Inquiry"
          target="_blank"
          className="text-tttDefault hover:underline"
          rel="noreferrer"
        >
          Contact us.
        </a>
      </p>
      <WelcomeVideoPopup />
    </div>
  );
};

export default Welcome;

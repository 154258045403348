import { FC } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { PasswordChangeParameters } from '../types';
import { useChangePasswordMutation } from '../services/apiSlice';
import { validatePasswordChangeInput } from '../util';

import Input from '../components/Input';
import Form from '../components/Form';
import { Loading } from '../components/Loading';

const PasswordChange: FC = () => {
  const navigate = useNavigate();
  const [changePassword, { isLoading }] = useChangePasswordMutation();

  const onSubmit = async (data: PasswordChangeParameters) => {
    const validateResult = validatePasswordChangeInput(data);
    if (validateResult !== true) {
      toast.error(validateResult);
      return;
    }
    try {
      await changePassword(data).unwrap();
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          request: 'changePassword',
        },
      });
      toast.error(
        'Some unexpected error happened while trying to change your password. Please try again later!',
      );
      return;
    }
    toast.success('Password successfully updated');
    navigate('/login');
  };

  return (
    <div className="grid grid-cols-6">
      <div className="grid sm:col-start-3 col-span-6 sm:col-span-2 justify-self-center self-center place-self-center overflow-hidden py-10 px-12 rounded-lg shadow-xl border-tttDefault border-[1px]">
        <div className="box mb-3">
          <h1 className="text-xl font-bold text-center">
            Change your password
          </h1>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center h-[160px]">
            <Loading />
          </div>
        ) : (
          <>
            <div>
              For your security please choose a complex password that only you
              can memorize or store in a safe place.
            </div>
            <div className="box">
              <Form onSubmit={onSubmit}>
                <div className="font-medium">Current password</div>
                <Input type="password" name="currentPassword" />
                <div className="font-medium">New password</div>
                <Input type="password" name="newPassword" />
                <div className="font-medium">Confirm new password</div>
                <Input type="password" name="confirm" />
                <input
                  type="submit"
                  value="Change password"
                  className="justify-self-center max-w-[90%] mt-6 py-2.5 px-2 bg-tttDefault text-white font-bold text-xs uppercase rounded shadow-md hover:bg-blue-600 hover:shadow-lg cursor-pointer"
                />
              </Form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PasswordChange;

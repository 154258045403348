import { Event } from '@sentry/react';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';
import toast from 'react-hot-toast';

import { apiService } from './services/APIService';
import { topPercentageThreshold, midPercentageThreshold } from './constants';
import {
  PasswordChangeParameters,
  PasswordResetParameters,
  ExtendedTrack,
  CompletedQuiz,
  CurrentQuiz,
  QuizWithInfo,
  MentorAssignment,
  Quiz,
} from './types';

interface DownloadWorkbookParameters {
  quizId: string;
  url?: string;
  isCheckpointWorkbook?: boolean;
}

export const initGA = () => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }
  if (!process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    console.error('REACT_APP_GOOGLE_ANALYTICS_ID is missing');
    return;
  }
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
};

export const setGAtrackingEnabled = async (enable: boolean) => {
  const GAtrackingResponse = await apiService.post('config/GAtracking', {
    enable: enable ? 1 : 0,
  });
  if (GAtrackingResponse?.data) {
    return GAtrackingResponse.data;
  }
};

export const getGAtrackingEnabled = async () => {
  const GAtrackingResponse = await apiService.get('config/GAtracking');
  if (GAtrackingResponse?.data) {
    return GAtrackingResponse.data.GAtrackingEnabled;
  }
};

export const getColor = (percentage: number) => {
  percentage = Math.round(percentage);
  return percentage >= topPercentageThreshold
    ? 'green'
    : percentage < topPercentageThreshold &&
      percentage >= midPercentageThreshold
    ? 'yellow'
    : 'orange';
};

export const getColorHex = (percentage: number) => {
  percentage = Math.round(percentage);
  return percentage >= topPercentageThreshold
    ? '#10B981'
    : percentage < topPercentageThreshold &&
      percentage >= midPercentageThreshold
    ? '#FFCD00'
    : '#E47D00';
};

export const validatePasswordResetInput = (
  passwordResetParameters: PasswordResetParameters,
) => {
  const { password, confirm } = passwordResetParameters;

  if (password.length < 8) {
    return 'Password must be at least 8 characters long';
  }

  if (!password || !confirm) {
    return 'You need to provide the password in both fields';
  }

  if (password !== confirm) {
    return 'Password and confirm must match';
  }

  return true;
};

export const validatePasswordChangeInput = (
  passwordChangeParameters: PasswordChangeParameters,
) => {
  const { currentPassword, newPassword, confirm } = passwordChangeParameters;

  if (!currentPassword || currentPassword.length < 4) {
    return 'You need to provide your current password';
  }

  if (newPassword.length < 8) {
    return 'Password must be at least 8 characters long';
  }

  if (!newPassword || !confirm) {
    return 'You need to provide the password in both fields';
  }

  if (newPassword !== confirm) {
    return 'Password and confirm must match';
  }

  return true;
};

export const getTextColor = (number_: number) => {
  number_ = Math.round(number_);
  return number_ >= topPercentageThreshold
    ? 'text-green'
    : number_ < topPercentageThreshold && number_ >= midPercentageThreshold
    ? 'text-yellow'
    : 'text-orange';
};

export const getQuiz = ({
  quizId,
  track,
  isStudent,
}: {
  quizId: string;
  track?: ExtendedTrack;
  isStudent: boolean;
}) => {
  if (!track) {
    return;
  }

  const { completedAssignments, currentAssignments, missedAssignments } = track;
  const currentQuiz = currentAssignments.find((quiz) => quiz.id === quizId);
  if (currentQuiz) {
    return currentQuiz;
  }

  if (isStudent) {
    const missedQuiz = missedAssignments?.find((quiz) => quiz.id === quizId);
    if (missedQuiz) {
      return missedQuiz;
    }

    const completedQuiz = (completedAssignments as CompletedQuiz[])?.find(
      (quiz) => quiz.id === quizId,
    );
    if (completedQuiz) {
      return completedQuiz;
    }
  } else {
    const { upcomingAssignments, pastAssignments } = track as ExtendedTrack;
    const pastQuiz = pastAssignments?.find((quiz) => quiz.id === quizId);
    if (pastQuiz) {
      return pastQuiz;
    }

    const upcomingQuiz = upcomingAssignments?.find(
      (quiz) => quiz.id === quizId,
    );
    if (upcomingQuiz) {
      return upcomingQuiz;
    }
  }
};

const pad = (n: number) => (n < 10 ? `0${n}` : n);

export const formatSeconds = (secs: number) => {
  const hours = Math.floor(secs / 3600);
  const minutes = Math.floor(secs / 60) - hours * 60;
  const seconds = Math.floor(secs - hours * 3600 - minutes * 60);

  return `${pad(minutes)} min ${pad(seconds)} sec`;
};

export const shouldExcludeFromSentry = (event: Event) =>
  event.exception &&
  event.exception.values &&
  event.exception.values[0] &&
  event.exception.values[0].value &&
  (event.exception.values[0].value.includes(
    'Password must be at least 8 characters long',
  ) ||
    event.exception.values[0].value.includes(
      'You need to provide the password in both fields',
    ) ||
    event.exception.values[0].value.includes(
      'Password and confirm must match',
    ));

export async function downloadWorkbook({
  quizId,
  url,
  isCheckpointWorkbook,
}: DownloadWorkbookParameters) {
  if (!url) {
    Sentry.captureException(
      new Error('Cannot download workbook without a URL'),
      {
        tags: {
          quizId,
          url,
        },
      },
    );
    return;
  }
  toast('PDF downloaded started. Please wait.');
  try {
    const response = await fetch(url);

    if (!response.ok) {
      const errorData = await response.json();
      toast.error(`Failed to download workbook: ${errorData.message}`);
      return;
    }

    const blob = await response.blob();

    if (blob.type === 'application/pdf') {
      const contentDisposition = response.headers.get('content-disposition');
      const filenameMatch =
        contentDisposition && contentDisposition.match(/filename="(.+?)"/);
      const filename = filenameMatch ? filenameMatch[1] : 'workbook.pdf';
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = filename;
      document.body.append(downloadLink);
      downloadLink.click();
      downloadLink.remove();
    } else {
      console.error('Invalid content type:', blob.type);
      toast.error('Failed to download workbook. Please try again.');
      return;
    }
  } catch {
    Sentry.captureException(
      new Error(`Failed to download workbook of ${quizId}`),
      {
        tags: {
          quizId,
          url,
        },
      },
    );

    toast.error('Failed to download workbook. Please try again.');
    return;
  }
  const quizNumber = Number(quizId.replace(/Quiz (\d+)/, '$1')); // FIXME: real data instead of assumption-based math
  const successText = isCheckpointWorkbook ? (
    <div>
      PDF downloaded finished. This Checkpoint Quiz is used to re-test Quizzes{' '}
      {quizNumber - 3}-{quizNumber}. This should be assigned{' '}
      <span className="font-bold">after</span> the {quizId} due date.
    </div>
  ) : (
    'PDF downloaded finished.'
  );
  toast.success(successText, {
    duration: isCheckpointWorkbook ? 8000 : 3000,
  });
}

export const getCheckpointTestInfo = (
  quiz:
    | Quiz
    | CurrentQuiz
    | QuizWithInfo
    | CompletedQuiz
    | MentorAssignment
    | undefined,
) => {
  if (!quiz) {
    return;
  }
  const quizNumber = Number(quiz.id.replace(/Quiz (\d+)/, '$1')); // FIXME: real data instead of assumption-based math
  const checkpointQuizNumber = quizNumber - (quizNumber % 4) + 4;
  return quiz.checkpointTestUrl
    ? `Covers topics from Quiz ${Math.max(1, quizNumber - 3)}-${quizNumber}`
    : `Quizzes ${Math.max(
        1,
        checkpointQuizNumber - 3,
      )}-${checkpointQuizNumber} can be re-tested with a checkpoint test under Quiz ${checkpointQuizNumber}.`;
};

export const transformToPercentage = (number: number): string => {
  const percentage = number * 100;
  return `${percentage.toFixed(0)}%`;
};

export const formatPhone = (phone: string) => {
  const rawPhoneNumber = phone.replace(/\D/g, '');
  let formattedPhoneNumber = '';

  if (rawPhoneNumber.length < 4) {
    formattedPhoneNumber = rawPhoneNumber;
  } else if (rawPhoneNumber.length < 7) {
    formattedPhoneNumber = `(${rawPhoneNumber.slice(
      0,
      3,
    )}) ${rawPhoneNumber.slice(3)}`;
  } else {
    formattedPhoneNumber = `(${rawPhoneNumber.slice(
      0,
      3,
    )}) ${rawPhoneNumber.slice(3, 6)}-${rawPhoneNumber.slice(6, 10)}`;
  }

  return formattedPhoneNumber;
};

export const unformatPhone = (phone: string) => phone.replace(/\D/g, '');

import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import toast from 'react-hot-toast';

import { history, useAppSelector } from '../stores/AppStore';
import {
  selectIsCompleted,
  selectIsPractice,
} from '../features/quiz/quizSlice';

import { selectAnyReviewRequestPending } from '../services/apiSlice';

const useBlockReviewNavigation = () => {
  const isPractice = useAppSelector(selectIsPractice);
  const isAnyRequestsPending = useAppSelector(selectAnyReviewRequestPending);
  const isCompleted = useAppSelector(selectIsCompleted);
  const currentQuiz = useAppSelector((state) => state.quiz.selectedQuiz);

  useEffect(() => {
    if ((!isPractice || isAnyRequestsPending) && currentQuiz) {
      const unblock = history.block(({ location }) => {
        if (isCompleted) {
          unblock();
          history.push(location.pathname);
          return;
        }
        if (
          /password-reset/.test(location.pathname) ||
          /help/.test(location.pathname)
        ) {
          unblock();
          history.push(location.pathname);
          return;
        } else if (
          !/review/.test(location.pathname) &&
          !/login/.test(location.pathname)
        ) {
          Sentry.captureException(new Error('Cannot leave mandatory review'), {
            tags: {
              quizId: currentQuiz.id,
            },
          });
          toast.error(
            'You are unable to proceed until your current review is completed.',
          );
          return;
        }
        unblock();
        history.push(location.pathname);
      });
      return unblock;
    }
  }, [history, isAnyRequestsPending, currentQuiz, isCompleted]);
};

export default useBlockReviewNavigation;

import { Controller, useForm } from 'react-hook-form';

import {
  SignUpState,
  selectMentor,
  selectNumberOfLicenses,
  setMentor,
} from '../../features/signUp/signUpSlice';
import { store, useAppSelector } from '../../stores/AppStore';
import {
  signupDevices,
  signupGoals,
  signupReferralTypes,
} from '../../constants';
import ProgressBar from './ProgressBar';
import Option from './Option';

interface MentorDetailsProperties {
  onNextStep: () => void;
  onPreviousStep: () => void;
}

const SignUpQuestionnaire = ({
  onNextStep,
  onPreviousStep,
}: MentorDetailsProperties) => {
  const mentor = useAppSelector(selectMentor);
  const numberOfLicenses = useAppSelector(selectNumberOfLicenses);
  const { control, getValues, handleSubmit } = useForm<SignUpState['mentor']>({
    mode: 'all',
    defaultValues: mentor,
  });

  const handleContinue = async () => {
    store.dispatch(setMentor(getValues()));
    onNextStep();
  };

  return (
    <form
      data-testid="mentor-details"
      onSubmit={handleSubmit(handleContinue)}
      className="flex flex-col gap-2 md:gap-4 w-full h-full"
    >
      <div className="flex flex-col gap-2 md:gap-4 w-full md:w-[70%] lg:w-[60%] mx-auto">
        <h1 className="text-3xl md:text-4xl font-medium w-full">
          Optional Questions
        </h1>
        <p className="text-gray-500 text-sm md:text-base">
          Answering these questions will help us better serve you, but there's
          no pressure — your experience with Fill the Math GAPS will be great
          either way.
        </p>
        <div className="flex flex-col gap-2 md:gap-4 w-full pb-4 border-b-2 border-gray-200">
          <label
            htmlFor="numberOfChildren"
            className="text-sm md:text-base font-medium "
          >
            Number of children in your household
          </label>
          <Controller
            render={({ field }) => (
              <div className="flex gap-2">
                {Array.from({ length: 5 }, (_, index) => (
                  <Option
                    key={`children-${index + 1}`}
                    dataTestId={`children-${index + 1}`}
                    value={index + 1}
                    onClick={() =>
                      field.onChange(String(index === 4 ? '5+' : index + 1))
                    }
                    isSelected={
                      field.value === String(index === 4 ? '5+' : index + 1)
                    }
                    isRadio={false}
                  >
                    <p className="mx-1.5 md:-mx-0.5 md:-my-2">
                      {index === 4 ? '5+' : index + 1}
                    </p>
                  </Option>
                ))}
              </div>
            )}
            name="numberOfChildren"
            control={control}
          />
        </div>
        <div className="flex flex-col gap-2 md:gap-4 w-full pb-4 border-b-2 border-gray-200">
          <label htmlFor="goal" className="text-sm md:text-base font-medium">
            What is your primary goal for joining our program?
          </label>
          <Controller
            render={({ field }) => (
              <div className="flex gap-2 flex-wrap">
                {signupGoals.map((goal, index) => (
                  <Option
                    key={`goal-${index + 1}`}
                    dataTestId={`goal-${index + 1}`}
                    value={index + 1}
                    onClick={() => field.onChange(goal)}
                    isSelected={field.value === goal}
                    isRadio={false}
                  >
                    <p className="mx-1.5 md:-mx-0.5 md:-my-2 whitespace-nowrap">
                      {goal}
                    </p>
                  </Option>
                ))}
              </div>
            )}
            name="goal"
            control={control}
          />
        </div>
        <div className="flex flex-col gap-2 md:gap-4 w-full pb-4 border-b-2 border-gray-200">
          <label
            htmlFor="preferredDevice"
            className="text-sm md:text-base font-medium"
          >
            What device will your{' '}
            {numberOfLicenses === 1 ? 'Thinker' : 'Thinkers'} primarily use to
            access our services?
          </label>
          <Controller
            render={({ field }) => (
              <div className="flex gap-2 flex-wrap">
                {signupDevices.map((device, index) => (
                  <Option
                    key={`device-${index + 1}`}
                    dataTestId={`device-${index + 1}`}
                    value={index + 1}
                    onClick={() => field.onChange(device)}
                    isSelected={field.value === device}
                    isRadio={false}
                  >
                    <p className="mx-1.5 md:-mx-0.5 md:-my-2 whitespace-nowrap">
                      {device}
                    </p>
                  </Option>
                ))}
              </div>
            )}
            name="preferredDevice"
            control={control}
          />
        </div>
        <div className="flex flex-col gap-2 md:gap-4 w-full">
          <label
            htmlFor="referral"
            className="text-sm md:text-base font-medium"
          >
            Who referred you to our service?
          </label>
          <Controller
            render={({ field }) => (
              <div className="flex gap-2 flex-wrap">
                {signupReferralTypes.map((referral, index) => (
                  <Option
                    key={`referral-${index + 1}`}
                    dataTestId={`referral-${index + 1}`}
                    value={index + 1}
                    onClick={() => field.onChange(referral)}
                    isSelected={field.value === referral}
                    isRadio={false}
                  >
                    <p className="mx-1.5 md:-mx-0.5 md:-my-2 whitespace-nowrap">
                      {referral}
                    </p>
                  </Option>
                ))}
              </div>
            )}
            name="referral"
            control={control}
          />
        </div>
      </div>

      <ProgressBar />

      {/* Action Buttons */}
      <div className="flex gap-2 pt-4 md:pt-6 w-full justify-between items-center">
        <button
          data-testid={`back-button`}
          type="button"
          className="
            px-10 py-2 w-max rounded-full border font-medium
            transition-all duration-300 ease-in-out
            hover:shadow hover:outline hover:outline-tttDefault hover:outline-[1px]
            disabled:opacity-50 disabled:cursor-not-allowed
          "
          onClick={onPreviousStep}
        >
          Back
        </button>
        <button
          data-testid={`continue-button`}
          type="submit"
          className="
            px-10 py-2 w-max rounded-full
            font-medium text-white bg-tttDefault
            transition-all duration-300 ease-in-out
            hover:shadow hover:bg-opacity-90
            disabled:opacity-50 disabled:cursor-not-allowed
          "
        >
          Finish
        </button>
      </div>
    </form>
  );
};

export default SignUpQuestionnaire;

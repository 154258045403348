import { useEffect, useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  FilterFn,
  flexRender,
  PaginationState,
  SortingState,
  OnChangeFn,
  ColumnDef,
  RowData,
} from '@tanstack/react-table';
import { rankItem } from '@tanstack/match-sorter-utils';

import { LoadingBlock } from './Loading';
import { TableDataResponse } from '../types';

export type { PaginationState, SortingState } from '@tanstack/react-table';

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    className: string;
  }
}

interface Properties<T> {
  columns: ColumnDef<any, any>[];
  data?: TableDataResponse<T>;
  isLoading?: boolean;
  onSelect?: (item: T) => void;
  globalFilter?: string;
  onGlobalFilterChange?: OnChangeFn<string>;
  pagination?: PaginationState;
  onPaginationChange?: OnChangeFn<PaginationState>;
  sorting?: SortingState;
  onSortingChange?: OnChangeFn<SortingState>;
  classNames?: string;
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({ itemRank });

  return itemRank.passed;
};

export default function Table<T>({
  columns,
  data,
  isLoading,
  onSelect,
  globalFilter,
  onGlobalFilterChange,
  pagination,
  onPaginationChange,
  sorting,
  onSortingChange,
  classNames,
}: Properties<T>) {
  const table = useReactTable({
    columns,
    data: data ? data.items : [],
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      globalFilter,
      pagination,
      sorting,
    },
    onGlobalFilterChange,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: !!pagination,
    onPaginationChange,
    pageCount: pagination
      ? data?.total
        ? Math.ceil(data.total / pagination.pageSize)
        : undefined
      : undefined,
    onSortingChange,
  });

  return (
    <>
      {typeof globalFilter === 'string' &&
        typeof onGlobalFilterChange === 'function' && (
          <>
            <div className="grid sm:justify-items-end pb-4">
              <DebouncedInput
                value={globalFilter ?? ''}
                onChange={(value) => {
                  if (String(value).length === 1) {
                    return; // Avoid searching 1 character long search expression, leave everything unchanged. 0 is needed for clearing
                  }
                  onGlobalFilterChange(String(value));
                  if (
                    !!pagination &&
                    typeof onPaginationChange === 'function'
                  ) {
                    onPaginationChange({
                      pageIndex: 0,
                      pageSize: pagination.pageSize,
                    });
                  }
                }}
                className="p-2 xl:text-lg shadow border border-block rounded-lg"
                placeholder="Search..."
              />
            </div>
            <div className="h-2" />
          </>
        )}
      <table
        cellPadding={2}
        className={`w-full border-separate border-spacing-0 ${classNames}`}
      >
        <thead className="hidden md:table-header-group bg-tttDefault text-white text-left">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    className={`bg-tttDefault ${
                      header.column.columnDef.meta?.className ?? ''
                    }`}
                    key={header.id}
                    colSpan={header.colSpan}
                  >
                    {header.isPlaceholder ? undefined : (
                      <div
                        {...{
                          className: `px-3 py-2 text-sm xl:text-base ${
                            header.column.getCanSort() ? 'cursor-pointer' : ''
                          }`,
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: ' 🔼',
                          desc: ' 🔽',
                        }[header.column.getIsSorted() as string] ?? undefined}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {isLoading ? (
            <tr className="w-full justify-items-center text-center align-middle">
              <td colSpan={table.getAllColumns().length}>
                <LoadingBlock />
              </td>
            </tr>
          ) : data && data.total > 0 ? (
            table.getCoreRowModel().rows.map((row, index) => {
              return (
                <tr
                  key={row.id}
                  className={`${
                    typeof onSelect === 'function' ? 'cursor-pointer' : ''
                  } py-2 grid grid-flow-row md:py-0 md:table-row ${
                    index % 2 === 0 ? 'bg-white' : 'bg-blue-50'
                  }`}
                  onClick={() => {
                    if (typeof onSelect === 'function') {
                      onSelect(row.original);
                    }
                  }}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className={`px-4 md:px-4 md:py-2 ${
                          cell.column.id === '_id'
                            ? 'text-xs overflow-hidden min-w-16 md:max-w-[150px] xl:max-w-[200px]'
                            : cell.column.id === 'email'
                            ? 'text-sm md:text-xs lg:text-sm xl:text-base md:md:max-w-[150px] md:overflow-hidden'
                            : 'text-sm xl:text-base'
                        }
                        ${index % 2 === 0 ? 'bg-white' : 'bg-blue-50'}
                        ${cell.column.columnDef.meta?.className ?? ''}`}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <tr className="w-full justify-items-center text-center align-middle">
              <td
                colSpan={table.getAllColumns().length}
                className="py-4 text-gray-500"
              >
                No results
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {pagination && (
        <>
          <div className="h-2" />
          <div className="flex items-center gap-2 text-sm lg:text-base'">
            <button
              className="border rounded p-2"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {'<'}
            </button>
            <button
              className="border rounded p-2"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {'>'}
            </button>
            <span className="flex items-center gap-1 min-w-max">
              <div>Page</div>
              <div className="flex w-full font-bold">
                {table.getState().pagination.pageIndex + 1} of{' '}
                {table.getPageCount()}
              </div>
            </span>
            <select
              className="col-span-2 md:col-span-1"
              value={table.getState().pagination.pageSize}
              onChange={(event) => {
                table.setPageSize(Number(event.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
            {data?.total && (
              <div className="w-full text-end">{data.total} results</div>
            )}
          </div>
        </>
      )}
    </>
  );
}

export function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...properties
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);
    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      {...properties}
      value={value}
      onChange={(event) => setValue(event.target.value)}
    />
  );
}

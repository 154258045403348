/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable unicorn/no-null */
import { useState, FC } from 'react';
import Select from 'react-select';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';

import { TrackDocument } from '../types';

import {
  useGetOwnerProblemIdsQuery,
  useGetOwnerProblemsQuery,
  useGetTracksQuery,
} from '../services/apiSlice';
import { Loading, LoadingBlock } from '../components/Loading';
import ProblemContent from '../components/ProblemContent';

const ProblemPreview: FC = () => {
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState<{
    track?: TrackDocument | null;
    quiz?: string | null;
    problemId?: string | null;
  }>({});
  const { data: tracks, isLoading: isLoadingTracks } = useGetTracksQuery();
  const { data: problemIds, isLoading: isLoadingProblemIds } =
    useGetOwnerProblemIdsQuery({});
  const { data, isLoading, isFetching } = useGetOwnerProblemsQuery(
    {
      trackId: filter.track?._id,
      quizId: filter.quiz,
      problemId: filter.problemId,
      page: currentPage,
      pageSize,
    },
    { refetchOnMountOrArgChange: true },
  );
  const quizFilterOptions = filter.track
    ? tracks
        ?.find((track) => track._id === filter.track?._id)
        ?.quizzes?.map((quiz) => ({ label: quiz.id, value: quiz.id }))
    : [];
  const pageSizeOptions = [
    { label: '3 / page', value: 3 },
    { label: '5 / page', value: 5 },
    { label: '10 / page', value: 10 },
  ];

  return (
    <div className="mx-auto md:w-[90%] xl:w-[80%]">
      <div
        className="grid grid-cols-1 gap-2 px-2 md:px-4 pb-6 mb-12 pt-3 shadow-2xl rounded-xl border-tttDefault border-[1px] transition-all duration-1000 relative"
        data-testid="header-section"
      >
        <div className="grid justify-self-center col-span-3 max-w-[90%] justify-items-center items-center uppercase text-white font-bold text-2xl p-3 -mt-7 mb-3 shadow-md rounded-xl bg-tttDefault">
          Problem Content Preview
        </div>
        <div className="absolute top-0 right-0 mt-3 mr-3">
          {isFetching && <Loading />}
        </div>
        <div className="flex p-2">
          <div className="flex flex-1">
            <div data-testid={'track-selector'} className="max-w-[360px] ml-2">
              <Select
                value={
                  filter.track
                    ? { value: filter.track, label: filter.track.track }
                    : null
                }
                onChange={(selectedTrack) => {
                  setFilter(() => ({
                    track: selectedTrack?.value,
                  }));
                }}
                placeholder={'Click to filter by Track'}
                options={tracks?.map((track) => ({
                  label: track.track,
                  value: track,
                }))}
                isLoading={isLoadingTracks}
                isClearable
              />
            </div>
            <div className="min-w-[120px] max-w-[200px] ml-2">
              <Select
                value={
                  filter.quiz
                    ? { value: filter.quiz, label: filter.quiz }
                    : null
                }
                onChange={(selectedQuiz) => {
                  setFilter((previousState) => ({
                    ...previousState,
                    quiz: selectedQuiz?.label,
                  }));
                }}
                placeholder={'Filter by Quiz'}
                options={quizFilterOptions}
                isDisabled={!filter.track}
                isClearable
              />
            </div>
          </div>
          <div className="max-w-[200px]">
            <Select
              value={
                filter.problemId
                  ? { value: filter.problemId, label: filter.problemId }
                  : null
              }
              onChange={(selectedProblem) => {
                setFilter(() => ({
                  problemId: selectedProblem?.label,
                }));
              }}
              isLoading={isLoadingProblemIds}
              options={problemIds?.map((pId) => ({
                label: pId.id,
                value: pId.id,
              }))}
              placeholder={'Search Problem'}
              isClearable
            />
          </div>
        </div>
      </div>
      {!isLoading && !!data ? (
        <div className="grid w-full gap-8 place-items-center">
          {data.problems.length === 0 ? (
            <div className="w-full text-center text-slate-600">
              No problems available.
            </div>
          ) : (
            data.problems?.map((problem, index) => (
              <ProblemContent key={index} problem={problem} />
            ))
          )}
          <div className="flex justify-center items-center mt-4">
            <Pagination
              current={currentPage}
              onChange={(page) => {
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                });
                setCurrentPage(page);
              }}
              total={data.totalPages * pageSize}
              pageSize={pageSize}
            />
            <Select
              className="ml-8"
              defaultValue={pageSizeOptions[1]} // default 5
              value={pageSizeOptions.find(
                (option) => option.value === pageSize,
              )}
              onChange={(selectedPageSize) => {
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                });
                setPageSize(selectedPageSize?.value || 5);
                setCurrentPage(1);
              }}
              options={pageSizeOptions}
              isClearable={false}
              isSearchable={false}
            />
          </div>
        </div>
      ) : (
        <LoadingBlock />
      )}
    </div>
  );
};

export default ProblemPreview;

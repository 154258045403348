/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable unicorn/no-null */
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import { ChevronLeftIcon, PlusIcon } from '@heroicons/react/outline';
import * as Sentry from '@sentry/react';
import toast from 'react-hot-toast';

import {
  useCreateTargetedPracticeProblemMutation,
  useGetSkillsQuery,
  useGetTargetedPracticeProblemsQuery,
} from '../../services/apiSlice';
import { Subject } from '../../constants';
import { TargetedPracticeProblemDocument } from '../../types';

import { Loading, LoadingBlock } from '../../components/Loading';
import ProblemEditorModal from '../../components/ProblemEditorModal';
import ProblemContent from '../../components/ProblemContent';

const TargetedPracticeProblems = () => {
  const navigate = useNavigate();
  const [searchParameters, setSearchParameters] = useSearchParams();
  const { state } = useLocation();
  const skillParameter = searchParameters.get('skill');
  const headerReference = useRef<HTMLDivElement>(null);
  const [showAddProblemModal, setShowAddProblemModal] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [skillFilter, setSkillFilter] = useState<
    | {
        _id: string;
        skill: string;
        subject: string;
        isAvailableForTargetedPractice?: boolean;
      }
    | null
    | undefined
  >(state?.skill ?? null);
  const skillsQueries = Object.values(Subject).map((subject) =>
    useGetSkillsQuery({ subject: subject as Subject }),
  );
  const isFetchingSkillsData = skillsQueries.some((query) => query.isFetching);
  const skillsData = (
    skillsQueries.flatMap((query) => query.data) as {
      _id: string;
      skill: string;
      subject: string;
      isAvailableForTargetedPractice?: boolean;
    }[]
  ).filter(Boolean);
  const {
    data,
    isLoading,
    isFetching: isFetchingProblems,
    refetch,
  } = useGetTargetedPracticeProblemsQuery(
    {
      skillId: skillFilter?._id,
      page: currentPage,
      pageSize,
    },
    { refetchOnMountOrArgChange: true },
  );
  const [createProblem] = useCreateTargetedPracticeProblemMutation();

  const pageSizeOptions = [
    { label: '3 / page', value: 3 },
    { label: '5 / page', value: 5 },
    { label: '10 / page', value: 10 },
  ];

  const handleCreateProblem = async (
    data: Omit<
      TargetedPracticeProblemDocument,
      'id' | 'difficulty' | 'subject' | 'additionalImages'
    >,
  ) => {
    try {
      await createProblem(data).unwrap();
    } catch (error) {
      Sentry.captureException(error, {
        tags: { request: 'createTargetedPracticeProblem' },
      });
      toast.error(
        'Some unexpected error happened while trying to create the problem. Please try again later!',
      );
      throw error;
    }
    toast.success('Problem created successfully!');
  };

  useEffect(() => {
    if (!skillParameter || skillParameter === skillFilter?.skill) {
      return;
    }

    if (skillsData && skillsData.length > 0) {
      const skill = skillsData.find((s) => s.skill === skillParameter);
      setSkillFilter(skill);
    } else {
      setSkillFilter({ _id: '', skill: skillParameter, subject: '' });
    }
  }, [skillParameter]);

  useEffect(() => {
    if (skillsData && skillsData.length > 0) {
      const skill = skillsData.find((s) => s.skill === skillParameter);
      setSkillFilter(skill);
    }
  }, [isFetchingSkillsData]);

  return (
    <div className="mx-auto md:w-[90%] xl:w-[80%]">
      <div
        ref={headerReference}
        className="flex flex-col px-2 md:px-4 pb-6 mb-12 pt-3 shadow-lg rounded-xl border-tttDefault border-[1px] transition-all duration-1000 relative"
        data-testid="header-section"
      >
        <div className="grid self-center col-span-3 max-w-[90%] uppercase text-white text-center font-bold text-lg md:text-xl xl:text-2xl p-2 md:p-3 -mt-7 mb-3 shadow-md rounded-xl bg-tttDefault">
          Targeted Practice Problem Editor
        </div>
        <div className="absolute top-0 right-0 mt-3 mr-3">
          {(isFetchingSkillsData || isFetchingProblems) && <Loading />}
        </div>
        <div className="flex justify-between w-full">
          <button
            data-testid={`back-button`}
            className="
              px-2 py-1 w-max
              flex gap-1 items-center
              text-tttDefault bg-blue-200 rounded-xl
              hover:shadow-md transition-all duration-300 ease-in-out hover:outline hover:outline-tttDefault hover:outline-[1px]
            "
            onClick={() => navigate('/targeted-practice/manage')}
          >
            <ChevronLeftIcon className="h-5 w-5 self-center" />
            Back
          </button>
          <button
            data-testid={`add-problem-button`}
            className="
              px-2 py-1 w-max
              flex gap-2 items-center
              text-tttDefault bg-blue-200 rounded-xl
              hover:shadow-md transition-all duration-300 ease-in-out hover:outline hover:outline-tttDefault hover:outline-[1px]
            "
            onClick={() => setShowAddProblemModal(true)}
          >
            Add Problem
            <PlusIcon className="h-5 w-5" />
          </button>
        </div>
        <div className="w-full pt-6">
          <Select
            data-testid={'skill-selector'}
            value={
              skillFilter
                ? { label: skillFilter.skill, value: skillFilter }
                : null
            }
            onChange={(selectedSkill) => {
              setSkillFilter(selectedSkill?.value);
              setSearchParameters(
                selectedSkill ? { skill: selectedSkill.label } : undefined,
              );
            }}
            placeholder={'Click to filter by skill'}
            options={
              skillsData?.map((s) => ({ label: s.skill, value: s })) ?? []
            }
            isLoading={isFetchingSkillsData}
            isClearable
          />
        </div>
      </div>
      <div className="grid w-full gap-8 place-items-center">
        <ProblemEditorModal
          isVisible={showAddProblemModal}
          skills={skillsData}
          onClose={() => setShowAddProblemModal(false)}
          onSubmit={async (data) => {
            await handleCreateProblem(data);
            await refetch();
            setShowAddProblemModal(false);
          }}
        />
        {!isLoading && !!data ? (
          <>
            {data.problems.length === 0 ? (
              <div className="w-full text-center text-slate-600">
                No problems available.
              </div>
            ) : (
              data.problems?.map((problem, index) => (
                <ProblemContent key={index} problem={problem} editable />
              ))
            )}
            <div className="flex justify-center items-center mt-4">
              <Pagination
                current={currentPage}
                onChange={(page) => {
                  headerReference?.current?.scrollIntoView({
                    behavior: 'smooth',
                  });
                  setCurrentPage(page);
                }}
                total={data.totalPages * pageSize}
                pageSize={pageSize}
              />
              <Select
                className="ml-8"
                defaultValue={pageSizeOptions[1]} // default 5
                value={pageSizeOptions.find(
                  (option) => option.value === pageSize,
                )}
                onChange={(selectedPageSize) => {
                  headerReference?.current?.scrollIntoView({
                    behavior: 'smooth',
                  });
                  setPageSize(selectedPageSize?.value || 5);
                  setCurrentPage(1);
                }}
                options={pageSizeOptions}
                isClearable={false}
                isSearchable={false}
              />
            </div>
          </>
        ) : (
          <LoadingBlock />
        )}
      </div>
    </div>
  );
};

export default TargetedPracticeProblems;

import { ReactElement, FC } from 'react';
import { observer } from 'mobx-react-lite';
import { MenuIcon } from '@heroicons/react/outline';
import { useIsFetching } from '@tanstack/react-query';

import { useStores } from '../stores/RootStore';
import { useAppSelector } from '../stores/AppStore';

import UserMenu from './UserMenu';
import { selectAnyRequestPending } from '../services/apiSlice';
import { selectIsAuthenticated } from '../features/user/userSlice';

const BaseHeader: FC = (): ReactElement => {
  const {
    rootStore: { uiStore },
  } = useStores();
  const toggleSidebar = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    uiStore.toggleSidebar.apply(uiStore);
    uiStore.hideUserMenu.apply(uiStore);
  };

  const isFetching = useIsFetching();
  const isAnyRequestPending = useAppSelector(selectAnyRequestPending);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const { host } = window.location;
  const isProduction =
    process.env.NODE_ENV === 'production' && !host.startsWith('staging');
  const environment =
    process.env.NODE_ENV === 'development' ? 'develop' : host.slice(0, 7);

  return (
    <header className="sticky lg:relative top-0 relative grid gap-2 bg-white w-full z-10">
      {!isProduction && (
        <div className="fixed right-[-5px] top-[-5px] z-50 overflow-hidden w-[75px] h-[75px] text-right pointer-events-none">
          <span className="block absolute text-xs font-bold font-sans text-white uppercase text-center leading-6 rotate-45 w-[100px] opacity-90 bg-gradient-to-b from-orange to-amber-800 shadow-md top-[22px] right-[-18px]">
            {environment}
          </span>
        </div>
      )}
      <div className="box">
        <div
          className={`flex w-full bg-tttDefault justify-end p-1 ${
            isFetching || isAnyRequestPending ? 'animate-pulse' : ''
          }`}
        ></div>
      </div>
      <div className="grid grid-flow-col grid-cols-[1fr_auto_1fr] max-h-max items-center p-1 px-2 lg:px-6">
        {!uiStore.apiNetworkError && isAuthenticated ? (
          <MenuIcon
            className="block col-start-1 lg:hidden h-8 w-8 md:h-10 md:w-10 text-tttDefault cursor-pointer"
            onClick={toggleSidebar}
          />
        ) : (
          <div className="col-start-1"></div>
        )}
        <div className="box col-start-2 grid grid-flow-col auto-cols-auto max-w-max items-end font-ferrara">
          <p className="text-3xl lg:text-4xl -mb-1 pr-1">Fill the Math GAPS</p>
          <p className="hidden md:block">by</p>
          <img
            src="/logo.png"
            className="hidden md:block md:pb-1 md:max-h-[40px] lg:max-h-[50px]"
            alt="The Think Tank Logo"
          />
        </div>
        <div className="col-start-3 z-40">
          {!uiStore.apiNetworkError && <UserMenu />}
        </div>
      </div>
    </header>
  );
};

const Header = observer(BaseHeader);
export default Header;

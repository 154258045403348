import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import {
  CompletedQuiz,
  CurrentQuiz,
  StudentTrackWithSchedule,
  QuizWithInfo,
  ExtendedTrack,
} from '../types';
import { getQuiz } from '../util';
import { ActionButton } from '../components/List';
import { quizscheduleTimeZone } from '../constants';

const useUpdatedStudentAssignments = (
  track: StudentTrackWithSchedule | undefined,
) => {
  const localTZ = dayjs.tz.guess();
  const [todoAssignments, setTodoAssignments] = useState<CurrentQuiz[]>([]);
  const [updatedCurrentAssignments, setUpdatedCurrentAssignments] = useState<
    CurrentQuiz[]
  >([]);
  const [updatedCompletedAssignments, setCompletedAssignments] = useState<
    CompletedQuiz[]
  >([]);
  const [updatedMissedAssignments, setUpdatedMissedAssignments] = useState<
    QuizWithInfo[]
  >([]);

  const getActionButtons = (quizId: string) => {
    const quiz = getQuiz({
      quizId,
      track: track as unknown as ExtendedTrack, // FIXME: type hack to make it work temporarily... it's closer to StudentTrackWithSchedule but still not the same
      isStudent: true,
    });
    if (!quiz) {
      return [
        {
          text: 'Practice',
          targetPath: `/quiz/${track?.serialNum}/${quizId}/practice`,
        },
      ];
    }
    const buttons: ActionButton[] = [
      {
        text: 'Practice',
        targetPath: `/quiz/${track?.serialNum}/${quizId}/practice`,
      },
      { text: 'Scores', targetPath: `/assignments/scores/${quizId}` },
    ];
    return buttons;
  };

  useEffect(() => {
    if (!track) {
      return;
    }

    setTodoAssignments(
      track.currentAssignments
        .filter((assignment) => {
          const isCompleted = track.completedAssignments?.some(
            (q) => q.id === assignment.id,
          );

          return isCompleted ? undefined : assignment;
        })
        .filter(Boolean)
        .map((assignment, index) => {
          const schedule = track.currentSchedules.find(
            (schedule) => schedule.quiz === assignment.id,
          );
          const dueDate = dayjs
            .tz(schedule?.dueDate, quizscheduleTimeZone)
            .tz(localTZ);
          const isPreviousCompletedOrMissed =
            track.completedAssignments?.some(
              (q) => q.index === assignment.index - 1,
            ) ||
            track.missedAssignments?.some(
              (q) => q.index === assignment.index - 1,
            );
          return {
            ...assignment,
            dueDate: dayjs
              .tz(dueDate, quizscheduleTimeZone)
              .tz(localTZ)
              .format('MMM DD, YYYY'),
            isOverdue: dayjs().tz(localTZ).isAfter(dueDate),
            canStart: index === 0 || isPreviousCompletedOrMissed,
            trackSerialNum: track.serialNum,
          };
        }),
    );

    setUpdatedCurrentAssignments(
      track.currentAssignments.map((assignment, index) => {
        const isCompleted = track.completedAssignments?.some(
          (q) => q.id === assignment.id,
        );
        const schedule = track.currentSchedules.find(
          (schedule) => schedule.quiz === assignment.id,
        );
        const dueDate = dayjs
          .tz(schedule?.dueDate, quizscheduleTimeZone)
          .tz(localTZ);
        const isPreviousCompletedOrMissed =
          track.completedAssignments?.some(
            (q) => q.index === assignment.index - 1,
          ) ||
          track.missedAssignments?.some(
            (q) => q.index === assignment.index - 1,
          );

        return {
          ...assignment,
          isCompleted,
          dueDate: dayjs().tz(localTZ).to(dueDate),
          canStart: index === 0 || isPreviousCompletedOrMissed,
          trackSerialNum: track.serialNum,
        };
      }),
    );

    const updatedMissedAssignments = track.missedAssignments?.map(
      (missedAssignment) => {
        const schedule = track.pastSchedules?.find(
          (schedule) => schedule.quiz === missedAssignment.id,
        );
        return {
          ...missedAssignment,
          canStart: true,
          info: `(Ended on ${dayjs
            .tz(schedule?.dueDate, quizscheduleTimeZone)
            .tz(localTZ)
            .format('MM/DD/YYYY')})`,
          link: `/quiz/${track.serialNum}/${missedAssignment.id}/practice`,
          trackSerialNum: track.serialNum,
        };
      },
    );

    if (updatedMissedAssignments) {
      setUpdatedMissedAssignments(updatedMissedAssignments);
    }

    const updatedCompletedAssignments = track.completedAssignments?.map(
      (completedAssignment) => {
        return {
          ...completedAssignment,
          date: new Date(completedAssignment.date),
          canStart: true,
          actionButtons: getActionButtons(completedAssignment.id),
          link: `/quiz/${track.serialNum}/${completedAssignment.id}/practice`,
          trackSerialNum: track.serialNum,
        };
      },
    );

    if (updatedCompletedAssignments) {
      setCompletedAssignments(updatedCompletedAssignments);
    }
  }, [track]);

  return {
    currentAssignments: updatedCurrentAssignments,
    completedAssignments: updatedCompletedAssignments,
    missedAssignments: updatedMissedAssignments,
    todoAssignments: todoAssignments,
  };
};

export default useUpdatedStudentAssignments;

import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Vimeo from '@u-wave/react-vimeo';

import { getHelpVideos } from '../constants';
import { useAppSelector } from '../stores/AppStore';

const BaseHelp: FC = () => {
  const { user } = useAppSelector((state) => state.user);
  const videoUrls = getHelpVideos(user?.roles ?? []);

  if (videoUrls.length === 0) {
    return (
      <div
        className={`grid grid-cols-1 gap-6 px-6 pb-6 pt-3 shadow-2xl rounded-xl border-tttDefault border-[1px]`}
      >
        <div
          className={`grid justify-self-center max-w-[90%] justify-items-center items-center text-center overflow-hidden uppercase text-white font-bold md:text-xl lg:text-2xl p-3 -mt-7 shadow-md rounded-xl bg-tttDefault`}
        >
          Help
        </div>
        <div
          className={`grid grid-flow-row auto-cols-auto gap-6 justify-items-center w-full`}
        >
          <div>
            If you have any questions, feel free to&nbsp;
            <a
              href="mailto:help@thinktankprep.com?subject=Fill The Math GAPS"
              target="_blank"
              className="text-tttDefault hover:underline"
              rel="noreferrer"
            >
              reach out to us
            </a>
            .
          </div>
        </div>
      </div>
    );
  }

  let videoWidth = 270;
  if (window.innerWidth >= 480 && window.innerWidth < 640) {
    videoWidth = 360;
  } else if (window.innerWidth >= 640 && window.innerWidth < 768) {
    videoWidth = 440;
  } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
    videoWidth = 500;
  } else if (window.innerWidth >= 1024 && window.innerWidth < 1280) {
    videoWidth = 560;
  } else if (window.innerWidth >= 1280) {
    videoWidth = 660;
  }

  return (
    <div
      className={`grid grid-cols-1 gap-6 px-6 pb-6 pt-3 shadow-2xl rounded-xl border-tttDefault border-[1px]`}
    >
      <div
        className={`grid justify-self-center max-w-[90%] justify-items-center items-center text-center overflow-hidden uppercase text-white font-bold md:text-xl lg:text-2xl p-3 -mt-7 shadow-md rounded-xl bg-tttDefault`}
      >
        Help
      </div>
      <div
        className={`grid grid-flow-row auto-cols-auto gap-6 justify-items-center w-full`}
      >
        <div>
          Please watch the following video to learn more about how to use the
          Fill the Math GAPS program.
          <br />
          If you have further questions, feel free to&nbsp;
          <a
            href="mailto:help@thinktankprep.com?subject=Fill The Math GAPS"
            target="_blank"
            className="text-tttDefault hover:underline"
            rel="noreferrer"
          >
            reach out to us
          </a>
          .
        </div>
        {videoUrls.length > 0 &&
          videoUrls.map((videoUrl, index) => (
            <div
              key={index}
              className="grid grid-flow-row justify-items-center bg-gray-100 rounded-xl p-3 gap-4"
            >
              <Vimeo
                video={videoUrl}
                controls={true}
                speed={true}
                key={videoUrl}
                width={videoWidth}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

const Help = observer(BaseHelp);
export default Help;

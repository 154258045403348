import { FC, useEffect, useState } from 'react';

import { FILTERS, FilterType } from '../constants';
import { ClassResultOverview, ClassTotal, ClassQuizResult } from '../types';

import useFilteredResults from '../hooks/useFilteredResults';

import BreakoutCircular from '../components/BreakoutCircular';
import Card from '../components/Card';
import Filter, { FilterValue } from '../components/Filter';
import { Loading } from '../components/Loading';
import QuizSelectorFilter from '../components/QuizSelectorFilter';

interface Properties {
  circularOnSelect: (period: string) => void;
  circularOnHover: (period: string) => void;
  circularSelected?: string;
  classResultOverview?: ClassResultOverview;
  classTotals?: ClassTotal[];
  isRefreshing: boolean;
  isQuizFilter: boolean;
  setIsQuizFilter: (isQuizFilter: boolean) => void;
  filter: FilterValue | null;
  setFilter: (newFilter: FilterValue | null) => void;
  shouldShowTeacherOverview: boolean;
  title: string;
}

const TeacherClassesHeader: FC<Properties> = ({
  circularOnSelect,
  circularOnHover,
  circularSelected,
  classResultOverview,
  classTotals,
  isRefreshing,
  isQuizFilter,
  setIsQuizFilter,
  filter,
  setFilter,
  shouldShowTeacherOverview,
  title,
}) => {
  const [classQuizResult, setClassQuizResult] = useState<ClassQuizResult>();

  const shouldShowSubmissionRate = !!(
    classResultOverview?.submissionRates &&
    classResultOverview?.submissionRates[filter?.value]
  );

  const displayedClassTotals = useFilteredResults({
    filter: filter?.value,
    result: classTotals,
    label: 'class',
    sortBy: 'label',
  });

  const filterOptions: FilterValue[] = [
    { label: 'Cumulative score', value: FILTERS.overall },
    { label: 'Review score', value: FILTERS.reviewOverall },
  ];

  useEffect(() => {
    if (isQuizFilter) {
      const relevantResult = classResultOverview?.quizResults?.find(
        (result) => result.quiz === filter?.value,
      );
      setClassQuizResult(relevantResult);
      setIsQuizFilter(true);
    }
  }, [filter, isQuizFilter, classResultOverview]);

  return (
    <div
      data-testid={'heading-section'}
      className="grid grid-cols-2 lg:grid-cols-4 gap-6 px-6 pb-6 pt-3 shadow-xl rounded-xl border-tttDefault border-[1px]"
    >
      <div
        data-testid={'teacher-classes-title'}
        className="grid justify-self-center text-center col-span-2 lg:col-span-4 max-w-[90%] justify-items-center items-center overflow-hidden uppercase text-white font-bold md:text-xl lg:text-2xl p-3 -mt-7 shadow-md rounded-xl bg-tttDefault"
      >
        {title}
      </div>
      <div className="absolute right-8">{isRefreshing && <Loading />}</div>
      {shouldShowTeacherOverview && classResultOverview ? (
        <>
          <div className="grid grid-cols-2 row-start-2 row-span-1 col-start-1 col-span-2 lg:col-start-1 lg:col-end-1 lg:grid-cols-1 lg:row-span-2 justify-items-center items-start gap-3">
            <div className="box w-[80%] capitalize">
              <Card
                id="cumulative-card"
                title="Cumulative"
                body={
                  classResultOverview?.overallResult
                    ? classResultOverview.overallResult * 100
                    : undefined
                }
              />
            </div>
            <div className="box w-[80%] capitalize">
              <Card
                id="review-card"
                title="Review Score"
                body={
                  classResultOverview.reviewOverall
                    ? classResultOverview.reviewOverall * 100
                    : undefined
                }
              />
            </div>
            {isQuizFilter && (
              <div className="box w-[80%] capitalize">
                <Card
                  id="quiz-card"
                  title={filter?.value}
                  body={
                    classQuizResult?.result
                      ? classQuizResult.result * 100
                      : undefined
                  }
                />
              </div>
            )}
            {shouldShowSubmissionRate && (
              <div className="box w-[80%] capitalize">
                <Card
                  id="submission-rate-card"
                  title={`Submission rate - ${filter?.value}`}
                  body={
                    classResultOverview.submissionRates
                      ? classResultOverview.submissionRates[filter?.value] * 100
                      : 0
                  }
                  tooltipText={`${filter?.value} submission rate for the selected class.`}
                />
              </div>
            )}
          </div>
          <div className="box grid overflow-hidden row-start-3 lg:row-start-2 lg:row-span-2 col-start-1 lg:col-start-2 col-span-2 lg:col-span-3 font-medium justify-items-center">
            <div className="grid md:grid-cols-[2fr,1fr] items-center w-[95%] m-1 gap-3 md:gap-0 h-min">
              <Filter
                filterType={FilterType.quizOrReviewOrOverall}
                options={filterOptions}
                selected={filter as FilterValue}
                onSelect={(selectedFilter: FilterValue) => {
                  setIsQuizFilter(false);
                  setFilter(selectedFilter);
                }}
              />
              <QuizSelectorFilter
                isActive={isQuizFilter}
                selectFn={(selected) => {
                  setIsQuizFilter(true);
                  setFilter(selected);
                }}
                // eslint-disable-next-line unicorn/no-null
                value={isQuizFilter ? filter : null}
                selectOptions={classResultOverview.quizResults?.map((r) => ({
                  value: r.quiz,
                  label: r.quiz,
                }))}
              />
            </div>
            {displayedClassTotals && displayedClassTotals.length > 0 ? (
              <div className="grid grid-flow-row gap-12 p-6 auto-cols-auto grid-cols-2 md:grid-cols-3 justify-center font-bold capitalize md:text-xl w-full">
                <BreakoutCircular
                  data={displayedClassTotals}
                  labelPrefix={'Period '}
                  selected={circularSelected}
                  isSelectable={true}
                  onSelect={circularOnSelect}
                  onHover={circularOnHover}
                />
              </div>
            ) : (
              <div className="justify-self-center text-center p-6 font-medium text-sm md:text-base text-gray-500">
                The selected class does not have any results available.
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="col-span-2 lg:col-span-4 justify-self-center text-center p-6 font-medium text-sm md:text-base text-gray-500">
          Your classes don't have any results available.
        </div>
      )}
    </div>
  );
};

export default TeacherClassesHeader;

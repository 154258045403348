import { useEffect, useState } from 'react';
import { EyeIcon, EyeOffIcon, XIcon } from '@heroicons/react/outline';
import Select from 'react-select';
import { MathJax } from 'better-react-mathjax';
import { Transition } from '@headlessui/react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { ClipLoader } from 'react-spinners';
import { SaveIcon } from 'lucide-react';

import { TargetedPracticeProblemDocument } from '../types';

interface ProblemEditorModalProperties {
  isVisible: boolean;
  skills: {
    _id: string;
    skill: string;
    subject: string;
    isAvailableForTargetedPractice?: boolean;
  }[];
  onClose: () => void;
  onSubmit: (
    data: Omit<
      TargetedPracticeProblemDocument,
      'id' | 'difficulty' | 'subject' | 'additionalImages'
    >,
  ) => Promise<void>;
}

const ProblemEditorModal = ({
  isVisible,
  skills,
  onClose,
  onSubmit,
}: ProblemEditorModalProperties) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [skillOptions, setSkillOptions] = useState<
    {
      _id: string;
      skill: string;
      subject: string;
      isAvailableForTargetedPractice?: boolean;
    }[]
  >([]);
  const {
    control,
    getValues,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<
    Omit<
      TargetedPracticeProblemDocument,
      'id' | 'difficulty' | 'subject' | 'additionalImages' | 'answers'
    > & { answers: { value: string }[] }
  >({
    defaultValues: {
      question: '',
      answers: Array.from({ length: 5 }).fill({ value: '' }) as {
        value: string;
      }[],
      correctAnswerKey: '',
      skills: [],
      writtenSolution: '',
    },
  });
  const { fields } = useFieldArray({ control, name: 'answers' });

  const answerKeys = ['A', 'B', 'C', 'D', 'E'];

  const handleDiscard = () => {
    // TODO save as draft
    setIsPreviewing(false);
    reset();
    onClose();
  };

  const handleCreate = async (
    data: Omit<
      TargetedPracticeProblemDocument,
      'id' | 'difficulty' | 'subject' | 'additionalImages' | 'answers'
    > & { answers: { value: string }[] },
  ) => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    try {
      const answers = data.answers
        .map((answer) => answer.value ?? undefined)
        .filter(Boolean) as string[];
      await onSubmit({ ...data, answers });
    } catch {
      return;
    } finally {
      setIsSubmitting(false);
    }
    setIsPreviewing(false);
    reset();
  };

  useEffect(() => {
    setSkillOptions(skills.filter((s) => s.isAvailableForTargetedPractice));
  }, [skills]);

  return (
    <Transition
      data-testid="add-problem-modal"
      show={isVisible}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className="flex flex-col gap-2 p-6 w-full items-center shadow-xl rounded-xl border-dashed border-tttDefault border-[1px]"
    >
      <div className="flex w-full justify-between items-center">
        <p className="italic text-gray-400">Draft</p>
        <button
          data-testid={`discard-button`}
          type="button"
          className="
            px-2 py-1 w-max
            flex gap-2 items-center
            text-red bg-rose-300 rounded-xl
            hover:shadow-md transition-all duration-300 ease-in-out hover:outline hover:outline-red hover:outline-[1px]
            disabled:opacity-50 disabled:cursor-not-allowed
          "
          disabled={isSubmitting}
          onClick={handleDiscard}
        >
          Discard
          <XIcon className="h-5 w-5" />
        </button>
      </div>

      <form onSubmit={handleSubmit(handleCreate)} className="w-full">
        {/* Question */}
        <div className="flex flex-col gap-2 w-full pb-2">
          <label className="text-xl font-bold">Question</label>
          {isPreviewing || isSubmitting ? (
            getValues('question') ? (
              <MathJax>{getValues('question')}</MathJax>
            ) : (
              <p className="w-full text-gray-500">No question provided yet.</p>
            )
          ) : (
            <textarea
              className="w-full h-24 p-2 border-[1px] border-tttDefault rounded"
              {...register('question', {
                required: true,
                minLength: 5,
                maxLength: 500,
              })}
              placeholder="Enter question here..."
            />
          )}
          {errors.question && (
            <p className="text-sm text-rose-600">
              {errors.question.type === 'required'
                ? 'Question is required.'
                : errors.question.type === 'minLength'
                ? 'Question must be at least 5 characters long.'
                : 'Question must be at most 500 characters long.'}
            </p>
          )}
        </div>

        <div className="grid grid-cols-2 gap-6 w-full py-2">
          {/* Answers */}
          <div className="flex flex-col gap-4 justify-self-start w-full">
            {fields.map((_, index) => (
              <div
                key={`answer-${index}`}
                data-testid={`answer-${index}`}
                className={`grid grid-cols-[auto_1fr] gap-3 justify-items-start items-center`}
              >
                <Controller
                  render={({ field }) => (
                    <p
                      data-testid={'answerKey'}
                      className={`w-10 h-10 font-bold text-xl text-center py-1
                        ${
                          !isSubmitting &&
                          `hover:border-2 hover:rounded-full hover:border-emerald-500 cursor-pointer`
                        }
                        ${
                          field.value === answerKeys[index] &&
                          'bg-emerald-500 rounded-full text-white'
                        }`}
                      onClick={() => field.onChange(answerKeys[index])}
                    >
                      {answerKeys[index]}
                    </p>
                  )}
                  name={'correctAnswerKey'}
                  control={control}
                  rules={{ required: true }}
                />

                {isPreviewing || isSubmitting ? (
                  getValues('answers')[index].value ? (
                    <MathJax>{getValues('answers')[index].value}</MathJax>
                  ) : (
                    <p className="w-full text-base text-gray-500">
                      No answer {answerKeys[index]} provided yet.
                    </p>
                  )
                ) : (
                  <Controller
                    render={({ field }) => (
                      <input
                        {...field}
                        className="w-full p-1 text-base border-[1px] border-tttDefault rounded"
                        placeholder={`Enter answer ${answerKeys[index]} here...`}
                      />
                    )}
                    name={`answers.${index}.value`}
                    control={control}
                    rules={{ required: true }}
                  />
                )}
              </div>
            ))}
            {errors.answers && (
              <p className="text-sm text-rose-600">Answers are required.</p>
            )}
            {errors.correctAnswerKey && (
              <p className="text-sm text-rose-600">
                Correct answer key selection is required.
              </p>
            )}
          </div>

          {/* Skills */}
          <div>
            <label className="text-xl font-bold">Skills</label>
            <Controller
              name="skills"
              control={control}
              render={({ field }) => (
                <Select
                  onChange={(selectedSkills) => {
                    const newSkills = skillOptions?.filter((s) =>
                      selectedSkills?.some(
                        (selectedSkill) => selectedSkill.value === s._id,
                      ),
                    );
                    field.onChange(newSkills.map((s) => s._id));
                  }}
                  isMulti
                  isOptionDisabled={() => field.value.length > 0}
                  placeholder={'Select skills...'}
                  options={skillOptions?.map((s) => ({
                    value: s._id,
                    label: s.skill,
                  }))}
                  isDisabled={isPreviewing || isSubmitting}
                  className={`w-full my-2 rounded ${
                    !isPreviewing && 'border-[1px] border-tttDefault'
                  }`}
                />
              )}
              rules={{ required: true }}
            />
            {errors.skills && (
              <p className="text-sm text-rose-600">Skill is required.</p>
            )}
          </div>
        </div>

        {/* Written Solution */}
        <div className="flex flex-col gap-2 w-full py-2">
          <label className="text-xl font-bold">Written Solution</label>
          {isPreviewing || isSubmitting ? (
            getValues('writtenSolution') ? (
              <MathJax>{getValues('writtenSolution')}</MathJax>
            ) : (
              <p className="w-full text-gray-500">
                No written solution provided yet.
              </p>
            )
          ) : (
            <textarea
              className="w-full h-24 p-2 border-[1px] border-tttDefault rounded"
              placeholder="Enter the written solution here..."
              {...register('writtenSolution', {
                required: true,
                minLength: 5,
                maxLength: 500,
              })}
            />
          )}
          {errors.writtenSolution && (
            <p className="text-sm text-rose-600">
              {errors.writtenSolution.type === 'required'
                ? 'Written solution is required.'
                : errors.writtenSolution.type === 'minLength'
                ? 'Written solution must be at least 5 characters long.'
                : 'Written solution must be at most 500 characters long.'}
            </p>
          )}
        </div>

        {/* Action Buttons */}
        <div className="flex gap-2 w-full justify-end items-center">
          <button
            data-testid={`preview-button`}
            type="button"
            className={`
              px-2 py-1 w-max
              flex gap-2 items-center
              text-tttDefault bg-blue-200 rounded-xl
              transition-all duration-300 ease-in-out
              hover:shadow-md hover:outline hover:outline-tttDefault hover:outline-[1px]
              disabled:opacity-50 disabled:cursor-not-allowed
              ${
                isPreviewing &&
                'shadow-md outline outline-tttDefault outline-[1px]'
              }
            `}
            disabled={isSubmitting}
            onClick={() => setIsPreviewing((previous) => !previous)}
          >
            Preview
            {isPreviewing || isSubmitting ? (
              <EyeOffIcon className="h-5 w-5" />
            ) : (
              <EyeIcon className="h-5 w-5" />
            )}
          </button>
          <button
            data-testid={`save-button`}
            type="submit"
            className="
              px-2 py-1 w-max
              flex gap-2 items-center
              text-emerald-800 bg-emerald-200 rounded-xl
              hover:shadow-md transition-all duration-300 ease-in-out hover:outline hover:outline-emerald-800 hover:outline-[1px]
              disabled:opacity-50 disabled:cursor-not-allowed
            "
            disabled={isSubmitting}
          >
            Save
            {isSubmitting ? (
              <ClipLoader color="#065F46" size={20} />
            ) : (
              <SaveIcon className="h-5 w-5" />
            )}
          </button>
        </div>
      </form>
    </Transition>
  );
};

export default ProblemEditorModal;

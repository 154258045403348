import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';

import { Loading } from '../Loading';

import { useGetTracksForSignupQuery } from '../../services/apiSlice';
import {
  selectThinkerFirstNames,
  selectTrackGroup,
  setThinkerTrack,
} from '../../features/signUp/signUpSlice';
import { store, useAppSelector } from '../../stores/AppStore';
import { SignupTrack, TrackGroup } from '../../types';
import { trackRecommendationInfoTexts } from '../../constants';

import Option from './Option';
import ProgressBar from './ProgressBar';
import toast from 'react-hot-toast';

interface TrackRecommendationProperties {
  onNextStep: () => void;
  onPreviousStep: () => void;
  licenseIndex: number;
}

const TrackRecommendation = ({
  onNextStep,
  onPreviousStep,
  licenseIndex,
}: TrackRecommendationProperties) => {
  const [step, setStep] = useState(1); // track recommendation sub-steps
  const [previousIndex, setPreviousIndex] = useState(1);
  const [selectedGradeLevel, setSelectedGradeLevel] = useState<SignupTrack>();
  const [selectedTrack, setSelectedTrack] = useState<SignupTrack>();
  const trackGroup = useAppSelector(selectTrackGroup);
  const [recommendedTracks, setRecommendedTracks] =
    useState<(SignupTrack & { recommended?: boolean; info: string })[]>();
  const [selectedSkillLevel, setSelectedSkillLevel] = useState<{
    text: string;
    value: number;
  }>();
  const { data: tracks, isFetching: isFetchingTracks } =
    useGetTracksForSignupQuery();

  const handlePreviousStep = () => {
    if (step === 1) {
      onPreviousStep();
      return;
    }
    setStep(step - 1); // track recommendation sub-steps
  };

  const handleNextStep = () => {
    if (trackGroup === TrackGroup.ACTSATPrep) {
      if (!tracks) {
        Sentry.captureException(new Error('Failed to select track'), {
          tags: {
            step: 'Track recommendation',
            function: 'handleNextStep',
            tracks: typeof tracks,
          },
        });
        toast.error(
          'Failed to select track. Please contact us at help@thinktankprep.com',
        );
        return;
      }
      const ACTTrack = tracks.highSchoolTracks.find(
        (t) => t.serialNum === '301',
      );
      if (!ACTTrack) {
        Sentry.captureException(new Error('Failed to set ACT track'), {
          tags: {
            step: 'Track recommendation',
            function: 'handleNextStep',
            tracks: typeof tracks,
          },
        });
        toast.error(
          'Failed to set ACT track. Please contact us at help@thinktankprep.com',
        );
        return;
      }
      store.dispatch(
        setThinkerTrack({ index: licenseIndex, trackId: ACTTrack._id }),
      );
      onNextStep();
      return;
    }
    if (step === 3) {
      if (!selectedTrack) {
        return;
      }
      store.dispatch(
        setThinkerTrack({ index: licenseIndex, trackId: selectedTrack._id }),
      );
      onNextStep();
      return;
    }
    setStep(step + 1); // track recommendation sub-steps
  };

  const renderStep = () => {
    if (trackGroup === TrackGroup.ACTSATPrep) {
      if (!tracks) {
        return <></>;
      }
      const ACTTrack = tracks.highSchoolTracks.find(
        (t) => t.serialNum === '301',
      );
      if (ACTTrack) {
        return (
          <RecommendedTracks
            licenseIndex={licenseIndex}
            selectedGradeLevel={ACTTrack}
            selectedSkillLevel={{
              text: "Has some skill gaps. Let's reinforce!",
              value: 0,
            }}
            tracks={[
              { ...ACTTrack, recommended: true, info: 'Be well prepared!' },
            ]}
            selectedTrack={ACTTrack}
            setSelectedTrack={setSelectedTrack}
          />
        );
      }
    }
    switch (step) {
      case 1: {
        return (
          <CurrentGradeLevel
            licenseIndex={licenseIndex}
            tracks={tracks}
            selectedGradeLevel={selectedGradeLevel}
            setSelectedGradeLevel={setSelectedGradeLevel}
          />
        );
      }
      case 2: {
        return (
          <SkillLevel
            licenseIndex={licenseIndex}
            selectedSkillLevel={selectedSkillLevel}
            setSelectedSkillLevel={setSelectedSkillLevel}
          />
        );
      }
      case 3: {
        return (
          <RecommendedTracks
            licenseIndex={licenseIndex}
            selectedGradeLevel={selectedGradeLevel}
            selectedSkillLevel={selectedSkillLevel}
            tracks={recommendedTracks}
            selectedTrack={selectedTrack}
            setSelectedTrack={setSelectedTrack}
          />
        );
      }
      default: {
        return <></>;
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setPreviousIndex(step);
    }, 500);
  }, [step]);

  useEffect(() => {
    if (selectedGradeLevel && selectedSkillLevel && tracks) {
      const allTracks = [
        ...tracks.middleSchoolTracks,
        ...tracks.highSchoolTracks,
      ];
      const selectedTrackIndex = allTracks.findIndex(
        (track) => track._id === selectedGradeLevel._id,
      );
      if (selectedTrackIndex === -1) {
        return;
      }
      switch (selectedSkillLevel.value) {
        case 0: {
          setSelectedTrack(allTracks[selectedTrackIndex]);
          const start = Math.max(0, selectedTrackIndex - 1); // one lower from selected
          const end = selectedTrackIndex + 2; // upto selected plus 1 higher
          const recommendedTracks = allTracks
            .slice(start, end)
            .map((track, index) => {
              return {
                ...track,
                recommended: track._id === allTracks[selectedTrackIndex]._id,
                info: trackRecommendationInfoTexts[index],
              };
            });
          setRecommendedTracks(recommendedTracks);
          break;
        }
        case -1: {
          const recommendation =
            selectedTrackIndex > 0
              ? allTracks[selectedTrackIndex - 1]
              : allTracks[0];
          setSelectedTrack(recommendation);
          const start = Math.max(0, selectedTrackIndex - 1); // one lower from selected
          const end = selectedTrackIndex + 1; // upto selected
          const recommendedTracks = allTracks
            .slice(start, end)
            .map((track, index) => {
              return {
                ...track,
                recommended: track._id === recommendation._id,
                info: trackRecommendationInfoTexts[index],
              };
            });
          setRecommendedTracks(recommendedTracks);
          break;
        }
        case 1: {
          const recommendation =
            selectedTrackIndex < allTracks.length - 1
              ? allTracks[selectedTrackIndex + 1]
              : allTracks[allTracks.length - 1];
          setSelectedTrack(recommendation);
          const start = Math.max(0, selectedTrackIndex); // from selected
          const end = selectedTrackIndex + 2; // upto selected plus 1 higher
          const recommendedTracks = allTracks
            .slice(start, end)
            .map((track, index) => {
              return {
                ...track,
                recommended: track._id === recommendation._id,
                info: trackRecommendationInfoTexts.slice(1)[index],
              };
            });
          setRecommendedTracks(recommendedTracks);
          break;
        }
      }
    }
  }, [selectedGradeLevel, selectedSkillLevel]);

  if (isFetchingTracks) {
    return (
      <div className="w-full text-center p-4">
        <Loading />
      </div>
    );
  }

  const didSelectedTrack =
    selectedGradeLevel || trackGroup === TrackGroup.ACTSATPrep;

  return (
    <div className="flex flex-col w-full h-full">
      <div
        className={`flex flex-col gap-4 md:gap-6 w-full md:w-[70%] lg:w-[60%] mx-auto  ${
          previousIndex < step
            ? 'animate-slideInFromRight'
            : previousIndex > step
            ? 'animate-slideInFromLeft'
            : ''
        }`}
      >
        {renderStep()}
      </div>

      <ProgressBar />

      {/* Action Buttons */}
      <div className="flex gap-2 pt-4 md:pt-6 w-full justify-between items-center mt-auto">
        <button
          data-testid={`back-button`}
          type="button"
          className="
            px-10 py-2 w-max rounded-full border font-medium
            transition-all duration-300 ease-in-out
            hover:shadow hover:outline hover:outline-tttDefault hover:outline-[1px]
            disabled:opacity-50 disabled:cursor-not-allowed
          "
          onClick={handlePreviousStep}
        >
          Back
        </button>
        <button
          data-testid={`continue-button`}
          onClick={handleNextStep}
          disabled={isFetchingTracks || !didSelectedTrack}
          className="
            px-10 py-2 w-max rounded-full
            font-medium text-white bg-tttDefault
            transition-all duration-300 ease-in-out
            hover:shadow hover:bg-opacity-90
            disabled:opacity-50 disabled:cursor-not-allowed
          "
        >
          Next
        </button>
      </div>
    </div>
  );
};

interface CurrentGradeLevelProperties {
  licenseIndex: number;
  tracks?: {
    middleSchoolTracks: SignupTrack[];
    highSchoolTracks: SignupTrack[];
  };
  selectedGradeLevel?: SignupTrack;
  setSelectedGradeLevel: (track: SignupTrack) => void;
}

const CurrentGradeLevel = ({
  licenseIndex,
  tracks,
  selectedGradeLevel,
  setSelectedGradeLevel,
}: CurrentGradeLevelProperties) => {
  const thinkerFirstNames = useAppSelector(selectThinkerFirstNames);
  return (
    <>
      <h1 className="text-3xl md:text-4xl font-medium w-full">
        What is {thinkerFirstNames[licenseIndex] ?? 'your Thinker'}'s current
        grade level / math class?
      </h1>
      <div className="flex flex-col gap-2 md:gap-4">
        <label className="font-medium text-lg md:text-xl">Middle School</label>
        <div
          data-testid="middle-school-grade-levels"
          className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4"
        >
          {tracks?.middleSchoolTracks?.map((track, index) => (
            <Option
              key={index}
              dataTestId={`middleSchoolTrack-${index}`}
              isSelected={selectedGradeLevel?._id === track._id}
              onClick={() => setSelectedGradeLevel(track)}
              value={track._id}
            >
              <p className="font-medium">{track.track}</p>
            </Option>
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-2 md:gap-4">
        <label className="font-medium text-lg md:text-xl">High School</label>
        <div
          data-testid="high-school-grade-levels"
          className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4"
        >
          {tracks?.highSchoolTracks?.map((track, index) => (
            <Option
              key={index}
              dataTestId={`highSchoolTrack-${index}`}
              isSelected={selectedGradeLevel?._id === track._id}
              onClick={() => setSelectedGradeLevel(track)}
              value={track._id}
            >
              <p className="font-medium">{track.track}</p>
            </Option>
          ))}
        </div>
      </div>
    </>
  );
};

interface SkillLevelProperties {
  licenseIndex: number;
  selectedSkillLevel?: {
    text: string;
    value: number;
  };
  setSelectedSkillLevel: (level: { text: string; value: number }) => void;
}

const SkillLevel = ({
  licenseIndex,
  selectedSkillLevel,
  setSelectedSkillLevel,
}: SkillLevelProperties) => {
  const skillLevels = [
    { text: "Has very few skill gaps. Let's have a challenge!", value: 1 },
    { text: "Has some skill gaps. Let's reinforce!", value: 0 },
    { text: "Has a lot of gaps. Let's re-teach!", value: -1 },
  ];
  const thinkerFirstNames = useAppSelector(selectThinkerFirstNames);

  return (
    <>
      <h1
        data-testid="skill-level-title"
        className="text-3xl md:text-4xl font-medium w-full"
      >
        How does {thinkerFirstNames[licenseIndex] ?? 'your Thinker'} do with
        foundational skills from previous levels?
      </h1>
      <div data-testid="skill-levels" className="flex flex-col gap-2 md:gap-4">
        {skillLevels.map((level, index) => (
          <Option
            key={index}
            dataTestId={`level-${index}`}
            isSelected={selectedSkillLevel?.value === level.value}
            onClick={() => setSelectedSkillLevel(level)}
            value={level.value}
          >
            <p className="font-medium"> {level.text}</p>
            <img
              src={
                level.value === 1
                  ? '/images/icons/level3.svg'
                  : level.value === 0
                  ? '/images/icons/level2.svg'
                  : '/images/icons/level1.svg'
              }
              alt="level-icon"
              className="w-5 h-5 ml-auto mr-1"
            />
          </Option>
        ))}
      </div>
    </>
  );
};

interface RecommendedTracksProperties {
  licenseIndex: number;
  tracks?: (SignupTrack & { recommended?: boolean; info: string })[];
  selectedGradeLevel?: SignupTrack;
  selectedSkillLevel?: {
    text: string;
    value: number;
  };
  selectedTrack?: SignupTrack;
  setSelectedTrack: (track: SignupTrack) => void;
}

const RecommendedTracks = ({
  licenseIndex,
  tracks,
  selectedGradeLevel,
  selectedSkillLevel,
  selectedTrack,
  setSelectedTrack,
}: RecommendedTracksProperties) => {
  const thinkerFirstNames = useAppSelector(selectThinkerFirstNames);
  return (
    <>
      <h1 className="text-3xl md:text-4xl font-medium w-full">
        Recommendations
      </h1>
      <p className="text-gray-500">
        You can proceed with our recommendation or choose a different track
        based on your {thinkerFirstNames[licenseIndex] ?? 'your Thinker'}’s
        current grade level.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 bg-gray-100 rounded-xl p-4">
        <div className="flex gap-2">
          <img
            src="/images/icons/star.svg"
            alt="star-icon"
            className="text-tttDefault w-5 h-5 pt-1"
          />
          <div>
            <p className="text-gray-500">Current grade level</p>
            <p className="text-gray-800">{selectedGradeLevel?.track}</p>
          </div>
        </div>
        <div className="flex gap-2">
          <img
            src="/images/icons/level.svg"
            alt="level-icon"
            className="text-tttDefault w-5 h-5 pt-1"
          />
          <div>
            <p className="text-gray-500">Foundational skill level</p>
            <p className="text-gray-800">{selectedSkillLevel?.text}</p>
          </div>
        </div>
      </div>
      <div data-testid="recommended-tracks" className="flex flex-col gap-4">
        {tracks?.map((track, index) => (
          <Option
            key={index}
            dataTestId={`track-${index}`}
            isSelected={track._id === selectedTrack?._id}
            onClick={() => setSelectedTrack(track)}
            value={track._id}
          >
            <div className="flex flex-col md:flex-row items-start md:items-center gap-1 md:gap-3 w-full">
              <div className="flex gap-4 items-center">
                <p className="font-medium"> {track.track}</p>
                {track.recommended && (
                  <p className="rounded-full bg-blue-200 text-tttDefault text-xs md:text-sm py-1 px-3 uppercase">
                    Recommended
                  </p>
                )}
              </div>
              <p className="md:text-right text-sm text-gray-500 md:ml-auto">
                {track.info}
              </p>
            </div>
          </Option>
        ))}
      </div>
    </>
  );
};

export default TrackRecommendation;

import { FC } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  BarElement,
  ScatterController,
  Align,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import chartTrendline from 'chartjs-plugin-trendline';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';

import { ChartData } from '../types';
import { LoadingBlock } from './Loading';

const plugin = {
  id: 'increase-legend-spacing',
  beforeInit(chart: any) {
    // Get reference to the original fit function
    const originalFit = chart.legend.fit;

    // Override the fit function
    chart.legend.fit = function fit() {
      // Call original function and bind scope in order to use `this` correctly inside it
      originalFit.bind(chart.legend)();
      // Change the height as suggested in another answers
      this.height += 30;
    };
  },
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  ScatterController,
  LineElement,
  PointElement,
  BarElement,
  Tooltip,
  Legend,
  chartTrendline,
);

interface ChartProperties {
  data?: ChartData;
  title?: string;
  styledTitle?: string;
  emptyText?: string;
  baseType?: 'line' | 'bar';
  loading?: boolean;
  id?: string;
  areDatapointsClickable?: boolean;
}

const Chart: FC<ChartProperties> = ({
  id,
  baseType,
  data,
  loading,
  title,
  styledTitle,
  emptyText,
  areDatapointsClickable,
}) => {
  const navigate = useNavigate();
  const options = {
    maintainAspectRatio: true,
    elements: {
      line: { tension: 0.2 },
      point: { radius: 6 },
    },
    clip: false,
    responsive: true,
    spanGaps: true,
    scales: {
      xAxis: {
        ticks: {
          font: function (context: any) {
            if (/review/i.test(context.tick.label)) {
              return { weight: 'bold' };
            }
            return { weight: 'normal' };
          },
          color: function (context: any) {
            if (/current/i.test(context.tick.label)) {
              return '#E47D00';
            }
            if (/review/i.test(context.tick.label)) {
              return '#0069D9';
            }
            return '#000000';
          },
        },
      },
      y: {
        max: 100,
        min: 0,
        ticks: {
          stepSize: 10,
        },
      },
    },
    layout: {
      padding: {
        top: 15,
      },
    },
    onClick: (event: any, itemsClicked: any[]) => {
      if (!areDatapointsClickable) {
        return;
      }
      const quizIndex =
        Array.isArray(itemsClicked) && itemsClicked.length > 0
          ? itemsClicked[0].index
          : undefined;
      if (Number.isFinite(quizIndex) && data) {
        setTimeout(() => {
          navigate(`/assignments/scores/${data.labels[quizIndex]}`);
        }, 500);
      }
    },
    plugins: {
      tooltip: {
        events: ['click'],
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += ' ' + context.parsed.y + ' %';
            }
            return label;
          },
        },
      },
      legend: {
        display: data?.datasets.every((dataset) => !!dataset.label),
        align: 'start' as Align,
        labels: { boxWidth: 12, boxHeight: 12 },
      },
    },
  };

  if (!data || data.datasets.every((dataset) => dataset.data.length === 0)) {
    return (
      <div
        data-testid={id}
        id={id}
        className="flex flex-col gap-2 items-center text-center rounded-xl bg-white p-4 md:p-6"
      >
        <img
          src="/images/icons/chart-icon.svg"
          alt="Chart Icon"
          className="w-24 md:w-28 p-2"
        />
        {styledTitle ??
          (title ? <h1 className="font-medium text-xl">{title}</h1> : <></>)}
        <p className="text-gray-500 md:max-w-[70%]">
          {emptyText ?? 'No data available.'}
        </p>
      </div>
    );
  }

  for (const dataset of data.datasets) {
    if (dataset.data.every((dataPoint) => typeof dataPoint !== 'number')) {
      Sentry.captureException(
        new Error(
          `Chart data was empty: ${dataset.label} of ${styledTitle ?? title}`,
        ),
      );
      return (
        <div className="w-full text-center text-slate-400">
          Sorry, chart data is incomplete. Please try again later.
        </div>
      );
    }
  }

  return (
    <div
      data-testid={id}
      id={id}
      className="grid grid-cols-1 p-4 md:p-6 lg:p-8 rounded-xl bg-white"
    >
      {styledTitle && (
        <div
          className={`grid justify-self-center max-w-[90%] justify-items-center items-center text-center overflow-hidden uppercase text-white font-bold md:text-xl lg:text-2xl p-3 -mt-10 shadow-md rounded-xl bg-tttDefault`}
        >
          {styledTitle}
        </div>
      )}
      {title && <h2 className="text-2xl font-medium capitalize">{title}</h2>}
      {loading ? (
        <LoadingBlock width={1} height={32} />
      ) : (
        <div className="box">
          {!baseType || baseType === 'line' ? (
            <Line
              plugins={[plugin, chartTrendline]}
              data={data}
              options={options}
            />
          ) : (
            <Bar
              plugins={[plugin, chartTrendline]}
              data={data}
              options={options}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Chart;

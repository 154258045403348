import { DeepRequired, FieldErrorsImpl } from 'react-hook-form';

export function FieldError({
  errors,
  field,
  fieldName,
}: {
  errors: FieldErrorsImpl<
    DeepRequired<{
      [x: string]: string;
    }>
  >;
  field: string;
  fieldName: string;
}) {
  let message = '';
  switch (errors[field]?.type) {
    case 'required': {
      message = `${fieldName} is required`;
      break;
    }
    case 'minLength': {
      message = `${fieldName} is too short`;
      break;
    }
    case 'maxLength': {
      message = `${fieldName} is too long`;
      break;
    }
    case 'pattern': {
      message = `${fieldName} doesn't seem valid, please check it again`;
      break;
    }
  }
  if (!message) {
    return <></>;
  }
  return <p className="text-sm text-rose-600">{message}</p>;
}
